import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import FullCalendar from "@fullcalendar/react";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import intearctionPlugin from "@fullcalendar/interaction";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import Switch from "react-switch";
// import Switch from "react-input-switch";
// import Select from "react-select";
import {
  Container,
  Card,
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  Spinner,
  Breadcrumb,
} from "react-bootstrap";
import "../../assets/css/App.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useContext } from "react";
import NotyfContext from "../../contexts/NotyfContext";
import useSidebar from "../../hooks/useSidebar";
import { Plus, X } from "react-feather";
import Newschedulemodal from "../../components/Newschedulemodal";
import Editschedulemodal from "../../components/Editschedulemodal";
import mixpanel from "mixpanel-browser";
import useAuth from "../../hooks/useAuth";
import PermissionRequireModal from "../../components/PermissionRequireModal";
var currentMonth = moment(new Date()).startOf("month").valueOf();
var previousMonth = moment(new Date()).startOf("month").valueOf();
var start = -1;
var end = 1;
var counter = 0;
function truncateTextByWordCount(text, wordLimit) {
  console.log(text.length);
  if (text.length > wordLimit) {
    return text.slice(0, wordLimit) + "...";
  } else {
    console.log(text);
    return text;
  }
}
const Schedule = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  // var eventsData = [];
  const [openModals, setOpenModals] = useState(false);
  const [token, settoken] = useState("");
  const [loading, setLoading] = useState(true);
  const [select, setSelected] = useState("SCHEDULE");
  const [createnewmodal, setCreatenewmodal] = useState(false);
  const [editmmodal, setEditmodal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const EventData = [];
  const [checked, setChecked] = useState(true);
  const [selecteddate, setSelecteddate] = useState(null);
  console.log("running .....");
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(false);
  const [teamMemberList, setTeamMemberList] = useState([]);
  const { isTeamAccount, userPermission } = useAuth();
  let schedulePermission =
    Object.keys(userPermission).includes("schedule") &&
    Object.keys(userPermission.schedule).includes("permission_key")
      ? userPermission.schedule.permission_key
      : "";
  let bookingPermission =
    Object.keys(userPermission).includes("booking") &&
    Object.keys(userPermission.booking).includes("permission_key")
      ? userPermission.booking.permission_key
      : "";

  const [checked2, setChecked2] = useState(false);
  const handleChange2 = (nextChecked) => {
    setChecked2(nextChecked);
  };
  const [checked3, setChecked3] = useState(false);
  const handleChange3 = (nextChecked) => {
    setChecked3(nextChecked);
  };
  // let currentMonth = moment(new Date()).startOf("month").valueOf();
  // let previousMonth = moment(new Date()).startOf("month").valueOf();
  // var start = -1;
  // var end = 1;
  // var counter = 0;
  const [data2, setData2] = useState([]);
  var ss = moment().startOf("day").valueOf();
  var se = moment().startOf("day").add(1, "day").valueOf();
  var ws = moment().startOf("week").add(1, "day").valueOf();
  var t = moment().startOf("day").add(7, "days").valueOf();
  var ms = moment().startOf("month").valueOf();
  const timeZoneOffset = -(new Date().getTimezoneOffset() * 60);
  const getData = (token) => {
    fetch(
      `https://bestmate.us/api/dashboard/v2/${ms}/${se}/${ws}/${se}/${ss}/${t}/${timeZoneOffset}` +
        `${isTeamAccount ? "?team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setData2(result.appointments);
          console.log(result);
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };

  const getTeamMemberList = async () => {
    var user = firebase.auth().currentUser;
    if (user) {
      let token = await user.getIdToken();
      const response = await fetch(
        "https://bestmate.us/api/team/get-all-members",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let result = await response.json();
      setTeamMemberList(result);
      // setLoading(false);
    }
  };
  // console.log(start, counter, end);
  //fetching all the existing events within the time interval as mentioned
  const fetchData = (startTime, endTime, token, eventsData) => {
    // console.log(eventsData);
    fetch(
      "https://bestmate.us/api/appointments/findAllCombined/" +
        startTime +
        "/" +
        endTime +
        `${isTeamAccount ? "?team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result, eventsData);
          const d = result.map((elem) => {
            if (
              Object.keys(elem).includes("invoice_id") &&
              elem.invoice_workflow_type === "ORDER"
            ) {
              return {
                title: elem.invoice_title ? elem.invoice_title : "",
                start: elem.invoice_visits[0].appointment_start_time,
                end: elem.invoice_visits[0].appointment_end_time,
                description: elem.invoice_description
                  ? elem.invoice_description
                  : "",
                id: elem.invoice_id,
                color: "#1AB293",
                category: "booking",
                extendedProps: {
                  otherDetails: elem,
                },
              };
            } else if (
              Object.keys(elem).includes("invoice_id") &&
              elem.invoice_workflow_type === "REQUEST"
            ) {
              return {
                title: elem.invoice_title ? elem.invoice_title : "",
                start: elem.invoice_visits[0].appointment_start_time,
                end: elem.invoice_visits[0].appointment_end_time,
                description: elem.invoice_description
                  ? elem.invoice_description
                  : "",
                id: elem.invoice_id,
                color: "orange",
                category: "request",
                extendedProps: {
                  otherDetails: elem,
                },
              };
            } else {
              return {
                title: elem.appointment_name,
                start: elem.appointment_start_time,
                end: elem.appointment_end_time,
                description: elem.appointment_description,
                id: elem.appointment_id,

                category: "schedule",
                extendedProps: {
                  otherDetails: elem,
                },
              };
            }
          });
          console.log(typeof d);
          if (d.length != 0) {
            if (eventsData.length != 0) {
              for (let i = 0; i < eventsData.length; i++) {
                d.push(eventsData[i]);
              }
            }
            console.log(eventsData, d);
            setEventsData(d);
            // eventsData = d;
          }
          console.log(eventsData, d);
          // setLoading(false);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };

  useEffect(async () => {
    if (loading) {
      // firebase.auth().onAuthStateChanged(async (user) => {
      var user = firebase.auth().currentUser;

      if (user) {
        currentMonth = moment(new Date()).startOf("month").valueOf();
        previousMonth = moment(new Date()).startOf("month").valueOf();
        start = -1;
        end = 1;
        counter = 0;
        let tokenss = await user.getIdToken();
        // setEventsData([]);
        settoken(tokenss);
        getData(tokenss);
        let startMonthTime = moment(new Date())
          .startOf("month")
          .subtract(1, "month")
          .valueOf();
        let endMonthTime = moment(new Date())
          .endOf("month")
          .add(1, "month")
          .valueOf();
        console.log(
          moment(new Date()).startOf("month").add(1, "month").valueOf(),
          moment(new Date()).startOf("month").subtract(1, "month").valueOf()
        );
        setEventsData([]);
        fetchData(startMonthTime, endMonthTime, tokenss, []);
        if (schedulePermission.includes("ALL")) {
          getTeamMemberList();
        }
      }
    }
    // });
  }, [loading]);
  useEffect(() => {
    mixpanel.track(`Schedule List (Web)`);
    return () => {
      currentMonth = moment(new Date()).startOf("month").valueOf();
      previousMonth = moment(new Date()).startOf("month").valueOf();
      start = -1;
      end = 1;
      counter = 0;
    };
  }, []);
  // useEffect(() => {
  //   fetchData("0", "1672511400000");
  // }, []);
  console.log(eventsData);
  // console.log("start", start, "counter", counter, "end", end);
  return (
    <>
      <React.Fragment>
        <Helmet title="Schedule" />
        <Container fluid className="p-0">
          {/* <h1 className="h3 mb-3">Schedule</h1> */}
          <Card
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              // backgroundColor: "white",
              padding: "0rem 1.5rem 2rem 1.5rem",
            }}
            className="pt-2 pb-2 border-bottom "
          >
            <div>
              <h2
                className="mt-3 lead"
                style={{
                  fontSize: "24px",
                  // , fontWeight: 100
                }}
              >
                Schedule
              </h2>
              <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
                <Breadcrumb.Item
                  active
                  onClick={() => navigate("/dashboard")}
                  style={{ cursor: "pointer" }}
                >
                  Home
                </Breadcrumb.Item>

                <Breadcrumb.Item active className="font-weight-bold">
                  Schedule
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div style={{ marginRight: "21px" }}>
              {/* <Link to="/schedule/add-schedule"> */}
              {/* <PlusSquare stroke="white" fill="#316284" size={35} /> */}
              {schedulePermission.includes("ADD") ? (
                <div
                  style={{
                    // backgroundColor: "#3367d6",
                    backgroundColor: "#3f80ea",
                    borderRadius: "5px",
                    padding: "8px 15px",
                    // padding: "0.375rem 0.75rem",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                    // color: "#676a6c",
                    alignItems: "center",
                    textDecoration: "none",
                    // fontSize: 12,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    mixpanel.track("Add Schedule (Schedule Web)");
                    setCreatenewmodal(true);
                  }}
                >
                  <Plus
                    size={15}
                    strokeWidth={3.5}
                    stroke="white"
                    style={{ marginRight: "5px" }}
                  />
                  Add Event
                </div>
              ) : (
                <></>
              )}
              {/* </Link> */}
            </div>
          </Card>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              <Spinner animation="border" variant="dark" className="me-2" />
            </div>
          ) : (
            <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
              <Row>
                <Col lg="9" className="p-2">
                  <Card className="mb-4 border rounded-0">
                    <Card.Header>
                      <FullCalendar
                        plugins={[
                          bootstrapPlugin,
                          dayGridPlugin,
                          timeGridPlugin,
                          intearctionPlugin,
                        ]}
                        // themeSystem="standard"
                        themeSystem="bootstrap"
                        eventDisplay="block"
                        editable={false}
                        eventOrder="description"
                        initialView="dayGridMonth"
                        initialDate={new Date()}
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        bootstrapFontAwesome={{
                          prev: "fa-chevron-left",
                          next: "fa-chevron-right",
                        }}
                        datesSet={(e) => {
                          let month = new Date(e.view.title).valueOf();
                          console.log(
                            month,
                            currentMonth,
                            previousMonth,
                            start,
                            counter,
                            end
                          );

                          if (month === currentMonth) {
                            counter = 0;
                            previousMonth = currentMonth;
                          }
                          //to change value of counter as we move to next months
                          if (previousMonth > month) {
                            console.log("minus a counter");
                            counter = counter - 1;
                          } else if (previousMonth < month) {
                            counter = counter + 1;
                          }
                          previousMonth = month;
                          //in this section we take care of fetching data of next/previous moth if pointer reaches to the boundary
                          //first comment out both the fetch call in if and else if blocks to see when the fetch call is to be made
                          if (counter == start) {
                            // console.log(counter, start);
                            // console.log(
                            //   "you are at the starting boundary fetch data of previous month"
                            // );
                            console.log("fetching");
                            start = start - 1;
                            let getDataMonth = moment(new Date(month)).subtract(
                              1,
                              "month"
                            );
                            console.log("fetch call");
                            fetchData(
                              getDataMonth.valueOf().toString(),
                              getDataMonth.endOf("month").valueOf().toString(),
                              token,
                              eventsData
                            );
                          } else if (counter == end) {
                            end = end + 1;
                            let getDataMonth = moment(new Date(month)).add(
                              1,
                              "month"
                            );
                            console.log("fetch call");
                            fetchData(
                              getDataMonth.valueOf().toString(),
                              getDataMonth.endOf("month").valueOf().toString(),
                              token,
                              eventsData
                            );
                          }

                          console.log(
                            month,
                            currentMonth,
                            previousMonth,
                            start,
                            counter,
                            end
                          );
                          console.log("{}{");
                        }}
                        customButtons={
                          {
                            // myCustomButton: {
                            //   text: "custom!",
                            //   id: "prev",
                            // },
                          }
                        }
                        // eventColor={({ events }) => {
                        //   if (events.event.category === "job") {
                        //     return "red";
                        //   } else {
                        //     return "green";
                        //   }
                        // }}

                        events={eventsData}
                        // editable={true}
                        select={(events) => {
                          setData(events);
                          console.log(events);
                          // console.log("select", events);
                          mixpanel.track("Add Schedule (Schedule Web)");
                          setOpenModals(!openModals);
                        }}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        // bootstrapFontAwesome={false}
                        eventClick={(events) => {
                          // navigate(
                          //   "/editschedule/" +
                          //     events.event.extendedProps.otherDetails
                          //       .appointment_id,
                          //   {
                          //     state: events.event.extendedProps,
                          //   }
                          // );
                          console.log(events);
                          if (
                            events.event.extendedProps.category === "schedule"
                          ) {
                            mixpanel.track("Open Schdedule (Schedule Web)", {
                              ...events.event.extendedProps.otherDetails,
                            });
                            if (schedulePermission.includes("EDIT")) {
                              setSelectedEvent(
                                events.event.extendedProps.otherDetails
                              );
                              setEditmodal(true);
                            }

                            console.log(events.event.extendedProps);
                          } else if (
                            events.event.extendedProps.category === "request"
                          ) {
                            mixpanel.track("Open Request (Schedule Web)", {
                              ...events.event.extendedProps.otherDetails,
                            });
                            navigate(
                              "/requests/" +
                                events.event.extendedProps.otherDetails
                                  .invoice_id,
                              {
                                state: {
                                  row: events.event.extendedProps.otherDetails,
                                },
                              }
                            );
                          } else if (
                            events.event.extendedProps.category === "booking"
                          ) {
                            mixpanel.track("Open Booking (Schedule Web)", {
                              ...events.event.extendedProps.otherDetails,
                            });
                            navigate(
                              "/bookings/" +
                                events.event.extendedProps.otherDetails
                                  .invoice_id,
                              {
                                state: {
                                  row: events.event.extendedProps.otherDetails,
                                },
                              }
                            );
                          }
                          // alert(events);
                        }}
                        eventsSet={(events) => {
                          //contains list of all events
                          // console.log("set", events);
                        }}
                        // eventAdd={}
                      />
                      {/* )} */}
                    </Card.Header>
                  </Card>
                </Col>
                <Col lg="3" className="p-2">
                  <div style={{}}>
                    {/* <Card className="mb-2 border rounded-0">
                      <Card.Header>
                        <div style={{ display: "flex", width: "100%" }}>
                          <Button
                            className="m-2 p-2"
                            // variant="light"
                            style={{
                              display: "flex",
                              flex: 1,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => navigate("add-schedule")}
                          >
                            <Plus
                              size={15}
                              strokeWidth={3}
                              stroke="white"
                              style={{ marginRight: "8px" }}
                            />
                            Create New Event
                          </Button>
                        </div>
                      </Card.Header>
                    </Card> */}
                    {/* <Card
                      className="mb-2 border rounded-0"
                      style={{ marginTop: "10px" }}
                    >
                      <Card.Body>
                        <h5> Customer Details</h5>

                        <FloatingLabel
                          controlId="floatingInput"
                          label="Customer"
                          className="mb-3"
                          onClick={() => {
                            // setModloading(true);
                            // fetchPartners();
                            // setOpenModals(true);
                          }}
                        >
                          <Form.Control
                            disabled={true}
                            type="text"
                            // name="appointment_partner"
                            placeholder="text"
                            // value={values.appointment_partner}
                            // isInvalid={errors.appointment_partner}
                            style={{
                              backgroundColor: "white",
                              cursor: "pointer",
                            }}
                            // onChange={handleChange}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                          </Form.Control.Feedback>
                        </FloatingLabel>
                        <h5> Booking Details</h5>

                        <FloatingLabel
                          controlId="floatingInput"
                          label="Job Name "
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            // isInvalid={errors.appointment_name}
                            // name="appointment_name"
                            placeholder="text"
                            // value={values.appointment_name}
                            // onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                          </Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Job Description "
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            // isInvalid={errors.appointment_description}
                            // name="appointment_description"
                            placeholder="text"
                            // value={values.appointment_description}
                            // onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                          </Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Internal Notes "
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            // isInvalid={errors.appointment_notes}
                            name="appointment_notes"
                            placeholder="text"
                            // value={values.appointment_notes}
                            // onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                          </Form.Control.Feedback>
                        </FloatingLabel>
                        <h5> Booking Start Date & Time</h5>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Start Date "
                          className="mb-3"
                        >
                          <Form.Control
                            type="date"
                            // isInvalid={errors.appointment_start_date}
                            required
                            name="appointment_start_date"
                            placeholder="text"
                            // value={values.appointment_start_date}
                            // onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                          </Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Start Time "
                          className="mb-3"
                        >
                          <Form.Control
                            type="time"
                            // isInvalid={errors.appointment_start_time}
                            name="appointment_start_time"
                            placeholder="text"
                            // value={values.appointment_start_time}
                            // onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                          </Form.Control.Feedback>
                        </FloatingLabel>
                        <h5> Booking End Date & Time</h5>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="End Date "
                          className="mb-3"
                        >
                          <Form.Control
                            type="date"
                            // isInvalid={errors.appointment_end_date}
                            required
                            name="appointment_end_date"
                            placeholder="text"
                            // value={values.appointment_end_date}
                            // onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                          </Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="End Time "
                          className="mb-3"
                        >
                          <Form.Control
                            type="time"
                            // isInvalid={errors.appointment_end_time}
                            name="appointment_end_time"
                            placeholder="text"
                            // value={values.appointment_end_time}
                            // onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                          </Form.Control.Feedback>
                        </FloatingLabel>
                        <h5> Email Communication</h5>
                        <Form.Check
                          id="checkbox1"
                          label="Send confirmation email to customer"
                          name="appointment_send_confirmation"
                          style={{ fontSize: "13px" }}
                          // onChange={handleChange}
                          type="checkbox"
                        />
                        <Form.Check
                          id="checkbox2"
                          name="reminder"
                          label="Send reminder email to customer (one day before)"
                          style={{ fontSize: "13px" }}
                          // onChange={handleChange}
                          // value={1}
                          type="checkbox"
                        />
                        <div
                          style={{
                            marginTop: 20,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            type="submit"
                            variant="primary"
                            // disabled={partners.length === 0}
                            className="me-1 mb-1 center"
                            size="md"
                            // disabled={disablesave}
                          >
                            Save
                          </Button>
                        </div>
                      </Card.Body>
                    </Card> */}
                    <Card
                      className="mb-2 border rounded-0"
                      // style={{ marginTop: "10px" }}
                    >
                      <Card.Body>
                        {/* <Form.Group className="mb-3">
                        <Form.Label>Currency</Form.Label>
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          options={[
                            { value: "INR", label: "INR" },
                            { value: "USD", label: "USD" },
                            { value: "AUD", label: "AUD" },
                          ]}
                        />
                      </Form.Group>
                      <hr style={{ margin: "30px 0px" }} />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Payment Methord</p>
                        <Switch
                          onChange={handleChange}
                          checked={checked}
                          className="react-switch"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor="#2693e6"
                          handleDiameter={18}
                          height={20}
                          width={40}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Late Fees</p>
                        <Switch
                          onChange={handleChange2}
                          checked={checked2}
                          className="react-switch"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor="#2693e6"
                          handleDiameter={18}
                          height={20}
                          width={40}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Notes</p>
                        <Switch
                          onChange={handleChange3}
                          checked={checked3}
                          className="react-switch"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor="#2693e6"
                          handleDiameter={18}
                          height={20}
                          width={40}
                        />
                      </div>
                      <hr style={{ margin: "30px 0px" }} />
                      <div>
                        <div
                          className="d-flex justify-content-center"
                          style={{ width: "100%" }}
                        >
                          <Button
                            className="m-2 p-2"
                            variant="light"
                            style={{
                              display: "flex",
                              flex: 1,
                              justifyContent: "center",
                            }}
                          >
                            Preview
                          </Button>
                          <Button
                            className="m-2 p-2"
                            variant="light"
                            style={{
                              display: "flex",
                              flex: 1,
                              justifyContent: "center",
                            }}
                          >
                            Download
                          </Button>
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                          <Button
                            className="m-2 p-2"
                            // variant="light"
                            style={{
                              display: "flex",
                              flex: 1,
                              justifyContent: "center",
                            }}
                          >
                            Send Invoice
                          </Button>
                        </div>
                      </div> */}
                        <h4 className="mb-1">Upcoming Schedules</h4>
                        {/* <div style={{ color: "grey" }}>
                          Don't miss scheduled events
                        </div> */}
                        <div style={{ marginTop: "15px" }}>
                          {/* {data2 ? (
                            <ul className="timeline" style={{ width: "100%" }}>
                              {data2.length === 0 ? (
                                <>No meetings for now</>
                              ) : (
                                <>
                                  {data2.map((elem) => (
                                    <li className="timeline-item">
                                      <strong>{elem.appointment_name}</strong>
                                      <span className="float-end text-muted text-sm">
                                        {moment(
                                          elem.appointment_start_time
                                        ).format("DD MMM YYYY hh:mm a")}
                                      </span>
                                      <p>{elem.appointment_description}</p>
                                      <p>
                                        <strong>
                                          {elem.appointment_partner
                                            .partner_first_name +
                                            " " +
                                            elem.appointment_partner
                                              .partner_last_name}
                                        </strong>
                                      </p>
                                    </li>
                                  ))}
                                </>
                              )}
                            </ul>
                          ) : (
                            <></>
                          )} */}
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          {data2 ? (
                            <div className="" style={{ width: "100%" }}>
                              {data2.length === 0 ? (
                                <>No meetings for now</>
                              ) : (
                                <>
                                  {data2.map((elem) => {
                                    if (
                                      Object.keys(elem).includes(
                                        "invoice_id"
                                      ) &&
                                      elem.invoice_workflow_type === "ORDER"
                                    ) {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            // flexDirection: ",
                                            padding: "10px 0",
                                            borderTop: "1px solid #f5f6f9",
                                            alignItems: "center",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            mixpanel.track(
                                              "Individual Booking Row (Schedule Web)",
                                              {
                                                ...elem,
                                              }
                                            );
                                            navigate(
                                              "/bookings/" + elem.invoice_id,
                                              {
                                                state: {
                                                  row: elem,
                                                },
                                              }
                                            );
                                          }}
                                        >
                                          <div
                                            style={{
                                              height: 60,
                                              width: 4,
                                              backgroundColor: "#1AB293",
                                              marginRight: 10,
                                            }}
                                          ></div>
                                          <div
                                            style={{
                                              backgroundColor: "#f5f6f9",
                                              width: "40px",
                                              height: "43px",
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "11px",
                                                fontWeight: "bold",
                                                // lineHeight: 0.9,
                                              }}
                                            >
                                              {moment(
                                                elem.invoice_visits[0]
                                                  .appointment_start_time
                                              ).format("DD")}
                                            </div>
                                            <div
                                              style={{
                                                lineHeight: 1,
                                                fontSize: "13px",
                                              }}
                                            >
                                              {moment(
                                                elem.invoice_visits[0]
                                                  .appointment_start_time
                                              )
                                                .format("dddd")
                                                .substring(0, 3)}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              margin: "0 10px",
                                              // backgroundColor: "red",
                                              maxWidth: "70%",
                                            }}
                                          >
                                            <div>
                                              <strong style={{ fontSize: 14 }}>
                                                {truncateTextByWordCount(
                                                  `${elem.invoice_partner.partner_first_name} ${elem.invoice_partner.partner_last_name}`,
                                                  40
                                                )}
                                              </strong>
                                            </div>
                                            <div
                                              style={{
                                                fontSize: "12px",
                                                color: "gray",
                                              }}
                                            >
                                              {/* {`${moment(
                                                elem.invoice_visits[0]
                                                  .appointment_start_time
                                              ).format("hh:mm a")} - ${moment(
                                                elem.invoice_visits[0]
                                                  .appointment_end_time
                                              ).format("hh:mm a")}`} */}
                                              {`${moment(
                                                elem.invoice_visits[0]
                                                  .appointment_start_time
                                              ).format("hh:mm a")} ${moment(
                                                elem.invoice_visits[0]
                                                  .appointment_start_time
                                              ).format("MMM DD")} - ${moment(
                                                elem.invoice_visits[0]
                                                  .appointment_end_time
                                              ).format("hh:mm a")} ${moment(
                                                elem.invoice_visits[0]
                                                  .appointment_end_time
                                              ).format("MMM DD")}`}
                                            </div>
                                            <div>
                                              <strong style={{ fontSize: 12 }}>
                                                {`${
                                                  elem.invoice_title
                                                    ? truncateTextByWordCount(
                                                        elem.invoice_title,
                                                        50
                                                      )
                                                    : ""
                                                }`}
                                              </strong>
                                            </div>
                                            {bookingPermission.includes(
                                              "ALL"
                                            ) &&
                                            elem.invoice_assignee &&
                                            elem.invoice_assignee
                                              .team_member_name ? (
                                              <div
                                                style={{
                                                  fontSize: 12,
                                                  marginTop: 3,
                                                  color: "#424040",
                                                }}
                                              >
                                                Assigned to{" "}
                                                <span
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  {elem.invoice_assignee &&
                                                  elem.invoice_assignee
                                                    .team_member_name
                                                    ? truncateTextByWordCount(
                                                        elem.invoice_assignee
                                                          .team_member_name,
                                                        50
                                                      )
                                                    : ""}
                                                </span>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      Object.keys(elem).includes(
                                        "invoice_id"
                                      ) &&
                                      elem.invoice_workflow_type === "REQUEST"
                                    ) {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            // flexDirection: ",
                                            padding: "10px 0",
                                            borderTop: "1px solid #f5f6f9",
                                            alignItems: "center",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            mixpanel.track(
                                              "Individual Request Row (Schedule Web)",
                                              {
                                                ...elem,
                                              }
                                            );
                                            navigate(
                                              "/requests/" + elem.invoice_id,
                                              {
                                                state: {
                                                  row: elem,
                                                },
                                              }
                                            );
                                          }}
                                        >
                                          <div
                                            style={{
                                              height: 60,
                                              width: 4,
                                              backgroundColor: "orange",
                                              marginRight: 10,
                                            }}
                                          ></div>
                                          <div
                                            style={{
                                              backgroundColor: "#f5f6f9",
                                              width: "40px",
                                              height: "43px",
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "11px",
                                                fontWeight: "bold",
                                                // lineHeight: 0.9,
                                              }}
                                            >
                                              {moment(
                                                elem.invoice_visits[0]
                                                  .appointment_start_time
                                              ).format("DD")}
                                            </div>
                                            <div
                                              style={{
                                                lineHeight: 1,
                                                fontSize: "13px",
                                              }}
                                            >
                                              {moment(
                                                elem.invoice_visits[0]
                                                  .appointment_start_time
                                              )
                                                .format("dddd")
                                                .substring(0, 3)}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              margin: "0 10px",
                                              maxWidth: "70%",
                                            }}
                                          >
                                            <div>
                                              <strong style={{ fontSize: 14 }}>
                                                {truncateTextByWordCount(
                                                  `${elem.invoice_partner.partner_first_name} ${elem.invoice_partner.partner_last_name}`,
                                                  40
                                                )}
                                              </strong>
                                            </div>
                                            <div
                                              style={{
                                                fontSize: "12px",
                                                color: "gray",
                                              }}
                                            >
                                              {/* {`${moment(
                                                elem.invoice_visits[0]
                                                  .appointment_start_time
                                              ).format("hh:mm a")} - ${moment(
                                                elem.invoice_visits[0]
                                                  .appointment_end_time
                                              ).format("hh:mm a")}`} */}
                                              {`${moment(
                                                elem.invoice_visits[0]
                                                  .appointment_start_time
                                              ).format("hh:mm a")} ${moment(
                                                elem.invoice_visits[0]
                                                  .appointment_start_time
                                              ).format("MMM DD")} - ${moment(
                                                elem.invoice_visits[0]
                                                  .appointment_end_time
                                              ).format("hh:mm a")} ${moment(
                                                elem.invoice_visits[0]
                                                  .appointment_end_time
                                              ).format("MMM DD")}`}
                                            </div>
                                            <div>
                                              <strong style={{ fontSize: 12 }}>
                                                {`${
                                                  elem.invoice_title
                                                    ? truncateTextByWordCount(
                                                        elem.invoice_title,
                                                        50
                                                      )
                                                    : ""
                                                }`}
                                              </strong>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            // flexDirection: ",
                                            padding: "10px 0",
                                            borderTop: "1px solid #f5f6f9",
                                            alignItems: "center",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            mixpanel.track(
                                              "Individual Schedule Row (Schedule Web)",
                                              {
                                                ...elem,
                                              }
                                            );
                                            if (
                                              schedulePermission.includes(
                                                "EDIT"
                                              )
                                            ) {
                                              setSelectedEvent(elem);
                                              setEditmodal(true);
                                            }
                                          }}
                                        >
                                          <div
                                            style={{
                                              height: 60,
                                              width: 4,
                                              backgroundColor: "#407FEA",
                                              marginRight: 10,
                                            }}
                                          ></div>
                                          <div
                                            style={{
                                              backgroundColor: "#f5f6f9",
                                              width: "40px",
                                              height: "43px",
                                              display: "flex",
                                              flexDirection: "column",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontSize: "11px",
                                                fontWeight: "bold",
                                                // lineHeight: 0.9,
                                              }}
                                            >
                                              {moment(
                                                elem.appointment_start_time
                                              ).format("DD")}
                                            </div>
                                            <div
                                              style={{
                                                lineHeight: 1,
                                                fontSize: "13px",
                                              }}
                                            >
                                              {moment(
                                                elem.appointment_start_time
                                              )
                                                .format("dddd")
                                                .substring(0, 3)}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              margin: "0 10px",
                                              maxWidth: "70%",
                                            }}
                                          >
                                            <strong style={{ fontSize: 14 }}>
                                              {truncateTextByWordCount(
                                                `${elem.appointment_partner.partner_first_name} ${elem.appointment_partner.partner_last_name}`,
                                                40
                                              )}
                                            </strong>
                                            <div
                                              style={{
                                                fontSize: "12px",
                                                color: "gray",
                                              }}
                                            >
                                              {/* {`${moment(
                                                elem.appointment_start_time
                                              ).format("hh:mm a")} - ${moment(
                                                elem.appointment_end_time
                                              ).format("hh:mm a")}`} */}
                                              {`${moment(
                                                elem.appointment_start_time
                                              ).format("hh:mm a")} ${moment(
                                                elem.appointment_start_time
                                              ).format("MMM DD")} - ${moment(
                                                elem.appointment_end_time
                                              ).format("hh:mm a")} ${moment(
                                                elem.appointment_end_time
                                              ).format("MMM DD")}`}
                                            </div>
                                            <div>
                                              <strong style={{ fontSize: 12 }}>
                                                {truncateTextByWordCount(
                                                  `${elem.appointment_name}`,
                                                  50
                                                )}
                                              </strong>
                                            </div>
                                            {schedulePermission.includes(
                                              "ALL"
                                            ) &&
                                            elem.appointment_assignee &&
                                            elem.appointment_assignee
                                              .team_member_name ? (
                                              <div
                                                style={{
                                                  fontSize: 12,
                                                  marginTop: 3,
                                                  color: "#424040",
                                                }}
                                              >
                                                Assigned to{" "}
                                                <span
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  {elem.appointment_assignee &&
                                                  elem.appointment_assignee
                                                    .team_member_name
                                                    ? truncateTextByWordCount(
                                                        elem
                                                          .appointment_assignee
                                                          .team_member_name,
                                                        50
                                                      )
                                                    : ""}
                                                </span>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                                </>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Container>
        {/* Modal to ask user for creating a new booking before opening the form */}
        <Modal
          backdrop="static"
          show={openModals}
          onHide={() => {
            mixpanel.track("Close >>> Add Schedule (Schedule Web)");
            setOpenModals(!openModals);
          }}
          centered
          size="sm"
        >
          {/* <Modal.Header className="h6">Add Booking</Modal.Header> */}
          <Modal.Body style={{ padding: 0 }}>
            <div
              style={{
                width: "100%",
                backgroundColor: "#f4f4f4",
                height: 56,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px 24px",
              }}
            >
              <h1
                style={{
                  fontWeight: "bold",
                  fontSize: 20,
                  color: "black",
                  margin: 0,
                }}
              >
                Add Schedule
              </h1>
              <div
                onClick={() => {
                  mixpanel.track("Close >>> Add Schedule (Schedule Web)");
                  setOpenModals(!openModals);
                  setShow(false);
                }}
              >
                <X
                  size={24}
                  strokeWidth={2}
                  stroke="black"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div style={{ padding: "10px 24px 0" }}>
              <div
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  flexDirection: "column",
                  // alignItems: "center",
                  margin: "10px 0 20px",
                }}
              >
                <p style={{ margin: 0, fontSize: 15 }}>
                  What would you like to create?
                </p>
                <Form.Group as={Row}>
                  <Col sm={10}>
                    <div className="custom-controls-stacked d-flex gap-2">
                      <Form.Check
                        type="radio"
                        id="radio1"
                        name="type"
                        label="Event"
                        onChange={() => {
                          setSelected("SCHEDULE");
                        }}
                        className="form-control-lg"
                        value="SCHEDULE"
                        defaultChecked={true}
                        // defaultChecked={res.isProduct}
                      />
                      <Form.Check
                        type="radio"
                        id="radio2"
                        name="type"
                        label="Booking"
                        onChange={() => {
                          setSelected("BOOKING");
                        }}
                        className="form-control-lg"
                        value="BOOKING"
                        // defaultChecked={res.isService}
                      />
                    </div>
                  </Col>
                </Form.Group>
                {/* <p style={{ marginTop: 0 }}>
                  {new Date(data.start).toLocaleString("en-US", {
                    dateStyle: "full",
                    timeStyle: "medium",
                  })}
                  <br />
                  to
                  <br />
                  {new Date(data.end).toLocaleString("en-US", {
                    dateStyle: "full",
                    timeStyle: "medium",
                  })}
                </p> */}
              </div>

              <hr style={{ margin: 0 }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "12px 0",
                }}
              >
                <button
                  type="button"
                  style={{
                    marginRight: 5,
                    padding: "5px 10px",
                    backgroundColor: "white",
                    borderWidth: 1,
                  }}
                  onClick={() => {
                    mixpanel.track("Close >>> Add Schedule (Schedule Web)");
                    setOpenModals(!openModals);
                    setShow(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{
                    padding: "5px 10px",
                    backgroundColor: "#3679e8",
                    borderWidth: 0,
                    color: "white",
                  }}
                  type="submit"
                  // disabled={disablesave}
                  onClick={() => {
                    console.log(select);
                    if (select === "SCHEDULE") {
                      setOpenModals(!openModals);
                      setSelecteddate({
                        startStr: data.startStr,
                        endStr: data.endStr,
                      });
                      // navigate("add-schedule", {
                      //   state: { startStr: data.startStr, endStr: data.endStr },
                      // });
                      mixpanel.track("Event  >>> Add Schedule (Schedule Web)");
                      setCreatenewmodal(true);
                    } else if (select === "BOOKING") {
                      setOpenModals(!openModals);
                      mixpanel.track(
                        "Booking  >>> Add Schedule (Schedule Web)"
                      );
                      if (bookingPermission.includes("ADD")) {
                        navigate("/bookings/add", {
                          state: {
                            datedata: {
                              startStr: data.startStr,
                              endStr: data.endStr,
                            },
                          },
                        });
                      } else {
                        setShowAccessDeniedModal(true);
                      }
                    }
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Newschedulemodal
          type={"event"}
          createnewmodal={createnewmodal}
          setCreatenewmodal={setCreatenewmodal}
          setLoading={setLoading}
          setEventsData={setEventsData}
          selecteddate={selecteddate}
          setSelecteddate={setSelecteddate}
          teamMemberList={teamMemberList}
        />
        <Editschedulemodal
          type={"event"}
          createnewmodal={editmmodal}
          setCreatenewmodal={setEditmodal}
          setLoading={setLoading}
          setEventsData={setEventsData}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          teamMemberList={teamMemberList}
        />
        <PermissionRequireModal
          showAccessDeniedModal={showAccessDeniedModal}
          setShowAccessDeniedModal={setShowAccessDeniedModal}
        />
      </React.Fragment>
      {/* // )} */}
    </>
  );
};

export default Schedule;
