import React, { useMemo, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useTable, useGlobalFilter } from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import { useEffect, useState } from "react";
import { ArrowRight, Mail, Phone, Plus, PlusSquare, X } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import useAuth from "../../hooks/useAuth";
// import InputComponent from "./InputComponentPartner";
import InfiniteScroll from "react-infinite-scroll-component";
import mixpanel from "mixpanel-browser";

import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Row,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import NotyfContext from "../../contexts/NotyfContext";
import useSidebar from "../../hooks/useSidebar";
import empty_partner from "../../assets/img/icons/empty_partner.png";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useCurrencyFormating } from "../../utils/useCurrencyFormating";

const FilteringTable = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openModals, setOpenModals] = useState(false);
  const [disabledelete, setDisableDelete] = useState(false);
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const [nothavemodal, setNothavemodal] = useState(false);
  const [nothavetype, setNothavetype] = useState("Phone");
  const { currencyFormater } = useCurrencyFormating();
  console.log(location);

  let usercurrency = localStorage.getItem("currency") || "USD";
  function truncateTextByWordCount(text, wordLimit) {
    console.log(text.length);
    if (text.length > wordLimit) {
      return text.slice(0, wordLimit) + "...";
    } else {
      console.log(text);
      return text;
    }
  }
  useEffect(() => {
    if (openModals) {
      setDisableDelete(false);
    }
  }, [openModals]);
  const COLUMNS = [
    {
      Header: "Name",
      width: 200,
      accessor: (properties) => `${properties.team_member_name}`,
      Cell: ({ row, value }) => {
        return <span>{truncateTextByWordCount(value, 18)}</span>;
      },
    },
    {
      Header: "Job Profile",
      width: 150,
      accessor: "team_member_job_profile",
      Cell: ({ value, row }) => {
        console.log(row);
        if (
          Object.keys(row.original).includes("team_member_job_profile") &&
          row.original.team_member_job_profile !== ""
        ) {
          return (
            <span>
              {truncateTextByWordCount(
                row.original.team_member_job_profile,
                18
              )}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      Header: "Email",
      accessor: "team_member_email",
      width: 220,
      Cell: ({ value, row }) => {
        // if (value === "") {
        //   return "N/A";
        // } else {
        //   return value;
        // }
        if (
          Object.keys(row.original).includes("team_member_email") &&
          row.original.team_member_email !== ""
        ) {
          return (
            <span>
              {truncateTextByWordCount(row.original.team_member_email, 30)}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      Header: "Phone",
      width: 200,
      accessor: "team_member_phone",
      Cell: ({ value, row }) => {
        if (
          Object.keys(row.original).includes("team_member_phone") &&
          row.original.team_member_phone !== ""
        ) {
          return (
            <span>
              {truncateTextByWordCount(row.original.team_member_phone, 14)}
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      Header: () => (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Status
        </div>
      ),
      width: 150,
      accessor: "status",
      Cell: ({ value, row }) => {
        if (row.original.team_member_invite_accepted === "REQUESTED") {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#3f80ea",
                  padding: "4px",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: 75,

                  textAlign: "center",
                }}
              >
                Requested
              </div>
            </div>
          );
        } else if (row.original.team_member_invite_accepted === "DEACTIVATED") {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#DE5474",
                  padding: "4px",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: 75,
                  textAlign: "center",
                }}
              >
                Deactivated
              </div>
            </div>
          );
        } else if (row.original.team_member_invite_accepted === "ACTIVE") {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#1AB395",
                  padding: "4px",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: 75,

                  textAlign: "center",
                }}
              >
                Active
              </div>
            </div>
          );
        }
      },
    },
    {
      Header: () => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          Contact
        </div>
      ),
      width: 200,
      accessor: "contact",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              onClick={
                row.original.team_member_phone
                  ? (e) => {
                      e.stopPropagation();
                      mixpanel.track("Call (Team list Web)");
                      window.location = `tel:${row.original.team_member_phone}`;
                    }
                  : (e) => {
                      e.stopPropagation();
                      setNothavetype("Phone");
                      setNothavemodal(true);
                    }
              }
            >
              <Phone
                size={18}
                strokeWidth={2}
                stroke="#09B29C"
                style={{ cursor: "pointer", marginRight: 25 }}
              />
            </div>
            <div
              onClick={
                row.original.team_member_email
                  ? (e) => {
                      e.stopPropagation();
                      mixpanel.track("Mail (Team list Web)");
                      window.location = `mailto:${row.original.team_member_email}`;
                    }
                  : (e) => {
                      e.stopPropagation();
                      setNothavetype("Email");
                      setNothavemodal(true);
                    }
              }
            >
              <Mail
                size={18}
                strokeWidth={2}
                stroke="#3367d6"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        );
      },
    },
    {
      Header: () => (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      accessor: "actions",
      disableSortBy: true,
      width: 100,
      Cell: ({ row }) => {
        // const num = parseInt(value);
        return (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <ButtonGroup>
              <Button
                size="sm"
                variant="light"
                style={{ backgroundColor: "white" }}
                className="border"
                disabled={row.original.team_member_permissions.isOwner}
                onClick={(e) => {
                  e.stopPropagation();
                  mixpanel.track("Individual Team Row Edit (Team list Web)", {
                    ...row.original,
                  });
                  navigate("/manage-team/add/", {
                    state: { row: row.original },
                  });
                }}
              >
                Edit
              </Button>
            </ButtonGroup>
          </div>
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = props.data;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  return (
    <>
      <Table
        striped
        bordered
        size="sm"
        {...getTableProps()}
        style={{ color: "grey" }}
      >
        <thead style={{ color: "black", backgroundColor: "#F2f2f4" }}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getFooterGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} style={{ borderTop: 0, fontSize: 13 }}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                onClick={() => {
                  console.log(location);

                  mixpanel.track("Individual Team Row (Team list Web)", {
                    ...row.original,
                  });
                  if (row.original.team_member_permissions.isOwner === false) {
                    navigate("/manage-team/add", {
                      state: { row: row.original },
                    });
                  }
                }}
                style={{ cursor: "pointer" }}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        width: cell.column.width,
                        color: "black",
                        fontSize: "12.6px",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {props.data.length === 0 ? (
        <div
          className="d-flex justify-content-center align-items-center pt-3 flex-column "
          id={"Emptybox"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            mixpanel.track("Add Customer(Customer list Web)");
            navigate("add");
          }}
        >
          <p
            className="mt-2"
            style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
          >
            No Member found. Lets Add a Team Member{" "}
          </p>
          <img
            src={empty_partner}
            style={{ height: "80px", marginTop: 30, marginBottom: 20 }}
            alt="empty_invoice"
          ></img>
          <Button
            onClick={() => {
              mixpanel.track("Add Customer(Customer list Web)");
              navigate("add");
            }}
            style={{ padding: 10, fontWeight: "bold" }}
          >
            Add Team member
          </Button>
        </div>
      ) : (
        <></>
      )}
      {/* <Modal
        show={openModals}
        onHide={() => setOpenModals(!openModals)}
        centered
      >
        <Modal.Header className="font-weight-bold" closeButton>
          Delete Customer
        </Modal.Header>
        <Modal.Body className="text-left m-3">
          <p className="mb-0 font-weight-bold">
            Are you sure you want to delete {deleteData["partner_first_name"]}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setOpenModals(!openModals)}
          >
            Cancel
          </Button>{" "}
          <Button
            variant="danger"
            onClick={() => {
              setOpenModals(!openModals);
              props.deleteRecord(deleteData);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        show={nothavemodal}
        onHide={() => setNothavemodal(false)}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Not Available
            </h1>
            <div
              onClick={() => {
                setNothavemodal(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                // alignItems: "center",
              }}
            >
              <p style={{ margin: "15px 0 20px" }}>
                {nothavetype === "Phone"
                  ? " No phone number has been added. Add a new phone number from Team member edit page."
                  : " No Email has been added. Add a new email address from the Team member edit page."}
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                onClick={() => {
                  setNothavemodal(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const TeamMemberList = () => {
  const [token, settoken] = useState("");

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [searchtable, showSearchtable] = useState(false);
  const [searchdata, setSearchdata] = useState([]);
  const [searchname, setSearchname] = useState("");
  const [searchcont, setSearchcont] = useState("");
  const [searchstat, setSearchstat] = useState("");
  const [searchout, setSearchout] = useState("");
  const [id, setId] = useState("");
  const [hasmore, sethasmore] = useState(true);
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();

  const getData = (token) => {
    fetch("https://bestmate.us/api/team/get", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setData(result.team_members);
          setLoading(false);
          sethasmore(false);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };

  function searchnow(searchname, searchcont, searchout, searchstat) {
    let result = data;
    if (searchname !== "") {
      result = result.filter(
        (elem) =>
          elem.team_member_name.toLowerCase().includes(searchname.toLowerCase())
        // elem.partner_first_name.toLowerCase().includes(searchname.toLowerCase())
      );
    }

    if (searchcont !== "") {
      result = result.filter((elem) => {
        if ("team_member_phone" in elem && elem.team_member_phone !== "") {
          return elem.team_member_phone.includes(searchcont);
        }
      });
    }

    if (searchout !== "") {
      result = result.filter((elem) => {
        if ("team_member_email" in elem && elem.team_member_email !== "") {
          return elem.team_member_email.includes(searchout);
        }
      });
    }
    if (searchstat !== "") {
      result = result.filter((elem) => {
        if (
          "team_member_invite_accepted" in elem &&
          elem.team_member_invite_accepted !== ""
        ) {
          return elem.team_member_invite_accepted === searchstat;
        }
      });
    } else {
      result = result.filter((elem) => {
        return elem.team_member_invite_accepted;
      });
    }

    setSearchdata(result);
  }
  useEffect(() => {
    mixpanel.track(`On Team List (Web)`);
  }, []);
  useEffect(() => {
    showSearchtable(false);

    if (
      searchname !== "" ||
      searchcont !== "" ||
      searchout !== "" ||
      searchstat !== ""
    ) {
      showSearchtable(true);
      searchnow(searchname, searchcont, searchout, searchstat);
    }
  }, [searchname, searchcont, searchout, searchstat]);

  useEffect(() => {
    if (loading) {
      var user = firebase.auth().currentUser;

      if (user) {
        const func = async () => {
          let tokenss = await user.getIdToken();
          settoken(tokenss);
          setData([]);
          setId("");
          sethasmore(true);
          showSearchtable(false);
          setSearchdata([]);
          setSearchname("");
          setSearchcont("");
          setSearchstat("");
          setSearchout("");
          getData(tokenss);
        };
        func();
      }
    }
  }, [loading]);

  console.log(data, searchdata);

  return (
    <React.Fragment>
      <Helmet title="Customer" />
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Customer</h1> */}
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor: "white",
            padding: "0rem 1.5rem 2rem 1.5rem",
          }}
          className="pt-2 pb-2 border-bottom "
        >
          <div>
            <h2
              className="mt-3 lead"
              style={{
                fontSize: "24px",
                // , fontWeight: 100
              }}
            >
              Manage Team
            </h2>
            <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
              <Breadcrumb.Item
                active
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/dashboard")}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active className="font-weight-bold">
                Manage Team
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div style={{ marginRight: "21px" }}>
            <Link
              to="/manage-team/add"
              onClick={() => mixpanel.track("Add Team Member(Member list Web)")}
            >
              {/* <PlusSquare stroke="white" fill="#316284" size={35} /> */}
              <div
                style={{
                  // backgroundColor: "#3367d6",
                  backgroundColor: "#3f80ea",
                  borderRadius: "5px",
                  padding: "8px 15px",
                  // padding: "0.375rem 0.75rem",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  // color: "#676a6c",
                  alignItems: "center",
                  textDecoration: "none",
                  // fontSize: 12,
                  cursor: "pointer",
                }}
              >
                <Plus
                  size={15}
                  strokeWidth={3.5}
                  stroke="white"
                  style={{ marginRight: "5px" }}
                />
                Add Team Member
              </div>
            </Link>
          </div>
        </Card>
        <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
          <Row>
            <Col>
              <Card className="mb-2 border rounded-0">
                <Card.Header>
                  <Form>
                    <Row>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label> Name</Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            value={searchname}
                            onChange={(e) => {
                              mixpanel.track("Search Name (Team List Web)", {
                                searchterm: e.target.value,
                              });
                              setSearchname(e.target.value);
                            }}
                            placeholder=" Name"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label> Email</Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            value={searchout}
                            onChange={(e) => {
                              mixpanel.track(
                                "Search Outstanding (Customer List Web)",
                                { searchterm: e.target.value }
                              );
                              setSearchout(e.target.value);
                            }}
                            placeholder="Email"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>Phone </Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            value={searchcont}
                            onChange={(e) => {
                              mixpanel.track(
                                "Search Phone (Customer List Web)",
                                { searchterm: e.target.value }
                              );
                              setSearchcont(e.target.value);
                            }}
                            placeholder="Phone "
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>Status</Form.Label>

                          <Form.Select
                            value={searchstat}
                            // name="email"
                            onChange={(e) => {
                              setSearchstat(e.target.value);
                            }}
                          >
                            <option value="">All</option>
                            <option value="ACTIVE">Active</option>
                            <option value="REQUESTED">Requested</option>
                            <option value="DEACTIVATED">Deactivate</option>
                            {/* <option>Three</option> */}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Header>
              </Card>
              <Row>
                <Col lg="12">
                  <Card className="mb-2 border rounded-0">
                    <Card.Header>
                      {/* {urlparamsdata ? (
                  <InputComponent
                    urlparamsdata={urlparamsdata}
                    rowData={rowData}
                    // show={setShow}
                    token={token}
                    delete={deleteRecord}
                    getData={getData}
                    setSearchParams={setSearchParams}
                  />
                ) : (
                  <> */}
                      {loading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "200px" }}
                        >
                          <Spinner
                            animation="border"
                            variant="dark"
                            className="me-2"
                          />
                        </div>
                      ) : (
                        <>
                          {!searchtable ? (
                            <FilteringTable
                              data={data}
                              // show={setShow}
                              token={token}
                              // update={getData}
                              hasmore={hasmore}
                              id={id}
                            />
                          ) : (
                            <FilteringTable
                              data={searchdata}
                              // show={setShow}
                              token={token}
                              update={getData}
                              hasmore={hasmore}
                              id={id}
                            />
                          )}
                        </>
                      )}
                      {/* </>
                )} */}
                    </Card.Header>
                    {/* <Card.Body>&nbsp;</Card.Body> */}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
    // <>
    //   <div>ed</div>
    // </>
  );
};

export default TeamMemberList;
