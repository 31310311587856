import React, { useState, useEffect, useMemo, useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTable } from "react-table";
import {
  Card,
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  Nav,
  Tab,
  Table,
  Breadcrumb,
  Badge,
  ButtonGroup,
  Spinner,
} from "react-bootstrap";
import empty_invoice from "../../assets/img/icons/empty_invoice.png";
import Select from "react-select";
import NotyfContext from "../../contexts/NotyfContext";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import useSidebar from "../../hooks/useSidebar";
import mixpanel from "mixpanel-browser";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import {
  Plus,
  Edit2,
  Phone,
  Mail,
  MessageSquare,
  X,
  Filter,
  ChevronRight,
} from "react-feather";
import moment from "moment";
import "../../assets/css/App.css";
import Page404Authenticated from "../auth/Page404authenticated";
import { useSumwithDiscountAndTax } from "../../utils/useSumwithDiscountandTax";
import { useCurrencyFormating } from "../../utils/useCurrencyFormating";
import useAuth from "../../hooks/useAuth";

/*
  Input Component Partner containes the edit form , Invoices , Estimate and Order
  The Edit form contains three buttons (Save, cancel and delete).
  -> If user edits any information user can save the edited information by pressing Save button
  -> If user don't want to edit any information user can use cancel button
  -> If user want to delete that particular record he can press delete button
  The Invoice tab, estimate tab and orders tab containes details corresoponding to particular partner
*/
// const EditComponent = (props) => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const { isOpen } = useSidebar();
//   const [openModals, setOpenModals] = useState(false);
//   const [deleteData, setDeleteData] = useState({});
//   const [rowdata, setRowdata] = useState();
//   const [token, settoken] = useState("");
//   const [pricelist, setPricelist] = useState({});
//   const [options, setOptions] = useState([]);
//   const notyf = useContext(NotyfContext);
//   const fetchPriceList = (token) => {
//     fetch("https://bestmate.us/api/pricelist/findAll", {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + token,
//       },
//     })
//       .then((res) => res.json())
//       .then(
//         (result) => {
//           console.log(result);
//           let data = result.map((elem) => {
//             return {
//               label: `${elem.price_list_name} : ${elem.price_list_multiplier}x`,
//               value: {
//                 price_list_name: elem.price_list_name,
//                 price_list_multiplier: elem.price_list_multiplier,
//                 price_list_id: elem.price_list_id,
//               },
//             };
//           });
//           console.log(data);
//           setOptions(data);
//         },
//         (error) => {
//           console.error("Error fetching data: ", error);
//         }
//       );
//   };

//   const getRequiredData = (token) => {
//     fetch("https://bestmate.us/api/partners/get/" + id, {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + token,
//       },
//     })
//       .then((res) => res.json())
//       .then(
//         (result) => {
//           setRowdata(result);
//           props.setPartner(result);
//         },
//         (error) => {
//           console.error("Error fetching data: ", error);
//         }
//       );
//   };

//   const deleteRecord = (deleteData) => {
//     fetch("https://bestmate.us/api/partners/delete", {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + token,
//       },
//       body: JSON.stringify(deleteData),
//     })
//       .then((response) => {
//         console.log("response", response);
//         navigate("/customer");
//         notyf.open({
//           type: isOpen ? "home" : "full",
//           message: "Partner deleted successfully",
//           ripple: "true",
//           dismissible: "true",
//         });
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//     // setShow(false);
//   };
//   useEffect(() => {
//     firebase.auth().onAuthStateChanged(async (user) => {
//       if (user) {
//         let tokenss = await user.getIdToken();
//         settoken(tokenss);
//         fetchPriceList(tokenss);
//         if (location.state !== null) {
//           setRowdata(location.state.row);
//           props.setPartner(location.state.row);
//           let pldata;
//           if (
//             Object.entries(location.state.row.partner_price_list).length === 0
//           ) {
//             console.log(location.state.row.partner_price_list);
//             pldata = {};
//           } else {
//             pldata = {
//               label: `${location.state.row.partner_price_list.price_list_name} : ${location.state.row.partner_price_list.price_list_multiplier}x`,
//               value: location.state.row.partner_price_list,
//             };
//           }
//           setPricelist(pldata);
//         } else {
//           getRequiredData(tokenss);
//         }
//       }
//     });
//   }, []);

//   return (
//     <>
//       {rowdata !== undefined ? (
//         <>
//           <Formik
//             initialValues={{
//               partner_id: rowdata.partner_id,
//               partner_type: rowdata.partner_type,
//               first_name: rowdata.partner_first_name,
//               last_name: rowdata.partner_last_name,
//               email: rowdata.partner_email,
//               number: rowdata.partner_number,
//               billing_street: rowdata.partner_billing_address.street,
//               billing_city: rowdata.partner_billing_address.city,
//               billing_state: rowdata.partner_billing_address.state,
//               billing_zipcode: rowdata.partner_billing_address.zip_code,
//               billing_country: rowdata.partner_billing_address.country,
//               shipping_street: rowdata.partner_shipping_address.street,
//               shipping_city: rowdata.partner_shipping_address.city,
//               shipping_state: rowdata.partner_shipping_address.state,
//               shipping_zipcode: rowdata.partner_shipping_address.zip_code,
//               shipping_country: rowdata.partner_shipping_address.country,
//               notes: rowdata.partner_notes,
//               payment_terms: rowdata.partner_payment_terms,
//               tax_number: rowdata.partner_tax_number,
//             }}
//             validationSchema={Yup.object().shape({
//               first_name: Yup.string()
//                 .max(255)
//                 .required("Item name is required"),
//               last_name: Yup.string()
//                 .max(255)
//                 .required("Item name is required"),
//               // number: Yup.string().matches(
//               //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
//               //   "Phone number is not valid"
//               // ),
//             })}
//             onSubmit={(values) => {
//               // console.log(values);
//               const data = {
//                 partner_id: values.partner_id,
//                 partner_first_name: values.first_name,
//                 partner_last_name: values.last_name,
//                 partner_email: values.email,
//                 partner_number: values.number,
//                 partner_price_list: pricelist.value,
//                 partner_billing_address: {
//                   street: values.billing_street,
//                   city: values.billing_city,
//                   state: values.billing_state,
//                   zip_code: values.billing_zipcode,
//                   country: values.billing_country,
//                 },
//                 partner_shipping_address: {
//                   street: values.shipping_street,
//                   city: values.shipping_city,
//                   state: values.shipping_state,
//                   zip_code: values.shipping_zipcode,
//                   country: values.shipping_country,
//                 },
//                 partner_payment_terms: values.payment_terms,
//                 partner_notes: values.notes,
//                 partner_tax_number: values.tax_number,
//                 partner_type: values.partner_type,
//               };
//               // console.log(data);
//               fetch("https://bestmate.us/api/partners/update", {
//                 method: "POST",
//                 headers: {
//                   Accept: "application/json",
//                   "Content-Type": "application/json",
//                   Authorization: "Bearer " + token,
//                 },
//                 body: JSON.stringify(data),
//               })
//                 .then((response) => {
//                   navigate("/customer");
//                   notyf.open({
//                     type: isOpen ? "home" : "full",
//                     message: "Partner updated successfully",
//                     ripple: "true",
//                     dismissible: "true",
//                   });
//                 })
//                 .catch((error) => {
//                   console.error(error);
//                 });
//               // props.show(false);
//               // props.setSearchParams({});
//             }}
//             onReset={() => {
//               // props.show(false);
//               // props.setSearchParams({});
//               navigate("/customer");
//             }}
//           >
//             {({
//               handleSubmit,
//               values,
//               errors,
//               handleChange,
//               handleReset,
//               // dirty,
//             }) => (
//               <Form onSubmit={handleSubmit}>
//                 {errors.submit && (
//                   <Alert className="my-3" variant="danger">
//                     <div className="alert-message">{errors.submit}</div>
//                   </Alert>
//                 )}
//                 {/* Card 1 Partner Profile */}

//                 {/* <Card>
//                   <Card.Header className="pb-0">
//                     <Card.Title className="mb-0">Partner Profile</Card.Title>
//                   </Card.Header>
//                   <Card.Body>
//                     <Row className="g-2">
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="First Name "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.first_name}
//                             name="first_name"
//                             placeholder="text"
//                             value={values.first_name}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.first_name}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="Last Name "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.last_name}
//                             name="last_name"
//                             placeholder="text"
//                             value={values.last_name}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.last_name}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                     </Row>
//                     <FloatingLabel
//                       controlId="floatingInput"
//                       label="Email "
//                       className="mb-3"
//                     >
//                       <Form.Control
//                         type="text"
//                         placeholder="Email"
//                         name="email"
//                         value={values.email}
//                         onChange={handleChange}
//                       />
//                     </FloatingLabel>
//                     <FloatingLabel
//                       controlId="floatingInput"
//                       label="Phone Number "
//                       className="mb-3"
//                     >
//                       <Form.Control
//                         type="text"
//                         placeholder="0"
//                         name="number"
//                         value={values.number}
//                         onChange={handleChange}
//                         // isInvalid={!!errors.number}
//                       />

//                     </FloatingLabel>
//                   </Card.Body>
//                 </Card> */}
//                 {/* Card 2 Billing Address*/}
//                 <Card>
//                   <Card.Header className="pb-0">
//                     <Card.Title className="mb-0">Billing Address</Card.Title>
//                   </Card.Header>
//                   <Card.Body>
//                     <FloatingLabel
//                       controlId="floatingInput"
//                       label="Street "
//                       className="mb-3"
//                     >
//                       <Form.Control
//                         type="text"
//                         isInvalid={errors.billing_street}
//                         name="billing_street"
//                         placeholder="text"
//                         value={values.billing_street}
//                         onChange={handleChange}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.billing_street}
//                       </Form.Control.Feedback>
//                     </FloatingLabel>
//                     <Row className="g-2">
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="City "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.billing_city}
//                             name="billing_city"
//                             placeholder="text"
//                             value={values.billing_city}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.billing_city}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="State "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.billing_state}
//                             name="billing_state"
//                             placeholder="text"
//                             value={values.billing_state}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.billing_state}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                     </Row>
//                     <Row className="g-2">
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="Zip Code "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.billing_zipcode}
//                             name="billing_zipcode"
//                             placeholder="text"
//                             value={values.billing_zipcode}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.billing_zipcode}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="Country "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.billing_country}
//                             name="billing_country"
//                             placeholder="text"
//                             value={values.billing_country}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.billing_country}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                     </Row>
//                   </Card.Body>
//                 </Card>
//                 {/* Card 3 Shipping Address*/}
//                 <Card>
//                   <Card.Header className="pb-0">
//                     <Card.Title className="mb-0">Shipping Address</Card.Title>
//                   </Card.Header>
//                   <Card.Body>
//                     <FloatingLabel
//                       controlId="floatingInput"
//                       label="Street "
//                       className="mb-3"
//                     >
//                       <Form.Control
//                         type="text"
//                         isInvalid={errors.shipping_street}
//                         name="shipping_street"
//                         placeholder="text"
//                         value={values.shipping_street}
//                         onChange={handleChange}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.shipping_street}
//                       </Form.Control.Feedback>
//                     </FloatingLabel>
//                     <Row className="g-2">
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="City "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.shipping_city}
//                             name="shipping_city"
//                             placeholder="text"
//                             value={values.shipping_city}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.shipping_city}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="State "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.shipping_state}
//                             name="shipping_state"
//                             placeholder="text"
//                             value={values.shipping_state}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.shipping_state}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                     </Row>
//                     <Row className="g-2">
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="Zip Code "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.shipping_zipcode}
//                             name="shipping_zipcode"
//                             placeholder="text"
//                             value={values.shipping_zipcode}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.shipping_zipcode}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md>
//                         <FloatingLabel
//                           controlId="floatingInput"
//                           label="Country "
//                           className="mb-3"
//                         >
//                           <Form.Control
//                             type="text"
//                             isInvalid={errors.shipping_country}
//                             name="shipping_country"
//                             placeholder="text"
//                             value={values.shipping_country}
//                             onChange={handleChange}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.shipping_country}
//                           </Form.Control.Feedback>
//                         </FloatingLabel>
//                       </Col>
//                     </Row>
//                   </Card.Body>
//                 </Card>
//                 {/* Card 4 Other Details */}
//                 <Card>
//                   <Card.Header className="pb-0">
//                     <Card.Title className="mb-0">Other Details</Card.Title>
//                   </Card.Header>
//                   <Card.Body>
//                     {/* <FloatingLabel
//                       controlId="floatingInput"
//                       label="Price List"
//                       className="mb-3"
//                     > */}
//                     <Form.Label>Price List</Form.Label>
//                     {/* <div className="d-flex gap-2">
//                       <Select
//                         className="react-select-container mb-3"
//                         classNamePrefix="react-select"
//                         value={
//                           Object.entries(pricelist).length === 0
//                             ? ""
//                             : pricelist
//                         }
//                         onChange={setPricelist}
//                         options={options}
//                       />
//                       <Button> Add PriceList</Button>
//                     </div> */}
//                     {/* <Row>
//                       <Col md>
//                         <Select
//                           className="react-select-container mb-3"
//                           classNamePrefix="react-select"
//                           value={
//                             Object.entries(pricelist).length === 0
//                               ? ""
//                               : pricelist
//                           }
//                           onChange={setPricelist}
//                           options={options}
//                         />
//                       </Col>
//                       <Col md>
//                         <Button> Add PriceList</Button>
//                       </Col>
//                     </Row> */}
//                     <Select
//                       className="react-select-container mb-3"
//                       classNamePrefix="react-select"
//                       value={
//                         Object.entries(pricelist).length === 0 ? "" : pricelist
//                       }
//                       onChange={setPricelist}
//                       options={options}
//                     />
//                     {/* <Form.Control
//                         type="text"
//                         placeholder="0"
//                         isInvalid={errors.price_list}
//                         name="price_list"
//                         value={values.price_list}
//                         onChange={handleChange}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.price_list}
//                       </Form.Control.Feedback>
//                     </FloatingLabel> */}
//                     <FloatingLabel
//                       controlId="floatingInput"
//                       label="Payment Terms"
//                       className="mb-3"
//                     >
//                       <Form.Control
//                         type="text"
//                         placeholder="0"
//                         isInvalid={errors.payment_terms}
//                         name="payment_terms"
//                         value={values.payment_terms}
//                         onChange={handleChange}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.payment_terms}
//                       </Form.Control.Feedback>
//                     </FloatingLabel>
//                     <FloatingLabel
//                       controlId="floatingInput"
//                       label="Notes"
//                       className="mb-3"
//                     >
//                       <Form.Control
//                         type="text"
//                         placeholder="0"
//                         isInvalid={errors.notes}
//                         name="notes"
//                         value={values.notes}
//                         onChange={handleChange}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.notes}
//                       </Form.Control.Feedback>
//                     </FloatingLabel>
//                     <FloatingLabel
//                       controlId="floatingInput"
//                       label="Tax Number"
//                       className="mb-3"
//                     >
//                       <Form.Control
//                         type="text"
//                         placeholder="0"
//                         isInvalid={errors.tax_number}
//                         name="tax_number"
//                         value={values.tax_number}
//                         onChange={handleChange}
//                       />
//                       <Form.Control.Feedback type="invalid">
//                         {errors.tax_number}
//                       </Form.Control.Feedback>
//                     </FloatingLabel>
//                   </Card.Body>
//                 </Card>
//                 {/* Call to Action Buttons*/}
//                 <div className="d-flex justify-content-between">
//                   <div>
//                     <Button
//                       variant="danger"
//                       size="lg"
//                       onClick={() => {
//                         setDeleteData({
//                           partner_id: rowdata.partner_id,
//                           partner_first_name: rowdata.partner_first_name,
//                         });
//                         setOpenModals(!openModals);
//                       }}
//                       className="me-1 mb-1 center"
//                     >
//                       Delete
//                     </Button>
//                     <Button
//                       variant="secondary"
//                       size="lg"
//                       type="reset"
//                       onClick={handleReset}
//                       className="me-1 mb-1 center"
//                     >
//                       Cancel
//                     </Button>
//                   </div>
//                   <Button
//                     variant="primary"
//                     // disabled={!dirty}
//                     size="lg"
//                     type="submit"
//                     className="me-1 mb-1 center"
//                   >
//                     Save
//                   </Button>
//                 </div>
//               </Form>
//             )}
//           </Formik>
//           <Modal
//             show={openModals}
//             onHide={() => setOpenModals(!openModals)}
//             centered
//           >
//             <Modal.Header className="font-weight-bold" closeButton>
//               Delete Product
//             </Modal.Header>
//             <Modal.Body className="text-left m-3">
//               <p className="mb-0 font-weight-bold">
//                 Are you sure you want to delete{" "}
//                 {deleteData["partner_first_name"]}?
//               </p>
//             </Modal.Body>
//             <Modal.Footer>
//               <Button
//                 variant="secondary"
//                 onClick={() => setOpenModals(!openModals)}
//               >
//                 Cancel
//               </Button>{" "}
//               <Button
//                 variant="danger"
//                 onClick={() => {
//                   deleteRecord(deleteData);
//                   setOpenModals(!openModals);
//                 }}
//               >
//                 Delete
//               </Button>
//             </Modal.Footer>
//           </Modal>
//         </>
//       ) : (
//         <></>
//       )}
//     </>
//   );
// };

function truncateTextByWordCount(text, wordLimit) {
  console.log(text.length);
  if (text.length > wordLimit) {
    return text.slice(0, wordLimit) + "...";
  } else {
    console.log(text);
    return text;
  }
}

const FilteringTable = (props) => {
  // console.log(props.data);

  const navigate = useNavigate();
  const { currencyFormater } = useCurrencyFormating();

  const { sumCalculation } = useSumwithDiscountAndTax();
  const { userPermission, isTeamAccount } = useAuth();
  console.log(userPermission);
  let estimatePermission =
    Object.keys(userPermission).includes("estimate") &&
    Object.keys(userPermission.estimate).includes("permission_key")
      ? userPermission.estimate.permission_key
      : "";

  let bookingPermission =
    Object.keys(userPermission).includes("booking") &&
    Object.keys(userPermission.booking).includes("permission_key")
      ? userPermission.booking.permission_key
      : "";
  let invoicePermission =
    Object.keys(userPermission).includes("invoice") &&
    Object.keys(userPermission.invoice).includes("permission_key")
      ? userPermission.invoice.permission_key
      : "";
  let requestPermission =
    Object.keys(userPermission).includes("request") &&
    Object.keys(userPermission.request).includes("permission_key")
      ? userPermission.request.permission_key
      : "";
  let usercurrency = localStorage.getItem("currency") || "USD";
  let InvoiceTotal = 0;

  const COLUMNS =
    props.type === "Invoice"
      ? [
          {
            Header: `${props.type} #`,
            accessor: "invoice_number",
            Cell: ({ value, row }) => {
              return (
                <div style={{}}>{truncateTextByWordCount(`${value}`, 10)}</div>
              );
            },
          },
          {
            Header: "Date",
            accessor: (elem) =>
              `${moment(new Date(elem.invoice_created_at)).format(
                "DD MMM YYYY"
              )}`,
          },
          {
            Header: () => (
              <div style={{ textAlign: "right" }}>{props.type} Total</div>
            ),
            accessor: "invoicetotal",
            Cell: ({ value, row }) => {
              console.log(value, row);
              let invoice = row.original;
              var sum = 0;
              var price_list = 1;
              if (
                invoice &&
                invoice.invoice_partner &&
                invoice.invoice_partner.partner_price_list &&
                invoice.invoice_partner.partner_price_list
                  .price_list_multiplier &&
                !isNaN(
                  invoice.invoice_partner.partner_price_list
                    .price_list_multiplier
                )
              ) {
                price_list = Number(
                  invoice.invoice_partner.partner_price_list
                    .price_list_multiplier
                );
              }
              for (var i = 0; i < invoice.invoice_order.length; i++) {
                if (
                  invoice.invoice_partner_type === "CUSTOMER" ||
                  invoice.invoice_partner_type === "LEAD"
                ) {
                  sum =
                    sum +
                    (invoice.invoice_order[i].item_quantity
                      ? Number(invoice.invoice_order[i].item_quantity)
                      : 1) *
                      (invoice.invoice_order[i].item_selling_price &&
                      !isNaN(invoice.invoice_order[i].item_selling_price)
                        ? Number(invoice.invoice_order[i].item_selling_price)
                        : 0) *
                      (price_list && !isNaN(price_list) ? price_list : 1);
                  console.log(sum);
                } else {
                  sum =
                    sum +
                    (invoice.invoice_order[i].item_quantity
                      ? Number(invoice.invoice_order[i].item_quantity)
                      : 1) *
                      (invoice.invoice_order[i].item_cost_price &&
                      !isNaN(invoice.invoice_order[i].item_cost_price)
                        ? Number(invoice.invoice_order[i].item_cost_price)
                        : 0);
                }
              }
              var sumWithDiscountAndTax = sumCalculation(sum, invoice);

              InvoiceTotal = sumWithDiscountAndTax;
              console.log(price_list, sum, sumWithDiscountAndTax);
              return (
                <div style={{ textAlign: "right" }}>
                  {/* {sumWithDiscountAndTax.toLocaleString("en-US", {
                    style: "currency",
                    currency:
                      invoice && invoice.invoice_currency_code
                        ? invoice.invoice_currency_code
                        : usercurrency,
                  })} */}
                  {truncateTextByWordCount(
                    currencyFormater(
                      sumWithDiscountAndTax,
                      invoice && invoice.invoice_currency_code
                        ? invoice.invoice_currency_code
                        : usercurrency
                    ),
                    25
                  )}
                </div>
              );
            },
          },

          {
            Header: () => <div style={{ textAlign: "right" }}>Pending</div>,

            accessor: "invoice_settled_amount",
            Cell: ({ value, row }) => {
              const num = InvoiceTotal - Number(value);
              if (Number.isNaN(num)) {
                return <div style={{ textAlign: "right" }}>{"-"}</div>;
              } else {
                return (
                  <div style={{ textAlign: "right" }}>
                    {/* {num.toLocaleString("en-US", {
                      style: "currency",
                      currency:
                        row.original && row.original.invoice_currency_code
                          ? row.original.invoice_currency_code
                          : usercurrency,
                    })} */}
                    {truncateTextByWordCount(
                      currencyFormater(
                        num,
                        row.original && row.original.invoice_currency_code
                          ? row.original.invoice_currency_code
                          : usercurrency
                      ),
                      25
                    )}
                  </div>
                );
              }
            },
          },
          {
            // Header: "Status",
            Header: () => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // textAlign: "center",
                }}
              >
                Status
              </div>
            ),
            width: 180,
            accessor: "status",
            Cell: ({ row }) => {
              console.log(InvoiceTotal, row.values);
              if (props.type === "Invoice") {
                if (
                  Number(row.original.invoice_grand_total) -
                    Number(row.original.invoice_settled_amount) <=
                  0
                  // row.original.invoice_payment_status === "PAID"
                ) {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1AB395",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 55,
                          textAlign: "center",
                        }}
                      >
                        Paid
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#DE5474",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 55,
                          textAlign: "center",
                        }}
                      >
                        Unpaid
                      </div>
                    </div>
                  );
                }
              } else if (props.type === "Estimate") {
                if (row.original.invoice_status === "ACCEPTED") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1AB395",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 65,
                          textAlign: "center",
                        }}
                      >
                        Accepted
                      </div>
                    </div>
                  );
                } else if (row.original.invoice_status === "CONVERTED") {
                  if (
                    row.original.invoice_children &&
                    row.original.invoice_children.some(
                      (e) => e.invoice_workflow_type === "INVOICE"
                    )
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            // backgroundColor: "#1a7bb8",
                            backgroundColor: "#3f80ea",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 65,
                            textAlign: "center",
                          }}
                        >
                          Invoiced
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#71BE1D",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            // width: 65,
                            textAlign: "center",
                          }}
                        >
                          {/* Converted to Job */}
                          Booking Created
                        </div>
                      </div>
                    );
                  }
                } else if (row.original.invoice_status === "OPEN") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#DE5474",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 65,
                          textAlign: "center",
                        }}
                      >
                        Open
                      </div>
                    </div>
                  );
                }
              } else if (props.type === "Booking") {
                if (row.original.invoice_status === "CONVERTED") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          // backgroundColor: "#1a7bb8",
                          backgroundColor: "#3f80ea",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 75,
                          textAlign: "center",
                        }}
                      >
                        Invoiced
                      </div>
                    </div>
                  );
                } else {
                  if (
                    row.original.invoice_time_status === "PAST" &&
                    row.original.invoice_visits &&
                    row.original.invoice_visits.length !== 0
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#807e7f",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 75,
                            textAlign: "center",
                          }}
                        >
                          Past
                        </div>
                      </div>
                    );
                  } else if (
                    row.original.invoice_time_status === "UPCOMING" &&
                    row.original.invoice_visits &&
                    row.original.invoice_visits.length !== 0
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#71BE1D",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 75,
                            textAlign: "center",
                          }}
                        >
                          Upcoming
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            padding: "4px",
                            color: "grey",
                            border: "1px solid #71BE1D",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            // width: 75,
                            textAlign: "center",
                          }}
                        >
                          Unscheduled
                        </div>
                      </div>
                    );
                  }
                }
              }
            },
          },
          {
            // Header: "Actions",
            width: 180,
            Header: () => (
              <div
                style={{
                  // display: "flex",
                  // justifyContent: "center",
                  textAlign: "center",
                }}
              >
                Actions
              </div>
            ),
            accessor: "actions",
            disableSortBy: true,
            Cell: ({ row }) => {
              // const num = parseInt(value);
              return (
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <ButtonGroup>
                    <Button
                      size="sm"
                      variant="light"
                      style={{ backgroundColor: "white" }}
                      className="border"
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log("ddd");
                        mixpanel.track(
                          "Individual Invoice Row (Partner Detail Web)",
                          {
                            ...row.original,
                          }
                        );
                        navigate(
                          `/${props.type.toLowerCase()}s/` +
                            row.original.invoice_id,
                          {
                            state: { row: row.original, items: props.items },
                          }
                        );
                      }}
                    >
                      View
                    </Button>

                    {invoicePermission.includes("EDIT") ? (
                      <Button
                        size="sm"
                        variant="light"
                        style={{ backgroundColor: "white" }}
                        className="border"
                        onClick={(e) => {
                          e.stopPropagation();
                          mixpanel.track(
                            `Individual Invoice Row Edit (${props.type} Detail Web)`,
                            {
                              ...row.original,
                            }
                          );
                          navigate(
                            `/${props.type.toLowerCase()}s/edit/` +
                              row.original.invoice_id,
                            {
                              state: row.original,
                            }
                          );
                        }}
                      >
                        Edit
                      </Button>
                    ) : (
                      <></>
                    )}
                    {invoicePermission.includes("DELETE") ? (
                      <Button
                        size="sm"
                        variant="light"
                        style={{ backgroundColor: "white" }}
                        className="border"
                        onClick={(e) => {
                          e.stopPropagation();
                          mixpanel.track(
                            `Individual Invoice Row Delete (${props.type} Detail Web)`,
                            {
                              id: row.original.invoice_id,
                            }
                          );
                          console.log(row.original.invoice_id);
                          props.setdeleteinvoiceid(row.original.invoice_id);
                          props.setInvoicedeleteModals(true);
                        }}
                      >
                        Delete
                      </Button>
                    ) : (
                      <></>
                    )}
                  </ButtonGroup>
                </div>
              );
            },
          },
        ]
      : props.type === "Booking"
      ? [
          {
            Header: `${props.type} #`,
            accessor: "invoice_number",
            width: 80,
            Cell: ({ value, row }) => {
              return (
                <div style={{}}>{truncateTextByWordCount(`${value}`, 10)}</div>
              );
            },
          },
          {
            // Header: " Pending",
            Header: () => (
              <div
                style={
                  {
                    // textAlign: "left",
                  }
                }
              >
                {/* Date */}
                Start Time
              </div>
            ),
            width: 200,
            accessor: "jobdates",
            // accessor: (elem) => elem.invoice_settled_amount,
            Cell: ({ row }) => {
              if (
                row.original.invoice_visits &&
                row.original.invoice_visits.length !== 0
              ) {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      // alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12.5,
                      }}
                    >
                      {`${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("hh:mm a DD MMM")}`}
                    </div>
                    {/* <div style={{ fontSize: 12, fontStyle: "italic" }}>to</div> */}
                    {/* <div style={{ fontSize: 13 }}>
                      {` ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("h:mm A")}  -  ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_end_time
                        )
                      ).format("h:mm A")}`}
                    </div> */}
                  </div>
                );
              } else {
                return <div style={{ fontSize: 12.5 }}>-</div>;
              }
            },
          },
          {
            // Header: " Pending",
            Header: () => <div>End Time</div>,
            width: 200,
            accessor: "jobtime",
            // accessor: (elem) => elem.invoice_settled_amount,
            Cell: ({ row }) => {
              if (
                row.original.invoice_visits &&
                row.original.invoice_visits.length !== 0
              ) {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      // alignItems: "center",
                      // marginLeft: 10,
                    }}
                  >
                    {/* <div style={{}}>
                      {`${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("DD MMM YYYY")}`}
                    </div> */}
                    {/* <div style={{ fontSize: 12, fontStyle: "italic" }}>to</div> */}
                    <div style={{ fontSize: 12.5 }}>
                      {/* {` ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("h:mm A")}  -  ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_end_time
                        )
                      ).format("h:mm A")}`} */}
                      {`${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_end_time
                        )
                      ).format("hh:mm a DD MMM")}`}
                    </div>
                  </div>
                );
              } else {
                return <div style={{ marginLeft: 10, fontSize: 12.5 }}>-</div>;
              }
            },
          },
          {
            Header: () => (
              <div style={{ textAlign: "right" }}>{props.type} Total</div>
            ),
            accessor: "invoicetotal",
            Cell: ({ value, row }) => {
              console.log(value, row);
              let invoice = row.original;
              var sum = 0;
              var price_list = 1;
              if (
                invoice &&
                invoice.invoice_partner &&
                invoice.invoice_partner.partner_price_list &&
                invoice.invoice_partner.partner_price_list
                  .price_list_multiplier &&
                !isNaN(
                  invoice.invoice_partner.partner_price_list
                    .price_list_multiplier
                )
              ) {
                price_list = Number(
                  invoice.invoice_partner.partner_price_list
                    .price_list_multiplier
                );
              }
              for (var i = 0; i < invoice.invoice_order.length; i++) {
                if (
                  invoice.invoice_partner_type === "CUSTOMER" ||
                  invoice.invoice_partner_type === "LEAD"
                ) {
                  sum =
                    sum +
                    (invoice.invoice_order[i].item_quantity
                      ? Number(invoice.invoice_order[i].item_quantity)
                      : 1) *
                      (invoice.invoice_order[i].item_selling_price &&
                      !isNaN(invoice.invoice_order[i].item_selling_price)
                        ? Number(invoice.invoice_order[i].item_selling_price)
                        : 0) *
                      (price_list && !isNaN(price_list) ? price_list : 1);
                  console.log(sum);
                } else {
                  sum =
                    sum +
                    (invoice.invoice_order[i].item_quantity
                      ? Number(invoice.invoice_order[i].item_quantity)
                      : 1) *
                      (invoice.invoice_order[i].item_cost_price &&
                      !isNaN(invoice.invoice_order[i].item_cost_price)
                        ? Number(invoice.invoice_order[i].item_cost_price)
                        : 0);
                }
              }
              var sumWithDiscountAndTax = sumCalculation(sum, invoice);
              InvoiceTotal = sumWithDiscountAndTax;
              console.log(price_list, sum, sumWithDiscountAndTax);
              return (
                <div style={{ textAlign: "right" }}>
                  {/* {sumWithDiscountAndTax.toLocaleString("en-US", {
                    style: "currency",
                    currency:
                      invoice && invoice.invoice_currency_code
                        ? invoice.invoice_currency_code
                        : usercurrency,
                  })} */}
                  {truncateTextByWordCount(
                    currencyFormater(
                      sumWithDiscountAndTax,
                      invoice && invoice.invoice_currency_code
                        ? invoice.invoice_currency_code
                        : usercurrency
                    ),
                    25
                  )}
                </div>
              );
            },
          },

          {
            // Header: "Status",
            Header: () => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // textAlign: "center",
                }}
              >
                Status
              </div>
            ),
            width: 180,
            accessor: "status",
            Cell: ({ row }) => {
              console.log(InvoiceTotal, row.values);
              if (props.type === "Invoice") {
                if (
                  Number(row.original.invoice_grand_total) -
                    Number(row.original.invoice_settled_amount) <=
                  0
                  // row.original.invoice_payment_status === "PAID"
                ) {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1AB395",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 55,
                          textAlign: "center",
                        }}
                      >
                        Paid
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#DE5474",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 55,
                          textAlign: "center",
                        }}
                      >
                        Unpaid
                      </div>
                    </div>
                  );
                }
              } else if (props.type === "Estimate") {
                if (row.original.invoice_status === "ACCEPTED") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1AB395",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 65,
                          textAlign: "center",
                        }}
                      >
                        Accepted
                      </div>
                    </div>
                  );
                } else if (row.original.invoice_status === "CONVERTED") {
                  if (
                    row.original.invoice_children &&
                    row.original.invoice_children.some(
                      (e) => e.invoice_workflow_type === "INVOICE"
                    )
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            // backgroundColor: "#1a7bb8",
                            backgroundColor: "#3f80ea",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 65,
                            textAlign: "center",
                          }}
                        >
                          Invoiced
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#71BE1D",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            // width: 65,
                            textAlign: "center",
                          }}
                        >
                          {/* Converted to Job */}
                          Booking Created
                        </div>
                      </div>
                    );
                  }
                } else if (row.original.invoice_status === "OPEN") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#DE5474",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 65,
                          textAlign: "center",
                        }}
                      >
                        Open
                      </div>
                    </div>
                  );
                }
              } else if (props.type === "Booking") {
                if (row.original.invoice_status === "CONVERTED") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          // backgroundColor: "#1a7bb8",
                          backgroundColor: "#3f80ea",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 75,
                          textAlign: "center",
                        }}
                      >
                        Invoiced
                      </div>
                    </div>
                  );
                } else {
                  if (
                    row.original.invoice_time_status === "PAST" &&
                    row.original.invoice_visits &&
                    row.original.invoice_visits.length !== 0
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#807e7f",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 75,
                            textAlign: "center",
                          }}
                        >
                          Past
                        </div>
                      </div>
                    );
                  } else if (
                    row.original.invoice_time_status === "UPCOMING" &&
                    row.original.invoice_visits &&
                    row.original.invoice_visits.length !== 0
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#71BE1D",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 75,
                            textAlign: "center",
                          }}
                        >
                          Upcoming
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            padding: "4px",
                            color: "grey",
                            border: "1px solid #71BE1D",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            // width: 75,
                            textAlign: "center",
                          }}
                        >
                          Unscheduled
                        </div>
                      </div>
                    );
                  }
                }
              }
            },
          },
          {
            // Header: "Actions",
            // width: 200,
            Header: () => (
              <div
                style={{
                  // display: "flex",
                  // justifyContent: "center",
                  textAlign: "center",
                }}
              >
                Actions
              </div>
            ),
            width: 180,
            accessor: "actions",
            disableSortBy: true,
            Cell: ({ row }) => {
              // const num = parseInt(value);
              return (
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <ButtonGroup>
                    <Button
                      size="sm"
                      variant="light"
                      style={{ backgroundColor: "white" }}
                      className="border"
                      onClick={(e) => {
                        e.stopPropagation();
                        mixpanel.track(
                          `Individual Booking Row (${props.type} Detail Web)`,
                          {
                            ...row.original,
                          }
                        );
                        console.log("ddd");
                        navigate(
                          `/${props.type.toLowerCase()}s/` +
                            row.original.invoice_id,
                          {
                            state: { row: row.original, items: props.items },
                          }
                        );
                      }}
                    >
                      View
                    </Button>

                    {bookingPermission.includes("EDIT") ? (
                      <Button
                        size="sm"
                        variant="light"
                        style={{ backgroundColor: "white" }}
                        className="border"
                        onClick={(e) => {
                          e.stopPropagation();
                          mixpanel.track(
                            `Individual Booking Row Edit (${props.type} Detail Web)`,
                            {
                              ...row.original,
                            }
                          );
                          navigate(
                            `/${props.type.toLowerCase()}s/edit/` +
                              row.original.invoice_id,
                            {
                              state: row.original,
                            }
                          );
                        }}
                      >
                        Edit
                      </Button>
                    ) : (
                      <></>
                    )}
                    {bookingPermission.includes("DELETE") ? (
                      <Button
                        size="sm"
                        variant="light"
                        style={{ backgroundColor: "white" }}
                        className="border"
                        onClick={(e) => {
                          e.stopPropagation();
                          mixpanel.track(
                            `Individual Booking Row Delete (${props.type} Detail Web)`,
                            {
                              id: row.original.invoice_id,
                            }
                          );
                          console.log(row.original.invoice_id);
                          props.setdeleteinvoiceid(row.original.invoice_id);
                          props.setInvoicedeleteModals(true);
                        }}
                      >
                        Delete
                      </Button>
                    ) : (
                      <></>
                    )}
                  </ButtonGroup>
                </div>
              );
            },
          },
        ]
      : props.type === "Request"
      ? [
          {
            Header: `${props.type} #`,
            accessor: "invoice_number",
            width: 100,
            Cell: ({ value, row }) => {
              return (
                <div style={{}}>{truncateTextByWordCount(`${value}`, 10)}</div>
              );
            },
          },
          {
            // Header: " Pending",
            Header: () => (
              <div
                style={
                  {
                    // textAlign: "left",
                  }
                }
              >
                {/* Date */}
                Start Time
              </div>
            ),
            width: 150,
            accessor: "jobdates",
            // accessor: (elem) => elem.invoice_settled_amount,
            Cell: ({ row }) => {
              if (
                row.original.invoice_visits &&
                row.original.invoice_visits.length !== 0
              ) {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      // alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12.5,
                      }}
                    >
                      {`${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("hh:mm a DD MMM")}`}
                    </div>
                    {/* <div style={{ fontSize: 12, fontStyle: "italic" }}>to</div> */}
                    {/* <div style={{ fontSize: 13 }}>
                      {` ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("h:mm A")}  -  ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_end_time
                        )
                      ).format("h:mm A")}`}
                    </div> */}
                  </div>
                );
              } else {
                return <div style={{ fontSize: 12.5 }}>-</div>;
              }
            },
          },
          {
            // Header: " Pending",
            Header: () => <div>End Time</div>,
            width: 150,
            accessor: "jobtime",
            // accessor: (elem) => elem.invoice_settled_amount,
            Cell: ({ row }) => {
              if (
                row.original.invoice_visits &&
                row.original.invoice_visits.length !== 0
              ) {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      // alignItems: "center",
                      // marginLeft: 10,
                    }}
                  >
                    {/* <div style={{}}>
                      {`${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("DD MMM YYYY")}`}
                    </div> */}
                    {/* <div style={{ fontSize: 12, fontStyle: "italic" }}>to</div> */}
                    <div style={{ fontSize: 12.5 }}>
                      {/* {` ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("h:mm A")}  -  ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_end_time
                        )
                      ).format("h:mm A")}`} */}
                      {`${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_end_time
                        )
                      ).format("hh:mm a DD MMM")}`}
                    </div>
                  </div>
                );
              } else {
                return <div style={{ marginLeft: 10, fontSize: 12.5 }}>-</div>;
              }
            },
          },

          {
            // Header: "Status",
            Header: () => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // textAlign: "center",
                }}
              >
                Status
              </div>
            ),
            width: 140,
            accessor: "status",
            Cell: ({ row }) => {
              return (
                <>
                  {row.original.invoice_status === "CONVERTED" ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#3f80ea",
                            padding: "4px",
                            marginLeft: "5px",
                            color: "white",
                            borderRadius: "5px",
                            // marginBottom: "13.2px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 75,
                            textAlign: "center",
                          }}
                        >
                          Converted
                        </div>
                      </div>
                    </>
                  ) : row.original.invoice_status === "ARCHIVED" ? (
                    <div
                      style={{
                        // width: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#807e7f",
                          padding: "4px",
                          // marginBottom: "13.2px",
                          marginLeft: "5px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 75,
                          textAlign: "center",
                        }}
                      >
                        Archived
                      </div>
                    </div>
                  ) : row.original.invoice_partner_type === "LEAD" ? (
                    <div
                      style={{
                        // width: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#71BE1D",
                          padding: "4px",
                          color: "white",
                          // marginBottom: "13.2px",
                          marginLeft: "5px",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 75,
                          textAlign: "center",
                        }}
                      >
                        Lead
                      </div>
                    </div>
                  ) : row.original.invoice_status === "OPEN" ? (
                    <div
                      style={{
                        // width: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#DE5474",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 75,

                          textAlign: "center",
                        }}
                      >
                        Open
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            },
          },
          {
            // Header: "Actions",
            // width: 200,
            Header: () => (
              <div
                style={{
                  // display: "flex",
                  // justifyContent: "center",
                  textAlign: "center",
                }}
              >
                Actions
              </div>
            ),
            width: 150,
            accessor: "actions",
            disableSortBy: true,
            Cell: ({ row }) => {
              // const num = parseInt(value);
              return (
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <ButtonGroup>
                    <Button
                      size="sm"
                      variant="light"
                      style={{ backgroundColor: "white" }}
                      className="border"
                      onClick={(e) => {
                        e.stopPropagation();
                        mixpanel.track(
                          `Individual Booking Row (${props.type} Detail Web)`,
                          {
                            ...row.original,
                          }
                        );
                        console.log("ddd");
                        navigate(
                          `/${props.type.toLowerCase()}s/` +
                            row.original.invoice_id,
                          {
                            state: { row: row.original, items: props.items },
                          }
                        );
                      }}
                    >
                      View
                    </Button>

                    {requestPermission.includes("EDIT") ? (
                      <Button
                        size="sm"
                        variant="light"
                        style={{ backgroundColor: "white" }}
                        className="border"
                        onClick={(e) => {
                          e.stopPropagation();
                          mixpanel.track(
                            `Individual Booking Row Edit (${props.type} Detail Web)`,
                            {
                              ...row.original,
                            }
                          );
                          navigate(
                            `/${props.type.toLowerCase()}s/edit/` +
                              row.original.invoice_id,
                            {
                              state: row.original,
                            }
                          );
                        }}
                      >
                        Edit
                      </Button>
                    ) : (
                      <></>
                    )}
                    {requestPermission.includes("DELETE") ? (
                      <Button
                        size="sm"
                        variant="light"
                        style={{ backgroundColor: "white" }}
                        className="border"
                        onClick={(e) => {
                          e.stopPropagation();
                          mixpanel.track(
                            `Individual Booking Row Delete (${props.type} Detail Web)`,
                            {
                              id: row.original.invoice_id,
                            }
                          );
                          console.log(row.original.invoice_id);
                          props.setdeleteinvoiceid(row.original.invoice_id);
                          props.setInvoicedeleteModals(true);
                        }}
                      >
                        Delete
                      </Button>
                    ) : (
                      <></>
                    )}
                  </ButtonGroup>
                </div>
              );
            },
          },
        ]
      : [
          {
            Header: `${props.type} #`,
            accessor: "invoice_number",
            Cell: ({ value, row }) => {
              return (
                <div style={{}}>{truncateTextByWordCount(`${value}`, 10)}</div>
              );
            },
          },
          {
            Header: "Date",
            accessor: (elem) =>
              `${moment(new Date(elem.invoice_created_at)).format(
                "DD MMM YYYY"
              )}`,
          },
          {
            Header: () => (
              <div style={{ textAlign: "right" }}>{props.type} Total</div>
            ),
            accessor: "invoicetotal",
            Cell: ({ value, row }) => {
              console.log(value, row);
              let invoice = row.original;
              var sum = 0;
              var price_list = 1;
              if (
                invoice &&
                invoice.invoice_partner &&
                invoice.invoice_partner.partner_price_list &&
                invoice.invoice_partner.partner_price_list
                  .price_list_multiplier &&
                !isNaN(
                  invoice.invoice_partner.partner_price_list
                    .price_list_multiplier
                )
              ) {
                price_list = Number(
                  invoice.invoice_partner.partner_price_list
                    .price_list_multiplier
                );
              }
              for (var i = 0; i < invoice.invoice_order.length; i++) {
                if (
                  invoice.invoice_partner_type === "CUSTOMER" ||
                  invoice.invoice_partner_type === "LEAD"
                ) {
                  sum =
                    sum +
                    (invoice.invoice_order[i].item_quantity
                      ? Number(invoice.invoice_order[i].item_quantity)
                      : 1) *
                      (invoice.invoice_order[i].item_selling_price &&
                      !isNaN(invoice.invoice_order[i].item_selling_price)
                        ? Number(invoice.invoice_order[i].item_selling_price)
                        : 0) *
                      (price_list && !isNaN(price_list) ? price_list : 1);
                  console.log(sum);
                } else {
                  sum =
                    sum +
                    (invoice.invoice_order[i].item_quantity
                      ? Number(invoice.invoice_order[i].item_quantity)
                      : 1) *
                      (invoice.invoice_order[i].item_cost_price &&
                      !isNaN(invoice.invoice_order[i].item_cost_price)
                        ? Number(invoice.invoice_order[i].item_cost_price)
                        : 0);
                }
              }
              var sumWithDiscountAndTax = sumCalculation(sum, invoice);
              InvoiceTotal = sumWithDiscountAndTax;
              console.log(price_list, sum, sumWithDiscountAndTax);
              return (
                <div style={{ textAlign: "right" }}>
                  {/* {sumWithDiscountAndTax.toLocaleString("en-US", {
                    style: "currency",
                    currency:
                      invoice && invoice.invoice_currency_code
                        ? invoice.invoice_currency_code
                        : usercurrency,
                  })} */}
                  {truncateTextByWordCount(
                    currencyFormater(
                      sumWithDiscountAndTax,
                      invoice && invoice.invoice_currency_code
                        ? invoice.invoice_currency_code
                        : usercurrency
                    ),
                    25
                  )}
                </div>
              );
            },
          },

          {
            // Header: "Status",
            Header: () => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // textAlign: "center",
                }}
              >
                Status
              </div>
            ),
            width: 180,
            accessor: "status",
            Cell: ({ row }) => {
              console.log(InvoiceTotal, row.values);
              if (props.type === "Invoice") {
                if (
                  Number(row.original.invoice_grand_total) -
                    Number(row.original.invoice_settled_amount) <=
                  0
                  // row.original.invoice_payment_status === "PAID"
                ) {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1AB395",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 55,
                          textAlign: "center",
                        }}
                      >
                        Paid
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#DE5474",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 55,
                          textAlign: "center",
                        }}
                      >
                        Unpaid
                      </div>
                    </div>
                  );
                }
              } else if (props.type === "Estimate") {
                if (row.original.invoice_status === "ACCEPTED") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#1AB395",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 65,
                          textAlign: "center",
                        }}
                      >
                        Accepted
                      </div>
                    </div>
                  );
                } else if (row.original.invoice_status === "CONVERTED") {
                  if (
                    row.original.invoice_children &&
                    row.original.invoice_children.some(
                      (e) => e.invoice_workflow_type === "INVOICE"
                    )
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            // backgroundColor: "#1a7bb8",
                            backgroundColor: "#3f80ea",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 65,
                            textAlign: "center",
                          }}
                        >
                          Invoiced
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#71BE1D",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            // width: 65,
                            textAlign: "center",
                          }}
                        >
                          {/* Converted to Job */}
                          Booking Created
                        </div>
                      </div>
                    );
                  }
                } else if (row.original.invoice_status === "OPEN") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#DE5474",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 65,
                          textAlign: "center",
                        }}
                      >
                        Open
                      </div>
                    </div>
                  );
                }
              } else if (props.type === "Booking") {
                if (row.original.invoice_status === "CONVERTED") {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          // backgroundColor: "#1a7bb8",
                          backgroundColor: "#3f80ea",
                          padding: "4px",
                          color: "white",
                          borderRadius: "5px",
                          fontSize: "10px",
                          fontWeight: "bold",
                          width: 75,
                          textAlign: "center",
                        }}
                      >
                        Invoiced
                      </div>
                    </div>
                  );
                } else {
                  if (
                    row.original.invoice_time_status === "PAST" &&
                    row.original.invoice_visits &&
                    row.original.invoice_visits.length !== 0
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#807e7f",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 75,
                            textAlign: "center",
                          }}
                        >
                          Past
                        </div>
                      </div>
                    );
                  } else if (
                    row.original.invoice_time_status === "UPCOMING" &&
                    row.original.invoice_visits &&
                    row.original.invoice_visits.length !== 0
                  ) {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#71BE1D",
                            padding: "4px",
                            color: "white",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            width: 75,
                            textAlign: "center",
                          }}
                        >
                          Upcoming
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            padding: "4px",
                            color: "grey",
                            border: "1px solid #71BE1D",
                            borderRadius: "5px",
                            fontSize: "10px",
                            fontWeight: "bold",
                            // width: 75,
                            textAlign: "center",
                          }}
                        >
                          Unscheduled
                        </div>
                      </div>
                    );
                  }
                }
              }
            },
          },
          {
            // Header: "Actions",
            width: 180,
            Header: () => (
              <div
                style={{
                  // display: "flex",
                  // justifyContent: "center",
                  textAlign: "center",
                }}
              >
                Actions
              </div>
            ),
            accessor: "actions",
            disableSortBy: true,
            Cell: ({ row }) => {
              // const num = parseInt(value);
              return (
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <ButtonGroup>
                    <Button
                      size="sm"
                      variant="light"
                      style={{ backgroundColor: "white" }}
                      className="border"
                      onClick={(e) => {
                        e.stopPropagation();
                        mixpanel.track(
                          `Individual Estimate Row (${props.type} Detail Web)`,
                          {
                            ...row.original,
                          }
                        );
                        console.log("ddd");
                        navigate(
                          `/${props.type.toLowerCase()}s/` +
                            row.original.invoice_id,
                          {
                            state: { row: row.original, items: props.items },
                          }
                        );
                      }}
                    >
                      View
                    </Button>

                    {estimatePermission.includes("EDIT") ? (
                      <Button
                        size="sm"
                        variant="light"
                        style={{ backgroundColor: "white" }}
                        className="border"
                        onClick={(e) => {
                          e.stopPropagation();
                          mixpanel.track(
                            `Individual Estimate Row edit (${props.type} Detail Web)`,
                            {
                              ...row.original,
                            }
                          );
                          navigate(
                            `/${props.type.toLowerCase()}s/edit/` +
                              row.original.invoice_id,
                            {
                              state: row.original,
                            }
                          );
                        }}
                      >
                        Edit
                      </Button>
                    ) : (
                      <></>
                    )}
                    {estimatePermission.includes("DELETE") ? (
                      <Button
                        size="sm"
                        variant="light"
                        style={{ backgroundColor: "white" }}
                        className="border"
                        onClick={(e) => {
                          e.stopPropagation();
                          mixpanel.track(
                            `Individual Estimate Row Delete (${props.type} Detail Web)`,
                            {
                              id: row.original.invoice_id,
                            }
                          );
                          console.log(row.original.invoice_id);
                          props.setdeleteinvoiceid(row.original.invoice_id);
                          props.setInvoicedeleteModals(true);
                        }}
                      >
                        Delete
                      </Button>
                    ) : (
                      <></>
                    )}
                  </ButtonGroup>
                </div>
              );
            },
          },
        ];

  const columns = useMemo(() => COLUMNS, []);
  const data = props.data;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <div style={{ overflowY: "hidden", overflowX: "scroll" }}>
      <Table striped bordered size="sm" {...getTableProps()}>
        <thead style={{ color: "black", backgroundColor: "#F2f2f4" }}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getFooterGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} style={{ borderTop: 0 }}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        width: cell.column.width,
                        color: "black",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

const Invoices = (props) => {
  let data = props.dataInvoice;
  const filter = props.filter;
  const navigate = useNavigate();
  const { isTeamAccount, userPermission } = useAuth();
  let invoicePermission =
    Object.keys(userPermission).includes("invoice") &&
    Object.keys(userPermission.invoice).includes("permission_key")
      ? userPermission.invoice.permission_key
      : "";
  console.log(props.filter);

  if (filter) {
    data = props.dataInvoice.filter(
      (elem) => elem.invoice_payment_status === filter
    );
  }

  return (
    <>
      {/* <Card>
        <Card.Header> */}
      {props.loading ? (
        <>
          {" "}
          <div
            className="d-flex justify-content-center"
            style={{ height: 400, alignItems: "center" }}
          >
            <Spinner
              animation="border"
              variant="dark"
              size="md"
              className="me-2"
            />
          </div>
        </>
      ) : (
        <>
          {data.length === 0 ? (
            <div
              className="d-flex justify-content-center align-items-center p-3 flex-column "
              id={"Emptybox"}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                if (invoicePermission.includes("ADD")) {
                  mixpanel.track(`Add Invoice (${props.type} Detail Web)`);
                  navigate("/invoices/add/", {
                    state: {
                      invoice_partner: props.rowdata,
                      invoice_partner_id: props.rowdata.partner_id,
                      invoice_partner_type: props.rowdata.partner_type,
                      invoice_order: [],
                    },
                  });
                }
              }}
            >
              <p
                // className="mt-2"
                style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
              >
                {/* No Invoice Added. Start by Clicking On Add Invoice */}
                Create an Invoice
              </p>

              <img
                src={empty_invoice}
                style={{ height: "80px", marginTop: 30, marginBottom: 20 }}
                alt="empty_invoice"
              ></img>

              {invoicePermission.includes("ADD") ? (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    mixpanel.track(`Add Invoice (${props.type} Detail Web)`);
                    navigate("/invoices/add/", {
                      state: {
                        invoice_partner: props.rowdata,
                        invoice_partner_id: props.rowdata.partner_id,
                        invoice_partner_type: props.rowdata.partner_type,
                        invoice_order: [],
                      },
                    });
                  }}
                  style={{ padding: 10, fontWeight: "bold" }}
                >
                  Add Invoice
                </Button>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <FilteringTable
              type="Invoice"
              data={data}
              setInvoicedeleteModals={props.setInvoicedeleteModals}
              setdeleteinvoiceid={props.setdeleteinvoiceid}
              filter={props.filter}
            />
          )}
        </>
      )}

      {/* </Card.Header>
      </Card> */}
    </>
  );
};

const Estimates = (props) => {
  let data = props.dataEstimate;
  const filter = props.filter;
  console.log(props.filter);
  const navigate = useNavigate();
  const { userPermission } = useAuth();
  let estimatePermission =
    Object.keys(userPermission).includes("estimate") &&
    Object.keys(userPermission.estimate).includes("permission_key")
      ? userPermission.estimate.permission_key
      : "";
  if (filter) {
    data = props.dataEstimate.filter((elem) => elem.invoice_status === filter);
  }
  return (
    <>
      {/* <Card>
        <Card.Header> */}
      {data.length === 0 ? (
        <div
          className="d-flex justify-content-center align-items-center p-3 flex-column "
          id={"Emptybox"}
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            if (estimatePermission.includes("ADD")) {
              mixpanel.track(`Add Estimate (${props.type} Detail Web)`);
              navigate("/estimates/add/", {
                state: {
                  invoice_partner: props.rowdata,
                  invoice_partner_id: props.rowdata.partner_id,
                  invoice_partner_type: props.rowdata.partner_type,
                  invoice_order: [],
                },
              });
            }
          }}
        >
          <p
            // className="mt-2"
            style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
          >
            {/* No Invoice Added. Start by Clicking On Add Invoice */}
            Create an Estimate
          </p>

          <img
            src={empty_invoice}
            style={{ height: "80px", marginTop: 30, marginBottom: 20 }}
            alt="empty_invoice"
          ></img>

          {estimatePermission.includes("ADD") ? (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                mixpanel.track(`Add Estimate (${props.type} Detail Web)`);
                navigate("/estimates/add/", {
                  state: {
                    invoice_partner: props.rowdata,
                    invoice_partner_id: props.rowdata.partner_id,
                    invoice_partner_type: props.rowdata.partner_type,
                    invoice_order: [],
                  },
                });
              }}
              style={{ padding: 10, fontWeight: "bold" }}
            >
              Add Estimate
            </Button>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <FilteringTable
          type="Estimate"
          data={data}
          setInvoicedeleteModals={props.setInvoicedeleteModals}
          setdeleteinvoiceid={props.setdeleteinvoiceid}
        />
      )}
      {/* </Card.Header>
      </Card> */}
    </>
  );
};

const Orders = (props) => {
  let data = props.dataOrder;
  const filter = props.filter;
  console.log(props);
  const { isTeamAccount, userPermission } = useAuth();
  let bookingPermission =
    Object.keys(userPermission).includes("booking") &&
    Object.keys(userPermission.booking).includes("permission_key")
      ? userPermission.booking.permission_key
      : "";
  const navigate = useNavigate();
  if (filter) {
    data = props.dataOrder.filter((elem) => {
      if (filter === "CONVERTED") {
        return elem.invoice_status === filter;
      } else if (filter === "UNSCHEDULED") {
        return (
          elem.invoice_time_status === "PAST" &&
          elem.invoice_latest_start_time === -1
        );
      } else if (filter === "PAST") {
        return (
          elem.invoice_time_status === "PAST" &&
          elem.invoice_latest_start_time !== -1
        );
      } else {
        return elem.invoice_time_status === filter;
      }
    });
  }
  return (
    <>
      {/* <Card>
        <Card.Header> */}
      {data.length === 0 ? (
        <div
          className="d-flex justify-content-center align-items-center p-3 flex-column "
          id={"Emptybox"}
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            if (bookingPermission.includes("ADD")) {
              mixpanel.track(`Add Booking (${props.type} Detail Web)`);
              navigate("/bookings/add/", {
                state: {
                  invoice_partner: props.rowdata,
                  invoice_partner_id: props.rowdata.partner_id,
                  invoice_partner_type: props.rowdata.partner_type,
                  invoice_order: [],
                },
              });
            }
          }}
        >
          <p
            // className="mt-2"
            style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
          >
            {/* No Invoice Added. Start by Clicking On Add Invoice */}
            Create a Booking
          </p>

          <img
            src={empty_invoice}
            style={{ height: "80px", marginTop: 30, marginBottom: 20 }}
            alt="empty_invoice"
          ></img>

          {bookingPermission.includes("ADD") ? (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                mixpanel.track(`Add Booking (${props.type} Detail Web)`);
                navigate("/bookings/add/", {
                  state: {
                    invoice_partner: props.rowdata,
                    invoice_partner_id: props.rowdata.partner_id,
                    invoice_partner_type: props.rowdata.partner_type,
                    invoice_order: [],
                  },
                });
              }}
              style={{ padding: 10, fontWeight: "bold" }}
            >
              Add Booking
            </Button>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <FilteringTable
          type="Booking"
          data={data}
          setInvoicedeleteModals={props.setInvoicedeleteModals}
          setdeleteinvoiceid={props.setdeleteinvoiceid}
          filter={props.filter}
        />
      )}
      {/* </Card.Header>
      </Card> */}
    </>
  );
};
const Requests = (props) => {
  let data = props.dataOrder;
  const filter = props.filter;
  console.log(props);
  const navigate = useNavigate();
  const { isTeamAccount, userPermission } = useAuth();
  let requestPermission =
    Object.keys(userPermission).includes("request") &&
    Object.keys(userPermission.request).includes("permission_key")
      ? userPermission.request.permission_key
      : "";
  if (filter) {
    data = props.dataOrder.filter((elem) => {
      return elem.invoice_status === filter;
    });
  }
  return (
    <>
      {/* <Card>
        <Card.Header> */}
      {data.length === 0 ? (
        <div
          className="d-flex justify-content-center align-items-center p-3 flex-column "
          id={"Emptybox"}
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            if (requestPermission.includes("ADD")) {
              mixpanel.track(`Add Request (${props.type} Detail Web)`);
              navigate("/requests/add/", {
                state: {
                  invoice_partner: props.rowdata,
                  invoice_partner_id: props.rowdata.partner_id,
                  invoice_partner_type: props.rowdata.partner_type,
                  invoice_order: [],
                },
              });
            }
          }}
        >
          <p
            // className="mt-2"
            style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
          >
            {/* No Invoice Added. Start by Clicking On Add Invoice */}
            Create a Request
          </p>

          <img
            src={empty_invoice}
            style={{ height: "80px", marginTop: 30, marginBottom: 20 }}
            alt="empty_invoice"
          ></img>

          {requestPermission.includes("ADD") ? (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                mixpanel.track(`Add Requests (${props.type} Detail Web)`);
                navigate("/requests/add/", {
                  state: {
                    invoice_partner: props.rowdata,
                    invoice_partner_id: props.rowdata.partner_id,
                    invoice_partner_type: props.rowdata.partner_type,
                    invoice_order: [],
                  },
                });
              }}
              style={{ padding: 10, fontWeight: "bold" }}
            >
              Add Request
            </Button>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <FilteringTable
          type="Request"
          data={data}
          setInvoicedeleteModals={props.setInvoicedeleteModals}
          setdeleteinvoiceid={props.setdeleteinvoiceid}
          filter={props.filter}
        />
      )}
      {/* </Card.Header>
      </Card> */}
    </>
  );
};

const ViewPartner = (props) => {
  let usercurrency = localStorage.getItem("currency") || "USD";
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [pending, setpending] = useState(0);
  const [totalinvoice, settotalinvoice] = useState(0);
  const { isOpen } = useSidebar();
  const [openModals, setOpenModals] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [rowdata, setRowdata] = useState();
  const [token, settoken] = useState("");
  const [pricelist, setPricelist] = useState({});
  const [options, setOptions] = useState([]);
  const notyf = useContext(NotyfContext);
  const [convertbtnLoad, setConvertbtnLoad] = useState(false);
  // const [floading, setfloading] = useState(true);
  const [loading, setloading] = useState(true);
  const [invoicedeleteModals, setInvoicedeleteModals] = useState(false);
  const [deleteinvoiceid, setdeleteinvoiceid] = useState("");
  const [data, setData] = useState([]);
  const [activeTab, setactiveTab] = useState("first");
  const [partner, setPartner] = useState([]);
  const [dataInvoice, setDatainvoice] = useState([]);
  const [dataEstimate, setDataestimate] = useState([]);
  const [dataOrder, setDataorder] = useState([]);
  const [dataRequest, setDatarequest] = useState([]);

  const [nothavemodal, setNothavemodal] = useState(false);
  const [filter, setFilter] = useState("");
  const [navigateModal, setNavigateModal] = useState(false);
  const [fetcherror, setFetcherror] = useState(false);
  const [nothavetype, setNothavetype] = useState("Phone");
  const { currencyFormater } = useCurrencyFormating();
  const { isTeamAccount, userPermission } = useAuth();
  let estimatePermission =
    Object.keys(userPermission).includes("estimate") &&
    Object.keys(userPermission.estimate).includes("permission_key")
      ? userPermission.estimate.permission_key
      : "";

  let bookingPermission =
    Object.keys(userPermission).includes("booking") &&
    Object.keys(userPermission.booking).includes("permission_key")
      ? userPermission.booking.permission_key
      : "";
  let invoicePermission =
    Object.keys(userPermission).includes("invoice") &&
    Object.keys(userPermission.invoice).includes("permission_key")
      ? userPermission.invoice.permission_key
      : "";
  let requestPermission =
    Object.keys(userPermission).includes("request") &&
    Object.keys(userPermission.request).includes("permission_key")
      ? userPermission.request.permission_key
      : "";
  const fetchPriceList = (token) => {
    fetch("https://bestmate.us/api/pricelist/findAll", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          let data = result.map((elem) => {
            return {
              label: `${elem.price_list_name}: ${elem.price_list_multiplier}x`,
              value: {
                price_list_name: elem.price_list_name,
                price_list_multiplier: elem.price_list_multiplier,
                price_list_id: elem.price_list_id,
              },
            };
          });
          console.log(data);
          setOptions(data);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };

  const getRequiredData = (token) => {
    fetch(
      "https://bestmate.us/api/partners/get/" +
        id +
        `${isTeamAccount ? "&team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          setFetcherror(true);
          setloading(false);
          throw new Error(`HTTP error, status code ${res.status}`);
        }
        return res.json();
      })
      .then((result) => {
        setRowdata(result);
        // setfloading(false);
        props.setPartner(result);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  const deleteRecord = async (deleteData) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        deleteData.team = true;
      } else {
        delete deleteData.team;
      }
      fetch("https://bestmate.us/api/partners/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(deleteData),
      })
        .then((response) => {
          console.log("response", response);
          navigate("/customer");
          notyf.open({
            type: isOpen ? "home" : "full",
            message: "Partner deleted successfully",
            ripple: "true",
            dismissible: "true",
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // setShow(false);
  };

  const getInvoicedata = async () => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      const timeZoneOffset = -(new Date().getTimezoneOffset() * 60);
      fetch(
        `https://bestmate.us/api/invoices/findByPartner/${id}?timeZoneOffset=${timeZoneOffset}` +
          `${isTeamAccount ? "&team=true" : ""}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenss,
          },
        }
      )
        .then((res) => {
          if (!res.ok) {
            setFetcherror(true);
            setloading(false);
            throw new Error(`HTTP error, status code ${res.status}`);
          }
          return res.json();
        })
        .then((result) => {
          setData(result);
          let total = 0;
          result.forEach((elem) => {
            return (total +=
              (isNaN(elem.invoice_grand_total) ? 0 : elem.invoice_grand_total) -
              (isNaN(elem.invoice_settled_amount)
                ? 0
                : elem.invoice_settled_amount));
          });
          console.log(total);
          setpending(total);
          setactiveTab("first");
          setFilter("");
          setloading(false);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    }
    // });
  };
  const invoicedeleteHandler = async () => {
    var user = firebase.auth().currentUser;

    // firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      let tokenss = await user.getIdToken();
      console.log(rowdata.invoice_id);
      let da = {
        invoice_id: deleteinvoiceid,
        // invoice_id: "BmuiRP5cpYWsOUluqHbj",
      };
      if (isTeamAccount) {
        da.team = true;
      } else {
        delete da.team;
      }
      fetch("https://bestmate.us/api/invoices/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(da),
      })
        .then((res) => {
          getInvoicedata();
          // setloading(false);
          // setfloading(true);
          // getRequiredData(token);
          // navigate(-1);
          // navigate(`/invoice/${props.type}s`);
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
        });
    }
    // });
  };

  useEffect(async () => {
    mixpanel.track(`On ${props.type} Detail (Web)`);
    setloading(true);
    setFetcherror(false);
    // useEffect(() => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      settoken(tokenss);
      // fetchPriceList(tokenss);
      getInvoicedata(tokenss);
      if (location.state !== null) {
        setRowdata(location.state.row);
        // setfloading(false);
        // props.setPartner(location.state.row);
        // let pldata;
        // if (
        //   Object.entries(location.state.row.partner_price_list).length === 0
        // ) {
        //   console.log(location.state.row.partner_price_list);
        //   pldata = {};
        // } else {
        //   pldata = {
        //     label: `${location.state.row.partner_price_list.price_list_name} : ${location.state.row.partner_price_list.price_list_multiplier}x`,
        //     value: location.state.row.partner_price_list,
        //   };
        // }
        // setPricelist(pldata);
      } else {
        getRequiredData(tokenss);
      }
    }
    // });

    // settotalinvoice(dataInvoice.length);
    // }, []);
  }, []);
  // console.log(props);
  useEffect(() => {
    console.log(data);
    const Inv = [];
    const Est = [];
    const Ord = [];
    const Req = [];
    data.filter((elem) => {
      if (elem.invoice_workflow_type === "INVOICE") {
        Inv.push(elem);
      } else if (elem.invoice_workflow_type === "ESTIMATE") {
        Est.push(elem);
      } else if (elem.invoice_workflow_type === "ORDER") {
        Ord.push(elem);
      } else if (elem.invoice_workflow_type === "REQUEST") {
        Req.push(elem);
      }
    });
    setDatainvoice(Inv);
    setDataestimate(Est);
    setDataorder(Ord);
    setDatarequest(Req);
    // const totalinvoice = dataInvoice.length;
    settotalinvoice(Inv.length);
  }, [data]);
  console.log(activeTab);

  useEffect(() => {
    setFilter("");
  }, [activeTab]);

  useEffect(() => {
    function handleClick() {
      console.log("set to false");
      setNavigateModal(false);
    }
    if (navigateModal) {
      document.addEventListener("click", handleClick);
    }

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [navigateModal]);

  return (
    <>
      {/* {rowdata !== undefined ? ( */}
      {!loading ? (
        <>
          {!fetcherror && rowdata && data ? (
            <>
              <Card
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // backgroundColor: "white",
                  padding: "0rem 1.5rem 2rem 1.5rem",
                }}
                className="pt-2 pb-2 border-bottom "
              >
                <div>
                  <h2
                    className="mt-3 lead"
                    style={{
                      fontSize: "24px",
                      // , fontWeight: 100
                    }}
                  >
                    {/* {props.type} Details */}
                    {truncateTextByWordCount(
                      `${rowdata.partner_first_name} ${rowdata.partner_last_name}`,
                      40
                    )}
                  </h2>
                  <Breadcrumb
                    className="mb-0 lead"
                    style={{ fontSize: "13px" }}
                  >
                    <Breadcrumb.Item
                      active
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/dashboard")}
                    >
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Partners</Breadcrumb.Item>
                    <Breadcrumb.Item
                      active
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/${props.type.toLowerCase()}`)}
                    >{`${props.type}s`}</Breadcrumb.Item>
                    <Breadcrumb.Item active className="font-weight-bold">
                      {`${props.type} Details`}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "21px",
                  }}
                >
                  <div
                    onClick={() => {
                      mixpanel.track(
                        `Edit Partner (${props.type} Detail Web)`,
                        {
                          ...rowdata,
                        }
                      );
                      navigate(
                        `/${props.type.toLowerCase()}/` +
                          rowdata.partner_id +
                          `/edit`,
                        {
                          state: { row: rowdata, items: props.items },
                        }
                      );
                    }}
                  >
                    <div
                      style={{
                        border: "1px #E7EAEC solid",
                        borderRadius: "3px",
                        padding: "0.375rem 0.75rem",
                        display: "flex",
                        justifyContent: "center",
                        color: "white",
                        // backgroundColor: "#4f83cb",
                        backgroundColor: "#3f80ea",
                        alignItems: "center",
                        textDecoration: "none",
                        fontSize: "inherit",
                        cursor: "pointer",
                        width: "100px",
                      }}
                    >
                      <Edit2
                        size={15}
                        strokeWidth={2}
                        stroke="white"
                        style={{ marginRight: "8px" }}
                      />
                      <div>Edit</div>
                    </div>
                  </div>
                </div>
              </Card>
              <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
                {/* <Formik
              initialValues={{
                partner_id: rowdata.partner_id,
                partner_type: rowdata.partner_type,
                first_name: rowdata.partner_first_name,
                last_name: rowdata.partner_last_name,
                email: rowdata.partner_email,
                number: rowdata.partner_number,
                billing_street: rowdata.partner_billing_address.street,
                billing_city: rowdata.partner_billing_address.city,
                billing_state: rowdata.partner_billing_address.state,
                billing_zipcode: rowdata.partner_billing_address.zip_code,
                billing_country: rowdata.partner_billing_address.country,
                shipping_street: rowdata.partner_shipping_address.street,
                shipping_city: rowdata.partner_shipping_address.city,
                shipping_state: rowdata.partner_shipping_address.state,
                shipping_zipcode: rowdata.partner_shipping_address.zip_code,
                shipping_country: rowdata.partner_shipping_address.country,
                notes: rowdata.partner_notes,
                payment_terms: rowdata.partner_payment_terms,
                tax_number: rowdata.partner_tax_number,
              }}
              validationSchema={Yup.object().shape({
                first_name: Yup.string()
                  .max(255)
                  .required("Item name is required"),
                last_name: Yup.string()
                  .max(255)
                  .required("Item name is required"),
                // number: Yup.string().matches(
                //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                //   "Phone number is not valid"
                // ),
              })}
              onSubmit={(values) => {
                // console.log(values);
                const data = {
                  partner_id: values.partner_id,
                  partner_first_name: values.first_name,
                  partner_last_name: values.last_name,
                  partner_email: values.email,
                  partner_number: values.number,
                  partner_price_list: pricelist.value,
                  partner_billing_address: {
                    street: values.billing_street,
                    city: values.billing_city,
                    state: values.billing_state,
                    zip_code: values.billing_zipcode,
                    country: values.billing_country,
                  },
                  partner_shipping_address: {
                    street: values.shipping_street,
                    city: values.shipping_city,
                    state: values.shipping_state,
                    zip_code: values.shipping_zipcode,
                    country: values.shipping_country,
                  },
                  partner_payment_terms: values.payment_terms,
                  partner_notes: values.notes,
                  partner_tax_number: values.tax_number,
                  partner_type: values.partner_type,
                };
                // console.log(data);
                fetch("https://bestmate.us/api/partners/update", {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                  },
                  body: JSON.stringify(data),
                })
                  .then((response) => {
                    navigate("/customer");
                    notyf.open({
                      type: isOpen ? "home" : "full",
                      message: "Partner updated successfully",
                      ripple: "true",
                      dismissible: "true",
                    });
                  })
                  .catch((error) => {
                    console.error(error);
                  });
                // props.show(false);
                // props.setSearchParams({});
              }}
              onReset={() => {
                // props.show(false);
                // props.setSearchParams({});
                navigate("/customer");
              }}
            >
              {({
                handleSubmit,
                values,
                errors,
                handleChange,
                handleReset,
                // dirty,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )} */}
                <Row>
                  <Col lg="4">
                    <Card
                      className="mb-0 border rounded-0"
                      // style={{ overflow: "scroll" }}
                    >
                      {/* <Card.Body>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 15,
                      }}
                    >
                      <div>
                        <div className="mb-0">{`${rowdata.partner_first_name} ${rowdata.partner_last_name}`}</div>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                          }}
                        >
                          Company Name
                        </div>
                      </div>

                      <Edit2
                        size={15}
                        strokeWidth={3.5}
                        stroke="#676a6c"
                        style={{ marginRight: "2px", cursor: "pointer" }}
                        onClick={() => {
                          navigate(
                            "/customer/" +
                              rowdata.partner_id +
                              "/edit",
                            {
                              state: { row: rowdata, items: props.items },
                            }
                          );
                        }}
                      />
                    </div>

                    <Table size="sm" className="my-2">
                      <tbody>
                        <tr>
                          <th>Email</th>
                          <td>
                            {rowdata.partner_email
                              ? rowdata.partner_email
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Phone</th>
                          <td>
                            {rowdata.partner_number
                              ? rowdata.partner_number
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <div style={{ padding: "0 8px" }}>
                      <div style={{ fontWeight: "bold" }}>Billing Address</div>
                      <div style={{ marginLeft: 10, marginTop: 8 }}>
                        {rowdata.partner_billing_address ? "" : "N/A"}
                        {rowdata.partner_billing_address &&
                        rowdata.partner_billing_address.street
                          ? rowdata.partner_billing_address.street
                          : ""}

                        <div>
                          {rowdata.partner_billing_address &&
                          rowdata.partner_billing_address.city
                            ? rowdata.partner_billing_address.city
                            : ""}
                          {rowdata.partner_billing_address &&
                          rowdata.partner_billing_address.city &&
                          rowdata.partner_billing_address.state
                            ? ", "
                            : ""}
                          {rowdata.partner_billing_address &&
                          rowdata.partner_billing_address.state
                            ? rowdata.partner_billing_address.state
                            : ""}
                        </div>
                        <div>
                          {rowdata.partner_billing_address &&
                          rowdata.partner_billing_address.country
                            ? rowdata.partner_billing_address.country
                            : ""}
                          {rowdata.partner_billing_address &&
                          rowdata.partner_billing_address.country &&
                          rowdata.partner_billing_address.zip_code
                            ? " - "
                            : ""}
                          {rowdata.partner_billing_address &&
                          rowdata.partner_billing_address.zip_code
                            ? rowdata.partner_billing_address.zip_code
                            : ""}
                        </div>
                      </div>
                    </div>
                    <hr
                      style={{
                        borderColor: "#dee6ed",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    />

                    <div style={{ padding: "0 8px" }}>
                      <div style={{ fontWeight: "bold" }}>Shipping Address</div>
                      <div style={{ marginLeft: 10, marginTop: 8 }}>
                        {rowdata.partner_shipping_address ? "" : "N/A"}
                        {rowdata.partner_shipping_address &&
                        rowdata.partner_shipping_address.street
                          ? rowdata.partner_shipping_address.street
                          : ""}

                        <div>
                          {rowdata.partner_shipping_address &&
                          rowdata.partner_shipping_address.city
                            ? rowdata.partner_shipping_address.city
                            : ""}
                          {rowdata.partner_shipping_address &&
                          rowdata.partner_shipping_address.city &&
                          rowdata.partner_shipping_address.state
                            ? ", "
                            : ""}
                          {rowdata.partner_shipping_address &&
                          rowdata.partner_shipping_address.state
                            ? rowdata.partner_shipping_address.state
                            : ""}
                        </div>
                        <div>
                          {rowdata.partner_shipping_address &&
                          rowdata.partner_shipping_address.country
                            ? rowdata.partner_shipping_address.country
                            : ""}
                          {rowdata.partner_shipping_address &&
                          rowdata.partner_shipping_address.country &&
                          rowdata.partner_shipping_address.zip_code
                            ? " - "
                            : ""}
                          {rowdata.partner_shipping_address &&
                          rowdata.partner_shipping_address.zip_code
                            ? rowdata.partner_shipping_address.zip_code
                            : ""}
                        </div>
                      </div>
                    </div>
                    <hr
                      style={{
                        borderColor: "#dee6ed",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    />
                    <Table size="sm" className="my-2">
                      <tbody>
                        <tr>
                          <th>Tax Number</th>
                          <td>
                            {rowdata.partner_tax_number
                              ? rowdata.partner_tax_number
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Notes</th>
                          <td>
                            {rowdata.partner_notes
                              ? rowdata.partner_notes
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body> */}
                      <Card.Body style={{}}>
                        <div>
                          <div
                            className="mb-0"
                            style={{
                              textAlign: "center",
                              fontSize: 20,
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >{`${
                            rowdata.partner_first_name.charAt(0).toUpperCase() +
                            rowdata.partner_first_name.slice(1)
                          } ${
                            rowdata.partner_last_name.charAt(0).toUpperCase() +
                            rowdata.partner_last_name.slice(1)
                          }`}</div>
                          <div
                            style={{
                              // fontWeight: "bold",
                              fontSize: 15,
                              textAlign: "center",
                            }}
                          >
                            {/* Company Name */}
                            {rowdata.partner_company_name
                              ? rowdata.partner_company_name
                                  .charAt(0)
                                  .toUpperCase() +
                                rowdata.partner_company_name.slice(1)
                              : ""}
                          </div>
                        </div>
                        {/* <div
                      style={{
                        marginTop: 20,
                        // backgroundColor: "pink",
                        // height: 100,
                        width: "100%",
                        marginBottom: 20,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          color:
                            rowdata.partner_outstanding > 0 ? "red" : "green",
                          fontWeight: "bold",
                          fontSize: 18,
                        }}
                      >
                        {rowdata.partner_outstanding.toLocaleString("en-US", {
                          style: "currency",
                          currency: usercurrency,
                        })}
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        Total Outstanding
                      </div>
                    </div> */}
                        <div
                          style={{
                            marginTop: 15,
                            // backgroundColor: "pink",
                            // height: 100,
                            width: "100%",
                            marginBottom: 10,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {/* <div
                        style={{
                          // height: 20,
                          // width: 20,
                          // backgroundColor: "pink",
                          borderRadius: 5,
                          marginRight: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      > */}
                          <a
                            // href={
                            //   rowdata.partner_number
                            //     ? `tel:${rowdata.partner_number}`
                            //     : () => {}
                            // }
                            // onClick={
                            //   rowdata.partner_number
                            //     ? () => {}
                            //     : () => {
                            //         setNothavemodal(true);
                            //       }
                            // }
                            onClick={
                              rowdata.partner_number
                                ? (e) => {
                                    e.stopPropagation();
                                    mixpanel.track(
                                      `Phone Partner (${props.type} Detail Web)`
                                    );
                                    window.location = `tel:${rowdata.partner_number}`;
                                  }
                                : (e) => {
                                    e.stopPropagation();
                                    setNothavetype("Phone");
                                    setNothavemodal(true);
                                  }
                            }
                            style={{
                              backgroundColor: "#DCF1ED",
                              padding: "8px 12px",

                              margin: 5,
                              borderRadius: 10,
                            }}
                          >
                            <Phone
                              size={17}
                              strokeWidth={2}
                              stroke="#09B29C"
                              style={{ cursor: "pointer" }}
                            />
                          </a>

                          <a
                            onClick={
                              rowdata.partner_email
                                ? (e) => {
                                    e.stopPropagation();
                                    mixpanel.track(
                                      `Mail Partner (${props.type} Detail Web)`
                                    );
                                    window.location = `mailto:${rowdata.partner_email}`;
                                  }
                                : (e) => {
                                    e.stopPropagation();
                                    setNothavetype("Email");
                                    setNothavemodal(true);
                                  }
                            }
                            style={{
                              backgroundColor: "#FDE4DF",
                              padding: "8px 12px",

                              margin: 5,
                              borderRadius: 10,
                            }}
                          >
                            <Mail
                              size={17}
                              strokeWidth={2}
                              stroke="#EF6547"
                              style={{ cursor: "pointer" }}
                            />
                          </a>

                          <a
                            // href={
                            //   rowdata.partner_number ? "tel:8178099375" : () => {}
                            // }
                            onClick={
                              rowdata.partner_number
                                ? (e) => {
                                    e.stopPropagation();
                                    mixpanel.track(
                                      `Phone Partner (${props.type} Detail Web)`
                                    );
                                    window.location = `sms:${rowdata.partner_number}`;
                                  }
                                : (e) => {
                                    e.stopPropagation();
                                    setNothavetype("Phone");
                                    setNothavemodal(true);
                                  }
                            }
                            style={{
                              backgroundColor: "#FEF2E0",
                              padding: "8px 12px",

                              margin: 5,
                              borderRadius: 10,
                            }}
                          >
                            <MessageSquare
                              size={17}
                              strokeWidth={2}
                              stroke="#F6B84B"
                              style={{ cursor: "pointer" }}
                            />
                          </a>
                          {/* </div> */}
                        </div>
                        <div
                          style={{
                            maxWidth: 250,
                            margin: "auto",
                            // display: "flex",
                            // alignItems: "center",
                            // justifyContent: "center",
                            marginTop: 15,
                            marginBottom: 20,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-around ",
                            }}
                          >
                            <div
                              style={{
                                // width: "70px",
                                // height: "50px",
                                // backgroundColor: "pink",
                                border: "solid 0.5px #ebeced",
                                borderRadius: 3,
                                padding: 5,
                                // borderWidth: "10px",
                                // borderColor: "black",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                width: 100,
                              }}
                            >
                              <div
                                style={{
                                  color: "black",
                                  fontSize: "14px",
                                  maxWidth: 80,
                                }}
                              >
                                {/* {pending.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: usercurrency,
                                })} */}
                                {truncateTextByWordCount(
                                  currencyFormater(pending, usercurrency),
                                  80
                                )}
                              </div>
                              <div
                                style={{
                                  // fontWeight: "bold",
                                  color: "grey",
                                  fontSize: 11,
                                }}
                              >
                                Pending
                              </div>
                            </div>
                            <div
                              style={{
                                // width: "70px",
                                // height: "50px",
                                // border: "solid 0.5px grey",
                                // borderRadius: 3,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                border: "solid 0.5px #ebeced",
                                borderRadius: 3,
                                padding: 5,
                                width: 100,

                                // backgroundColor: "pink",
                                // borderWidth: 1,
                                // borderColor: "grey",
                              }}
                            >
                              <div
                                style={{
                                  color: "black",
                                  fontSize: 14,
                                  maxWidth: 80,
                                }}
                              >
                                {/* {totalinvoice} */}
                                {truncateTextByWordCount(totalinvoice, 20)}
                              </div>
                              <div
                                style={{
                                  // fontWeight: "bold",
                                  color: "grey",
                                  fontSize: 11,
                                }}
                              >
                                Invoice
                              </div>
                            </div>
                            {/* <div
                          style={{
                            width: "70px",
                            height: "50px",
                            // backgroundColor: "pink",
                            // borderWidth: 1,
                            // borderColor: "grey",
                            border: "solid 0.5px grey",
                            borderRadius: 3,
                          }}
                        >
                          <div>04</div>
                          <div>Estimate</div>
                        </div> */}
                          </div>
                        </div>

                        {/* <div style={{ padding: "0 8px" }}>
                      <div style={{ fontWeight: "bold", color: "black" }}>
                        Email Id
                      </div>
                      <div
                        style={{
                          marginLeft: 1,
                          marginTop: 2,
                          fontSize: 12.5,
                          color: "grey",
                        }}
                      >
                        {rowdata.partner_email ? rowdata.partner_email : "N/A"}
                      </div>
                    </div>
                    <hr
                      style={{
                        borderColor: "#dee6ed",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    />
                    <div style={{ padding: "0 8px" }}>
                      <div style={{ fontWeight: "bold", color: "black" }}>
                        Contact No
                      </div>
                      <div
                        style={{
                          marginLeft: 1,
                          marginTop: 2,
                          fontSize: 12.5,
                          color: "grey",
                        }}
                      >
                        {rowdata.partner_number
                          ? rowdata.partner_number
                          : "N/A"}
                      </div>
                    </div>
                    <hr
                      style={{
                        borderColor: "#dee6ed",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    /> */}
                        <div
                          style={{
                            width: "100%",
                            wordBreak: "break-word",
                          }}
                        >
                          <Table size="sm" className="my-2" style={{}}>
                            <tbody>
                              <tr>
                                <th style={{ color: "#545b61", width: 90 }}>
                                  Name
                                </th>
                                <td style={{ wordBreak: "break-word" }}>
                                  {rowdata.partner_first_name ||
                                  rowdata.partner_last_name
                                    ? rowdata.partner_first_name
                                        .charAt(0)
                                        .toUpperCase() +
                                      rowdata.partner_first_name.slice(1) +
                                      " " +
                                      rowdata.partner_last_name
                                        .charAt(0)
                                        .toUpperCase() +
                                      rowdata.partner_last_name.slice(1)
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th style={{ color: "#545b61" }}>Company</th>
                                <td
                                  style={{
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {rowdata.partner_company_name
                                    ? rowdata.partner_company_name
                                        .charAt(0)
                                        .toUpperCase() +
                                      rowdata.partner_company_name.slice(1)
                                    : "-"}
                                  {/* Company Name */}
                                </td>
                              </tr>
                              <tr>
                                <th style={{ color: "#545b61" }}>Email</th>
                                <td>
                                  {rowdata.partner_email
                                    ? rowdata.partner_email
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th style={{ color: "#545b61" }}>Phone</th>
                                <td>
                                  {rowdata.partner_number
                                    ? rowdata.partner_number
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th style={{ color: "#545b61" }}>Tax Number</th>
                                <td>
                                  {rowdata.partner_tax_number
                                    ? rowdata.partner_tax_number
                                    : "-"}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>

                        <div style={{ padding: "0 8px" }}>
                          <div style={{ fontWeight: "bold", color: "#545b61" }}>
                            Billing Address
                          </div>
                          <div
                            style={{
                              marginLeft: 1,
                              marginTop: 2,
                              fontSize: 12.5,
                              color: "grey",
                            }}
                          >
                            {rowdata.partner_billing_address &&
                            Object.keys(rowdata.partner_billing_address)
                              .length !== 0
                              ? ""
                              : "-"}
                            {rowdata.partner_billing_address &&
                            rowdata.partner_billing_address.street
                              ? rowdata.partner_billing_address.street
                              : ""}

                            <div>
                              {rowdata.partner_billing_address &&
                              rowdata.partner_billing_address.city
                                ? rowdata.partner_billing_address.city
                                : ""}
                              {rowdata.partner_billing_address &&
                              rowdata.partner_billing_address.city &&
                              rowdata.partner_billing_address.state
                                ? ", "
                                : ""}
                              {rowdata.partner_billing_address &&
                              rowdata.partner_billing_address.state
                                ? rowdata.partner_billing_address.state
                                : ""}
                            </div>
                            <div>
                              {rowdata.partner_billing_address &&
                              rowdata.partner_billing_address.country
                                ? rowdata.partner_billing_address.country
                                : ""}
                              {rowdata.partner_billing_address &&
                              rowdata.partner_billing_address.country &&
                              rowdata.partner_billing_address.zip_code
                                ? " - "
                                : ""}
                              {rowdata.partner_billing_address &&
                              rowdata.partner_billing_address.zip_code
                                ? rowdata.partner_billing_address.zip_code
                                : ""}
                            </div>
                          </div>
                        </div>
                        <hr
                          style={{
                            borderColor: "#dee6ed",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        />

                        <div style={{ padding: "0 8px" }}>
                          <div style={{ fontWeight: "bold", color: "#545b61" }}>
                            Service Address
                          </div>
                          <div
                            style={{
                              marginLeft: 1,
                              marginTop: 2,
                              fontSize: 12.5,
                              color: "grey",
                            }}
                          >
                            {rowdata.partner_shipping_address &&
                            Object.keys(rowdata.partner_shipping_address)
                              .length !== 0
                              ? ""
                              : "-"}
                            {rowdata.partner_shipping_address &&
                            rowdata.partner_shipping_address.street
                              ? rowdata.partner_shipping_address.street
                              : ""}

                            <div>
                              {rowdata.partner_shipping_address &&
                              rowdata.partner_shipping_address.city
                                ? rowdata.partner_shipping_address.city
                                : ""}
                              {rowdata.partner_shipping_address &&
                              rowdata.partner_shipping_address.city &&
                              rowdata.partner_shipping_address.state
                                ? ", "
                                : ""}
                              {rowdata.partner_shipping_address &&
                              rowdata.partner_shipping_address.state
                                ? rowdata.partner_shipping_address.state
                                : ""}
                            </div>
                            <div>
                              {rowdata.partner_shipping_address &&
                              rowdata.partner_shipping_address.country
                                ? rowdata.partner_shipping_address.country
                                : ""}
                              {rowdata.partner_shipping_address &&
                              rowdata.partner_shipping_address.country &&
                              rowdata.partner_shipping_address.zip_code
                                ? " - "
                                : ""}
                              {rowdata.partner_shipping_address &&
                              rowdata.partner_shipping_address.zip_code
                                ? rowdata.partner_shipping_address.zip_code
                                : ""}
                            </div>
                          </div>
                        </div>
                        <hr
                          style={{
                            borderColor: "#dee6ed",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        />

                        <div style={{ padding: "0 8px" }}>
                          <div style={{ fontWeight: "bold", color: "#545b61" }}>
                            Notes
                          </div>
                          <div
                            style={{
                              marginLeft: 1,
                              marginTop: 2,
                              fontSize: 12.5,
                              color: "grey",
                            }}
                          >
                            {rowdata.partner_notes
                              ? rowdata.partner_notes
                              : "-"}
                          </div>
                        </div>
                        <hr
                          style={{
                            borderColor: "#dee6ed",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        />
                        <div>
                          {rowdata.partner_type === "LEAD" ? (
                            <button
                              style={{
                                border: "1px solid #2E6ACF",
                                padding: 5,
                                backgroundColor: "transparent",
                                color: "#2E6ACF",
                                fontWeight: "bold",
                                marginRight: 10,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                              }}
                              disabled={convertbtnLoad}
                              onClick={async () => {
                                try {
                                  setConvertbtnLoad(true);
                                  var user = firebase.auth().currentUser;
                                  let token = await user.getIdToken();
                                  mixpanel.track(
                                    "Convert to Customer (Lead Detail Web)"
                                  );
                                  let da = {
                                    partner_id: rowdata.partner_id,
                                    partner_type: "CUSTOMER",
                                  };
                                  if (isTeamAccount) {
                                    da.team = true;
                                  } else {
                                    delete da.team;
                                  }

                                  fetch(
                                    "https://bestmate.us/api/partners/update",
                                    {
                                      method: "POST",
                                      headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                        Authorization: "Bearer " + token,
                                      },
                                      body: JSON.stringify(da),
                                    }
                                  )
                                    .then((response) => {
                                      if (
                                        response.status >= 200 &&
                                        response.status <= 300
                                      ) {
                                        notyf.open({
                                          type: isOpen ? "home" : "full",
                                          message:
                                            "Lead converted to Customer successfully",
                                          ripple: "true",
                                          dismissible: "true",
                                        });
                                        navigate(`/customer`);
                                        setConvertbtnLoad(false);
                                      } else {
                                        setConvertbtnLoad(false);
                                      }
                                    })
                                    .catch((error) => {
                                      console.error(error);
                                      setConvertbtnLoad(false);
                                    });
                                } catch (err) {}
                              }}
                            >
                              {convertbtnLoad ? (
                                <div style={{ width: "100%" }}>
                                  <Spinner
                                    animation="border"
                                    // variant="dark"
                                    className="me-2"
                                    size="sm"
                                    style={{ color: "#2E6ACF" }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{ color: "#2E6ACF", width: "100%" }}
                                >
                                  Convert to Customer
                                </div>
                              )}
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                        {/* <div style={{ padding: "0 8px" }}>
                      <div style={{ fontWeight: "bold", color: "black" }}>
                        Tax Id
                      </div>
                      <div
                        style={{
                          marginLeft: 1,
                          marginTop: 2,
                          fontSize: 12.5,
                          color: "grey",
                        }}
                      >
                        {rowdata.partner_tax_number
                          ? rowdata.partner_tax_number
                          : "N/A"}
                      </div>
                    </div> */}
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg="8">
                    <Card
                      className="mb-0 border rounded-0"
                      style={{ minHeight: "100%" }}
                    >
                      <Card.Body>
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="first"
                        >
                          <Nav
                            variant="tabs"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              // backgroundColor: "pink",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <Nav.Item
                                style={{
                                  width: 100,
                                  maxWidth: "100%",
                                }}
                              >
                                <Nav.Link
                                  className="text-dark"
                                  onClick={() => {
                                    mixpanel.track(
                                      `Request Tab (${props.type} Detail Web)`
                                    );
                                    setactiveTab("first");
                                    setFilter("");
                                  }}
                                  style={
                                    activeTab === "first"
                                      ? {
                                          borderBottom: "2px solid #4f83cc",
                                          fontWeight: "bold",
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }
                                      : {
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }
                                  }
                                  eventKey="first"
                                >
                                  Requests
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item
                                style={{
                                  width: 100,
                                  maxWidth: "100%",
                                }}
                              >
                                <Nav.Link
                                  className="text-dark"
                                  onClick={() => {
                                    mixpanel.track(
                                      `Estimate Tab (${props.type} Detail Web)`
                                    );
                                    setactiveTab("second");
                                    setFilter("");
                                  }}
                                  style={
                                    activeTab === "second"
                                      ? {
                                          borderBottom: "2px solid #DE5474",
                                          fontWeight: "bold",
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }
                                      : {
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }
                                  }
                                  eventKey="second"
                                >
                                  Estimates
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item
                                style={{
                                  width: 100,
                                  maxWidth: "100%",
                                }}
                              >
                                <Nav.Link
                                  className="text-dark"
                                  onClick={() => {
                                    mixpanel.track(
                                      `Booking Tab (${props.type} Detail Web)`
                                    );
                                    setactiveTab("third");
                                    setFilter("");
                                  }}
                                  style={
                                    activeTab === "third"
                                      ? {
                                          borderBottom: "2px solid #71BE1D",
                                          fontWeight: "bold",
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }
                                      : {
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }
                                  }
                                  eventKey="third"
                                >
                                  Bookings
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item
                                style={{
                                  width: 100,
                                  maxWidth: "100%",
                                }}
                              >
                                <Nav.Link
                                  className="text-dark"
                                  onClick={() => {
                                    mixpanel.track(
                                      `Invoice Tab (${props.type} Detail Web)`
                                    );
                                    setactiveTab("fourth");
                                    setFilter("");
                                  }}
                                  style={
                                    activeTab === "fourth"
                                      ? {
                                          borderBottom: "2px solid #4f83cc",
                                          fontWeight: "bold",
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }
                                      : {
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }
                                  }
                                  eventKey="fourth"
                                >
                                  Invoices
                                </Nav.Link>
                              </Nav.Item>
                            </div>
                            <div style={{ display: "flex" }}>
                              {navigateModal ? (
                                <div
                                  style={{
                                    marginRight: 5,
                                    width: 170,
                                    position: "absolute",
                                    right: 40,
                                    // top: 50,
                                    backgroundColor: "white",
                                    boxShadow: "1px 1px 4px",
                                  }}
                                >
                                  {requestPermission.includes("ADD") ? (
                                    <div
                                      className="modalbtns"
                                      style={{
                                        padding: "5px 10px",
                                        borderBottom: "solid 1px #f5f5f5",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        mixpanel.track(
                                          `Add Estimate (${props.type} Detail Web)`
                                        );
                                        navigate(`/requests/add/`, {
                                          state: {
                                            invoice_partner: rowdata,
                                            invoice_partner_id:
                                              rowdata.partner_id,
                                            invoice_partner_type:
                                              rowdata.partner_type,
                                            invoice_order: [],
                                          },
                                        });
                                      }}
                                    >
                                      Add Request
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {estimatePermission.includes("ADD") ? (
                                    <div
                                      className="modalbtns"
                                      style={{
                                        padding: "5px 10px",
                                        borderBottom: "solid 1px #f5f5f5",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        mixpanel.track(
                                          `Add Estimate (${props.type} Detail Web)`
                                        );
                                        navigate(`/estimates/add/`, {
                                          state: {
                                            invoice_partner: rowdata,
                                            invoice_partner_id:
                                              rowdata.partner_id,
                                            invoice_partner_type:
                                              rowdata.partner_type,
                                            invoice_order: [],
                                          },
                                        });
                                      }}
                                    >
                                      Add Estimate
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {bookingPermission.includes("ADD") ? (
                                    <div
                                      className="modalbtns"
                                      style={{
                                        padding: "5px 10px",
                                        cursor: "pointer",
                                        borderBottom: "solid 1px #f5f5f5",
                                      }}
                                      onClick={() => {
                                        mixpanel.track(
                                          `Add Booking (${props.type} Detail Web)`
                                        );
                                        navigate(`/bookings/add/`, {
                                          state: {
                                            invoice_partner: rowdata,
                                            invoice_partner_id:
                                              rowdata.partner_id,
                                            invoice_partner_type:
                                              rowdata.partner_type,
                                            invoice_order: [],
                                          },
                                        });
                                      }}
                                    >
                                      Add Booking
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  {invoicePermission.includes("ADD") ? (
                                    <div
                                      className="modalbtns"
                                      style={{
                                        padding: "5px 10px",
                                        cursor: "pointer",
                                        borderBottom: "solid 1px #f5f5f5",
                                      }}
                                      onClick={() => {
                                        mixpanel.track(
                                          `Add Invoice (${props.type} Detail Web)`
                                        );
                                        navigate(`/invoices/add/`, {
                                          state: {
                                            invoice_partner: rowdata,
                                            invoice_partner_id:
                                              rowdata.partner_id,
                                            invoice_partner_type:
                                              rowdata.partner_type,
                                            invoice_order: [],
                                          },
                                        });
                                      }}
                                    >
                                      Add Invoice
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}
                              {requestPermission.includes("ADD") ||
                              estimatePermission.includes("ADD") ||
                              bookingPermission.includes("ADD") ||
                              invoicePermission.includes("ADD") ? (
                                <div
                                  style={{
                                    backgroundColor: "#3367d6",
                                    borderRadius: "5px",
                                    padding: "3px 5px",
                                    textDecoration: "none",
                                    fontSize: 12,
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setNavigateModal(true);
                                  }}
                                >
                                  <Plus
                                    size={15}
                                    strokeWidth={3.5}
                                    stroke="white"
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Nav>

                          {/* <hr></hr> */}
                          <div
                            style={{
                              // backgroundColor: "pink",
                              // paddingRight: 10,
                              padding: "13px 0",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            <Filter
                              size={20}
                              strokeWidth={2}
                              stroke="grey"
                              style={{ cursor: "pointer" }}
                            />
                            <Form.Group
                              style={{
                                display: "flex",
                                alignItems: "center",
                                // backgroundColor: "red",
                                marginLeft: 10,
                              }}
                            >
                              <Form.Label style={{ margin: 0, marginRight: 5 }}>
                                Status
                              </Form.Label>

                              <Form.Select
                                value={filter}
                                style={{ width: 130 }}
                                onChange={(e) => {
                                  // controller.abort();
                                  // setsearchloading(true);
                                  // setSearchdata([]);
                                  // setSearchid(0);
                                  // setSearchhasmore(true);
                                  // setTempsearchtotal(e.target.value);
                                  // handleSearch2(e.target.value);
                                  mixpanel.track(
                                    `Filter ${e.target.value} (${props.type} Detail Web)`
                                  );
                                  setFilter(e.target.value);
                                }}
                              >
                                {activeTab === "first" ? (
                                  <>
                                    <option value="">All</option>
                                    <option value="OPEN">Open</option>
                                    <option value="CONVERTED">Converted</option>
                                    <option value="ARCHIVED">Archived</option>
                                  </>
                                ) : (
                                  <>
                                    {activeTab === "second" ? (
                                      <>
                                        <option value="">All</option>
                                        <option value="OPEN">Open</option>
                                        <option value="ACCEPTED">
                                          Accepted
                                        </option>
                                        <option value="CONVERTED">
                                          Converted
                                        </option>
                                      </>
                                    ) : (
                                      <>
                                        {activeTab === "third" ? (
                                          <>
                                            <option value="">All</option>
                                            <option value="UPCOMING">
                                              Upcoming
                                            </option>
                                            <option value="UNSCHEDULED">
                                              Unscheduled
                                            </option>
                                            <option value="PAST">Past</option>
                                            <option value="CONVERTED">
                                              Invoiced
                                            </option>
                                          </>
                                        ) : (
                                          <>
                                            <option value="">All</option>
                                            <option value="UNPAID">
                                              Unpaid
                                            </option>
                                            <option value="PAID">Paid</option>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </Form.Select>
                            </Form.Group>
                          </div>
                          <Tab.Content style={{}}>
                            {/* <Tab.Pane eventKey="first">

                        </Tab.Pane> */}
                            <Tab.Pane eventKey="first">
                              <Requests
                                dataOrder={dataRequest}
                                loading={loading}
                                rowdata={rowdata}
                                setInvoicedeleteModals={setInvoicedeleteModals}
                                setdeleteinvoiceid={setdeleteinvoiceid}
                                filter={filter}
                              />
                              {/* <></> */}
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <Estimates
                                dataEstimate={dataEstimate}
                                loading={loading}
                                setInvoicedeleteModals={setInvoicedeleteModals}
                                setdeleteinvoiceid={setdeleteinvoiceid}
                                filter={filter}
                                rowdata={rowdata}
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                              <Orders
                                dataOrder={dataOrder}
                                loading={loading}
                                rowdata={rowdata}
                                setInvoicedeleteModals={setInvoicedeleteModals}
                                setdeleteinvoiceid={setdeleteinvoiceid}
                                filter={filter}
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                              <Invoices
                                dataInvoice={dataInvoice}
                                loading={loading}
                                rowdata={rowdata}
                                setInvoicedeleteModals={setInvoicedeleteModals}
                                setdeleteinvoiceid={setdeleteinvoiceid}
                                filter={filter}
                              />
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                {/* </Form>
              )}
            </Formik> */}
                <Modal
                  show={openModals}
                  onHide={() => setOpenModals(!openModals)}
                  centered
                >
                  <Modal.Header className="font-weight-bold" closeButton>
                    Delete Product
                  </Modal.Header>
                  <Modal.Body className="text-left m-3">
                    <p className="mb-0 font-weight-bold">
                      Are you sure you want to delete{" "}
                      {deleteData["partner_first_name"]}?
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setOpenModals(!openModals)}
                    >
                      Cancel
                    </Button>{" "}
                    <Button
                      variant="danger"
                      onClick={() => {
                        deleteRecord(deleteData);
                        setOpenModals(!openModals);
                      }}
                    >
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={invoicedeleteModals}
                  onHide={() => {
                    mixpanel.track(
                      `Close >>> Delete Modal (${props.type} Detail Web)`
                    );
                    setInvoicedeleteModals(!invoicedeleteModals);
                  }}
                  centered
                  size="sm"
                >
                  <Modal.Body style={{ padding: 0 }}>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "#f4f4f4",
                        height: 46,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "16px 24px",
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          color: "black",
                          margin: 0,
                        }}
                      >
                        Delete{" "}
                        {activeTab === "second"
                          ? "Estimate"
                          : activeTab === "third"
                          ? "Booking"
                          : activeTab === "first"
                          ? "Request"
                          : "Invoice"}
                      </h1>
                      <div
                        onClick={() => {
                          mixpanel.track(
                            `Close >>> Delete Modal (${props.type} Detail Web)`
                          );
                          setInvoicedeleteModals(!invoicedeleteModals);
                        }}
                      >
                        <X
                          size={24}
                          strokeWidth={2}
                          stroke="black"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div style={{ padding: "10px 24px 0 24px" }}>
                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "center",
                          // flexDirection: "column",
                          // alignItems: "center",
                        }}
                      >
                        <p
                          style={{ margin: "15px 0 20px", textAlign: "center" }}
                        >
                          Are you sure you want to delete{" "}
                          {activeTab === "second"
                            ? "Estimate"
                            : activeTab === "third"
                            ? "Booking"
                            : activeTab === "first"
                            ? "Request"
                            : "Invoice"}
                          ?
                        </p>
                      </div>

                      <hr style={{ margin: 0 }} />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          margin: "12px 0",
                        }}
                      >
                        <button
                          type="button"
                          style={{
                            marginRight: 5,
                            padding: "5px 10px",
                            backgroundColor: "white",
                            borderWidth: 1,
                          }}
                          onClick={() => {
                            setdeleteinvoiceid("");
                            mixpanel.track(
                              `Close >>> Delete Modal (${props.type} Detail Web)`
                            );
                            setInvoicedeleteModals(!invoicedeleteModals);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          style={{
                            padding: "5px 10px",
                            backgroundColor: "#3679e8",
                            borderWidth: 0,
                            color: "white",
                            width: 80,
                          }}
                          onClick={() => {
                            setloading(true);
                            invoicedeleteHandler();
                            mixpanel.track(
                              `Delete >>> Delete Modal (${props.type} Detail Web)`
                            );
                            setInvoicedeleteModals(!invoicedeleteModals);
                            // console.log(deleteData);
                            // props.delete(deleteData);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal
                  show={nothavemodal}
                  onHide={() => setNothavemodal(false)}
                  centered
                  size="sm"
                >
                  {/* <Modal.Header className="font-weight-bold" closeButton>
                Delete Product
              </Modal.Header> */}
                  <Modal.Body style={{ padding: 0 }}>
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "#f4f4f4",
                        height: 46,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "16px 24px",
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          color: "black",
                          margin: 0,
                        }}
                      >
                        Not Available
                      </h1>
                      <div
                        onClick={() => {
                          setNothavemodal(false);
                        }}
                      >
                        <X
                          size={24}
                          strokeWidth={2}
                          stroke="black"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>

                    <div style={{ padding: "10px 24px 0 24px" }}>
                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "center",
                          // flexDirection: "column",
                          // alignItems: "center",
                        }}
                      >
                        <p style={{ margin: "15px 0 20px" }}>
                          {nothavetype === "Phone"
                            ? `No phone number has been added. Add a new phone number from the ${props.type.toLowerCase()} page.`
                            : `No Email has been added. Add a new email address from the ${props.type.toLowerCase()} page.`}
                        </p>
                      </div>

                      <hr style={{ margin: 0 }} />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          margin: "12px 0",
                        }}
                      >
                        {/* <button
                      type="button"
                      style={{
                        marginRight: 5,
                        padding: "5px 10px",
                        backgroundColor: "white",
                        borderWidth: 1,
                      }}
                      onClick={() => {
                        setNothavemodal(false);
                      }}
                    >
                      Cancel
                    </button> */}
                        <button
                          style={{
                            padding: "5px 10px",
                            backgroundColor: "#3679e8",
                            borderWidth: 0,
                            color: "white",
                            width: 80,
                          }}
                          // type="submit"
                          // disabled={disablesave}
                          // onClick={() => {
                          //   navigate(
                          //     "/customer/" + rowdata.partner_id + "/edit",
                          //     {
                          //       state: { row: rowdata, items: props.items },
                          //     }
                          //   );
                          // }}
                          onClick={() => {
                            setNothavemodal(false);
                          }}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                    {/* <p className="mb-0 font-weight-bold">
                  Are you sure you want to delete{" "}
                  {deleteData["partner_first_name"]}?
                </p> */}
                  </Modal.Body>
                  {/* <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setNothavemodal(false)}
                >
                  Cancel
                </Button>{" "}
                <Button
                  variant="danger"
                  onClick={() => {
                    deleteRecord(deleteData);
                    setNothavemodal(false);
                  }}
                >
                  Delete
                </Button>
              </Modal.Footer> */}
                </Modal>
                {/* <Modal
              show={navigateModal}
              onHide={() => setNavigateModal(false)}
              centered
              size="sm"
            >
              <Modal.Body style={{ padding: 0 }}>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#f4f4f4",
                    height: 46,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "16px 24px",
                  }}
                >
                  <h1
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      color: "black",
                      margin: 0,
                    }}
                  >
                    Add Modal
                  </h1>
                  <div
                    onClick={() => {
                      setNavigateModal(false);
                    }}
                  >
                    <X
                      size={24}
                      strokeWidth={2}
                      stroke="black"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                <div style={{ padding: "10px 24px 0 24px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      // alignItems: "center",
                      margginTop: 10,
                      marginBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        borderBottom: "solid 1px #f2f2f2",
                        // borderRadius: 5,
                        paddingTop: 10,
                        paddingBottom: 10,
                        // marginBottom: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/estimates/add/`, {
                          state: {
                            invoice_partner: rowdata,
                            invoice_partner_id: rowdata.partner_id,
                            invoice_partner_type: rowdata.partner_type,
                            invoice_order: [],
                          },
                        });
                      }}
                    >
                      <p style={{ margin: 0 }}>
                        Add New Estimate for {rowdata.partner_first_name}
                      </p>
                      <ChevronRight
                        size={20}
                        strokeWidth={2}
                        stroke="grey"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      style={{
                        borderBottom: "solid 1px #f2f2f2",
                        // borderRadius: 5,
                        paddingTop: 10,
                        paddingBottom: 10,
                        // marginBottom: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/jobs/add/`, {
                          state: {
                            invoice_partner: rowdata,
                            invoice_partner_id: rowdata.partner_id,
                            invoice_partner_type: rowdata.partner_type,
                            invoice_order: [],
                          },
                        });
                      }}
                    >
                      <p style={{ margin: 0 }}>
                        Add New Job for {rowdata.partner_first_name}
                      </p>
                      <ChevronRight
                        size={20}
                        strokeWidth={2}
                        stroke="grey"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div
                      style={{
                        borderBottom: "solid 1px #f2f2f2",
                        // borderRadius: 5,
                        paddingTop: 10,
                        paddingBottom: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/invoices/add/`, {
                          state: {
                            invoice_partner: rowdata,
                            invoice_partner_id: rowdata.partner_id,
                            invoice_partner_type: rowdata.partner_type,
                            invoice_order: [],
                          },
                        });
                      }}
                    >
                      <p style={{ margin: 0 }}>
                        Add New Invoice for {rowdata.partner_first_name}
                      </p>
                      <ChevronRight
                        size={20}
                        strokeWidth={2}
                        stroke="grey"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>


                </div>
              </Modal.Body>
            </Modal> */}
              </div>
            </>
          ) : (
            <Page404Authenticated />
          )}
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <Spinner animation="border" variant="dark" className="me-2" />
        </div>
      )}
    </>
  );
};
export default ViewPartner;
