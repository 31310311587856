import React, { useEffect, useState } from "react";
import {
  useRoutes,
  Redirect,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import firebase from "firebase/compat/app";
// import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
// import { store } from "./redux/store";

import "./i18n";
// import routes from "./routes";
// import { ErrorBoundary } from "react-error-boundary";
import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import ChartJsDefaults from "./utils/ChartJsDefaults";
import { FormProvider } from "./contexts/InvoiceformContext";
import useAuth from "./hooks/useAuth";

import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
//Dashboard
import Default from "./pages/dashboards/Default";

// Pages
import Blank from "./pages/pages/Blank";
import Invoice from "./pages/pages/Invoice";
import Estimate from "./pages/pages/Estimate";
import Orders from "./pages/pages/Orders";
import Settings from "./pages/pages/Settings";
import Product from "./pages/pages/Product";
import Services from "./pages/pages/Services";
import Customer from "./pages/pages/Customer";
import Supplier from "./pages/pages/Supplier";
import Transaction from "./pages/pages/Transaction";
import TransactionAdd from "./pages/pages/TransactionAdd";
import PriceList from "./pages/pages/PriceList";
import PriceListAdd from "./pages/pages/PriceListAdd";
import PriceListDisplay from "./pages/pages/PriceListDisplay";
import PriceListEdit from "./pages/pages/PriceListEdit";
import Schedule from "./pages/pages/Schedule";
import ScheduleEdit from "./pages/pages/ScheduleEdit";
import Expense from "./pages/pages/Expense";
import InvoiceSelection from "./pages/pages/InvoiceSelection";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";

// Protected routes
// import ProtectedPage from "./pages/protected/ProtectedPage";
import Partnerform from "./pages/pages/Partnerform";
import Service from "./pages/pages/Services";
import Itemform from "./pages/pages/Itemform";
import BusinessProfile from "./pages/pages/BusinessProfile";
import InvoiceDetail from "./pages/pages/InvoiceDetail";
import JobDetail from "./pages/pages/JobDetail";
import EditInvoice from "./pages/pages/EditInvoice";
import InputComponentPartner from "./pages/pages/InputComponentPartner";
import InputComponentItem from "./pages/pages/InputComponentItem";
import OnlinePayments from "./pages/pages/OnlinePayments";
import AddInvoice from "./pages/pages/AddInvoice";
import AddSchedule from "./pages/pages/AddSchedule";
import ViewPartner from "./pages/pages/ViewPartner";
import Page404Authenticated from "./pages/auth/Page404authenticated";
import Subscription from "./pages/pages/Subscription";
import NewBusinessmodal from "./components/NewBusinessmodal";
import Onboarding from "./pages/pages/Onboarding";
import BusinessWebsite from "./pages/pages/BusinessWebsite";
import MyIframeComponent from "./pages/pages/Iframe";
import Timesheet from "./pages/pages/Timesheet";
import { usePremium } from "./contexts/PremiumContext";
import { SelectedProvider } from "./contexts/SelectedTab";
import { MixpanelProvider } from "./store/Analytics";

import mixpanel from "mixpanel-browser";
import BusinessProfileEdit from "./pages/pages/BusinessProfileEdit";
import { ImageUploadProvider } from "./contexts/ImageUploadContext";
import { Spinner } from "react-bootstrap";
import WebsiteCustomization from "./pages/pages/WebsiteCustomization";
import BookingCustomization from "./pages/pages/BookingCustomization";
import Lead from "./pages/pages/Lead";
import Request from "./pages/pages/Request";
import RequestDetail from "./pages/pages/RequestDetail";
import SignUpUserPage from "./pages/auth/SignUpUser";
import { STRIPE_PRODUCTION } from "./constants";
import TeamAcceptSuccess from "./pages/pages/TeamAcceptSuccessMobile";
import TeamMemberList from "./pages/pages/TeamMemberList";
import AddTeamMember from "./pages/pages/AddTeamMember";
import UserProfile from "./pages/pages/Userprofile";
import UserProfileEdit from "./pages/pages/UserProfileEdit";

const MyModal = (props) => {
  const location = useLocation();
  console.log(location);
  const [createnewmodal, setCreatenewmodal] = useState(
    location.pathname.includes("user-signup") ? false : true
  );
  return (
    <>
      <React.Fragment>
        <NewBusinessmodal
          createnewmodal={createnewmodal}
          setCreatenewmodal={setCreatenewmodal}
        />
      </React.Fragment>
    </>
  );
};
const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    moreInfo,
    setMoreInfo,
    isPremium,
    setIsPremium,
    setIsFree,
    isTrial,
    setIsTrial,
    useIsFree,
    setMoreinfoCount,
  } = usePremium();
  // const [loading, setLoading] = useState(true);
  var script = document.createElement("script");
  script.src = "//code.tidio.co/0akbpi7l3kio1ijrvphxdsj1sunfs4k2.js";
  script.async = true;
  script.id = "tidioscripttag";
  const [businesExist, setBusinessExist] = useState(false);
  const [business_email, setBusiness_email] = useState("");
  const { userPermission } = useAuth();
  useEffect(() => {
    // mixpanel.init(
    //   // `31d65feec55899e0258956532fe315c8`,
    //   `287e8e8f710edb2d6a7cb82e75f1177b`,
    //   {
    //     // track_pageview: true,
    //     // persistence: "localStorage",
    //   }
    // );
    if (
      !(
        location.pathname.includes("sign") ||
        location.pathname.includes("reset") ||
        location.pathname.includes("edit") ||
        location.pathname.includes("profile") ||
        location.pathname.includes("add")
      )
    ) {
      console.log("insinde");
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      location.pathname.includes("sign") ||
      location.pathname.includes("reset") ||
      location.pathname.includes("edit") ||
      location.pathname.includes("profile") ||
      location.pathname.includes("add")
    ) {
      // document.body.appendChild(script);
      console.log(document.body.childNodes);
      if (document.getElementById("tidioscripttag")) {
        document.body.removeChild(document.getElementById("tidioscripttag"));
      }
      if (document.getElementById("tidio-chat-code")) {
        document.body.removeChild(document.getElementById("tidio-chat-code"));
      }
      if (document.getElementById("tidio-chat")) {
        document.body.removeChild(document.getElementById("tidio-chat"));
      }
    }
    return () => {
      if (
        location.pathname.includes("sign") ||
        location.pathname.includes("reset") ||
        location.pathname.includes("add") ||
        location.pathname.includes("profile") ||
        location.pathname.includes("edit")
      ) {
        document.body.appendChild(script);
      }
    };
  }, [location.pathname]);

  console.log(location);
  const {
    bdata,
    isAuthenticated,
    isInitialized,
    token,
    currency,
    setBdata,
    loading,
    setLoading,
    isTeamAccount,
    // showMobileAcceptui,
    isDeactivated,
  } = useAuth();
  let bookingPermission =
    Object.keys(userPermission).includes("booking") &&
    Object.keys(userPermission.booking).includes("permission_key")
      ? userPermission.booking.permission_key
      : "";
  let schedulePermission =
    Object.keys(userPermission).includes("schedule") &&
    Object.keys(userPermission.schedule).includes("permission_key")
      ? userPermission.schedule.permission_key
      : "";
  let invoicePermission =
    Object.keys(userPermission).includes("invoice") &&
    Object.keys(userPermission.invoice).includes("permission_key")
      ? userPermission.invoice.permission_key
      : "";
  let requestPermission =
    Object.keys(userPermission).includes("request") &&
    Object.keys(userPermission.request).includes("permission_key")
      ? userPermission.request.permission_key
      : "";
  let itemPermission =
    Object.keys(userPermission).includes("item") &&
    Object.keys(userPermission.item).includes("permission_key")
      ? userPermission.item.permission_key
      : "";
  let partnerPermission =
    Object.keys(userPermission).includes("partner") &&
    Object.keys(userPermission.partner).includes("permission_key")
      ? userPermission.partner.permission_key
      : "";
  let estimatePermission =
    Object.keys(userPermission).includes("estimate") &&
    Object.keys(userPermission.estimate).includes("permission_key")
      ? userPermission.estimate.permission_key
      : "";
  let timesheetPermission =
    Object.keys(userPermission).includes("timesheet") &&
    Object.keys(userPermission.timesheet).includes("permission_key")
      ? userPermission.timesheet.permission_key
      : "";
  let expensePermission =
    Object.keys(userPermission).includes("expense") &&
    Object.keys(userPermission.expense).includes("permission_key")
      ? userPermission.expense.permission_key
      : "";
  console.log("updating routes");
  let routes = [];
  // console.log(isInitialized, isAuthenticated);
  console.log(isInitialized, isAuthenticated, isTeamAccount);
  if (isInitialized && !isAuthenticated) {
    routes = [
      {
        path: "/",
        element: <Navigate replace to="/sign-in" />,
      },
      {
        path: "/",
        element: (
          // <GuestGuard>
          <AuthLayout />
        ),
        // </GuestGuard>

        children: [
          {
            path: "sign-in",
            element: <SignIn />,
          },
          {
            path: "sign-up",
            element: <SignUp />,
          },
          {
            path: "user-signup/:id",
            element: <SignUpUserPage />,
          },
          {
            path: "reset-password",
            element: <ResetPassword />,
          },
        ],
      },
      {
        path: "*",
        element: <Navigate replace to="/" />,
        // element: <AuthLayout />,
        // children: [
        //   {
        //     path: "*",
        //     element: <Page404 />,
        //   },
        // ],
      },
    ];
  } else if (isInitialized && isAuthenticated) {
    console.log(isTeamAccount, isDeactivated);
    if (isTeamAccount && isDeactivated) {
      routes = [
        {
          path: "/teams-block",
          element: <TeamAcceptSuccess isNormal={true} />,
        },

        {
          path: "*",
          element: (
            <Navigate replace to="/teams-block" state={{ isNormal: true }} />
          ),
        },
        {
          path: "user-signup/:id",
          element: <SignUpUserPage />,
        },
      ];
    } else {
      routes = [
        {
          path: "/teams-onboard-block",
          element: <TeamAcceptSuccess isNormal={false} />,
        },
        {
          path: "/",
          element: <Navigate replace to="/dashboard" />,
        },
        {
          path: "/sign-in",
          element: <Navigate replace to="/dashboard" />,
        },
        {
          path: "/sign-up",
          element: <Navigate replace to="/dashboard" />,
        },
        // {
        //   path: "user-signup-status/success",
        //   element: <TeamAcceptSuccess />,
        // },
        {
          path: "dashboard",
          element: (
            // <AuthGuard>
            <DashboardLayout />
            // </AuthGuard>
          ),
          children: [
            {
              path: "",
              element: <Default />,
            },
          ],
        },
        {
          path: "user-signup/:id",
          element: <SignUpUserPage />,
        },
        // {
        //   path: "user-signup-status/success",
        //   element: <TeamAcceptSuccess />,
        // },
        {
          path: "/",
          element: (
            // <AuthGuard>
            <DashboardLayout />
            // </AuthGuard>
          ),
          children: [
            // {
            //   path: "blank",
            //   element: <Blank />,
            // },

            //invoice routes

            {
              path: "gettingstarted",
              element: <Onboarding />,
            },

            //items routes

            //partners routes

            //pricelist
            // {
            //   path: "pricelist",
            //   element: <PriceList />,
            // },
            // {
            //   path: "pricelistadd",
            //   element: <PriceListAdd />,
            // },
            // {
            //   path: "pricelist/edit/:id",
            //   element: <PriceListEdit />,
            // },
            // {
            //   path: "pricelist/:id",
            //   element: <PriceListDisplay />,
            // },
            //transaction

            //schedule
            // {
            //   path: "invoiceselection",
            //   element: <InvoiceSelection />,
            // },

            {
              path: "settings",
              element: <Settings />,
            },
          ],
        },
        {
          path: "*",
          element: <DashboardLayout />,
          children: [
            {
              path: "*",
              element: <Navigate replace to="/" />,
            },
          ],
        },
      ];
      if (isTeamAccount) {
        routes[6].children.push(
          {
            path: "user-profile",
            element: <UserProfile />,
          },
          {
            path: "user-profile/edit",
            element: <UserProfileEdit />,
          }
        );
        if (invoicePermission) {
          routes[6].children.push(
            {
              path: "invoices",
              element: <Invoice />,
            },
            {
              path: "invoices/:id",
              element: <InvoiceDetail />,
            }
          );
        }
        if (invoicePermission.includes("ADD")) {
          routes[6].children.push({
            path: "invoices/add",
            element: <AddInvoice type="Invoice" key="invoice" />,
          });
        }
        if (invoicePermission.includes("EDIT")) {
          routes[6].children.push({
            path: "invoices/edit/:id",
            element: <EditInvoice type={"Invoice"} />,
          });
        }
        if (estimatePermission) {
          routes[6].children.push(
            {
              path: "estimates",
              element: <Estimate />,
            },
            {
              path: "estimates/:id",
              element: <InvoiceDetail />,
            }
          );
        }
        if (estimatePermission.includes("ADD")) {
          routes[6].children.push({
            path: "estimates/add",
            element: <AddInvoice type="Estimate" key="estaimte" />,
          });
        }
        if (estimatePermission.includes("EDIT")) {
          routes[6].children.push({
            path: "estimates/edit/:id",
            element: <EditInvoice type={"Estimate"} />,
          });
        }
        if (requestPermission) {
          routes[6].children.push(
            {
              path: "requests",
              element: <Request />,
            },
            {
              path: "requests/:id",
              element: <RequestDetail />,
            }
          );
        }
        if (requestPermission.includes("ADD")) {
          routes[6].children.push({
            path: "requests/add",
            element: <AddInvoice type="Request" key="request" />,
          });
        }
        if (requestPermission.includes("EDIT")) {
          routes[6].children.push({
            path: "requests/edit/:id",
            element: <EditInvoice type={"Request"} />,
          });
        }
        if (schedulePermission || bookingPermission || requestPermission) {
          routes[6].children.push({
            path: "schedule",
            element: <Schedule />,
          });
        }

        // {
        //   path: "schedule/add-schedule",
        //   element: <AddSchedule />,
        // },
        // {
        //   path: "editschedule/:id",
        //   element: <ScheduleEdit />,
        // },
        if (timesheetPermission) {
          routes[6].children.push({
            path: "timesheet",
            element: <Timesheet />,
          });
        }
        if (expensePermission) {
          routes[6].children.push({
            path: "expense",
            element: <Expense />,
          });
        }
        if (itemPermission) {
          routes[6].children.push(
            {
              path: "products",
              element: <Product />,
            },

            {
              path: "services",
              element: <Service />,
            }
          );
        }
        if (itemPermission.includes("ADD")) {
          routes[6].children.push(
            {
              path: "products/add-item",
              element: <Itemform type="Product" key="product" />,
            },
            {
              path: "services/add-item",
              element: <Itemform type="Service" key="service" />,
            },
            {
              path: "products/edit-item/:id",
              element: <InputComponentItem type="Product" />,
            },
            {
              path: "services/edit-item/:id",
              element: <InputComponentItem type="Service" />,
            }
          );
        }
        if (partnerPermission.includes("ADD")) {
          routes[6].children.push(
            {
              path: "customer",
              element: <Customer />,
            },
            {
              path: "customer/:id",
              element: <ViewPartner type="Customer" />,
            },
            {
              path: "customer/:id/edit",
              element: <InputComponentPartner type="Customer" />,
            },
            {
              path: "supplier",
              element: <Supplier />,
            },
            {
              path: "supplier/:id",
              element: <ViewPartner type="Supplier" />,
            },
            {
              path: "supplier/:id/edit",
              element: <InputComponentPartner type="Supplier" />,
            },
            {
              path: "supplier/add-supplier",
              element: <Partnerform type="Supplier" key="supplier" />,
            },
            {
              path: "customer/add-customer",
              element: <Partnerform type="Customer" key="customer" />,
            },
            {
              path: "lead",
              element: <Lead />,
            },
            {
              path: "lead/add-lead",
              element: <Partnerform type="Lead" key="lead" />,
            },
            {
              path: "lead/:id/edit",
              element: <InputComponentPartner type="Lead" />,
            },
            {
              path: "lead/:id",
              element: <ViewPartner type="Lead" />,
            }
          );
        }
        if (bookingPermission) {
          routes[6].children.push(
            {
              path: "bookings",
              element: <Orders />,
            },
            {
              path: "bookings/:id",
              element: <JobDetail />,
            }
          );
        }
        if (bookingPermission.includes("ADD")) {
          routes[6].children.push({
            path: "bookings/add",
            element: <AddInvoice type="Booking" key="booking" />,
          });
        }
        if (bookingPermission.includes("EDIT")) {
          routes[6].children.push({
            path: "bookings/edit/:id",
            element: <EditInvoice type={"Booking"} />,
          });
        }
      } else {
        routes[6].children.push(
          {
            path: "profile",
            element: <BusinessProfile />,
          },
          {
            path: "profile/edit",
            element: <BusinessProfileEdit />,
          },
          {
            path: "business-website",
            element: <BusinessWebsite />,
          },
          {
            path: "manage-team",
            element: <TeamMemberList />,
          },
          {
            path: "manage-team/add",
            element: <AddTeamMember />,
          },
          {
            path: "business-website/website",
            element: <WebsiteCustomization />,
          },
          {
            path: "business-website/booking",
            element: <BookingCustomization />,
          },
          {
            path: "iframe",
            element: <MyIframeComponent />,
          },
          {
            path: "transaction",
            element: <Transaction />,
          },
          {
            path: "transactionadd",
            element: <TransactionAdd />,
          },
          {
            path: "payments",
            element: <OnlinePayments />,
          },
          {
            path: "subscription",
            element: <Subscription />,
          },
          {
            path: "invoices",
            element: <Invoice />,
          },
          {
            path: "invoices/add",
            element: <AddInvoice type="Invoice" key="invoice" />,
          },
          {
            path: "invoices/:id",
            element: <InvoiceDetail />,
          },
          {
            path: "invoices/edit/:id",
            element: <EditInvoice type={"Invoice"} />,
          },
          {
            path: "estimates",
            element: <Estimate />,
          },
          {
            path: "estimates/add",
            element: <AddInvoice type="Estimate" key="estaimte" />,
          },
          {
            path: "estimates/:id",
            element: <InvoiceDetail />,
          },
          {
            path: "estimates/edit/:id",
            element: <EditInvoice type={"Estimate"} />,
          },
          {
            path: "requests",
            element: <Request />,
          },
          {
            path: "requests/add",
            element: <AddInvoice type="Request" key="request" />,
          },
          {
            path: "requests/:id",
            element: <RequestDetail />,
          },
          {
            path: "requests/edit/:id",
            element: <EditInvoice type={"Request"} />,
          },
          {
            path: "timesheet",
            element: <Timesheet />,
          },
          {
            path: "expense",
            element: <Expense />,
          },
          {
            path: "products",
            element: <Product />,
          },

          {
            path: "services",
            element: <Service />,
          },

          {
            path: "products/add-item",
            element: <Itemform type="Product" key="product" />,
          },
          {
            path: "services/add-item",
            element: <Itemform type="Service" key="service" />,
          },
          {
            path: "products/edit-item/:id",
            element: <InputComponentItem type="Product" />,
          },
          {
            path: "services/edit-item/:id",
            element: <InputComponentItem type="Service" />,
          },
          {
            path: "customer",
            element: <Customer />,
          },
          {
            path: "customer/:id",
            element: <ViewPartner type="Customer" />,
          },
          {
            path: "customer/:id/edit",
            element: <InputComponentPartner type="Customer" />,
          },
          {
            path: "supplier",
            element: <Supplier />,
          },
          {
            path: "supplier/:id",
            element: <ViewPartner type="Supplier" />,
          },
          {
            path: "supplier/:id/edit",
            element: <InputComponentPartner type="Supplier" />,
          },
          {
            path: "supplier/add-supplier",
            element: <Partnerform type="Supplier" key="supplier" />,
          },
          {
            path: "customer/add-customer",
            element: <Partnerform type="Customer" key="customer" />,
          },
          {
            path: "lead",
            element: <Lead />,
          },
          {
            path: "lead/add-lead",
            element: <Partnerform type="Lead" key="lead" />,
          },
          {
            path: "lead/:id/edit",
            element: <InputComponentPartner type="Lead" />,
          },
          {
            path: "lead/:id",
            element: <ViewPartner type="Lead" />,
          },
          {
            path: "bookings",
            element: <Orders />,
          },
          {
            path: "bookings/add",
            element: <AddInvoice type="Booking" key="booking" />,
          },
          {
            path: "bookings/:id",
            element: <JobDetail />,
          },
          {
            path: "bookings/edit/:id",
            element: <EditInvoice type={"Booking"} />,
          },
          {
            path: "schedule",
            element: <Schedule />,
          }
        );
      }
    }
  }

  const getData = async () => {
    var user = firebase.auth().currentUser;
    let token = await user.getIdToken();
    setBusiness_email(user.email);
    fetch(
      "https://bestmate.us/api/business/more-info" +
        `${isTeamAccount ? "?team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        fetch(
          `https://bestmate.us/api/business/web-subscription/get-subscription${
            STRIPE_PRODUCTION ? "?" : "?stripe_test=true&"
          }${isTeamAccount ? "team=true" : ""}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        )
          .then((res1) => res1.json())
          .then((result2) => {
            setBusinessExist(!result.businessExists);
            let final = { ...result, ...result2 };
            // let final = { ...result };
            setMoreInfo(final);
            console.log(final);
            setIsFree(final.isFreeUser);
            setIsTrial(final.isTrialUser);
            setIsPremium(
              final.isPremiumAndroidUser ||
                final.isPremiumIOSUser ||
                final.isPremiumStripeUser
            );
            let value = localStorage.getItem("PSCCount");
            console.log(value);
            let tempValue = JSON.parse(value);
            console.log(tempValue);
            let containId = false;
            if (value !== null)
              tempValue.map((elem) => {
                console.log(elem.id, user.email);
                if (elem.id === user.email) {
                  containId = true;
                  return;
                }
              });

            console.log(containId);
            if (value === null || !containId) {
              console.log("first");
              localStorage.setItem(
                "PSCCount",
                JSON.stringify(
                  value
                    ? [...JSON.parse(value), { id: user.email, attempt: 0 }]
                    : [{ id: user.email, attempt: 0 }]
                )
              );
            } else if (
              final.isTrialUser == true ||
              final.isPremiumAndroidUser == true ||
              final.isPremiumIOSUser == true ||
              final.isPremiumStripeUser == true ||
              (useIsFree && final.isFreeUser == true)
            ) {
              console.log("second");
              let obj = tempValue.map((elem) => {
                if (elem.id === user.email) {
                  return { id: user.email, attempt: 0 };
                } else {
                  return elem;
                }
              });

              localStorage.setItem("PSCCount", JSON.stringify(obj));
            } else {
              console.log("third");
              let PSCattempts = 0;
              let obj = tempValue.map((elem) => {
                if (elem.id === user.email) {
                  PSCattempts = ++elem.attempt;
                  setMoreinfoCount(PSCattempts);
                  return { id: user.email, attempt: PSCattempts };
                } else {
                  return elem;
                }
              });

              localStorage.setItem("PSCCount", JSON.stringify(obj));
            }
            console.log("setting loader to false on app.js");
            setLoading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      getData();
    } else {
      console.log("set remove");
      localStorage.removeItem("InviteData");
      localStorage.setItem("isRequest", false);
      setBusinessExist(false);
      // setLoading(false);
    }
  }, [isAuthenticated]);

  const content = useRoutes(routes);
  console.log(content);
  console.log(bdata, isAuthenticated, isInitialized, token, currency);
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | BestMate: The Next Generation Business App"
        defaultTitle="BestMate: The Next Generation Business App"
      />
      <SelectedProvider>
        <FormProvider>
          <ThemeProvider>
            <SidebarProvider>
              <ImageUploadProvider>
                <LayoutProvider>
                  <ChartJsDefaults />
                  {loading ? (
                    <div
                      style={{
                        height: "100vh",
                        width: "100vw",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner
                        animation="border"
                        size="md"
                        style={{ color: "#407FEA" }}
                      />
                    </div>
                  ) : (
                    <>
                      {document.documentElement?.style.setProperty(
                        "--widthh",
                        window.screen.width
                      )}
                      {businesExist === true && !isTeamAccount ? (
                        <MyModal businessEmail={business_email} token={token} />
                      ) : (
                        <></>
                      )}
                      {content}
                    </>
                  )}
                </LayoutProvider>
              </ImageUploadProvider>
            </SidebarProvider>
          </ThemeProvider>
        </FormProvider>
      </SelectedProvider>
    </HelmetProvider>
  );
};

export default App;
