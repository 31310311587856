import React, { useContext, useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useTable } from "react-table";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import "../../assets/css/App.css";
import empty_inventory from "../../assets/img/icons/empty_inventory.png";
import empty_partner from "../../assets/img/icons/empty_partner.png";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import moment from "moment";
import { v4 } from "uuid";
import mixpanel, { init } from "mixpanel-browser";
import {
  Plus,
  Minus,
  PlusCircle,
  Edit,
  Edit2,
  ArrowLeft,
  ArrowRight,
  Delete,
  Trash2,
  X,
  User,
} from "react-feather";
import Select from "react-select";
import Switch from "react-switch";
import {
  Card,
  Col,
  Row,
  Form,
  Container,
  Table,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  ListGroup,
  Breadcrumb,
  Spinner,
} from "react-bootstrap";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { Formik } from "formik";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import useForm from "../../hooks/useForm";
import useSidebar from "../../hooks/useSidebar";
import NotyfContext from "../../contexts/NotyfContext";
import Selectmodal from "../../components/Selectmodal";
import Newpartnermodal from "../../components/Newpatnermodal";
import Newitemmodal from "../../components/Newitemmodal";
import getSymbolFromCurrency from "currency-symbol-map";
import AttachDocuments from "../../components/AttaachDocumentsmodal";
import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import { logError } from "../../utils/useErrorlogging";
function formatNumber(number) {
  return String(number).padStart(3, "0");
}
const DisplayTable = (props) => {
  const [val, setVal] = useState();
  const navigate = useNavigate();
  // const [quantitymodal, setQuantitymodal] = useState(false);
  const [elemdata, setElemdata] = useState();
  console.log(props, val);
  console.log("++++++");
  // const { bdata } = useAuth();
  // console.log(bdata);
  let usercurrency = localStorage.getItem("currency") || "USD";
  if (props.invoicedata.length !== 0) {
    console.log(props.invoicedata, "true");
  } else {
    console.log(props.invoicedata, "false");
  }
  return (
    <>
      <Table style={{ marginBottom: props.invoicedata.length !== 0 ? 10 : 0 }}>
        <thead style={{ backgroundColor: "#f3f6f9" }}>
          <tr>
            <th style={{ width: "35%" }}>{props.type} Name</th>
            <th style={{ width: "20%" }}>Price</th>
            <th style={{ width: "15%" }}>Quantity</th>
            <th style={{ width: "20%" }}>Total</th>
            <th style={{ width: "10%" }}></th>
          </tr>
        </thead>

        <tbody style={{ borderStyle: "none" }}>
          {props.invoicedata.map((elem) => {
            // if (elem.item_quantity > 0) {
            console.log(elem);
            return (
              <tr key={elem.item_id} style={{ borderStyle: "none" }}>
                <td style={{ borderStyle: "none" }}>
                  {/* {elem.item_name} */}
                  <Form.Control
                    type="text"
                    // disabled
                    // name="subtotal"
                    placeholder={`${props.type} Name`}
                    value={elem.item_name}
                    onChange={(el) => {
                      // console.log(el.target.value);
                      // console.log(props.data.invoice_order);
                      let news = props.data.invoice_order.map((ele) => {
                        if (elem.item_id === ele.item_id) {
                          return { ...ele, item_name: el.target.value };
                        } else {
                          return ele;
                        }
                      });
                      props.setData({ ...props.data, invoice_order: news });
                    }}
                    style={{ borderColor: "#e8e8e8" }}
                  />
                  <Form.Control
                    type="text"
                    // disabled
                    // name="subtotal"
                    placeholder={`${props.type} Detail`}
                    as="textarea"
                    rows="2"
                    value={elem.item_description}
                    style={{ marginTop: 10, borderColor: "#e8e8e8" }}
                    // onChange={handleChange}
                    onChange={(el) => {
                      // console.log(el.target.value);
                      // console.log(props.data.invoice_order);
                      let news = props.data.invoice_order.map((ele) => {
                        if (elem.item_id === ele.item_id) {
                          return {
                            ...ele,
                            item_description: el.target.value,
                          };
                        } else {
                          return ele;
                        }
                      });
                      props.setData({ ...props.data, invoice_order: news });
                    }}
                  />
                </td>
                <td style={{ borderStyle: "none", verticalAlign: "top" }}>
                  <Form.Control
                    type="number"
                    step="any"
                    min={0}
                    // disabled
                    // name="subtotal"
                    placeholder="text"
                    value={Number(
                      (props.price === "SUPPLIER"
                        ? Number(
                            elem.item_cost_price ? elem.item_cost_price : 0
                          )
                        : Number(elem.item_selling_price)
                        ? elem.item_selling_price
                        : 0) * (props.pricelist ? props.pricelist : 1)
                    )}
                    style={{ borderColor: "#e8e8e8" }}
                    onChange={(el) => {
                      // console.log(el.target.value);
                      // console.log(props.data.invoice_order);
                      if (el.target.value.replace(/\s/g, "").length <= 10) {
                        let news = props.data.invoice_order.map((ele) => {
                          // let tt =
                          //   props.price === "SUPPLIER"
                          //     ? "item_cost_price"
                          //     : "item_selling_price";
                          if (elem.item_id === ele.item_id) {
                            // return { ...ele, tt: el.target.value };
                            if (props.price === "SUPPLIER") {
                              return {
                                ...ele,
                                item_cost_price: el.target.value,
                              };
                            } else {
                              return {
                                ...ele,
                                item_selling_price:
                                  el.target.value /
                                  (props.pricelist ? props.pricelist : 1),
                              };
                            }
                          } else {
                            return ele;
                          }
                        });
                        props.setData({ ...props.data, invoice_order: news });
                        console.log(elem.item_selling_price);
                        let minus = Number(
                          (props.price === "SUPPLIER"
                            ? Number(
                                elem.item_cost_price ? elem.item_cost_price : 0
                              ) *
                              Number(
                                elem.item_quantity ? elem.item_quantity : 1
                              )
                            : Number(
                                elem.item_selling_price
                                  ? elem.item_selling_price
                                  : 0
                              )) *
                            (props.pricelist ? props.pricelist : 1) *
                            Number(elem.item_quantity ? elem.item_quantity : 1)
                        );

                        props.setSubtotal(
                          (old) =>
                            old -
                            minus +
                            (props.price === "SUPPLIER"
                              ? Number(el.target.value) *
                                Number(
                                  elem.item_quantity ? elem.item_quantity : 1
                                )
                              : Number(el.target.value) /
                                (props.pricelist ? props.pricelist : 1)) *
                              (props.pricelist ? props.pricelist : 1) *
                              Number(
                                elem.item_quantity ? elem.item_quantity : 1
                              )
                        );
                      }
                    }}
                    // onChange={handleChange}
                  />
                  {/* {parseFloat(
                      (props.price === "SUPPLIER"
                        ? Number(elem.item_cost_price)
                        : Number(elem.item_selling_price)) * props.pricelist
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: usercurrency,
                    })} */}
                </td>
                <td
                  style={{
                    borderStyle: "none",
                    display: "flex",
                    verticalAlign: "top",
                    // justifyContent: "center",
                  }}
                >
                  {/* <div
                      style={{
                        border: "1px solid lightgrey",
                        padding: "5px",
                        //width: "180px",
                        // display: "flex",
                        // flexDirection: "row",
                        // justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{ backgroundColor: "#f3f6f6", padding: "3px" }}
                        onClick={() => {
                          // alert("decrement");
                          if (elem.item_quantity > 1) {
                            elem.item_quantity =
                              parseInt(elem.item_quantity) - 1;
                            setVal(elem.item_quantity);
                            props.setSubtotal(
                              (old) =>
                                old -
                                (props.price === "SUPPLIER"
                                  ? Number(elem.item_cost_price)
                                  : Number(elem.item_selling_price)) *
                                  props.pricelist
                            );
                          }
                          console.log(elem);
                          // if (elem.item_quantity === 0) {
                          //   setQuantitymodal(true);
                          //   setElemdata(elem);
                          // }
                          // props.setChange((old) => old + 1);
                        }}
                      >
                        <Minus
                          // style={{ backgroundColor: "#f3f6f6" }}
                          size={15}
                          strokeWidth={3}
                          // onClick={() => {
                          //   // alert("decrement");
                          //   if (elem.item_quantity > 1) {
                          //     elem.item_quantity =
                          //       parseInt(elem.item_quantity) - 1;
                          //     setVal(elem.item_quantity);
                          //     props.setSubtotal(
                          //       (old) =>
                          //         old -
                          //         (props.price === "SUPPLIER"
                          //           ? Number(elem.item_cost_price)
                          //           : Number(elem.item_selling_price)) *
                          //           props.pricelist
                          //     );
                          //   }
                          //   console.log(elem);
                          //   // if (elem.item_quantity === 0) {
                          //   //   setQuantitymodal(true);
                          //   //   setElemdata(elem);
                          //   // }
                          //   // props.setChange((old) => old + 1);
                          // }}
                        />
                      </span>

                      <span
                        className="m-2"
                        style={
                          {
                            // minWidth: "150px",
                          }
                        }
                      >
                        {isNaN(elem.item_quantity) ? 0 : elem.item_quantity}{" "}
                      </span>
                      <span
                        style={{
                          backgroundColor: "#f3f6f6",
                          padding: "3px",
                          textAlign: "right",
                        }}
                        onClick={() => {
                          // alert("increment");
                          console.log(elem.item_quantity);
                          if (isNaN(elem.item_quantity)) {
                            elem.item_quantity = 1;
                            return;
                          }
                          elem.item_quantity = parseInt(elem.item_quantity) + 1;
                          setVal(elem.item_quantity);
                          console.log(elem);
                          props.setSubtotal(
                            (old) =>
                              old +
                              (props.price === "SUPPLIER"
                                ? Number(elem.item_cost_price)
                                : Number(elem.item_selling_price)) *
                                props.pricelist
                          );
                          // props.setChange((old) => old + 1);
                        }}
                      >
                        <Plus
                          type="button"
                          style={{ backgroundColor: "#f3f6f6" }}
                          size={15}
                          strokeWidth={3}
                          // onClick={() => {
                          //   // alert("increment");
                          //   console.log(elem.item_quantity);
                          //   if (isNaN(elem.item_quantity)) {
                          //     elem.item_quantity = 1;
                          //     return;
                          //   }
                          //   elem.item_quantity =
                          //     parseInt(elem.item_quantity) + 1;
                          //   setVal(elem.item_quantity);
                          //   console.log(elem);
                          //   props.setSubtotal(
                          //     (old) =>
                          //       old +
                          //       (props.price === "SUPPLIER"
                          //         ? Number(elem.item_cost_price)
                          //         : Number(elem.item_selling_price)) *
                          //         props.pricelist
                          //   );
                          //   // props.setChange((old) => old + 1);
                          // }}
                        />
                      </span>
                    </div> */}
                  <Form.Control
                    type="number"
                    min={0}
                    step="any"
                    // disabled
                    // name="subtotal"
                    placeholder="Product Qty"
                    value={isNaN(elem.item_quantity) ? 0 : elem.item_quantity}
                    onChange={(el) => {
                      console.log("((((((())))))))))");
                      // console.log(el.target.value);
                      // console.log(props.data.invoice_order);
                      if (el.target.value.replace(/\s/g, "").length <= 10) {
                        let news = props.data.invoice_order.map((ele) => {
                          if (elem.item_id === ele.item_id) {
                            return {
                              ...ele,
                              item_quantity: el.target.value
                                ? Number(el.target.value)
                                : 0,
                            };
                          } else {
                            return ele;
                          }
                        });
                        props.setData({ ...props.data, invoice_order: news });
                        let minus = Number(
                          (props.price === "SUPPLIER"
                            ? Number(
                                elem.item_cost_price ? elem.item_cost_price : 0
                              ) *
                              Number(
                                elem.item_quantity ? elem.item_quantity : 0
                              )
                            : Number(
                                elem.item_selling_price
                                  ? elem.item_selling_price
                                  : 0
                              )) *
                            (props.pricelist ? props.pricelist : 1) *
                            Number(elem.item_quantity ? elem.item_quantity : 0)
                        );
                        console.log(minus);
                        console.log("((((((())))))))))");
                        props.setSubtotal(
                          (old) =>
                            old -
                            minus +
                            (props.price === "SUPPLIER"
                              ? Number(
                                  elem.item_cost_price
                                    ? elem.item_cost_price
                                    : 0
                                ) *
                                Number(el.target.value ? el.target.value : 0)
                              : Number(
                                  elem.item_selling_price
                                    ? elem.item_selling_price
                                    : 0
                                )) *
                              (props.pricelist ? props.pricelist : 1) *
                              Number(el.target.value ? el.target.value : 0)
                        );
                      }
                    }}
                    style={{ borderColor: "#e8e8e8" }}
                  />
                </td>

                <td style={{ borderStyle: "none", verticalAlign: "top" }}>
                  <div className="d-flex justify-content-between">
                    <span>
                      <Form.Control
                        type="text"
                        disabled
                        // name="subtotal"
                        placeholder="text"
                        value={(
                          elem.item_quantity *
                          (props.price === "SUPPLIER"
                            ? Number(
                                elem.item_cost_price ? elem.item_cost_price : 0
                              )
                            : Number(
                                elem.item_selling_price
                                  ? elem.item_selling_price
                                  : 0
                              )) *
                          (props.pricelist ? props.pricelist : 1)
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency:
                            props.data && props.data.invoice_currency_code
                              ? props.data.invoice_currency_code
                              : usercurrency,
                        })}
                        // onChange={handleChange}
                        style={{ borderColor: "#e8e8e8" }}
                      />
                      {/* {(
                          elem.item_quantity *
                          (props.price === "SUPPLIER"
                            ? Number(elem.item_cost_price)
                            : Number(elem.item_selling_price)) *
                          props.pricelist
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: usercurrency,
                        })} */}
                    </span>
                    {/* <div
                        onClick={() => {
                          // setQuantitymodal(true);
                          // setElemdata(elem);
                          console.log(elem);
                          // setVal(elem.item_quantity);
                          let quantity = elem.item_quantity;
                          props.setSubtotal(
                            (old) =>
                              old -
                              (props.price === "SUPPLIER"
                                ? Number(elem.item_cost_price)
                                : Number(elem.item_selling_price)) *
                                Number(quantity) *
                                props.pricelist
                          );
                          elem.item_quantity = 0;
                        }}
                      >
                        <Trash2 size={17} />
                      </div> */}
                  </div>
                </td>
                <td style={{ borderStyle: "none", verticalAlign: "top" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div></div>
                    {/* <div style={{ backgroundColor: "green" }}> */}
                    <button
                      type="button"
                      // variant="danger"
                      style={{
                        // marginRight: "30px",
                        backgroundColor: "#1ab294",
                        border: "none",
                        color: "white",
                        fontWeight: "bold",
                        padding: "5px 10px",
                      }}
                      // style={{ backgroundColor: "#1ab294" }}
                      // style={{ justifyContent: "end" }}
                      onClick={() => {
                        // setQuantitymodal(true);
                        // setElemdata(elem);
                        mixpanel.track(
                          `Remove Item (Edit ${props.invoicetype} Web)`
                        );

                        console.log(elem, props.pricelist);
                        // setVal(elem.item_quantity);
                        let quantity = elem.item_quantity;
                        props.setSubtotal(
                          (old) =>
                            old -
                            (props.price === "SUPPLIER"
                              ? Number(
                                  elem.item_cost_price
                                    ? elem.item_cost_price
                                    : 0
                                )
                              : Number(
                                  elem.item_selling_price
                                    ? elem.item_selling_price
                                    : 0
                                )) *
                              Number(quantity) *
                              (props.pricelist ? props.pricelist : 1)
                        );
                        elem.item_quantity = 0;
                        let id = elem.item_id;
                        console.log(props);
                        let invoiceorder = props.data.invoice_order.filter(
                          (elems) => elems.item_id !== id
                        );
                        console.log(invoiceorder);
                        props.setData({
                          ...props.data,
                          invoice_order: invoiceorder,
                        });
                      }}
                    >
                      {/* <Trash2 size={17} /> */}
                      Delete
                    </button>
                    {/* </div> */}
                  </div>
                </td>
              </tr>
            );
            // }
          })}
        </tbody>
      </Table>
      {props.invoicedata.length !== 0 ? (
        <></>
      ) : (
        <>
          <div
            id="Emptybox"
            style={{
              width: "100%",
              height: 130,
              // backgroundColor: "pink",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={
              props.type === "Product"
                ? () => {
                    mixpanel.track(
                      `Add product (Edit ${props.invoicetype} Web)`
                    );
                    props.setsearchtem("");
                    props.setModalloading(true);
                    props.fetchItems();
                    props.setModal(true);
                  }
                : () => {
                    mixpanel.track(
                      `Add service (Edit ${props.invoicetype} Web)`
                    );
                    props.setsearchtem("");
                    props.setModalloading(true);
                    props.fetchItems();
                    props.setModal(true);
                  }
            }
          >
            <img
              src={empty_inventory}
              style={{ height: "50px", marginBottom: 10 }}
              alt="empty_invoice"
            ></img>
            <text
              style={{}}
            >{`Add ${props.type} to this ${props.invoicetype}`}</text>
          </div>
        </>
      )}
    </>
  );
};
let startTime;
let endTime;
let startDate;
let endDate;
function truncateTextByWordCount(text, wordLimit) {
  console.log(text.length);
  if (text.length > wordLimit) {
    return text.slice(0, wordLimit) + "...";
  } else {
    console.log(text);
    return text;
  }
}

const Single = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [disablesave, setDisablesave] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [leadcreatenewmodal, setLeadcreatenewmodal] = useState(false);

  var store = firebase.storage();
  const { token } = useAuth();
  const { bdata } = useAuth();
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const { id } = useParams();
  const { number, setNumber } = useForm();
  let usercurrency = localStorage.getItem("currency") || "USD";
  // const [partnermodal, setPartnermodal] = useState(false);
  const [data, setData] = useState(
    {}
    // location.state
  );
  // const InvoiceData = location.state.invoice_order;
  // console.log(data, InvoiceData);
  const [discountmodal, setDiscountmodal] = useState(false);
  const [taxmodal, setTaxmodal] = useState(false);
  const [recievecmodal, setRecievedmodal] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [autoremindmodal, setAutoremindmodal] = useState(false);
  const [tax, setTax] = useState(
    // data.invoice_tax_rate
    0
  );
  const [discount, setDiscount] = useState(
    // data.invoice_discount_rate
    0
  );
  const [discounttype, setDispounttype] = useState("percentage");
  const [tempdiscounttype, setTempDispounttype] = useState("percentage");
  const [taxtype, setTaxtype] = useState("percentage");
  const [temptaxtype, setTempTaxtype] = useState("percentage");
  const [recieved, setRecieved] = useState(
    // data.invoice_settled_amount
    0
  );
  const [customerModal, setCustomerModal] = useState(false);
  const [customerModalloading, setCustomerModalloading] = useState(true);
  const [supplierModal, setSupplierModal] = useState(false);
  const [supplierModalloading, setSupplierModalloading] = useState(true);
  const [productModal, setProductModal] = useState(false);
  const [productModalloading, setProductModalloading] = useState(true);
  const [serviceModal, setServiceModal] = useState(false);
  const [serviceModalloading, setServiceModalloading] = useState(true);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [searchterm, setsearchtem] = useState("");
  const [grandtotal, setGrandtotal] = useState(0);
  const [customercreatenewmodal, setCustomercreatenewmodal] = useState(false);
  const [suppliercreatenewmodal, setSuppliercreatenewmodal] = useState(false);
  const [productcreatenewmodal, setProductcreatenewmodal] = useState(false);
  const [servicecreatenewmodal, setServicecreatenewmodal] = useState(false);
  const [sendmailmodal, setsendmailmodal] = useState(false);
  const [imageupload, setImageupload] = useState(null);
  const [imageerror, setImageerror] = useState("");
  const [upload, setupload] = useState("");
  const [imagename, setImagename] = useState("");
  const [loading, setLoading] = useState(true);
  const [sendmecopy, setSendmecopy] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [photoModal, setPhotoModal] = useState(false);
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [teamMember, setTeamMember] = useState({});
  const { isTeamAccount, userPermission } = useAuth();
  let partnerPermission =
    Object.keys(userPermission).includes("partner") &&
    Object.keys(userPermission.partner).includes("permission_key")
      ? userPermission.partner.permission_key
      : "";
  let bookingPermission =
    Object.keys(userPermission).includes("booking") &&
    Object.keys(userPermission.booking).includes("permission_key")
      ? userPermission.booking.permission_key
      : "";
  console.log(data);
  const [existingimage, setExistingimage] = useState(
    // data.invoice_attachment_id ? data.invoice_attachment_id : ""
    ""
  );
  const [addappointmentlater, setAddappointemlater] = useState(
    // data.invoice_visits && data.invoice_visits.length !== 0 ? false : true
    true
  );
  const file = useRef();

  const [action, setAction] = useState("save");
  const [Id, setID] = useState("");
  const [remiender, setRemiender] = useState(
    // Object.keys(data).includes("invoice_reminders")
    //   ? data.invoice_reminders
    //   : []
    []
  );

  const [type, setType] = useState(
    // Object.keys(data.invoice_partner).length !== 0
    //   ? data.invoice_partner.partner_type === "SUPPLIER"
    //     ? "SUPPLIER"
    //     : "CUSTOMER"
    //   : "CUSTOMER"
    "CUSTOMER"
  );
  const [pricelist, setPricelist] = useState(
    1
    // Object.keys(data.invoice_partner).length !== 0 &&
    //   Object.keys(data.invoice_partner).includes("partner_price_list")
    //   ? Object.keys(data.invoice_partner.partner_price_list).length !== 0
    //     ? data.invoice_partner.partner_type === "CUSTOMER"
    //       ? data.invoice_partner.partner_price_list.price_list_multiplier
    //       : 1
    //     : 1
    //   : 1
  );

  const uploadToCloud = () => {
    if (imageupload == null) return;
    const imageref = ref(store, `files/${imageupload.name + v4()}`);
    setupload("starting");
    uploadBytes(imageref, imageupload).then((res) => {
      setImagename(res.metadata.name);
      console.log("done");
      console.log(res);
      setupload("uploaded");
    });
    // }
  };

  // console.log(multiplier);
  const fetchPartners = async () => {
    var user = firebase.auth().currentUser;

    // firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      let tokenss = await user.getIdToken();
      fetch(
        "https://bestmate.us/api/partners/findAll" +
          `${isTeamAccount ? "?team=true" : ""}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenss,
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            let data = [
              {
                label: "Customer",
                options: [],
              },
              {
                label: "Supplier",
                options: [],
              },
              {
                label: "Lead",
                options: [],
              },
            ];
            result.map((elem) => {
              const tempData = {
                label: `${elem.partner_first_name} ${elem.partner_last_name}`,
                value: elem,
              };
              if (elem.partner_type === "CUSTOMER") {
                data["0"].options.push(tempData);
              } else if (elem.partner_type === "SUPPLIER") {
                data["1"].options.push(tempData);
              } else if (elem.partner_type === "LEAD") {
                data["2"].options.push(tempData);
              }
            });
            // console.log(data);
            setPartnerOptions(data);
            setSupplierModalloading(false);
            setCustomerModalloading(false);
            // props.setLoading(false);
          },
          (error) => {
            console.error("Error fetching data: ", error);
          }
        );
    }
    // });
  };
  const getTeamMemberList = async () => {
    var user = firebase.auth().currentUser;
    if (user) {
      let token = await user.getIdToken();
      const response = await fetch(
        "https://bestmate.us/api/team/get-all-members",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      let result = await response.json();
      setTeamMemberList(result);
      // setLoading(false);
    }
  };

  const handleKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };
  const fetchItems = async () => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      fetch(
        "https://bestmate.us/api/items/findAll" +
          `${isTeamAccount ? "?team=true" : ""}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenss,
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            let data = [
              {
                label: "Product",
                options: [],
              },
              {
                label: "Service",
                options: [],
              },
            ];
            result.map((elem) => {
              const tempData = {
                label: `${elem.item_name}`,
                value: elem,
              };
              if (elem.item_type === "PRODUCT") {
                data["0"].options.push(tempData);
              } else {
                data["1"].options.push(tempData);
              }
            });
            setItemOptions(data);
            setProductModalloading(false);
            setServiceModalloading(false);
            // props.setLoading(false);
            console.log("done");
          },
          (error) => {
            console.error("Error fetching data: ", error);
          }
        );
    }
    // });
  };
  let sendorders = [];
  let small = props.type.toLowerCase();

  // console.log(sendorders);
  // setNumber(500);
  // data.invoice_tax_rate = tax;
  // data.invoice_discount_rate = discount;
  // data.invoice_settled_amount = recieved;

  // let grandtotal =
  //   subtotal - subtotal * discount + (subtotal - subtotal * discount) * tax;

  const totalamount = (data) => {
    console.log(data, pricelist);
    data.invoice_order.forEach((elem) => {
      if (Object.keys(data.invoice_partner).length !== 0) {
        if (
          data.invoice_partner.partner_type === "CUSTOMER" ||
          data.invoice_partner.partner_type === "LEAD"
        ) {
          Object.keys(data.invoice_partner).includes("partner_price_list") &&
          Object.keys(data.invoice_partner.partner_price_list).length !== 0
            ? setSubtotal(
                (old) =>
                  old +
                  (elem.item_selling_price ? elem.item_selling_price : 0) *
                    elem.item_quantity *
                    Number(
                      data.invoice_partner &&
                        data.invoice_partner.partner_price_list &&
                        data.invoice_partner.partner_price_list
                          .price_list_multiplier
                        ? data.invoice_partner.partner_price_list
                            .price_list_multiplier
                        : 1
                      // pricelist
                    )
              )
            : setSubtotal(
                (old) =>
                  old +
                  (elem.item_selling_price ? elem.item_selling_price : 0) *
                    elem.item_quantity
              );
        } else {
          setSubtotal(
            (old) =>
              old +
              (elem.item_cost_price ? elem.item_cost_price : 0) *
                elem.item_quantity
          );
        }
      } else {
        console.log("calculating subtotal");
        setSubtotal(
          (old) =>
            old +
            (elem.item_selling_price ? elem.item_selling_price : 0) *
              elem.item_quantity
        );
      }

      console.log(pricelist, elem);
    });
  };

  // useEffect(() => {
  //   totalamount();
  // }, []);
  const getRequiredData = async () => {
    console.log("fetching data");
    var user = firebase.auth().currentUser;
    let tokenss = await user.getIdToken();
    if (user) {
      const timeZoneOffset = -(new Date().getTimezoneOffset() * 60);

      // fetch("https://bestmate.us/api/invoices/findAll", {
      fetch(
        `https://bestmate.us/api/invoices/get/${id}?timeZoneOffset=${timeZoneOffset}` +
          `${isTeamAccount ? "&team=true" : ""}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenss,
          },
        }
      )
        .then((res) => {
          if (!res.ok) {
            // setinvoiceerror(true);
            // setLoading(false);
            // setFetchError(true);
            throw new Error(`HTTP error, status code ${res.status}`);
          }

          return res.json();
        })
        .then((result) => {
          console.log(result);
          setData(result);
          sendorders = result.invoice_order.filter(
            (elem) => elem.item_quantity > 0
          );
          console.log("data changed");
          setTax(
            result.invoice_tax_type && result.invoice_tax_type === "absolute"
              ? result.invoice_tax_rate
              : result.invoice_tax_rate * 100
          );
          setTaxtype(
            result.invoice_tax_type && result.invoice_tax_type === "absolute"
              ? "absolute"
              : "percentage"
          );
          setTempTaxtype(
            result.invoice_tax_type && result.invoice_tax_type === "absolute"
              ? "absolute"
              : "percentage"
          );
          setDiscount(
            result.invoice_discount_type &&
              result.invoice_discount_type === "absolute"
              ? result.invoice_discount_rate
              : result.invoice_discount_rate * 100
          );
          setDispounttype(
            result.invoice_discount_type &&
              result.invoice_discount_type === "absolute"
              ? "absolute"
              : "percentage"
          );
          setTempDispounttype(
            result.invoice_discount_type &&
              result.invoice_discount_type === "absolute"
              ? "absolute"
              : "percentage"
          );
          setRecieved(result.invoice_settled_amount);
          // setExistingimage(
          //   result.invoice_attachment_id ? result.invoice_attachment_id : ""
          // );
          setImageData(
            result.invoice_attachments &&
              result.invoice_attachments.length !== 0
              ? data.invoice_attachments
              : []
          );
          setAddappointemlater(
            result.invoice_visits && result.invoice_visits.length !== 0
              ? false
              : true
          );
          setRemiender(
            Object.keys(result).includes("invoice_reminders")
              ? result.invoice_reminders
              : []
          );
          setType(
            Object.keys(result.invoice_partner).length !== 0
              ? result.invoice_partner.partner_type === "SUPPLIER"
                ? "SUPPLIER"
                : result.invoice_partner.partner_type === "LEAD"
                ? "LEAD"
                : "CUSTOMER"
              : "CUSTOMER"
          );
          setPricelist(
            Object.keys(result.invoice_partner).length !== 0 &&
              Object.keys(result.invoice_partner).includes("partner_price_list")
              ? Object.keys(result.invoice_partner.partner_price_list)
                  .length !== 0
                ? result.invoice_partner.partner_type === "CUSTOMER" ||
                  result.invoice_partner.partner_type === "LEAD"
                  ? result.invoice_partner.partner_price_list
                      .price_list_multiplier
                  : 1
                : 1
              : 1
          );
          setTeamMember(
            result && result.invoice_assignee ? result.invoice_assignee : {}
          );
          if (
            (props.type === "Booking" || props.type === "Request") &&
            result.invoice_visits &&
            result.invoice_visits.length !== 0
          ) {
            startTime = new Date(
              result.invoice_visits[0].appointment_start_time
            );
            endTime = new Date(result.invoice_visits[0].appointment_end_time);
            startDate = startTime.toLocaleDateString("sv");
            endDate = endTime.toLocaleDateString("sv");
            // console.log(isOpen);
            // startTime = startTime.toLocaleTimeString("en-US", {
            //   hour: "numeric",
            //   minute: "numeric",
            //   hour12: false,
            // });
            // endTime = endTime.toLocaleTimeString("en-US", {
            //   hour: "numeric",
            //   minute: "numeric",
            //   hour12: false,
            // });
            let start = startTime.toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: false,
            });
            if (start.startsWith("24")) {
              startTime = "00" + start.slice(2);
            } else {
              startTime = start;
            }
            let end = endTime.toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: false,
            });
            if (end.startsWith("24")) {
              endTime = "00" + end.slice(2);
            } else {
              endTime = end;
            }
          }
          setLoading(false);
          console.log("loading false");
        })
        .catch((error) => console.error("Error fetching data: ", error));
    }
  };
  useEffect(() => {
    mixpanel.track(`On Edit ${props.type} (Web)`);
    setLoading(true);
    if (location.state) {
      setData(location.state);
      sendorders = location.state.invoice_order.filter(
        (elem) => elem.item_quantity > 0
      );
      console.log("data changed");
      setTax(
        location.state.invoice_tax_type &&
          location.state.invoice_tax_type === "absolute"
          ? location.state.invoice_tax_rate
          : location.state.invoice_tax_rate * 100
      );
      setTaxtype(
        location.state.invoice_tax_type &&
          location.state.invoice_tax_type === "absolute"
          ? "absolute"
          : "percentage"
      );
      setTempTaxtype(
        location.state.invoice_tax_type &&
          location.state.invoice_tax_type === "absolute"
          ? "absolute"
          : "percentage"
      );
      setDiscount(
        location.state.invoice_discount_type &&
          location.state.invoice_discount_type === "absolute"
          ? location.state.invoice_discount_rate
          : location.state.invoice_discount_rate * 100
      );
      setDispounttype(
        location.state.invoice_discount_type &&
          location.state.invoice_discount_type === "absolute"
          ? "absolute"
          : "percentage"
      );
      setTempDispounttype(
        location.state.invoice_discount_type &&
          location.state.invoice_discount_type === "absolute"
          ? "absolute"
          : "percentage"
      );
      setRecieved(location.state.invoice_settled_amount);
      // setExistingimage(
      //   location.state.invoice_attachment_id
      //     ? location.state.invoice_attachment_id
      //     : ""
      // );
      setImageData(
        location.state.invoice_attachments &&
          location.state.invoice_attachments.length !== 0
          ? location.state.invoice_attachments
          : []
      );
      setAddappointemlater(
        location.state.invoice_visits &&
          location.state.invoice_visits.length !== 0
          ? false
          : true
      );
      setRemiender(
        Object.keys(location.state).includes("invoice_reminders")
          ? location.state.invoice_reminders
          : []
      );
      setType(
        Object.keys(location.state.invoice_partner).length !== 0
          ? location.state.invoice_partner.partner_type === "SUPPLIER"
            ? "SUPPLIER"
            : location.state.invoice_partner.partner_type === "LEAD"
            ? "LEAD"
            : "CUSTOMER"
          : "CUSTOMER"
      );
      setPricelist(
        Object.keys(location.state.invoice_partner).length !== 0 &&
          Object.keys(location.state.invoice_partner).includes(
            "partner_price_list"
          )
          ? Object.keys(location.state.invoice_partner.partner_price_list)
              .length !== 0
            ? location.state.invoice_partner.partner_type === "CUSTOMER" ||
              location.state.invoice_partner.partner_type === "LEAD"
              ? location.state.invoice_partner.partner_price_list
                  .price_list_multiplier
              : 1
            : 1
          : 1
      );
      setTeamMember(
        location.state && location.state.invoice_assignee
          ? location.state.invoice_assignee
          : {}
      );
      console.log("ASd");
      if (
        (props.type === "Booking" || props.type === "Request") &&
        location.state.invoice_visits &&
        location.state.invoice_visits.length !== 0
      ) {
        startTime = new Date(
          location.state.invoice_visits[0].appointment_start_time
        );
        endTime = new Date(
          location.state.invoice_visits[0].appointment_end_time
        );
        startDate = startTime.toLocaleDateString("sv");
        endDate = endTime.toLocaleDateString("sv");
        // console.log(isOpen);
        // startTime = startTime.toLocaleTimeString("en-US", {
        //   hour: "numeric",
        //   minute: "numeric",
        //   hour12: false,
        // });
        // endTime = endTime.toLocaleTimeString("en-US", {
        //   hour: "numeric",
        //   minute: "numeric",
        //   hour12: false,
        // });
        let start = startTime.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        });
        if (start.startsWith("24")) {
          startTime = "00" + start.slice(2);
        } else {
          startTime = start;
        }
        let end = endTime.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: false,
        });
        if (end.startsWith("24")) {
          endTime = "00" + end.slice(2);
        } else {
          endTime = end;
        }
        console.log(startTime, endTime, startDate, endDate);
      }
      setLoading(false);
    } else {
      getRequiredData();
    }
    if (bookingPermission.includes("ALL")) {
      getTeamMemberList();
    }
  }, []);

  useEffect(() => {
    // setData(data);
    console.log("second useeffect");
    if (data && Object.keys(data).length !== 0) {
      setSubtotal(0);

      // if (
      //   Object.keys(data.invoice_partner).length !== 0 &&
      //   data.invoice_order.length !== 0
      // ) {
      //   setDisablesave(false);
      // } else {
      //   setDisablesave(true);
      // }
      if (Object.keys(data.invoice_partner).length !== 0) {
        if (props.type !== "Booking" && props.type !== "Request") {
          if (data.invoice_order.length !== 0) {
            setHasError(false);
          } else {
            setHasError(true);
          }
        } else {
          setHasError(false);
        }
      } else {
        setHasError(true);
      }
      if (Object.keys(data.invoice_partner).length !== 0) {
        if (data.invoice_partner.partner_type === "SUPPLIER") {
          setType("SUPPLIER");
        } else {
          setType("CUSTOMER");
        }
      }
      Object.keys(data.invoice_partner).length !== 0 &&
      Object.keys(data.invoice_partner).includes("partner_price_list")
        ? Object.keys(data.invoice_partner.partner_price_list).length !== 0
          ? data.invoice_partner.partner_type === "CUSTOMER" ||
            data.invoice_partner.partner_type === "LEAD"
            ? setPricelist(
                Number(
                  data.invoice_partner.partner_price_list.price_list_multiplier
                )
              )
            : setPricelist(1)
          : setPricelist(1)
        : setPricelist(1);
      totalamount(data);
      console.log(subtotal);
    }
  }, [data, discount, tax, recieved]);
  useEffect(() => {
    setGrandtotal(
      // subtotal - subtotal * discount + (subtotal - subtotal * discount) * tax
      Number(subtotal) -
        Number(
          discounttype === "percentage" ? subtotal * (discount / 100) : discount
        ) +
        Number(
          taxtype === "percentage"
            ? discounttype === "percentage"
              ? Number((subtotal - subtotal * (discount / 100)) * (tax / 100))
              : Number((subtotal - discount) * (tax / 100))
            : Number(tax)
        )
    );
  }, [subtotal, discount, tax, discounttype, taxtype]);

  useEffect(() => {
    setImagename("");
    setupload("");
    if (imageupload) {
      console.log(imageupload);
      if (imageupload.size > 5242880) {
        setImageerror("Select file size upto 5 Mb");
        file.current.value = "";

        return;
      } else {
        setImageerror("");
        uploadToCloud();
      }
    } else {
      setImageerror("");
      setupload("");
    }
  }, [imageupload]);
  // const [initial, setInitial] = useState({
  //   invoice_discount_rate: discount,
  //   invoice_notes: data.invoice_notes,
  //   invoice_internal_notes: data.invoice_internal_notes,
  //   invoice_terms_and_conditions: data.invoice_terms_and_conditions,
  //   invoice_currency_code: data.invoice_currency_code,
  //   invoice_title: data.invoice_title,
  //   invoice_description: data.invoice_description,
  //   // invoice_partner_id: data.invoice_partner_id,
  //   // invoice_partner_type: data.invoice_partner_type,
  //   invoice_business_id: data.invoice_business_id,
  //   invoice_tax_type: Object.keys(data).includes("invoice_tax_type")
  //     ? data.invoice_tax_type
  //     : "percentage",
  //   invoice_discount_type: Object.keys(data).includes("invoice_discount_type")
  //     ? data.invoice_discount_type
  //     : "percentage",
  //   invoice_attachments: data.invoice_attachments,
  //   invoice_tax_rate: tax,
  //   invoice_order: data.invoice_order,
  //   invoice_partner: data.invoice_partner,
  //   invoice_reference_number: data.invoice_reference_number,
  //   invoice_due_date: new Date(data.invoice_due_date),
  //   invoice_grand_total: data.invoice_grand_total,
  //   invoice_number: data.invoice_number,
  //   invoice_workflow_type: data.invoice_workflow_type,
  //   invoice_settled_amount: recieved,
  //   invoice_created_at: new Date(data.invoice_created_at),
  //   invoice_id: data.invoice_id,
  //   invoice_reminders: remiender.length !== 0 ? remiender[0].value : 0,
  //   appointment_start_date:
  //     data.invoice_visits && data.invoice_visits.length !== 0 ? startDate : "",
  //   appointment_end_date:
  //     data.invoice_visits && data.invoice_visits.length !== 0 ? endDate : "",
  //   appointment_start_time:
  //     data.invoice_visits && data.invoice_visits.length !== 0 ? startTime : "",
  //   appointment_end_time:
  //     data.invoice_visits && data.invoice_visits.length !== 0 ? endTime : "",
  //   appointment_send_confirmation:
  //     //  data.job_send_confirmation
  //     //   ? data.job_send_confirmation
  //     //   : true,
  //     data && data.job_send_confirmation
  //       ? data.job_send_confirmation
  //         ? true
  //         : false
  //       : true,
  //   appointment_reminders:
  //     // data.invoice_visits &&
  //     // data.invoice_visits.length !== 0 &&
  //     // Object.keys(data.invoice_visits[0]).includes("appointment_reminders") &&
  //     // data.invoice_visits[0].appointment_reminders.length !== 0
  //     //   ? data.invoice_visits[0].appointment_reminders
  //     //   : true,
  //     data?.invoice_visits && data.invoice_visits.length !== 0
  //       ? data.invoice_visits[0].hasOwnProperty("appointment_reminders") &&
  //         data.invoice_visits[0].appointment_reminders.length !== 0
  //         ? true
  //         : false
  //       : true,

  //   // tax: tax,
  // });
  // useEffect(() => {
  //   if (Object.keys(data).length != 0) {
  //     setInitial({
  //       invoice_discount_rate: discount,
  //       invoice_notes: data.invoice_notes,
  //       invoice_internal_notes: data.invoice_internal_notes,
  //       invoice_terms_and_conditions: data.invoice_terms_and_conditions,
  //       invoice_currency_code: data.invoice_currency_code,
  //       invoice_title: data.invoice_title,
  //       invoice_description: data.invoice_description,
  //       // invoice_partner_id: data.invoice_partner_id,
  //       // invoice_partner_type: data.invoice_partner_type,
  //       invoice_business_id: data.invoice_business_id,
  //       invoice_tax_type: Object.keys(data).includes("invoice_tax_type")
  //         ? data.invoice_tax_type
  //         : "percentage",
  //       invoice_discount_type: Object.keys(data).includes(
  //         "invoice_discount_type"
  //       )
  //         ? data.invoice_discount_type
  //         : "percentage",
  //       invoice_attachments: data.invoice_attachments,
  //       invoice_tax_rate: tax,
  //       invoice_order: data.invoice_order,
  //       invoice_partner: data.invoice_partner,
  //       invoice_reference_number: data.invoice_reference_number,
  //       invoice_due_date: new Date(data.invoice_due_date),
  //       invoice_grand_total: data.invoice_grand_total,
  //       invoice_number: data.invoice_number,
  //       invoice_workflow_type: data.invoice_workflow_type,
  //       invoice_settled_amount: recieved,
  //       invoice_created_at: new Date(data.invoice_created_at),
  //       invoice_id: data.invoice_id,
  //       invoice_reminders: remiender.length !== 0 ? remiender[0].value : 0,
  //       appointment_start_date:
  //         data.invoice_visits && data.invoice_visits.length !== 0
  //           ? startDate
  //           : "",
  //       appointment_end_date:
  //         data.invoice_visits && data.invoice_visits.length !== 0
  //           ? endDate
  //           : "",
  //       appointment_start_time:
  //         data.invoice_visits && data.invoice_visits.length !== 0
  //           ? startTime
  //           : "",
  //       appointment_end_time:
  //         data.invoice_visits && data.invoice_visits.length !== 0
  //           ? endTime
  //           : "",
  //       appointment_send_confirmation:
  //         //  data.job_send_confirmation
  //         //   ? data.job_send_confirmation
  //         //   : true,
  //         data && data.job_send_confirmation
  //           ? data.job_send_confirmation
  //             ? true
  //             : false
  //           : true,
  //       appointment_reminders:
  //         // data.invoice_visits &&
  //         // data.invoice_visits.length !== 0 &&
  //         // Object.keys(data.invoice_visits[0]).includes("appointment_reminders") &&
  //         // data.invoice_visits[0].appointment_reminders.length !== 0
  //         //   ? data.invoice_visits[0].appointment_reminders
  //         //   : true,
  //         data?.invoice_visits && data.invoice_visits.length !== 0
  //           ? data.invoice_visits[0].hasOwnProperty("appointment_reminders") &&
  //             data.invoice_visits[0].appointment_reminders.length !== 0
  //             ? true
  //             : false
  //           : true,

  //       // tax: tax,
  //     });
  //   }
  // }, [data]);
  let initial = {
    invoice_discount_rate: discount,
    invoice_notes: data.invoice_notes,
    invoice_internal_notes: data.invoice_internal_notes,
    invoice_terms_and_conditions: data.invoice_terms_and_conditions,
    invoice_currency_code: data.invoice_currency_code,
    invoice_title: data.invoice_title,
    invoice_description: data.invoice_description,
    // invoice_partner_id: data.invoice_partner_id,
    // invoice_partner_type: data.invoice_partner_type,
    invoice_business_id: data.invoice_business_id,
    invoice_tax_type: Object.keys(data).includes("invoice_tax_type")
      ? data.invoice_tax_type
      : "percentage",
    invoice_discount_type: Object.keys(data).includes("invoice_discount_type")
      ? data.invoice_discount_type
      : "percentage",
    invoice_attachments: data.invoice_attachments,
    invoice_tax_rate: tax,
    invoice_order: data.invoice_order,
    invoice_partner: data.invoice_partner,
    invoice_reference_number: data.invoice_reference_number,
    invoice_due_date: data.invoice_due_date
      ? new Date(data.invoice_due_date)
      : "",
    invoice_grand_total: data.invoice_grand_total,
    invoice_number: data.invoice_number,
    invoice_workflow_type: data.invoice_workflow_type,
    invoice_settled_amount: recieved,
    invoice_created_at: data.invoice_due_date
      ? new Date(data.invoice_created_at)
      : "",
    invoice_id: data.invoice_id,
    invoice_reminders: remiender.length !== 0 ? remiender[0].value : 0,
    appointment_start_date:
      data.invoice_visits && data.invoice_visits.length !== 0 ? startDate : "",
    appointment_end_date:
      data.invoice_visits && data.invoice_visits.length !== 0 ? endDate : "",
    appointment_start_time:
      data.invoice_visits && data.invoice_visits.length !== 0 ? startTime : "",
    appointment_end_time:
      data.invoice_visits && data.invoice_visits.length !== 0 ? endTime : "",
    appointment_send_confirmation:
      //  data.job_send_confirmation
      //   ? data.job_send_confirmation
      //   : true,
      data && data.job_send_confirmation
        ? data.job_send_confirmation
          ? true
          : false
        : true,
    appointment_reminders:
      // data.invoice_visits &&
      // data.invoice_visits.length !== 0 &&
      // Object.keys(data.invoice_visits[0]).includes("appointment_reminders") &&
      // data.invoice_visits[0].appointment_reminders.length !== 0
      //   ? data.invoice_visits[0].appointment_reminders
      //   : true,
      data?.invoice_visits && data.invoice_visits.length !== 0
        ? data.invoice_visits[0].hasOwnProperty("appointment_reminders") &&
          data.invoice_visits[0].appointment_reminders.length !== 0
          ? true
          : false
        : true,

    // tax: tax,
  };
  console.log(data, initial);
  console.log("666666");
  const inputRef = useRef();
  const inputRef2 = useRef();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB2NVHakbiwVQsxEMJw0LnhwN6C6vuzEZE",
    libraries: ["places"],
  });

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      let country = place.address_components.filter((elem) =>
        elem.types.includes("country")
      );
      let state = place.address_components.filter((elem) =>
        elem.types.includes("administrative_area_level_1")
      );
      let city = place.address_components.filter((elem) =>
        elem.types.includes("locality")
      );
      let street = place.address_components.filter(
        (elem) =>
          elem.types.includes("street_number") ||
          elem.types.includes("route") ||
          elem.types.includes("sublocality")
      );
      let zip = place.address_components.filter((elem) =>
        elem.types.includes("postal_code")
      );
      // values.invoice_partner.partner_shipping_address.street =
      //   street.length !== 0
      //     ? street.map((elem) => elem.long_name).join(" ")
      //     : "";
      // values.invoice_partner.partner_shipping_address.city =
      //   city.length !== 0 ? city.map((elem) => elem.long_name).join(" ") : "";
      // values.invoice_partner.partner_shipping_address.state =
      //   state.length !== 0 ? state.map((elem) => elem.long_name).join(" ") : "";
      // values.invoice_partner.partner_shipping_address.zip_code =
      //   zip_code.length !== 0
      //     ? zip.map((elem) => elem.long_name).join(" ")
      //     : "";
      // values.invoice_partner.partner_shipping_address.country =
      //   country.length !== 0
      //     ? country.map((elem) => elem.long_name).join(" ")
      //     : "";
      setData((old) => {
        return {
          ...old,
          invoice_partner: {
            ...old.invoice_partner,
            partner_shipping_address: {
              ...old.invoice_partner.partner_shipping_address,
              street:
                street.length !== 0
                  ? street.map((elem) => elem.long_name).join(" ")
                  : "",
              country: country.length !== 0 ? country[0].long_name : "",
              state: state.length !== 0 ? state[0].long_name : "",
              city: city.length !== 0 ? city[0].long_name : "",
              zip_code: zip.length !== 0 ? zip[0].long_name : "",
            },
          },
        };
      });
    }
  };
  const handlePlaceChanged2 = () => {
    const [place] = inputRef2.current.getPlaces();
    if (place) {
      let country = place.address_components.filter((elem) =>
        elem.types.includes("country")
      );
      let state = place.address_components.filter((elem) =>
        elem.types.includes("administrative_area_level_1")
      );
      let city = place.address_components.filter((elem) =>
        elem.types.includes("locality")
      );
      let street = place.address_components.filter(
        (elem) =>
          elem.types.includes("street_number") ||
          elem.types.includes("route") ||
          elem.types.includes("sublocality")
      );
      let zip = place.address_components.filter((elem) =>
        elem.types.includes("postal_code")
      );
      setData((old) => {
        return {
          ...old,
          invoice_partner: {
            ...old.invoice_partner,
            partner_billing_address: {
              ...old.invoice_partner.partner_billing_address,
              street:
                street.length !== 0
                  ? street.map((elem) => elem.long_name).join(" ")
                  : "",
              country: country.length !== 0 ? country[0].long_name : "",
              state: state.length !== 0 ? state[0].long_name : "",
              city: city.length !== 0 ? city[0].long_name : "",
              zip_code: zip.length !== 0 ? zip[0].long_name : "",
            },
          },
        };
      });
    }
  };
  console.log(props.type, addappointmentlater);
  return (
    <>
      {!loading ? (
        <>
          <Card
            className="mb-2 border rounded-0"
            style={{
              marginRight: "2rem",
              marginLeft: "2rem",
              width: 850,
              maxWidth: "100%",
            }}
          >
            <Card.Header>
              <Formik
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initial}
                validationSchema={
                  props.type !== "Booking" && props.type !== "Request"
                    ? Yup.object().shape({
                        // invoice_number: Yup.number()
                        //   .required(`${props.type} Number is required`)
                        //   .min(1, "Must be greater than 0")
                        //   .test(
                        //     "is-numeric",
                        //     "Invoice Number must only contain numbers from 0 to 9",
                        //     (value) => /^\d+$/.test(value)
                        //   ),
                        invoice_number: Yup.number()
                          .required(`${props.type} Number is required`)
                          .positive("Must be greater than 0")
                          .integer("Must be an integer")
                          .test(
                            "valid-format",
                            `${props.type} Number must be at most 10 digits in Number format`,
                            (value) => /^[-+]?\d*\.?\d*(e\d+)?$/.test(value)
                          )
                          .test(
                            "max-length",
                            `${props.type} Number must be at most 10 digits in Number format`,
                            (value) =>
                              (value + "").replace(/[e.+-]/g, "").length <= 10
                          ),
                        invoice_created_at: Yup.date().required(
                          `${props.type}  Date is required`
                        ),
                        invoice_due_date: Yup.date()
                          .min(
                            Yup.ref("invoice_created_at"),
                            `${
                              props.type === "Estimate" ? "Validity" : "Due"
                            } date can't be before ${props.type} date`
                          )
                          .required(
                            `${
                              props.type === "Estimate" ? "Validity" : "Due"
                            } Date is required`
                          ),
                      })
                    : addappointmentlater
                    ? Yup.object().shape({
                        // invoice_number: Yup.number()
                        //   .required(`${props.type} Number is required`)
                        //   .min(1, "Must be greater than 0")
                        //   .test(
                        //     "is-numeric",
                        //     "Invoice Number must only contain numbers from 0 to 9",
                        //     (value) => /^\d+$/.test(value)
                        //   ),
                        invoice_number: Yup.number()
                          .required(`${props.type} Number is required`)
                          .positive("Must be greater than 0")
                          .integer("Must be an integer")
                          .test(
                            "valid-format",
                            `${props.type} Number must be at most 10 digits in Number format`,
                            (value) => /^[-+]?\d*\.?\d*(e\d+)?$/.test(value)
                          )
                          .test(
                            "max-length",
                            `${props.type} Number must be at most 10 digits in Number format`,
                            (value) =>
                              (value + "").replace(/[e.+-]/g, "").length <= 10
                          ),
                        invoice_title: Yup.string().required(
                          `${props.type} Name is required`
                        ),
                      })
                    : Yup.object().shape({
                        // invoice_number: Yup.number()
                        //   .required(`${props.type} Number is required`)
                        //   .min(1, "Must be greater than 0")
                        //   .test(
                        //     "is-numeric",
                        //     "Invoice Number must only contain numbers from 0 to 9",
                        //     (value) => /^\d+$/.test(value)
                        //   ),
                        invoice_number: Yup.number()
                          .required(`${props.type} Number is required`)
                          .positive("Must be greater than 0")
                          .integer("Must be an integer")
                          .test(
                            "valid-format",
                            `${props.type} Number must be at most 10 digits in Number format`,
                            (value) => /^[-+]?\d*\.?\d*(e\d+)?$/.test(value)
                          )
                          .test(
                            "max-length",
                            `${props.type} Number must be at most 10 digits in Number format`,
                            (value) =>
                              (value + "").replace(/[e.+-]/g, "").length <= 10
                          ),
                        invoice_title: Yup.string().required(
                          `${props.type} Name is required`
                        ),
                        appointment_start_date: Yup.date().required(
                          "Start Date is required"
                        ),
                        appointment_end_date: Yup.date()
                          .min(
                            Yup.ref("appointment_start_date"),
                            "End date can't be before start date"
                          )
                          .required("End Date is required"),
                        appointment_start_time: Yup.string().required(
                          "Start Time is required"
                        ),
                        appointment_end_time: Yup.string()
                          .required("End Time is required")
                          .test(
                            "is-start-date-time-valid",
                            "Start date and time must be before end date and time",
                            function (value) {
                              const {
                                appointment_start_date,
                                appointment_start_time,
                                appointment_end_date,
                              } = this.parent;
                              console.log(
                                appointment_start_date,
                                appointment_start_time,
                                appointment_end_date
                                // appointment_end_time
                              );

                              if (
                                appointment_start_date &&
                                !isNaN(appointment_start_date) &&
                                appointment_end_date &&
                                !isNaN(appointment_end_date) &&
                                appointment_start_time &&
                                value
                              ) {
                                const [shours, sminutes] =
                                  appointment_start_time.split(":").map(Number);

                                appointment_start_date.setHours(
                                  shours,
                                  sminutes,
                                  0,
                                  0
                                );

                                const stimeInMilliseconds =
                                  appointment_start_date.getTime();

                                console.log(stimeInMilliseconds);

                                const [ehours, eminutes] = value
                                  .split(":")
                                  .map(Number);

                                appointment_end_date.setHours(
                                  ehours,
                                  eminutes,
                                  0,
                                  0
                                );

                                const etimeInMilliseconds =
                                  appointment_end_date.getTime();

                                console.log(etimeInMilliseconds, value);

                                console.log(
                                  stimeInMilliseconds < etimeInMilliseconds
                                );
                                return (
                                  stimeInMilliseconds < etimeInMilliseconds
                                );
                              } else {
                                console.log("not passing");
                                return false;
                              }
                            }
                          ),
                      })
                }
                onSubmit={async (values) => {
                  console.log("submitting");
                  if (hasError) {
                    setValidationError(true);
                    return;
                  }
                  setDisablesave(true);
                  // console.log(data);
                  // console.log({
                  //   ...values,
                  //   invoice_partner_id: values.invoice_partner.partner_id,
                  //   invoice_partner_type: values.invoice_partner.partner_type,
                  //   invoice_discount_rate: discount,
                  //   invoice_grand_total: grandtotal,
                  //   invoice_tax_rate: tax,
                  //   invoice_order: sendorders,
                  //   invoice_reminders: data.invoice_reminders,
                  //   invoice_due_date: new Date(
                  //     values.invoice_due_date
                  //   ).toISOString(),
                  //   invoice_created_at: new Date(
                  //     values.invoice_created_at
                  //   ).toISOString(),
                  // });

                  let final = [];
                  let {
                    appointment_start_time,
                    appointment_start_date,
                    appointment_end_date,
                    appointment_end_time,
                    appointment_reminders,
                    appointment_zone_offset,
                    appointment_send_confirmation,
                    invoice_visits,
                    invoice_title,
                    invoice_description,
                    invoice_reminders,
                    ...rest
                  } = values;
                  console.log(values);
                  if (props.type === "Booking" || props.type === "Request") {
                    console.log(values);
                    var Start_Str = values["appointment_start_date"].concat(
                      " ",
                      values["appointment_start_time"]
                    );
                    var End_Str = values["appointment_end_date"].concat(
                      " ",
                      values["appointment_end_time"]
                    );

                    let final_old = {
                      ...rest,
                      invoice_number: formatNumber(values.invoice_number),
                      invoice_partner_id: values.invoice_partner.partner_id,
                      invoice_partner_type: values.invoice_partner.partner_type,
                      invoice_discount_rate:
                        discounttype === "percentage"
                          ? discount / 100
                          : discount,
                      invoice_tax_rate:
                        taxtype === "percentage" ? tax / 100 : tax,
                      invoice_tax_type: taxtype,
                      invoice_discount_type: discounttype,
                      invoice_order: data.invoice_order.filter(
                        (elem) => elem.item_quantity > 0
                      ),
                      invoice_attachments:
                        imageData.length !== 0 ? imageData : [],
                      invoice_currency_code:
                        data && data.invoice_currency_code
                          ? data.invoice_currency_code
                          : usercurrency,
                      invoice_zone_offset: -(
                        new Date(
                          values.invoice_created_at
                        ).getTimezoneOffset() * 60
                      ),
                      invoice_visits: addappointmentlater
                        ? []
                        : [
                            {
                              appointment_start_time: new Date(
                                Start_Str
                              ).getTime(),
                              appointment_end_time: new Date(End_Str).getTime(),
                              appointment_reminders:
                                values.appointment_reminders &&
                                values.appointment_reminders.length !== 0
                                  ? [{ value: 1 }]
                                  : [],
                              appointment_zone_offset: -(
                                new Date(
                                  values.appointment_start_date
                                ).getTimezoneOffset() * 60
                              ),
                              // appointment_send_confirmation:
                              //   values.appointment_send_confirmation,
                            },
                          ],
                      // invoice_business_id:
                      invoice_grand_total: Number(grandtotal),
                      // invoice_parent: invoice_parent,
                      invoice_due_date: new Date(
                        values.invoice_due_date
                      ).getTime(),
                      invoice_created_at: new Date(
                        values.invoice_created_at
                      ).getTime(),
                      invoice_workflow_type:
                        props.type === "Booking"
                          ? "ORDER"
                          : props.type.toUpperCase(),
                      job_send_confirmation:
                        values.appointment_send_confirmation,
                      // invoice_reminders: remiender
                      //   ? [{ value: Number(remiender) }]
                      //   : [],
                      // invoice_reminders: data.invoice_reminders,
                      invoice_title: values.invoice_title,
                      invoice_description: values.invoice_description
                        ? values.invoice_description
                        : "",
                    };
                    if (props.type === "Request") {
                      // final.job_send_confirmation =
                      //   values.appointment_send_confirmation;
                      // final.invoice_due_date = new Date(
                      //   values.invoice_due_date
                      // ).getTime();
                      const {
                        job_send_confirmation,
                        invoice_due_date,
                        invoice_notes,
                        invoice_internal_notes,
                        invoice_terms_and_conditions,
                        ...final_new
                      } = final_old;
                      final = final_new;
                    } else {
                      final = final_old;
                    }
                    // if (remiender) {
                    //   final.invoice_reminders = [{ value: Number(remiender) }];
                    // }
                  } else {
                    // console.log(location.state);

                    final = {
                      ...rest,
                      invoice_number: formatNumber(values.invoice_number),
                      invoice_partner_id: values.invoice_partner.partner_id,
                      invoice_partner_type: values.invoice_partner.partner_type,
                      invoice_discount_rate:
                        discounttype === "percentage"
                          ? discount / 100
                          : discount,
                      invoice_tax_rate:
                        taxtype === "percentage" ? tax / 100 : tax,
                      invoice_tax_type: taxtype,
                      invoice_discount_type: discounttype,
                      invoice_order: data.invoice_order.filter(
                        (elem) => elem.item_quantity > 0
                      ),
                      invoice_currency_code:
                        data && data.invoice_currency_code
                          ? data.invoice_currency_code
                          : usercurrency,
                      invoice_zone_offset: -(
                        new Date(
                          values.invoice_created_at
                        ).getTimezoneOffset() * 60
                      ),

                      // invoice_attachment_id: imagename
                      //   ? imagename
                      //   : values.invoice_attachment_id,
                      invoice_attachments:
                        imageData.length !== 0 ? imageData : [],
                      // invoice_currency_name: "Indian Rupee",
                      invoice_grand_total: Number(grandtotal),
                      // invoice_parent: invoice_parent,
                      invoice_due_date: new Date(
                        values.invoice_due_date
                      ).getTime(),
                      invoice_created_at: new Date(
                        values.invoice_created_at
                      ).getTime(),
                      invoice_workflow_type:
                        props.type === "Booking"
                          ? "ORDER"
                          : props.type.toUpperCase(),
                      // invoice_reminders: remiender
                      //   ? [{ value: Number(remiender) }]
                      //   : [],
                      // invoice_reminders: data.invoice_reminders,
                    };
                  }
                  mixpanel.track(`Save (Edit ${props.type} Web)`, {
                    ...final,
                  });
                  console.log(final);
                  var user = firebase.auth().currentUser;

                  if (user) {
                    let tokenss = await user.getIdToken();
                    if (isTeamAccount) {
                      final.team = true;
                    } else {
                      delete final.team;
                    }
                    if (
                      teamMember &&
                      Object.keys(teamMember).length !== 0 &&
                      props.type === "Booking"
                    ) {
                      final.invoice_assignee_uid = teamMember.team_member_uid;
                      final.invoice_assignee_ref_id =
                        teamMember.team_member_reference_id;
                      final.invoice_assignee = teamMember;
                    }
                    fetch("https://bestmate.us/api/invoices/update", {
                      method: "POST",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + tokenss,
                      },
                      body: JSON.stringify(final),
                    })
                      .then(async (response) => {
                        if (response.status >= 200 && response.status <= 300) {
                          const res = await response.json();
                          console.log(res);

                          // getData();
                          // navigate("/pricelist");
                          // if (action === "save") {
                          // navigate(`/invoice/${small}s/`);
                          console.log(action);
                          navigate(
                            `/${props.type.toLowerCase()}s/` + res.invoice_id,
                            {
                              state: { row: {}, items: props.items, action },
                            }
                          );
                          // } else if (action === "send") {
                          //   // setData();
                          //   setID(res.invoice_id);
                          //   setsendmailmodal(true);
                          // }
                          // navigate("/invoices/" + res.invoice_id, {
                          //   state: { row: res, items: props.items },
                          // });
                          notyf.open({
                            type: isOpen ? "home" : "full",
                            message: `${props.type} updated successfully`,
                            ripple: "true",
                            dismissible: "true",
                          });
                          setDisablesave(false);
                        } else {
                          notyf.open({
                            type: isOpen ? "homewarning" : "fullwarning",
                            message: `Something went wrong.`,
                            ripple: "true",
                            dismissible: "true",
                          });
                          setDisablesave(false);
                          // mixpanel.track(
                          //   `Fetch Error (Edit ${props.invoicetype} Web)`,
                          //   {
                          //     error: "invoice update api",
                          //   }
                          // );
                          logError({
                            error: "fetch error",
                            api: "invoice update api",
                            component: `Edit ${props.invoicetype} Web`,
                          });
                        }
                      })
                      .catch((error) => {
                        setDisablesave(false);
                        console.error(error);
                        // mixpanel.track(
                        //   `Error (Edit ${props.invoicetype} Web)`,
                        //   {
                        //     error: error,
                        //   }
                        // );
                        logError({
                          error: error,
                          component: `Error (Edit ${props.invoicetype} Web)`,
                        });
                      });
                  }
                  // console.log({
                  //   ...values,
                  //   invoice_partner_id: values.invoice_partner.partner_id,
                  //   invoice_partner_type: values.invoice_partner.partner_type,
                  //   invoice_discount_rate: discount,
                  //   invoice_grand_total: grandtotal,
                  //   invoice_tax_rate: tax,
                  //   invoice_order: sendorders,
                  //   invoice_reminders: data.invoice_reminders,
                  //   invoice_due_date: new Date(
                  //     values.invoice_due_date
                  //   ).toISOString(),
                  //   invoice_created_at: new Date(
                  //     values.invoice_created_at
                  //   ).toISOString(),
                  // });
                  // setSubmitting(false);
                }}
                // onReset={() => console.log("reset")}
              >
                {({
                  handleSubmit,
                  values,
                  errors,
                  setErrors,
                  handleChange,
                  handleReset,
                  validateForm,
                  setFieldValue,
                  // setSubmitting,
                  dirty,
                }) => {
                  // useEffect(() => {
                  //   // This code will run whenever errors change
                  //   console.log("Errors changed:", errors);
                  // }, [errors]);
                  console.log(values);
                  console.log(errors);

                  return (
                    <Form
                      noValidate
                      onSubmit={async (e) => {
                        e.preventDefault();
                        const obj = await validateForm();
                        console.log(obj);
                        if (Object.keys(obj).length !== 0) {
                          //   setErrors(obj);
                          setValidationError(true);
                        } else if (
                          props.type !== "Booking" &&
                          props.type !== "Request" &&
                          data.invoice_order.reduce(
                            (sum, obj) =>
                              sum + obj.item_quantity
                                ? Number(obj.item_quantity)
                                : 0,
                            0
                          ) === 0
                        ) {
                          setValidationError(true);
                        }
                        //  else {
                        handleSubmit();
                        // }
                        // setSubmitting(false);
                      }}
                    >
                      <div
                        style={{
                          margin: 10,
                          marginTop: 30,
                          marginBottom: 30,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h4>
                            {bdata && bdata.business_name
                              ? truncateTextByWordCount(bdata.business_name, 50)
                              : truncateTextByWordCount(
                                  bdata.business_user_name,
                                  50
                                )}
                          </h4>
                          {/* <p
                        style={{
                          marginBottom: 5,
                          fontWeight: "bold",
                          marginTop: 15,
                        }}
                      >
                        Address :{" "}
                      </p> */}
                          <div
                            style={{
                              wordWrap: "break-word",
                              maxWidth: 400,
                            }}
                          >
                            {bdata &&
                            bdata.business_address &&
                            bdata.business_address.street ? (
                              <p style={{ margin: 0, display: "inline" }}>
                                {truncateTextByWordCount(
                                  bdata.business_address.street,
                                  100
                                )}
                              </p>
                            ) : (
                              ""
                            )}

                            {bdata &&
                            bdata.business_address &&
                            bdata.business_address.street &&
                            bdata.business_address.city ? (
                              <p
                                style={{ margin: 0, display: "inline" }}
                              >{`, `}</p>
                            ) : (
                              ""
                            )}

                            {bdata &&
                            bdata.business_address &&
                            bdata.business_address.city ? (
                              <p style={{ margin: 0, display: "inline" }}>
                                {truncateTextByWordCount(
                                  bdata.business_address.city,
                                  50
                                )}
                              </p>
                            ) : (
                              ""
                            )}
                            {(bdata &&
                              bdata.business_address &&
                              bdata.business_address.street &&
                              bdata.business_address.state) ||
                            (bdata &&
                              bdata.business_address &&
                              bdata.business_address.city &&
                              bdata.business_address.state) ? (
                              <p
                                style={{ margin: 0, display: "inline" }}
                              >{`, `}</p>
                            ) : (
                              ""
                            )}
                            {bdata &&
                            bdata.business_address &&
                            bdata.business_address.state ? (
                              <p style={{ margin: 0, display: "inline" }}>
                                {truncateTextByWordCount(
                                  bdata.business_address.state,
                                  50
                                )}
                              </p>
                            ) : (
                              ""
                            )}
                            <div>
                              {bdata &&
                              bdata.business_address &&
                              bdata.business_address.country ? (
                                <p style={{ margin: 0, display: "inline" }}>
                                  {truncateTextByWordCount(
                                    bdata.business_address.country,
                                    50
                                  )}
                                </p>
                              ) : (
                                ""
                              )}
                              {(bdata &&
                                bdata.business_address &&
                                bdata.business_address.country &&
                                bdata.business_address.zip_code) ||
                              (bdata &&
                                bdata.business_address &&
                                bdata.business_address.country &&
                                bdata.business_address.zip_code) ? (
                                <p
                                  style={{ margin: 0, display: "inline" }}
                                >{` - `}</p>
                              ) : (
                                ""
                              )}
                              {bdata &&
                              bdata.business_address &&
                              bdata.business_address.zip_code ? (
                                <p style={{ margin: 0, display: "inline" }}>
                                  {truncateTextByWordCount(
                                    bdata.business_address.zip_code,
                                    50
                                  )}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: 400,
                            }}
                          >
                            <text>
                              {bdata && bdata.business_email
                                ? truncateTextByWordCount(
                                    bdata.business_email,
                                    50
                                  )
                                : ""}
                            </text>
                            <text>
                              {bdata && bdata.business_phone
                                ? truncateTextByWordCount(
                                    `Contact: ${bdata.business_phone}`,
                                    30
                                  )
                                : ""}
                            </text>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: 50,
                          }}
                        >
                          <h1
                            style={{
                              fontWeight: "bolder",
                              fontSize: 30,
                              color: "#adadad",
                            }}
                          >{`${props.type.toUpperCase()}`}</h1>
                        </div>
                      </div>
                      <hr />
                      <div className="m-3 p-0 h4 d-flex align-items-center">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: 1,
                            marginBottom: "1rem",
                          }}
                        >
                          <div style={{ flex: 1, marginRight: "2rem" }}>
                            <h6 className="mb-2">{`${props.type} No`}</h6>

                            <Form.Control
                              type="number"
                              name="invoice_number"
                              isInvalid={errors.invoice_number}
                              value={values.invoice_number}
                              onKeyDown={handleKeyDown}
                              onChange={(e) => {
                                handleChange(e);
                                data.invoice_number = Number(e.target.value);
                              }}
                              style={{ borderColor: "#e8e8e8" }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.invoice_number}
                            </Form.Control.Feedback>
                          </div>

                          {props.type === "Booking" ||
                          props.type === "Request" ? (
                            <div style={{ flexBasis: "60%" }}>
                              <h6 className="mb-2">{`${props.type} Name`}</h6>

                              <Form.Control
                                type="text"
                                // style={{ width: "100px" }}
                                name="invoice_title"
                                onKeyDown={handleKeyDown}
                                isInvalid={errors.invoice_title}
                                // placeholder="Input"
                                value={values.invoice_title}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  handleChange(e);
                                  data.invoice_title = e.target.value;
                                }}
                                style={{ borderColor: "#e8e8e8" }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.invoice_title}
                              </Form.Control.Feedback>
                            </div>
                          ) : (
                            <>
                              <div style={{ flex: 1, marginRight: "2rem" }}>
                                <h6 className="mb-2">
                                  {" "}
                                  {`${props.type} Date`}
                                </h6>
                                <div style={{ position: "relative" }}>
                                  <Form.Control
                                    type="date"
                                    className="calendar-input"
                                    name="invoice_created_at"
                                    placeholder="text"
                                    onKeyDown={handleKeyDown}
                                    isInvalid={errors.invoice_created_at}
                                    value={new Date(
                                      values.invoice_created_at
                                    ).toLocaleDateString("sv")}
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      handleChange(e);
                                      // data.invoice_created_at = new Date(
                                      //   e.target.value
                                      // );
                                    }}
                                    style={{ borderColor: "#e8e8e8" }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.invoice_created_at}
                                  </Form.Control.Feedback>
                                </div>
                              </div>
                              <div style={{ flex: 1 }}>
                                <h6 className="mb-2">
                                  {props.type === "Estimate"
                                    ? "Validity Date"
                                    : "Due Date"}
                                </h6>
                                <div style={{ position: "relative" }}>
                                  <Form.Control
                                    type="date"
                                    onKeyDown={handleKeyDown}
                                    className="calendar-input"
                                    name="invoice_due_date"
                                    placeholder="text"
                                    isInvalid={errors.invoice_due_date}
                                    value={new Date(
                                      values.invoice_due_date
                                    ).toLocaleDateString("sv")}
                                    onChange={(e) => {
                                      handleChange(e);
                                      // data.invoice_due_date = e.target.value;
                                      console.log(e.target.value);
                                    }}
                                    style={{ borderColor: "#e8e8e8" }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.invoice_due_date}
                                  </Form.Control.Feedback>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {/* <Form.Label
                    style={{ width: "120px" }}
                  >{`${props.type} No.:`}</Form.Label>

                  <Form.Control
                    type="number"
                    style={{ width: "70px" }}
                    name="invoice_number"
                    isInvalid={errors.invoice_number}
                    placeholder="Input"
                    value={values.invoice_number}
                    // onChange={handleChange}
                    onChange={(e) => {
                      handleChange(e);
                      data.invoice_number = e.target.value;
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.invoice_number}
                  </Form.Control.Feedback> */}
                      </div>
                      <hr />
                      {props.type === "Booking" || props.type === "Request" ? (
                        <>
                          {" "}
                          <div
                            className="m-3 p-0 h4 d-flex align-items-center"
                            // style={{ backgroundColor: "red" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexGrow: 1,
                                marginBottom: "1rem",
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <h6 className="mb-2">{`${props.type} Description`}</h6>

                                <Form.Control
                                  type="text"
                                  name="invoice_description"
                                  as="textarea"
                                  rows={4}
                                  isInvalid={errors.invoice_description}
                                  value={values.invoice_description}
                                  onChange={(e) => {
                                    handleChange(e);
                                    data.invoice_description = e.target.value;
                                  }}
                                  style={{ borderColor: "#e8e8e8" }}
                                />
                              </div>
                            </div>
                          </div>
                          <hr />
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="m-3 mb-4">
                        <h5 style={{ fontSize: 16, marginBottom: 0 }}>
                          Customer
                        </h5>
                        {Object.keys(values.invoice_partner).length !== 0 ? (
                          <></>
                        ) : (
                          <>
                            <div
                              id="Emptybox"
                              style={{
                                width: "100%",
                                height: 130,
                                // backgroundColor: "pink",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor:
                                  partnerPermission &&
                                  !partnerPermission.includes("LIMITED")
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                              onClick={() => {
                                // navigate("/customer", {
                                //   state: {
                                //     from: "addInvoice",
                                //     data: data,
                                //     type: `${props.type.toLowerCase()}s`,
                                //   },
                                // });
                                if (
                                  partnerPermission &&
                                  !partnerPermission.includes("LIMITED")
                                ) {
                                  mixpanel.track(
                                    `Add Customer (Edit ${props.type} Web)`
                                  );
                                  setsearchtem("");
                                  setCustomerModalloading(true);
                                  fetchPartners();
                                  setCustomerModal(true);
                                }
                              }}
                            >
                              <img
                                src={empty_partner}
                                style={{ height: "50px", marginBottom: 10 }}
                                alt="empty_invoice"
                              ></img>
                              <text
                                style={{}}
                              >{`Add Customer to this ${props.type}`}</text>
                            </div>
                            <div
                              style={{
                                marginTop: "0px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                type="button"
                                // className="m-2"
                                style={{
                                  marginRight: "30px",
                                  backgroundColor: "#EBF1FF",
                                  border: "none",
                                  color: "#3f80ea",
                                  fontWeight: "bold",
                                  padding: "10px 15px",
                                  cursor:
                                    partnerPermission &&
                                    !partnerPermission.includes("LIMITED")
                                      ? "pointer"
                                      : "not-allowed",
                                }}
                                onClick={() => {
                                  // navigate("/customer", {
                                  //   state: {
                                  //     from: "addInvoice",
                                  //     data: data,
                                  //     type: `${props.type.toLowerCase()}s`,
                                  //   },
                                  // });
                                  if (
                                    partnerPermission &&
                                    !partnerPermission.includes("LIMITED")
                                  ) {
                                    mixpanel.track(
                                      `Add Customer (Edit ${props.type} Web)`
                                    );
                                    setsearchtem("");
                                    setCustomerModalloading(true);
                                    fetchPartners();
                                    setCustomerModal(true);
                                  }
                                }}
                              >
                                <Plus size={13} strokeWidth={3} /> Add Customer
                              </button>
                              {/* <button
                          type="button"
                          style={{
                            backgroundColor: "#EBF1FF",
                            border: "none",
                            color: "#3f80ea",
                            fontWeight: "bold",
                            padding: "10px 15px",
                          }}
                          onClick={() => {
                            // navigate("/supplier", {
                            //   state: {
                            //     from: "addInvoice",
                            //     data: data,
                            //     type: `${props.type.toLowerCase()}s`,
                            //   },
                            // });
                            setsearchtem("");
                            setSupplierModalloading(true);
                            fetchPartners();
                            setSupplierModal(true);
                          }}
                        >
                          <Plus size={13} strokeWidth={3} /> Add Supplier
                        </button> */}
                            </div>
                          </>
                        )}

                        {Object.keys(values.invoice_partner).length !== 0 ? (
                          <>
                            <div
                              // className="m-3"

                              style={{
                                // backgroundColor: "pink",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                margin: "20px 0",
                              }}
                            >
                              <h4
                                style={{
                                  fontSize: 15,
                                  color: "#645cff",
                                  margin: 0,
                                }}
                              >
                                {truncateTextByWordCount(
                                  `${values.invoice_partner.partner_first_name} ${values.invoice_partner.partner_last_name}`,
                                  50
                                )}
                              </h4>
                              <button
                                type="button"
                                style={{
                                  // marginRight: "30px",
                                  backgroundColor: "#1ab294",
                                  border: "none",
                                  color: "white",
                                  fontWeight: "bold",
                                  padding: "5px 10px",
                                }}
                                onClick={() => {
                                  mixpanel.track(
                                    `Remove Customer (Edit ${props.invoicetype} Web)`
                                  );
                                  console.log("click");
                                  setData({ ...data, invoice_partner: {} });
                                  values.invoice_partner = {};
                                  setPricelist(1);
                                  setSubtotal(0);
                                  totalamount({ ...data, invoice_partner: {} });
                                  setType("CUSTOMER");
                                }}
                              >
                                Remove
                              </button>
                            </div>

                            <div>
                              <Row>
                                <Col xs={6}>
                                  <h5>Service Address :</h5>

                                  {/* <Form.Control
                                  type="text"
                                  // disabled
                                  // name="subtotal"
                                  placeholder="Street"
                                  value={
                                    values.invoice_partner &&
                                    values.invoice_partner
                                      .partner_shipping_address &&
                                    values.invoice_partner
                                      .partner_shipping_address.street
                                      ? values.invoice_partner
                                          .partner_shipping_address.street
                                      : ""
                                  }
                                  style={{
                                    marginBottom: 5,
                                    borderColor: "#e8e8e8",
                                  }}
                                  onChange={(elem) => {
                                    console.log(elem);
                                    values.invoice_partner.partner_shipping_address.street =
                                      elem.target.value;
                                    setData({
                                      ...data,
                                      invoice_partner: {
                                        ...data.invoice_partner,
                                        partner_shipping_address: {
                                          ...data.invoice_partner
                                            .partner_shipping_address,
                                          street: elem.target.value,
                                        },
                                      },
                                    });
                                  }}
                                /> */}
                                  {isLoaded ? (
                                    <StandaloneSearchBox
                                      onLoad={(ref) => (inputRef.current = ref)}
                                      onPlacesChanged={handlePlaceChanged}
                                    >
                                      <Form.Control
                                        type="text"
                                        onKeyDown={handleKeyDown}
                                        // disabled
                                        // name="subtotal"
                                        placeholder="Street"
                                        value={
                                          values.invoice_partner &&
                                          values.invoice_partner
                                            .partner_shipping_address &&
                                          values.invoice_partner
                                            .partner_shipping_address.street
                                            ? values.invoice_partner
                                                .partner_shipping_address.street
                                            : ""
                                        }
                                        style={{
                                          marginBottom: 5,
                                          borderColor: "#e8e8e8",
                                        }}
                                        onChange={(elem) => {
                                          console.log(elem);
                                          setData({
                                            ...data,
                                            invoice_partner: {
                                              ...data.invoice_partner,
                                              partner_shipping_address: {
                                                ...data.invoice_partner
                                                  .partner_shipping_address,
                                                street: elem.target.value,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </StandaloneSearchBox>
                                  ) : (
                                    <></>
                                  )}
                                  <Row>
                                    <Col
                                      xs={6}
                                      style={{ padding: "0 5px 0 12px" }}
                                    >
                                      <Form.Control
                                        type="text"
                                        onKeyDown={handleKeyDown}
                                        // disabled
                                        // name="subtotal"
                                        placeholder="City"
                                        value={
                                          values.invoice_partner &&
                                          values.invoice_partner
                                            .partner_shipping_address &&
                                          values.invoice_partner
                                            .partner_shipping_address.city
                                            ? values.invoice_partner
                                                .partner_shipping_address.city
                                            : ""
                                        }
                                        style={{
                                          marginBottom: 5,
                                          borderColor: "#e8e8e8",
                                        }}
                                        onChange={(elem) => {
                                          console.log(elem);
                                          values.invoice_partner.partner_shipping_address.city =
                                            elem.target.value;
                                          setData({
                                            ...data,
                                            invoice_partner: {
                                              ...data.invoice_partner,
                                              partner_shipping_address: {
                                                ...data.invoice_partner
                                                  .partner_shipping_address,
                                                city: elem.target.value,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </Col>
                                    <Col
                                      xs={6}
                                      style={{ padding: "0 12px 0 5px" }}
                                    >
                                      <Form.Control
                                        type="text"
                                        onKeyDown={handleKeyDown}
                                        // disabled
                                        // name="subtotal"
                                        placeholder="State"
                                        value={
                                          values.invoice_partner &&
                                          values.invoice_partner
                                            .partner_shipping_address &&
                                          values.invoice_partner
                                            .partner_shipping_address.state
                                            ? values.invoice_partner
                                                .partner_shipping_address.state
                                            : ""
                                        }
                                        style={{
                                          marginBottom: 5,
                                          borderColor: "#e8e8e8",
                                        }}
                                        onChange={(elem) => {
                                          console.log(elem);
                                          values.invoice_partner.partner_shipping_address.state =
                                            elem.target.value;
                                          setData({
                                            ...data,
                                            invoice_partner: {
                                              ...data.invoice_partner,
                                              partner_shipping_address: {
                                                ...data.invoice_partner
                                                  .partner_shipping_address,
                                                state: elem.target.value,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col
                                      xs={6}
                                      style={{ padding: "0 5px 0 12px" }}
                                    >
                                      <Form.Control
                                        type="text"
                                        onKeyDown={handleKeyDown}
                                        // disabled
                                        // name="subtotal"
                                        placeholder="Country"
                                        value={
                                          values.invoice_partner &&
                                          values.invoice_partner
                                            .partner_shipping_address &&
                                          values.invoice_partner
                                            .partner_shipping_address.country
                                            ? values.invoice_partner
                                                .partner_shipping_address
                                                .country
                                            : ""
                                        }
                                        style={{
                                          marginBottom: 5,
                                          borderColor: "#e8e8e8",
                                        }}
                                        // onChange={handleChange}
                                        onChange={(elem) => {
                                          console.log(elem);
                                          values.invoice_partner.partner_shipping_address.country =
                                            elem.target.value;
                                          setData({
                                            ...data,
                                            invoice_partner: {
                                              ...data.invoice_partner,
                                              partner_shipping_address: {
                                                ...data.invoice_partner
                                                  .partner_shipping_address,
                                                country: elem.target.value,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </Col>
                                    <Col
                                      xs={6}
                                      style={{ padding: "0 12px 0 5px" }}
                                    >
                                      <Form.Control
                                        type="text"
                                        onKeyDown={handleKeyDown}
                                        // disabled
                                        // name="subtotal"
                                        placeholder="Zip Code"
                                        value={
                                          values.invoice_partner &&
                                          values.invoice_partner
                                            .partner_shipping_address &&
                                          values.invoice_partner
                                            .partner_shipping_address.zip_code
                                            ? values.invoice_partner
                                                .partner_shipping_address
                                                .zip_code
                                            : ""
                                        }
                                        style={{
                                          marginBottom: 5,
                                          borderColor: "#e8e8e8",
                                        }}
                                        // onChange={handleChange}
                                        onChange={(elem) => {
                                          console.log(elem);
                                          values.invoice_partner.partner_shipping_address.zip_code =
                                            elem.target.value;
                                          setData({
                                            ...data,
                                            invoice_partner: {
                                              ...data.invoice_partner,
                                              partner_shipping_address: {
                                                ...data.invoice_partner
                                                  .partner_shipping_address,
                                                zip_code: elem.target.value,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col xs={6}>
                                  <h5>Billing Address :</h5>
                                  {isLoaded ? (
                                    <StandaloneSearchBox
                                      onLoad={(ref) =>
                                        (inputRef2.current = ref)
                                      }
                                      onPlacesChanged={handlePlaceChanged2}
                                    >
                                      <Form.Control
                                        type="text"
                                        onKeyDown={handleKeyDown}
                                        placeholder="Street"
                                        value={
                                          values.invoice_partner &&
                                          values.invoice_partner
                                            .partner_billing_address &&
                                          values.invoice_partner
                                            .partner_billing_address.street
                                            ? values.invoice_partner
                                                .partner_billing_address.street
                                            : ""
                                        }
                                        style={{
                                          marginBottom: 5,
                                          borderColor: "#e8e8e8",
                                        }}
                                        onChange={(elem) => {
                                          console.log(elem);

                                          setData({
                                            ...data,
                                            invoice_partner: {
                                              ...data.invoice_partner,
                                              partner_billing_address: {
                                                ...data.invoice_partner
                                                  .partner_billing_address,
                                                street: elem.target.value,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </StandaloneSearchBox>
                                  ) : (
                                    <></>
                                  )}
                                  {/* <Form.Control
                                  type="text"
                                  // disabled
                                  // name="subtotal"
                                  placeholder="Street"
                                  value={
                                    values.invoice_partner &&
                                    values.invoice_partner
                                      .partner_billing_address &&
                                    values.invoice_partner
                                      .partner_billing_address.street
                                      ? values.invoice_partner
                                          .partner_billing_address.street
                                      : ""
                                  }
                                  style={{
                                    marginBottom: 5,
                                    borderColor: "#e8e8e8",
                                  }}
                                  onChange={(elem) => {
                                    console.log(elem);
                                    values.invoice_partner.partner_billing_address.street =
                                      elem.target.value;
                                    setData({
                                      ...data,
                                      invoice_partner: {
                                        ...data.invoice_partner,
                                        partner_billing_address: {
                                          ...data.invoice_partner
                                            .partner_billing_address,
                                          street: elem.target.value,
                                        },
                                      },
                                    });
                                  }}
                                /> */}

                                  <Row>
                                    <Col
                                      xs={6}
                                      style={{ padding: "0 5px 0 12px" }}
                                    >
                                      <Form.Control
                                        type="text"
                                        onKeyDown={handleKeyDown}
                                        // disabled
                                        // name="subtotal"
                                        placeholder="City"
                                        value={
                                          values.invoice_partner &&
                                          values.invoice_partner
                                            .partner_billing_address &&
                                          values.invoice_partner
                                            .partner_billing_address.city
                                            ? values.invoice_partner
                                                .partner_billing_address.city
                                            : ""
                                        }
                                        style={{
                                          marginBottom: 5,
                                          borderColor: "#e8e8e8",
                                        }}
                                        onChange={(elem) => {
                                          console.log(elem);
                                          values.invoice_partner.partner_billing_address.city =
                                            elem.target.value;
                                          setData({
                                            ...data,
                                            invoice_partner: {
                                              ...data.invoice_partner,
                                              partner_billing_address: {
                                                ...data.invoice_partner
                                                  .partner_billing_address,
                                                city: elem.target.value,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </Col>
                                    <Col
                                      xs={6}
                                      style={{ padding: "0 12px 0 5px" }}
                                    >
                                      <Form.Control
                                        type="text"
                                        onKeyDown={handleKeyDown}
                                        // disabled
                                        // name="subtotal"
                                        placeholder="State"
                                        value={
                                          values.invoice_partner &&
                                          values.invoice_partner
                                            .partner_billing_address &&
                                          values.invoice_partner
                                            .partner_billing_address.state
                                            ? values.invoice_partner
                                                .partner_billing_address.state
                                            : ""
                                        }
                                        style={{
                                          marginBottom: 5,
                                          borderColor: "#e8e8e8",
                                        }}
                                        onChange={(elem) => {
                                          console.log(elem);
                                          values.invoice_partner.partner_billing_address.state =
                                            elem.target.value;
                                          setData({
                                            ...data,
                                            invoice_partner: {
                                              ...data.invoice_partner,
                                              partner_billing_address: {
                                                ...data.invoice_partner
                                                  .partner_billing_address,
                                                state: elem.target.value,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col
                                      xs={6}
                                      style={{ padding: "0 5px 0 12px" }}
                                    >
                                      <Form.Control
                                        type="text"
                                        onKeyDown={handleKeyDown}
                                        // disabled
                                        // name="subtotal"
                                        placeholder="Country"
                                        value={
                                          values.invoice_partner &&
                                          values.invoice_partner
                                            .partner_billing_address &&
                                          values.invoice_partner
                                            .partner_billing_address.country
                                            ? values.invoice_partner
                                                .partner_billing_address.country
                                            : ""
                                        }
                                        style={{
                                          marginBottom: 5,
                                          borderColor: "#e8e8e8",
                                        }}
                                        onChange={(elem) => {
                                          console.log(elem);
                                          values.invoice_partner.partner_billing_address.country =
                                            elem.target.value;
                                          setData({
                                            ...data,
                                            invoice_partner: {
                                              ...data.invoice_partner,
                                              partner_billing_address: {
                                                ...data.invoice_partner
                                                  .partner_billing_address,
                                                country: elem.target.value,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </Col>

                                    <Col
                                      xs={6}
                                      style={{ padding: "0 12px 0 5px" }}
                                    >
                                      <Form.Control
                                        type="text"
                                        onKeyDown={handleKeyDown}
                                        // disabled
                                        // name="subtotal"
                                        placeholder="Zip Code"
                                        value={
                                          values.invoice_partner &&
                                          values.invoice_partner
                                            .partner_billing_address &&
                                          values.invoice_partner
                                            .partner_billing_address.zip_code
                                            ? values.invoice_partner
                                                .partner_billing_address
                                                .zip_code
                                            : ""
                                        }
                                        style={{
                                          marginBottom: 5,
                                          borderColor: "#e8e8e8",
                                        }}
                                        onChange={(elem) => {
                                          console.log(elem);
                                          values.invoice_partner.partner_billing_address.zip_code =
                                            elem.target.value;
                                          setData({
                                            ...data,
                                            invoice_partner: {
                                              ...data.invoice_partner,
                                              partner_billing_address: {
                                                ...data.invoice_partner
                                                  .partner_billing_address,
                                                zip_code: elem.target.value,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {/* Date */}
                      {/* <div className="m-3">
                  <h4>Date</h4>
                  <Row className="justify-content-center">
                    <Col className="text-center h5 m-3" md="6" lg="3">
                      <p> {`${props.type} Date`}</p>
                      <Form.Control
                        type="date"
                        isInvalid={errors.appointment_start_date}
                        // required
                        name="invoice_created_at"
                        placeholder="text"
                        value={new Date(
                          values.invoice_created_at
                        ).toLocaleDateString("sv")}
                        // onChange={(e) => {
                        //   let upd = new Date(e.target.value).toISOString();
                        //   console.log(upd);
                        //   data.invoice_created_at = upd;
                        // }}
                        // onChange={handleChange}
                        onChange={(e) => {
                          handleChange(e);
                          data.invoice_created_at = new Date(
                            e.target.value
                          ).toISOString();
                        }}
                      />
                    </Col>
                    <div className="vr p-0"></div>
                    <Col className="text-center h5 m-3" md="6" lg="3">
                      <p>Due Date</p>

                      <Form.Control
                        type="date"
                        isInvalid={errors.appointment_start_date}
                        // required
                        name="invoice_due_date"
                        placeholder="text"
                        value={new Date(
                          values.invoice_due_date
                        ).toLocaleDateString("sv")}
                        // onChange={(e) => {
                        //   let upd = new Date(e.target.value).toISOString();
                        //   console.log(upd);
                        //   data.invoice_due_date = upd;
                        // }}
                        // onChange={handleChange}
                        onChange={(e) => {
                          handleChange(e);
                          data.invoice_due_date = new Date(
                            e.target.value
                          ).toISOString();
                        }}
                      />
                    </Col>
                  </Row>
                </div> */}
                      {/* <hr /> */}
                      {/* Partner */}
                      {/* <div className="m-3">
                  <div className="d-flex justify-content-between">
                    <h4>Partner</h4>
                    <Button
                      onClick={() =>
                        data.invoice_partner_type === "CUSTOMER"
                          ? navigate("/customer", {
                              state: {
                                from: location.pathname,
                                data: data,
                              },
                            })
                          : navigate("/supplier", {
                              state: {
                                from: location.pathname,
                                data: data,
                              },
                            })
                      }
                    >
                      Edit Partner
                    </Button>
                  </div>
                  <div className="m-3">
                    <h4 style={{ fontSize: 15, color: "#645cff" }}>
                      {values.invoice_partner.partner_first_name}{" "}
                      {values.invoice_partner.partner_last_name}
                    </h4>
                  </div>
                  <div className="m-4">
                    <Row>
                      <Col xs={6}>
                        <h5>Billing Address :</h5>
                        {values.invoice_partner.partner_billing_address.street}
                        <br />
                        {values.invoice_partner.partner_billing_address.city}
                        <br />
                        {
                          values.invoice_partner.partner_billing_address.state
                        }{" "}
                        {
                          values.invoice_partner.partner_billing_address
                            .zip_code
                        }
                        <br />
                        {values.invoice_partner.partner_billing_address.country}
                        <br />
                      </Col>
                      <Col xs={6}>
                        <h5>Shipping Address :</h5>
                        {values.invoice_partner.partner_shipping_address.street}
                        <br />
                        {values.invoice_partner.partner_shipping_address.city}
                        <br />
                        {
                          values.invoice_partner.partner_shipping_address.state
                        }{" "}
                        {
                          values.invoice_partner.partner_shipping_address
                            .zip_code
                        }
                        <br />
                        {
                          values.invoice_partner.partner_shipping_address
                            .country
                        }
                        <br />
                      </Col>
                    </Row>
                  </div>
                </div> */}
                      {props.type === "Booking" || props.type === "Request" ? (
                        <>
                          <hr />
                          <div className="m-3">
                            <Form.Check
                              // id="checkbox1"
                              label={`Add ${props.type} Appointment time later`}
                              // name="appointment_send_confirmation"
                              // value={addappointmentlater}
                              defaultChecked={
                                data.invoice_visits &&
                                data.invoice_visits.length !== 0
                                  ? false
                                  : true
                              }
                              style={{ fontSize: "15px", marginBottom: 20 }}
                              onChange={(e) => {
                                mixpanel.track(
                                  `Add booking appointment time later(Edit ${props.invoicetype} Web)`
                                );
                                console.log(
                                  addappointmentlater,
                                  e.target.checked
                                );
                                values.appointment_start_date = "";
                                values.appointment_start_time = "";
                                values.appointment_end_date = "";
                                values.appointment_end_time = "";
                                setAddappointemlater(e.target.checked);
                                // handleChange(e)
                              }}
                              type="checkbox"
                            />
                            <h5 style={{ fontSize: 16 }}>
                              {" "}
                              {props.type} Start Time
                            </h5>
                            <Row className="g-2" style={{}}>
                              <Col md>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Start Date "
                                  // className="mb-3"
                                >
                                  <Form.Control
                                    type="date"
                                    onKeyDown={handleKeyDown}
                                    isInvalid={errors.appointment_start_date}
                                    // required
                                    className="calendar-input"
                                    name="appointment_start_date"
                                    placeholder="text"
                                    value={values.appointment_start_date}
                                    disabled={
                                      addappointmentlater ? true : false
                                    }
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      handleChange(e);
                                      data.appointment_start_date =
                                        e.target.value;
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.appointment_start_date}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col md>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Start Time "
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="time"
                                    onKeyDown={handleKeyDown}
                                    isInvalid={errors.appointment_start_time}
                                    className="calendar-input"
                                    name="appointment_start_time"
                                    placeholder="text"
                                    disabled={
                                      addappointmentlater ? true : false
                                    }
                                    value={values.appointment_start_time}
                                    onChange={(e) => {
                                      handleChange(e);
                                      data.appointment_start_time =
                                        e.target.value;
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.appointment_start_time}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <h5 style={{ fontSize: 16 }}>
                              {" "}
                              {props.type} End Time
                            </h5>
                            <Row className="g-2" style={{}}>
                              <Col md>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="End Date "
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="date"
                                    onKeyDown={handleKeyDown}
                                    isInvalid={errors.appointment_end_date}
                                    className="calendar-input"
                                    // required
                                    name="appointment_end_date"
                                    placeholder="text"
                                    disabled={
                                      addappointmentlater ? true : false
                                    }
                                    value={values.appointment_end_date}
                                    onChange={(e) => {
                                      handleChange(e);
                                      data.appointment_end_date =
                                        e.target.value;
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.appointment_end_date}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                              <Col md>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="End Time "
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="time"
                                    onKeyDown={handleKeyDown}
                                    className="calendar-input"
                                    isInvalid={errors.appointment_end_time}
                                    name="appointment_end_time"
                                    placeholder="text"
                                    disabled={
                                      addappointmentlater ? true : false
                                    }
                                    value={values.appointment_end_time}
                                    onChange={(e) => {
                                      handleChange(e);
                                      data.appointment_end_time =
                                        e.target.value;
                                    }}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.appointment_end_time}
                                  </Form.Control.Feedback>
                                </FloatingLabel>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {props.type !== "Request" ? (
                        <>
                          {" "}
                          <hr />
                          {/* Products */}
                          {/* <div className="m-3">
                  <h4>Product</h4>
                  <div className="mt-3 ">
                    <DisplayTable
                      type="Product"
                      invoicedata={values.invoice_order.filter(
                        (elem) => elem.item_type === "PRODUCT"
                      )}
                      // setChange={setChange}
                      setSubtotal={setSubtotal}
                      multiplier={multiplier}
                      price={type}
                      //table containing list of products
                    />
                    <div className="text-center">
                      <Button
                        onClick={() => {
                          console.log(data);
                          navigate("/products", {
                            state: {
                              from: location.pathname,
                              data: data,
                            },
                          });
                        }}
                      >
                        <PlusCircle /> Add Product
                      </Button>
                    </div>
                  </div>
                </div> */}
                          <div className="m-3">
                            <h5 style={{ fontSize: 16 }}>Product</h5>
                            <div className="mt-3 mb-4">
                              <DisplayTable
                                type="Product"
                                invoicedata={
                                  data.invoice_order
                                    ? data.invoice_order.filter(
                                        (elem) => elem.item_type === "PRODUCT"
                                      )
                                    : []
                                }
                                data={data}
                                setData={setData}
                                setSubtotal={setSubtotal}
                                pricelist={pricelist}
                                price={type}
                                invoicetype={props.type}
                                setsearchtem={setsearchtem}
                                setModal={setProductModal}
                                setModalloading={setProductModalloading}
                                fetchItems={fetchItems}
                                //table containing list of products
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#EBF1FF",
                                    border: "none",
                                    color: "#3f80ea",
                                    fontWeight: "bold",
                                    padding: "10px 15px",
                                  }}
                                  onClick={() => {
                                    // console.log(data);
                                    // navigate("/products", {
                                    //   state: {
                                    //     from: "addInvoice",
                                    //     data: data,
                                    //     type: `${props.type.toLowerCase()}s`,
                                    //   },
                                    // });
                                    mixpanel.track(
                                      `Add Product (Edit ${props.invoicetype} Web)`
                                    );
                                    setsearchtem("");
                                    setProductModalloading(true);
                                    fetchItems();
                                    setProductModal(true);
                                  }}
                                >
                                  <Plus size={13} strokeWidth={3} /> Add Product
                                </button>
                              </div>
                            </div>
                          </div>
                          <hr />
                          {/* Services */}
                          {/* <div className="m-3">
                  <h4>Service</h4>
                  <DisplayTable
                    type="Service"
                    invoicedata={values.invoice_order.filter(
                      (elem) => elem.item_type === "SERVICE"
                    )}
                    setSubtotal={setSubtotal}
                    multiplier={multiplier}
                    price={type}
                    //table containing list of services
                  />
                  <div className="text-center">
                    <Button
                      onClick={() => {
                        console.log(data);
                        navigate("/services", {
                          state: {
                            from: location.pathname,
                            data: data,
                          },
                        });
                      }}
                    >
                      <PlusCircle /> Add Service
                    </Button>
                  </div>
                </div> */}
                          <div className="m-3">
                            <h5 style={{ fontSize: 16 }}>Service</h5>
                            <DisplayTable
                              type="Service"
                              invoicedata={
                                data.invoice_order
                                  ? data.invoice_order.filter(
                                      (elem) => elem.item_type === "SERVICE"
                                    )
                                  : []
                              }
                              data={data}
                              setData={setData}
                              setSubtotal={setSubtotal}
                              pricelist={pricelist}
                              price={type}
                              invoicetype={props.type}
                              setsearchtem={setsearchtem}
                              setModal={setServiceModal}
                              setModalloading={setServiceModalloading}
                              fetchItems={fetchItems}

                              //table containing list of services
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <button
                                type="button"
                                style={{
                                  backgroundColor: "#EBF1FF",
                                  border: "none",
                                  color: "#3f80ea",
                                  fontWeight: "bold",
                                  padding: "10px 15px",
                                }}
                                onClick={() => {
                                  // navigate("/services", {
                                  //   state: {
                                  //     from: "addInvoice",
                                  //     data: data,
                                  //     type: `${props.type.toLowerCase()}s`,
                                  //   },
                                  // });
                                  mixpanel.track(
                                    `Add Service (Edit ${props.invoicetype} Web)`
                                  );
                                  setsearchtem("");
                                  setServiceModalloading(true);
                                  fetchItems();
                                  setServiceModal(true);
                                }}
                              >
                                <Plus size={13} strokeWidth={3} /> Add Service
                              </button>
                            </div>
                          </div>
                          <hr />
                          {/* Amount Section */}
                          <div
                            style={{
                              color: "black",
                              display: "flex",
                              flexDirection: "column",
                              // justifyContent: "flex-end",
                              alignItems: "end",
                            }}
                            className="text-end m-3"
                          >
                            <p
                              style={{
                                margin: "2px",
                                padding: "0",
                                lineHeight: "24px",
                                fontWeight: "bold",
                                minWidth: 350,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              Subtotal{" "}
                              <span
                                style={{
                                  display: "inline-block",
                                  width: "180px",
                                  marginLeft: "5px",
                                }}
                              >
                                <Form.Control
                                  type="text"
                                  onKeyDown={handleKeyDown}
                                  disabled
                                  name="subtotal"
                                  placeholder="text"
                                  value={subtotal.toLocaleString("en-US", {
                                    style: "currency",
                                    currency:
                                      data && data.invoice_currency_code
                                        ? data.invoice_currency_code
                                        : usercurrency,
                                  })}
                                  onChange={handleChange}
                                  style={{ borderColor: "#e8e8e8" }}
                                />
                              </span>
                            </p>
                            {props.type !== "Booking" &&
                            props.type !== "Request" ? (
                              <>
                                {" "}
                                <p
                                  style={{
                                    margin: "2px",
                                    padding: "0",
                                    lineHeight: "24px",
                                    minWidth: 350,
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {/* Discount ({discount ? `${discount * 100}%` : "ADD"}) */}
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Discount
                                    <span
                                      style={{
                                        marginLeft: 2,
                                        color: "#3f80ea",
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => {
                                        mixpanel.track(
                                          `Add Discount (Edit ${props.invoicetype} Web)`
                                        );
                                        setDiscountmodal(true);
                                      }}
                                    >
                                      {/* ({discount ? `${discount * 100}%` : "ADD"}) */}
                                      <span
                                        style={{ marginLeft: 5, fontSize: 12 }}
                                      >
                                        {discount === 0
                                          ? "(Add)"
                                          : discounttype === "percentage"
                                          ? `(${discount}%)`
                                          : ""}
                                      </span>
                                    </span>
                                  </span>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "180px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <Form.Control
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      style={{
                                        cursor: "pointer",
                                        borderColor: "#e8e8e8",
                                      }}
                                      name="discount"
                                      placeholder="text"
                                      value={Number(
                                        discounttype === "percentage"
                                          ? (subtotal * discount) / 100
                                          : discount
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency:
                                          data && data.invoice_currency_code
                                            ? data.invoice_currency_code
                                            : usercurrency,
                                      })}
                                      onClick={() => {
                                        mixpanel.track(
                                          `Add Discount (Edit ${props.invoicetype} Web)`
                                        );
                                        setDiscountmodal(true);
                                      }}
                                      onChange={(e) => {
                                        handleChange(e);
                                        data.invoice_discount_rate =
                                          e.target.value;
                                      }}
                                    />
                                  </span>
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                    padding: "0",
                                    lineHeight: "24px",
                                    minWidth: 350,
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {/* Tax ({tax ? `${tax * 100}%` : "ADD"}) */}
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Tax
                                    <span
                                      style={{
                                        marginLeft: 2,
                                        color: "#3f80ea",
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => {
                                        mixpanel.track(
                                          `Add Tax (Edit ${props.invoicetype} Web)`
                                        );
                                        setTaxmodal(true);
                                      }}
                                    >
                                      {/* ({tax ? `${tax * 100}%` : "ADD"}) */}
                                      <span
                                        style={{ marginLeft: 40, fontSize: 12 }}
                                      >
                                        {tax === 0
                                          ? "(Add)"
                                          : taxtype === "percentage"
                                          ? `(${tax}%)`
                                          : ""}
                                      </span>
                                    </span>
                                  </span>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "180px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <Form.Control
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      style={{
                                        cursor: "pointer",
                                        borderColor: "#e8e8e8",
                                      }}
                                      name="tax"
                                      placeholder="text"
                                      value={Number(
                                        // (subtotal - subtotal * discount) * tax

                                        taxtype === "percentage"
                                          ? discounttype === "percentage"
                                            ? Number(
                                                (subtotal -
                                                  subtotal * (discount / 100)) *
                                                  (tax / 100)
                                              )
                                            : Number(
                                                (subtotal - discount) *
                                                  (tax / 100)
                                              )
                                          : Number(tax)
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency:
                                          data && data.invoice_currency_code
                                            ? data.invoice_currency_code
                                            : usercurrency,
                                      })}
                                      onClick={() => {
                                        mixpanel.track(
                                          `Add Tax (Edit ${props.invoicetype} Web)`
                                        );
                                        setTaxmodal(true);
                                      }}
                                      onChange={(e) => {
                                        handleChange(e);
                                        data.invoice_tax_rate = e.target.value;
                                      }}
                                    />
                                  </span>
                                </p>
                                {/* <br /> */}
                                <p
                                  // className="total-txt"
                                  style={{
                                    margin: "2px",
                                    padding: "0",
                                    // paddingTop: "5px",
                                    fontWeight: "bold",
                                    lineHeight: "24px",
                                    minWidth: 350,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  Total
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "180px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <Form.Control
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      disabled
                                      placeholder="text"
                                      value={(
                                        Number(subtotal) -
                                        Number(
                                          discounttype === "percentage"
                                            ? (subtotal * discount) / 100
                                            : discount
                                        ) +
                                        Number(
                                          taxtype === "percentage"
                                            ? discounttype === "percentage"
                                              ? Number(
                                                  (subtotal -
                                                    subtotal *
                                                      (discount / 100)) *
                                                    (tax / 100)
                                                )
                                              : Number(
                                                  (subtotal - discount) *
                                                    (tax / 100)
                                                )
                                            : Number(tax)
                                        )
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency:
                                          data && data.invoice_currency_code
                                            ? data.invoice_currency_code
                                            : usercurrency,
                                      })}
                                      onChange={handleChange}
                                      style={{ borderColor: "#e8e8e8" }}
                                    />
                                  </span>
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                    padding: "0",
                                    lineHeight: "24px",
                                    minWidth: 350,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  Payment Received
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "180px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <Form.Control
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      style={{
                                        cursor: "pointer",
                                        borderColor: "#e8e8e8",
                                      }}
                                      name="recieved"
                                      placeholder="text"
                                      value={Number(recieved).toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency:
                                            data && data.invoice_currency_code
                                              ? data.invoice_currency_code
                                              : usercurrency,
                                        }
                                      )}
                                      onClick={() => {
                                        mixpanel.track(
                                          `Add Settled (Edit ${props.invoicetype} Web)`
                                        );
                                        setRecievedmodal(true);
                                      }}
                                    />
                                  </span>
                                </p>
                                {/* <br /> */}
                                <p
                                  style={{
                                    margin: "2px",
                                    padding: "0",
                                    lineHeight: "24px",
                                    fontWeight: "bold",
                                    minWidth: 350,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  Amount Due{" "}
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "180px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <Form.Control
                                      type="text"
                                      onKeyDown={handleKeyDown}
                                      disabled
                                      //  name="recieved"
                                      placeholder="text"
                                      value={(
                                        Number(subtotal) -
                                        Number(
                                          discounttype === "percentage"
                                            ? (subtotal * discount) / 100
                                            : discount
                                        ) +
                                        Number(
                                          taxtype === "percentage"
                                            ? discounttype === "percentage"
                                              ? Number(
                                                  (subtotal -
                                                    subtotal *
                                                      (discount / 100)) *
                                                    (tax / 100)
                                                )
                                              : Number(
                                                  (subtotal - discount) *
                                                    (tax / 100)
                                                )
                                            : Number(tax)
                                        ) -
                                        Number(recieved)
                                      ).toLocaleString("en-US", {
                                        style: "currency",
                                        currency:
                                          data && data.invoice_currency_code
                                            ? data.invoice_currency_code
                                            : usercurrency,
                                      })}
                                      onChange={handleChange}
                                      style={{ borderColor: "#e8e8e8" }}
                                    />
                                  </span>
                                </p>{" "}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          {props.type === "Booking" &&
                          bookingPermission.includes("ALL") ? (
                            <>
                              <hr />
                              <div className="m-3">
                                <h5 style={{ fontSize: 16 }}>Team Member</h5>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Team Member"
                                  className="mb-3"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="category"
                                    onKeyDown={handleKeyDown}
                                    onChange={(event) => {
                                      const selectedId = event.target.value;
                                      if (selectedId) {
                                        const selectedMember =
                                          teamMemberList.find((member) => {
                                            return (
                                              member.team_member_email ===
                                              selectedId
                                            );
                                          });
                                        console.log(selectedMember);
                                        setTeamMember(selectedMember);
                                      } else {
                                        setTeamMember({});
                                      }
                                    }}
                                    value={teamMember.team_member_email}
                                  >
                                    <option
                                      disabled={
                                        teamMember &&
                                        Object.keys(teamMember).length === 0
                                          ? false
                                          : true
                                      }
                                      value={""}
                                    >
                                      Select Team Member
                                    </option>

                                    {teamMemberList.map((elem) => (
                                      <option value={elem.team_member_email}>
                                        {elem.team_member_name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </FloatingLabel>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <hr />
                          {/* Call to action button */}
                          {/* <div className="text-center m-4">
                    <button
                      type="button"
                      style={{
                        marginRight: "30px",
                        backgroundColor: "#EBF1FF",
                        border: "none",
                        color: "#3f80ea",
                        fontWeight: "bold",
                        padding: "10px 15px",
                      }}
                      // className="m-2"
                      onClick={() =>
                        // navigate("/payments")
                        window.open("/payments", "_blank")
                      }
                    >
                      Online Payments
                    </button>
                    <button
                      type="button"
                      style={{
                        marginRight: "30px",
                        backgroundColor: "#EBF1FF",
                        border: "none",
                        color: "#3f80ea",
                        fontWeight: "bold",
                        padding: "10px 15px",
                      }}
                      // className="m-2"
                      onClick={() => setAutoremindmodal(true)}
                    >
                      Auto Remind
                    </button>
                  </div> */}
                          {/* Notes and reference number */}
                          <div className="m-3">
                            <FloatingLabel
                              controlId="floatingTextarea2"
                              label="Notes"
                              className="mb-3"
                            >
                              <Form.Control
                                as="textarea"
                                // placeholder="Leave a comment here"
                                name="invoice_notes"
                                value={values.invoice_notes}
                                style={{
                                  height: "100px",
                                  resize: "none",
                                  borderColor: "#e8e8e8",
                                }}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  handleChange(e);
                                  data.invoice_notes = e.target.value;
                                }}
                              />
                            </FloatingLabel>
                            <FloatingLabel
                              controlId="floatingTextarea2"
                              label="Internal Notes"
                              className="mb-3"
                            >
                              <Form.Control
                                as="textarea"
                                // rows="4"
                                // placeholder="Leave a comment here"
                                name="invoice_internal_notes"
                                value={values.invoice_internal_notes}
                                style={{
                                  height: "100px",
                                  resize: "none",
                                  borderColor: "#e8e8e8",
                                }}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  handleChange(e);
                                  data.invoice_internal_notes = e.target.value;
                                }}
                              />
                            </FloatingLabel>
                            <FloatingLabel
                              controlId="floatingTextarea2"
                              label="Terms and Conditions"
                              className="mb-3"
                            >
                              <Form.Control
                                as="textarea"
                                // rows="4"
                                // placeholder="Leave a comment here"
                                name="invoice_terms_and_conditions"
                                value={values.invoice_terms_and_conditions}
                                style={{
                                  height: "100px",
                                  resize: "none",
                                  borderColor: "#e8e8e8",
                                }}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  handleChange(e);
                                  data.invoice_terms_and_conditions =
                                    e.target.value;
                                }}
                              />
                            </FloatingLabel>
                          </div>
                          {/* <FloatingLabel
                    controlId="floatingInput"
                    label="Reference Number"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="invoice_reference_number"
                      value={values.invoice_reference_number}
                      // onChange={handleChange}
                      style={{ borderColor: "#e8e8e8" }}
                      onChange={(e) => {
                        handleChange(e);
                        data.invoice_reference_number = e.target.value;
                      }}
                    />
                  </FloatingLabel> */}
                          {props.type === "Booking" ||
                          props.type === "Request" ? (
                            <div className="m-3" style={{ paddingLeft: 9 }}>
                              <Row className="g-3">
                                <Form.Check
                                  id="checkbox1"
                                  label="Send confirmation email to customer"
                                  name="appointment_send_confirmation"
                                  style={{ fontSize: "15px" }}
                                  onChange={handleChange}
                                  type="checkbox"
                                  defaultChecked={
                                    values.appointment_send_confirmation
                                  }
                                />
                              </Row>
                              {addappointmentlater ? (
                                <></>
                              ) : (
                                <Row className="g-3">
                                  <Form.Check
                                    id="checkbox2"
                                    name="appointment_reminders"
                                    label="Send reminder email to customer (one day before)"
                                    style={{ fontSize: "15px" }}
                                    onChange={handleChange}
                                    // value={1}
                                    type="checkbox"
                                    defaultChecked={
                                      values.appointment_reminders
                                    }
                                  />
                                </Row>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                          {/* Call to action button */}
                          {/* <div className="text-center m-4">
                  <Button className="me-1 mb-1" size="lg">
                    Send Invoice
                  </Button>
                  <Button className="me-1 mb-1" type="submit" size="lg">
                    Save Only
                  </Button>
                </div> */}
                          {/* {existingimage ? (
                      <div style={{ margin: "5px 0" }}>

                        <button
                          type="button"
                          style={{
                            padding: "5px 10px",
                            backgroundColor: "#3679e8",
                            borderWidth: 1,
                            borderColor: "#3679e8",

                            color: "white",
                          }}
                          onClick={async () => {
                            mixpanel.track(
                              `View Document (Edit ${props.invoicetype} Web)`
                            );
                            store
                              .ref("files/" + data.invoice_attachment_id)
                              .getDownloadURL()
                              .then((res) => {
                                window.open(res);
                              });
                          }}
                        >
                          View Document
                        </button>
                        <button
                          type="button"
                          style={{
                            padding: "5px 10px",
                            backgroundColor: "white",
                            borderWidth: 1,
                            borderColor: "black",
                            marginLeft: 5,
                            color: "black",
                          }}
                          onClick={() => {
                            mixpanel.track(
                              `Replace Document (Edit ${props.invoicetype} Web)`
                            );
                            setExistingimage("");
                          }}
                        >
                          Replace
                        </button>
                      </div>
                    ) : (
                      <div style={{ margin: "5px 0" }}>
                        <Form.Group className="mb-3">
                          <Form.Label style={{ color: "black" }}>
                            {props.type} Document
                          </Form.Label>
                          <Form.Control
                            ref={file}
                            type="file"
                            placeholder="Unit Type"
                            onChange={(val) => {
                              mixpanel.track(
                                `Add Document (Edit ${props.invoicetype} Web)`
                              );
                              setImageupload(val.target.files[0]);
                              // uploadToCloud(val);
                            }}
                          />
                        </Form.Group>
                        {upload ? (
                          upload === "starting" ? (
                            <p style={{ color: "black", fontSize: 13 }}>
                              Uploading ...
                            </p>
                          ) : (
                            <p style={{ color: "black", fontSize: 13 }}>
                              &#x2705; Uploaded
                            </p>
                          )
                        ) : (
                          <></>
                        )}
                        {imageerror ? (
                          <p style={{ color: "red", fontSize: 11 }}>
                            {imageerror}
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                    )} */}
                          {/* <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#3679E8",
                        cursor: "pointer",
                      }}
                      className="m-3"
                      onClick={() => setPhotoModal(true)}
                    >
                      {imageData.length === 0
                        ? "Add Photos & Documents"
                        : `Photos & Documents (${imageData.length})`}
                    </div> */}
                          <div className="m-3" style={{ display: "flex" }}>
                            <div
                              style={{
                                backgroundColor: "#fafafa",
                                padding: "5px 10px",
                                borderRadius: "3px 0px 0px 3px",
                                border: "1px solid #E8E8E8",
                                color: "black",
                                cursor: "pointer",
                              }}
                              onClick={() => setPhotoModal(true)}
                            >
                              Add File/Photo
                            </div>
                            <div
                              style={{
                                backgroundColor: "white",
                                padding: "5px 10px",
                                border: "1px solid #E8E8E8",
                                color: "black",
                                borderRadius: "0px 3px 3px 0px",
                                width: 300,
                                cursor: "pointer",
                                flex: 1,
                              }}
                              onClick={() => setPhotoModal(true)}
                            >
                              {imageData.length === 0 ? "No" : imageData.length}{" "}
                              {imageData.length > 1 ? "files " : "file "} chosen
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: 30,
                          marginBottom: 15,
                        }}
                        className="m-3"
                      >
                        <div>
                          <Button
                            style={{ marginRight: "15px" }}
                            onClick={() => {
                              mixpanel.track(
                                `Cancel (Edit ${props.invoicetype} Web)`
                              );
                              navigate(`/${(props.type + "s").toLowerCase()}`);
                            }}
                            size="lg"
                            variant="secondary"
                            // ref={saveonly}
                          >
                            Cancel
                          </Button>
                        </div>

                        <div>
                          <Button
                            style={{}}
                            type="submit"
                            size="lg"
                            disabled={disablesave}
                            onClick={() => setAction("save")}
                            // ref={saveonly}
                          >
                            Save {props.type}
                          </Button>
                          {/* <Button
                        // style={{
                        //   marginRight: "15px",
                        // }}
                        size="lg"
                        disabled={disablesave}
                        onClick={() => setAction("send")}
                        type="submit"
                        // ref={sendinvoice}
                      >
                        Save & Send {props.type}
                      </Button> */}
                        </div>
                      </div>
                      {/* <Modal
                  show={discountmodal}
                  onHide={() => setDiscountmodal(false)}
                  centered
                >
                  <Modal.Header className="font-weight-bold" closeButton>
                    Set Discount
                  </Modal.Header>
                  <Modal.Body className="text-left m-3">
                    <p className="mb-0 font-weight-bold">Set Discount Rate</p>
                    <Form.Control
                      type="number"
                      isInvalid={errors.invoice_discount_rate}
                      // required
                      name="invoice_discount_rate"
                      // placeholder="10"
                      value={values.invoice_discount_rate}
                      // defaultValue={data.invoice_created_at}
                      onChange={handleChange}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setDiscountmodal(false)}
                    >
                      Cancel
                    </Button>{" "}
                    <Button
                      variant="danger"
                      onClick={() => {
                        setDiscount(values.invoice_discount_rate / 100);
                        setDiscountmodal(false);
                      }}
                    >
                      Apply
                    </Button>
                  </Modal.Footer>
                </Modal> */}
                      <Modal
                        show={discountmodal}
                        onHide={() => {
                          mixpanel.track(
                            `Close >>> Discount Modal (Edit ${props.invoicetype} Web)`
                          );
                          setDiscountmodal(false);
                          setTempDispounttype(discounttype);
                          setFieldValue(
                            "invoice_discount_rate",
                            discount ? discount : 0
                          );
                        }}
                        centered
                        size="sm"
                      >
                        <Modal.Body style={{ padding: 0 }}>
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#f4f4f4",
                              height: 46,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "16px 24px",
                            }}
                          >
                            <h1
                              style={{
                                fontWeight: "bold",
                                fontSize: 18,
                                color: "black",
                                margin: 0,
                              }}
                            >
                              Set Discount
                            </h1>
                            <div
                              onClick={() => {
                                mixpanel.track(
                                  `Close >>> Discount Modal (Edit ${props.invoicetype} Web)`
                                );
                                setDiscountmodal(false);
                                setTempDispounttype(discounttype);
                                setFieldValue(
                                  "invoice_discount_rate",
                                  discount ? discount : 0
                                );
                              }}
                            >
                              <X
                                size={24}
                                strokeWidth={2}
                                stroke="black"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>
                          <div style={{ padding: "10px 24px 0 24px" }}>
                            <div
                              style={{
                                display: "flex",
                                // justifyContent: "center",
                                flexDirection: "row",
                                // alignItems: "center",
                                marginTop: 15,
                                marginBottom: 25,
                              }}
                            >
                              {/* <p className="mb-0 font-weight-bold">
                            Set Discount Rate
                          </p> */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  backgroundColor: "#edebeb",
                                  alignItems: "center",
                                  marginRight: 20,
                                  borderRadius: 5,
                                  padding: "0px 2px",
                                }}
                              >
                                <div
                                  style={{
                                    paddingRight: 15,
                                    paddingLeft: 15,
                                    backgroundColor:
                                      tempdiscounttype !== "percentage"
                                        ? "transparent"
                                        : "white",
                                    borderRadius: 5,
                                    height: "100",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setTempDispounttype("percentage")
                                  }
                                >
                                  %
                                </div>
                                <div
                                  style={{
                                    paddingRight: 15,
                                    paddingLeft: 15,
                                    cursor: "pointer",
                                    backgroundColor:
                                      tempdiscounttype !== "absolute"
                                        ? "transparent"
                                        : "white",
                                    borderRadius: 5,
                                    height: "100",
                                  }}
                                  onClick={() =>
                                    setTempDispounttype("absolute")
                                  }
                                >
                                  $
                                </div>
                              </div>
                              <Form.Control
                                type="number"
                                isInvalid={errors.invoice_discount_rate}
                                min={0}
                                // required
                                name="invoice_discount_rate"
                                // placeholder="10"
                                defaultValue={discount}
                                value={values.invoice_discount_rate}
                                onChange={(event) => {
                                  if (
                                    event.target.value.replace(/\s/g, "")
                                      .length <= 10
                                  ) {
                                    handleChange(event);
                                  }
                                }}
                                // onChange={handleChange}
                              />
                            </div>

                            <hr style={{ margin: 0 }} />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: "12px 0",
                              }}
                            >
                              <button
                                type="button"
                                style={{
                                  padding: "5px 10px",
                                  backgroundColor: "#3679e8",
                                  borderWidth: 0,
                                  color: "white",
                                  width: 80,
                                  marginRight: 10,
                                }}
                                onClick={() => {
                                  mixpanel.track(
                                    `Close >>> Discount Modal (Edit ${props.invoicetype} Web)`
                                  );
                                  setDiscountmodal(false);
                                  setTempDispounttype(discounttype);
                                  setFieldValue(
                                    "invoice_discount_rate",
                                    discount ? discount : 0
                                  );
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                style={{
                                  padding: "5px 10px",
                                  backgroundColor: "#3679e8",
                                  borderWidth: 0,
                                  color: "white",
                                  width: 80,
                                }}
                                onClick={() => {
                                  mixpanel.track(
                                    `Apply >>> Discount Modal (Edit ${props.invoicetype} Web)`
                                  );
                                  setDiscount(values.invoice_discount_rate);
                                  setDispounttype(tempdiscounttype);
                                  setDiscountmodal(false);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                      {/* <Modal
                  show={taxmodal}
                  onHide={() => setTaxmodal(false)}
                  centered
                >
                  <Modal.Header className="font-weight-bold" closeButton>
                    Set Tax
                  </Modal.Header>
                  <Modal.Body className="text-left m-3">
                    <p className="mb-0 font-weight-bold">Set Tax Rate</p>
                    <Form.Control
                      type="number"
                      // isInvalid={errors.appointment_start_date}
                      // required
                      isInvalid={errors.invoice_tax_rate}
                      name="invoice_tax_rate"
                      placeholder="10"
                      value={values.invoice_tax_rate}
                      onChange={handleChange}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setTaxmodal(false)}
                    >
                      Cancel
                    </Button>{" "}
                    <Button
                      variant="danger"
                      onClick={() => {
                        console.log("sfre" + values.invoice_tax_rate, tax);
                        setTax(values.invoice_tax_rate / 100);
                        data.invoice_tax_rate = values.invoice_tax_rate / 100;
                        setTaxmodal(false);
                      }}
                    >
                      Apply
                    </Button>
                  </Modal.Footer>
                </Modal> */}
                      <Modal
                        show={taxmodal}
                        onHide={() => {
                          mixpanel.track(
                            `Close >>> Tax Modal (Edit ${props.invoicetype} Web)`
                          );
                          setFieldValue("invoice_tax_rate", tax ? tax : 0);
                          setTempTaxtype(taxtype);

                          setTaxmodal(false);
                        }}
                        centered
                        size="sm"
                      >
                        <Modal.Body style={{ padding: 0 }}>
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#f4f4f4",
                              height: 46,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "16px 24px",
                            }}
                          >
                            <h1
                              style={{
                                fontWeight: "bold",
                                fontSize: 18,
                                color: "black",
                                margin: 0,
                              }}
                            >
                              Set Tax
                            </h1>
                            <div
                              onClick={() => {
                                mixpanel.track(
                                  `Close >>> Tax Modal (Edit ${props.invoicetype} Web)`
                                );
                                setTaxmodal(false);
                                setTempTaxtype(taxtype);
                                setFieldValue(
                                  "invoice_tax_rate",
                                  tax ? tax : 0
                                );
                              }}
                            >
                              <X
                                size={24}
                                strokeWidth={2}
                                stroke="black"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>
                          <div style={{ padding: "10px 24px 0 24px" }}>
                            <div
                              style={{
                                display: "flex",
                                // justifyContent: "center",
                                flexDirection: "row",
                                // alignItems: "center",
                                marginTop: 15,
                                marginBottom: 25,
                              }}
                            >
                              {/* <p className="mb-0 font-weight-bold">Set Tax Rate</p> */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  backgroundColor: "#edebeb",
                                  alignItems: "center",
                                  marginRight: 20,
                                  borderRadius: 5,
                                  padding: "0px 2px",
                                }}
                              >
                                <div
                                  style={{
                                    paddingRight: 15,
                                    paddingLeft: 15,
                                    backgroundColor:
                                      temptaxtype !== "percentage"
                                        ? "transparent"
                                        : "white",
                                    borderRadius: 5,
                                    height: "100",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setTempTaxtype("percentage")}
                                >
                                  %
                                </div>
                                <div
                                  style={{
                                    paddingRight: 15,
                                    paddingLeft: 15,
                                    cursor: "pointer",
                                    backgroundColor:
                                      temptaxtype !== "absolute"
                                        ? "transparent"
                                        : "white",
                                    borderRadius: 5,
                                    height: "100",
                                  }}
                                  onClick={() => setTempTaxtype("absolute")}
                                >
                                  {/* {getSymbolFromCurrency(usercurrency)} */}$
                                </div>
                              </div>
                              <Form.Control
                                type="number"
                                // isInvalid={errors.appointment_start_date}
                                // required
                                min={0}
                                isInvalid={errors.invoice_tax_rate}
                                name="invoice_tax_rate"
                                // placeholder="10"
                                defaultValue={tax}
                                value={values.invoice_tax_rate}
                                onChange={(event) => {
                                  if (
                                    event.target.value.replace(/\s/g, "")
                                      .length <= 10
                                  ) {
                                    handleChange(event);
                                  }
                                }}
                                // onChange={handleChange}
                              />
                            </div>

                            <hr style={{ margin: 0 }} />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: "12px 0",
                              }}
                            >
                              <button
                                type="button"
                                style={{
                                  padding: "5px 10px",
                                  backgroundColor: "#3679e8",
                                  borderWidth: 0,
                                  color: "white",
                                  width: 80,
                                  marginRight: 10,
                                }}
                                onClick={() => {
                                  mixpanel.track(
                                    `Close >>> Tax Modal (Edit ${props.invoicetype} Web)`
                                  );
                                  setTaxmodal(false);
                                  setTempTaxtype(taxtype);
                                  setFieldValue(
                                    "invoice_tax_rate",
                                    tax ? tax : 0
                                  );
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                style={{
                                  padding: "5px 10px",
                                  backgroundColor: "#3679e8",
                                  borderWidth: 0,
                                  color: "white",
                                  width: 80,
                                }}
                                onClick={() => {
                                  mixpanel.track(
                                    `Apply >>> Discount Modal (Edit ${props.invoicetype} Web)`
                                  );
                                  console.log(
                                    "sfre" + values.invoice_tax_rate,
                                    tax
                                  );
                                  setTax(values.invoice_tax_rate);
                                  data.invoice_tax_rate =
                                    values.invoice_tax_rate;
                                  setTaxtype(temptaxtype);
                                  setTaxmodal(false);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                      {/* <Modal
                  show={recievecmodal}
                  onHide={() => setRecievedmodal(false)}
                  centered
                >
                  <Modal.Header className="font-weight-bold" closeButton>
                    Set Settled Amount
                  </Modal.Header>
                  <Modal.Body className="text-left m-3">
                    <p className="mb-0 font-weight-bold">
                      Enter Settled Amount
                    </p>
                    <Form.Control
                      type="number"
                      isInvalid={errors.invoice_settled_amount}
                      // required
                      name="invoice_settled_amount"
                      placeholder="100000"
                      value={values.invoice_settled_amount}
                      onChange={handleChange}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setRecievedmodal(false)}
                    >
                      Cancel
                    </Button>{" "}
                    <Button
                      variant="danger"
                      onClick={() => {
                        setRecieved(values.invoice_settled_amount);
                        setRecievedmodal(false);
                      }}
                    >
                      Apply
                    </Button>
                  </Modal.Footer>
                </Modal> */}
                      <Modal
                        show={recievecmodal}
                        onHide={() => {
                          setRecievedmodal(false);
                          setFieldValue(
                            "invoice_settled_amount",
                            recieved ? recieved : 0
                          );
                        }}
                        centered
                        size="sm"
                      >
                        <Modal.Body style={{ padding: 0 }}>
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#f4f4f4",
                              height: 46,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "16px 24px",
                            }}
                          >
                            <h1
                              style={{
                                fontWeight: "bold",
                                fontSize: 18,
                                color: "black",
                                margin: 0,
                              }}
                            >
                              Set Settled Amount
                            </h1>
                            <div
                              onClick={() => {
                                setRecievedmodal(false);
                                setFieldValue(
                                  "invoice_settled_amount",
                                  recieved ? recieved : 0
                                );
                              }}
                            >
                              <X
                                size={24}
                                strokeWidth={2}
                                stroke="black"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>
                          <div style={{ padding: "10px 24px 0 24px" }}>
                            <div
                              style={{
                                display: "flex",
                                // justifyContent: "center",
                                flexDirection: "column",
                                // alignItems: "center",
                                marginTop: 15,
                                marginBottom: 25,
                              }}
                            >
                              <p className="mb-0 font-weight-bold">
                                Enter Settled Amount
                              </p>
                              <Form.Control
                                type="number"
                                isInvalid={errors.invoice_settled_amount}
                                // required
                                name="invoice_settled_amount"
                                min={0}
                                // placeholder="100000"
                                defaultValue={recieved}
                                value={values.invoice_settled_amount}
                                onChange={(event) => {
                                  if (
                                    event.target.value.replace(/\s/g, "")
                                      .length <= 10
                                  ) {
                                    handleChange(event);
                                  }
                                }}
                                // onChange={handleChange}
                              />
                            </div>

                            <hr style={{ margin: 0 }} />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: "12px 0",
                              }}
                            >
                              <button
                                type="button"
                                style={{
                                  padding: "5px 10px",
                                  backgroundColor: "#3679e8",
                                  borderWidth: 0,
                                  color: "white",
                                  width: 80,
                                  marginRight: 10,
                                }}
                                onClick={() => {
                                  setRecievedmodal(false);
                                  setFieldValue(
                                    "invoice_settled_amount",
                                    recieved ? recieved : 0
                                  );
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                style={{
                                  padding: "5px 10px",
                                  backgroundColor: "#3679e8",
                                  borderWidth: 0,
                                  color: "white",
                                  width: 80,
                                }}
                                onClick={() => {
                                  setRecieved(values.invoice_settled_amount);
                                  setRecievedmodal(false);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                      <Modal
                        show={validationError}
                        // show={validationError}
                        onHide={() => {
                          // console.log("ojn");
                          setValidationError(false);
                        }}
                        centered
                        size="sm"
                      >
                        <Modal.Body style={{ padding: 0 }}>
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#f4f4f4",
                              height: 46,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "16px 24px",
                            }}
                          >
                            <h1
                              style={{
                                fontWeight: "bold",
                                fontSize: 18,
                                color: "black",
                                margin: 0,
                              }}
                            >
                              Error
                            </h1>
                            <div
                              onClick={() => {
                                setValidationError(false);
                              }}
                            >
                              <X
                                size={24}
                                strokeWidth={2}
                                stroke="black"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>
                          <div style={{ padding: "10px 24px 0 24px" }}>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <p style={{ margin: "15px 0 20px" }}>
                                {Object.keys(data.invoice_partner).length === 0
                                  ? `Please add a customer for this ${props.type}`
                                  : props.type !== "Booking" &&
                                    props.type !== "Request"
                                  ? data.invoice_order.length === 0
                                    ? `Please add a product or service for this ${props.type}`
                                    : props.type !== "Booking" &&
                                      props.type !== "Request" &&
                                      data.invoice_order.reduce(
                                        (sum, obj) =>
                                          sum + obj.item_quantity
                                            ? Number(obj.item_quantity)
                                            : 0,
                                        0
                                      ) === 0
                                    ? "Product / Service Quantity can not be empty"
                                    : "There was an error saving the form. Please check the messages in the form and try again."
                                  : "There was an error saving the form. Please check the messages in the form and try again."}
                              </p>
                            </div>

                            <hr style={{ margin: 0 }} />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: "12px 0",
                              }}
                            >
                              <button
                                style={{
                                  padding: "5px 10px",
                                  backgroundColor: "#3679e8",
                                  borderWidth: 0,
                                  color: "white",
                                  width: 80,
                                }}
                                onClick={() => {
                                  setValidationError(false);
                                }}
                              >
                                OK
                              </button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                      {/* <Modal
                  show={autoremindmodal}
                  onHide={() => setAutoremindmodal(false)}
                  centered
                >
                  <Modal.Header className="font-weight-bold" closeButton>
                    Automatic Remiender
                  </Modal.Header>
                  <Modal.Body className="text-left m-3">
                    <p className="mb-0 font-weight-bold">
                      Set Automatic Reminder
                    </p>
                    <Form.Control
                      style={{
                        width: "100px",
                        padding: "10px",
                        display: "inline",
                        marginRight: "10px",
                      }}
                      type="number"
                      // isInvalid={errors.appointment_start_date}
                      required
                      name="invoice_reminders"
                      placeholder="0"
                      defaultValue={
                        // data.invoice_reminders.length !== 0
                        //   ? data.invoice_reminders[0].value
                        //   : 0
                        // data.invoice_reminders
                        remiender.length !== 0 ? remiender[0].value : 0
                      }
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span>days before</span>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setAutoremindmodal(false)}
                    >
                      Cancel
                    </Button>{" "}
                    <Button
                      variant="danger"
                      onClick={() => {
                        setRemiender(
                          values.invoice_reminders !== 0
                            ? [{ value: values.invoice_reminders }]
                            : []
                        );
                        data.invoice_reminders =
                          values.invoice_reminders !== 0
                            ? [{ value: values.invoice_reminders }]
                            : [];

                        setAutoremindmodal(false);
                      }}
                    >
                      Apply
                    </Button>
                  </Modal.Footer>
                </Modal> */}
                      <Modal
                        show={autoremindmodal}
                        onHide={() => setAutoremindmodal(false)}
                        centered
                        size="sm"
                      >
                        <Modal.Body style={{ padding: 0 }}>
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#f4f4f4",
                              height: 46,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "16px 24px",
                            }}
                          >
                            <h1
                              style={{
                                fontWeight: "bold",
                                fontSize: 18,
                                color: "black",
                                margin: 0,
                              }}
                            >
                              Automatic Reminder
                            </h1>
                            <div
                              onClick={() => {
                                setAutoremindmodal(false);
                              }}
                            >
                              <X
                                size={24}
                                strokeWidth={2}
                                stroke="black"
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>
                          <div style={{ padding: "10px 24px 0 24px" }}>
                            <div
                              style={{
                                display: "flex",
                                // justifyContent: "center",
                                flexDirection: "column",
                                // alignItems: "center",
                                marginBottom: 10,
                              }}
                            >
                              <p className="mb-0 font-weight-bold">
                                Set Automatic Reminder
                              </p>
                              <Form.Control
                                style={{
                                  width: "100px",
                                  padding: "10px",
                                  display: "inline",
                                  marginRight: "10px",
                                }}
                                type="number"
                                // isInvalid={errors.appointment_start_date}
                                required
                                min={0}
                                name="invoice_reminders"
                                placeholder="0"
                                defaultValue={
                                  // data.invoice_reminders.length !== 0
                                  //   ? data.invoice_reminders[0].value
                                  //   : 0
                                  // data.invoice_reminders
                                  remiender.length !== 0
                                    ? remiender[0].value
                                    : 0
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                              <span>days before</span>
                            </div>

                            <hr style={{ margin: 0 }} />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: "7px 0",
                              }}
                            >
                              <button
                                type="button"
                                style={{
                                  padding: "5px 10px",
                                  backgroundColor: "#3679e8",
                                  borderWidth: 0,
                                  color: "white",
                                  width: 80,
                                  marginRight: 10,
                                }}
                                onClick={() => {
                                  setAutoremindmodal(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                style={{
                                  padding: "5px 10px",
                                  backgroundColor: "#3679e8",
                                  borderWidth: 0,
                                  color: "white",
                                  width: 80,
                                }}
                                onClick={() => {
                                  setRemiender(
                                    values.invoice_reminders !== 0
                                      ? [{ value: values.invoice_reminders }]
                                      : []
                                  );
                                  data.invoice_reminders =
                                    values.invoice_reminders !== 0
                                      ? [{ value: values.invoice_reminders }]
                                      : [];

                                  setAutoremindmodal(false);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>

                      {/* <Persist name={"form" + id} /> */}
                    </Form>
                  );
                }}
              </Formik>
              <Selectmodal
                type={"customer"}
                isLead={props.type === "Request" ? true : false}
                partner={type}
                pricelist={pricelist}
                index={0}
                openModals={customerModal}
                setOpenModals={setCustomerModal}
                modloading={customerModalloading}
                searchterm={searchterm}
                setsearchtem={setsearchtem}
                fetchPriceList={fetchPartners}
                setsavemodalopen={() => {}}
                setCreatenewmodal={setCustomercreatenewmodal}
                setCreatenewmodallead={setLeadcreatenewmodal}
                options={partnerOptions}
                setFieldValue={() => {}}
                setPartners={() => {}}
                data={data}
                setData={setData}
              />
              <Newpartnermodal
                type={"customer"}
                createnewmodal={customercreatenewmodal}
                setCreatenewmodal={setCustomercreatenewmodal}
                setFieldValue={() => {}}
                setPartners={() => {}}
                data={data}
                setData={setData}
              />
              <Newpartnermodal
                type={"lead"}
                createnewmodal={leadcreatenewmodal}
                setCreatenewmodal={setLeadcreatenewmodal}
                setFieldValue={() => {}}
                setPartners={() => {}}
                data={data}
                setData={setData}
              />
              <Selectmodal
                type={"supplier"}
                partner={type}
                pricelist={pricelist}
                index={1}
                openModals={supplierModal}
                setOpenModals={setSupplierModal}
                modloading={supplierModalloading}
                searchterm={searchterm}
                setsearchtem={setsearchtem}
                fetchPriceList={fetchPartners}
                setsavemodalopen={() => {}}
                setCreatenewmodal={setSuppliercreatenewmodal}
                options={partnerOptions}
                setFieldValue={() => {}}
                setPartners={() => {}}
                data={data}
                setData={setData}
              />
              <Newpartnermodal
                type={"supplier"}
                createnewmodal={suppliercreatenewmodal}
                setCreatenewmodal={setSuppliercreatenewmodal}
                setFieldValue={() => {}}
                setPartners={() => {}}
                data={data}
                setData={setData}
              />
              <Selectmodal
                type={"product"}
                partner={type}
                pricelist={pricelist}
                index={0}
                openModals={productModal}
                setOpenModals={setProductModal}
                modloading={productModalloading}
                searchterm={searchterm}
                setsearchtem={setsearchtem}
                fetchPriceList={fetchItems}
                setsavemodalopen={() => {}}
                setCreatenewmodal={setProductcreatenewmodal}
                options={itemOptions}
                setFieldValue={() => {}}
                setPartners={() => {}}
                data={data}
                setData={setData}
              />
              <Newitemmodal
                type={"product"}
                createnewmodal={productcreatenewmodal}
                setCreatenewmodal={setProductcreatenewmodal}
                setFieldValue={() => {}}
                setPartners={() => {}}
                data={data}
                setData={setData}
              />
              <Selectmodal
                type={"service"}
                partner={type}
                pricelist={pricelist}
                index={1}
                openModals={serviceModal}
                setOpenModals={setServiceModal}
                modloading={serviceModalloading}
                searchterm={searchterm}
                setsearchtem={setsearchtem}
                fetchPriceList={fetchItems}
                setsavemodalopen={() => {}}
                setCreatenewmodal={setServicecreatenewmodal}
                options={itemOptions}
                setFieldValue={() => {}}
                setPartners={() => {}}
                data={data}
                setData={setData}
              />
              <Newitemmodal
                type={"service"}
                createnewmodal={servicecreatenewmodal}
                setCreatenewmodal={setServicecreatenewmodal}
                setFieldValue={() => {}}
                setPartners={() => {}}
                data={data}
                setData={setData}
              />
              <Modal
                show={sendmailmodal}
                onHide={() => setsendmailmodal(!sendmailmodal)}
                centered
              >
                <Modal.Body className="text-left m-3">
                  <p className="mb-4 font-weight-bold">Send {props.type}</p>
                  <Formik
                    validationSchema={Yup.object().shape({
                      mail: Yup.string()
                        .email("Must be a valid email")
                        .required("Email is required"),
                      subject: Yup.string().required(),
                    })}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={async (elem) => {
                      const dat = {
                        invoice_id: Id,
                        invoice_email_recipient: elem.mail,
                        invoice_email_subject: elem.subject,
                        invoice_send_copy: sendmecopy,
                      };
                      // firebase.auth().onAuthStateChanged(async (user) => {
                      var user = firebase.auth().currentUser;

                      if (user) {
                        let tokenss = await user.getIdToken();
                        if (isTeamAccount) {
                          dat.team = true;
                        } else {
                          delete dat.team;
                        }
                        fetch("https://bestmate.us/api/invoices/send", {
                          method: "POST",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + tokenss,
                          },
                          body: JSON.stringify(dat),
                        })
                          .then((res) => {
                            console.log(res);
                            navigate(`/${small}s/`);
                            // navigate(
                            //   "/invoices/" + data.invoice_id,
                            //   {
                            //     state: { row: data, items: props.items },
                            //   }
                            // );
                            // setMailstatus("sent");
                          })
                          .catch((err) => {
                            console.log(err);
                            // setMailstatus("fail");
                          });
                      }
                      // });
                      console.log(dat);
                      // setOpenModals(!openModals);
                    }}
                    initialValues={{
                      mail: data.invoice_partner.partner_email,
                      subject: `You have new ${props.type} from ${
                        bdata && bdata.business_name ? bdata.business_name : ""
                      }`,
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      dirty,
                      setFieldValue,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Recipient"
                          className="mb-3"
                        >
                          <Form.Control
                            type="mail"
                            name="mail"
                            placeholder="text"
                            value={values.mail}
                            onChange={handleChange}
                            isInvalid={!!errors.mail}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.mail}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Subject"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="subject"
                            placeholder="text"
                            value={values.subject}
                            onChange={handleChange}
                            isInvalid={!!errors.subject}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.subject}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                        <div className="mt-3 d-flex justify-content-end">
                          <Button
                            variant="secondary"
                            onClick={() => setsendmailmodal(!sendmailmodal)}
                            className="m-2"
                          >
                            Cancel
                          </Button>{" "}
                          <Button
                            variant="primary"
                            className="m-2"
                            type="submit"
                          >
                            Send
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>
            </Card.Header>
          </Card>
          <AttachDocuments
            imageData={imageData}
            setImageData={setImageData}
            showModal={photoModal}
            setShowModal={setPhotoModal}
          />
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <Spinner animation="border" variant="dark" className="me-2" />
        </div>
      )}
    </>
  );
};
const EditInvoice = (props) => {
  const title = `Edit ${props.type}`;
  const [checked, setChecked] = useState(true);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const [checked2, setChecked2] = useState(false);
  const handleChange2 = (nextChecked) => {
    setChecked2(nextChecked);
  };
  const [checked3, setChecked3] = useState(false);
  const handleChange3 = (nextChecked) => {
    setChecked3(nextChecked);
  };
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Helmet title={title} />
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Update {props.type}</h1> */}
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor: "white",
            padding: "0rem 1.5rem 2rem 1.5rem",
          }}
          className="pt-2 pb-2 border-bottom "
        >
          <div>
            <h2
              className="mt-3 lead"
              style={{
                fontSize: "24px",
                // , fontWeight: 100
              }}
            >
              {title}
            </h2>
            <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
              <Breadcrumb.Item
                active
                onClick={() => navigate("/dashboard")}
                style={{ cursor: "pointer" }}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Work</Breadcrumb.Item>
              <Breadcrumb.Item
                active
                style={{ cursor: "pointer" }}
                onClick={() =>
                  // props.type === "Invoice"
                  //   ? navigate("/invoices")
                  //     ? props.type === "Estimate"
                  //     : navigate("/estimates")
                  navigate(`/${(props.type + "s").toLowerCase()}`)
                }
              >
                {props.type + "s"}
              </Breadcrumb.Item>
              <Breadcrumb.Item active className="font-weight-bold">
                {title}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "21px",
            }}
          >
            <Link to="/invoices/add">
              <div
                style={{
                  border: "1px #E7EAEC solid",
                  borderRadius: "3px",
                  padding: "0.375rem 0.75rem",
                  display: "flex",
                  justifyContent: "center",
                  color: "#676a6c",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: "inherit",
                  cursor: "pointer",
                  marginRight: "6px",
                }}
              >
                <Plus
                  size={15}
                  strokeWidth={3.5}
                  stroke="#676a6c"
                  style={{ marginRight: "2px" }}
                />
                Btn 1
              </div>
            </Link>
            <Link to="/invoices/add">
              <div
                style={{
                  border: "1px #E7EAEC solid",
                  borderRadius: "3px",
                  padding: "0.375rem 0.75rem",
                  display: "flex",
                  justifyContent: "center",
                  color: "#676a6c",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: "inherit",
                  cursor: "pointer",
                  marginRight: "6px",
                }}
              >
                <Plus
                  size={15}
                  strokeWidth={3.5}
                  stroke="#676a6c"
                  style={{ marginRight: "2px" }}
                />
                Btn 2
              </div>
            </Link>
            <Link to="/invoices/add">
              <div
                style={{
                  border: "1px #E7EAEC solid",
                  borderRadius: "3px",
                  padding: "0.375rem 0.75rem",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#4f83cb",
                  alignItems: "center",
                  textDecoration: "none",
                  fontSize: "inherit",
                  cursor: "pointer",
                  width: "100px",
                }}
              >
                <Plus
                  size={15}
                  strokeWidth={3.5}
                  stroke="white"
                  style={{ marginRight: "2px" }}
                />
                Btn 3
              </div>
            </Link>
          </div> */}
        </Card>
        <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
          <Row>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              className="p-2"
            >
              <Single type={props.type} />
            </Col>
            {/* <Col lg="3" className="p-2">
              <Card className="mb-2 border rounded-0">
                <Card.Body>
                  <Form.Group className="mb-3">
                    <Form.Label>Currency</Form.Label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      options={[
                        { value: "INR", label: "INR" },
                        { value: "USD", label: "USD" },
                        { value: "AUD", label: "AUD" },
                      ]}
                    />
                  </Form.Group>
                  <hr style={{ margin: "30px 0px" }} />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>Payment Methord</p>
                    <Switch
                      onChange={handleChange}
                      checked={checked}
                      className="react-switch"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onColor="#2693e6"
                      handleDiameter={18}
                      height={20}
                      width={40}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>Late Fees</p>
                    <Switch
                      onChange={handleChange2}
                      checked={checked2}
                      className="react-switch"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onColor="#2693e6"
                      handleDiameter={18}
                      height={20}
                      width={40}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>Notes</p>
                    <Switch
                      onChange={handleChange3}
                      checked={checked3}
                      className="react-switch"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onColor="#2693e6"
                      handleDiameter={18}
                      height={20}
                      width={40}
                    />
                  </div>
                  <hr style={{ margin: "30px 0px" }} />
                  <div>
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: "100%" }}
                    >
                      <Button
                        className="m-2 p-2"
                        variant="light"
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                        }}
                      >
                        Preview
                      </Button>
                      <Button
                        className="m-2 p-2"
                        variant="light"
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                        }}
                      >
                        Download
                      </Button>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <Button
                        className="m-2 p-2"
                        // variant="light"
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                        }}
                      >
                        Send Invoice
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default EditInvoice;
