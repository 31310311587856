import React, { useCallback, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTable, useGlobalFilter, useFilters } from "react-table";
// import "./table.css"
import { GlobalFilter } from "./GlobalFilter";
// import { ColumnFilter } from "./ColumnFilter";
import { useEffect, useState } from "react";
import { PlusSquare, ChevronRight, Plus } from "react-feather";
import useAuth from "../../hooks/useAuth";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Form,
  Container,
  Table,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  Spinner,
  Breadcrumb,
  ButtonGroup,
} from "react-bootstrap";
import empty_transaction from "../../assets/img/icons/empty_transaction.png";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";
import moment from "moment";
import mixpanel from "mixpanel-browser";
import { useCurrencyFormating } from "../../utils/useCurrencyFormating";
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const FilteringTable = (props) => {
  const navigate = useNavigate();
  let usercurrency = localStorage.getItem("currency") || "USD";
  const workingdata = props.productData;
  const { currencyFormater } = useCurrencyFormating();

  function truncateTextByWordCount(text, wordLimit) {
    console.log(text.length);
    if (text.length > wordLimit) {
      return text.slice(0, wordLimit) + "...";
    } else {
      console.log(text);
      return text;
    }
  }
  const COLUMNS = [
    {
      Header: "Invoice #",
      width: 100,
      accessor: (elem) =>
        "Invoice No: " +
        (elem &&
          elem.transaction_invoice &&
          elem.transaction_invoice.invoice_number &&
          !isNaN(elem.transaction_invoice.invoice_number))
          ? truncateTextByWordCount(
              Number(elem.transaction_invoice.invoice_number),
              15
            )
          : "",
      // accessor: (elem) =>
      //   elem.transaction_invoice
      //     ? "Invoice No: " + Number(elem.transaction_invoice.invoice_number)
      //     : "No Invoice",
    },
    {
      Header: "Transaction Date",
      accessor: (elem) =>
        `${moment(new Date(elem.transaction_created_at)).format(
          "DD MMM YYYY"
        )}`,
    },
    {
      Header: "Customer / Supplier Name",
      width: 250,
      accessor: (elem) =>
        elem && elem.transaction_partner ? (
          <>
            {truncateTextByWordCount(
              `${elem.transaction_partner.partner_first_name} ${elem.transaction_partner.partner_last_name}`,
              20
            )}
          </>
        ) : (
          ""
        ),
    },

    {
      Header: () => {
        return <div style={{ textAlign: "right" }}>Amount</div>;
      },
      accessor: "transaction_total_amount",
      Cell: ({ value, row }) => {
        const num = Number(value);
        // console.log(num, value, row);
        if (Number.isNaN(num)) {
          return <div style={{ textAlign: "right" }}>{"-"}</div>;
        } else {
          return (
            <div style={{ textAlign: "right" }}>
              {/* {Math.abs(num).toLocaleString("en-US", {
                style: "currency",
                currency:
                  row.original && row.original.transaction_currency_code
                    ? row.original.transaction_currency_code
                    : usercurrency,
              })} */}
              {truncateTextByWordCount(
                currencyFormater(
                  Math.abs(num),
                  row.original && row.original.transaction_currency_code
                    ? row.original.transaction_currency_code
                    : usercurrency
                ),
                30
              )}
            </div>
          );
        }
      },
    },

    {
      Header: () => {
        return <div style={{ textAlign: "center" }}>Reference Invoice</div>;
      },
      accessor: `reference`,
      Cell: ({ row }) => {
        if (
          row &&
          row.original &&
          row.original.transaction_invoice &&
          row.original.transaction_invoice.invoice_workflow_type
        ) {
          return (
            <div style={{ textAlign: "center" }}>
              {capitalizeFirstLetter(
                row.original.transaction_invoice.invoice_workflow_type.toLowerCase() ===
                  "order"
                  ? "Booking"
                  : row.original.transaction_invoice.invoice_workflow_type.toLowerCase()
              )}{" "}
              No.{" "}
              {truncateTextByWordCount(
                row.original.transaction_invoice?.invoice_number,
                15
              )}
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      Header: () => {
        return <div style={{ textAlign: "center" }}>Status</div>;
      },
      accessor: "status",
      Cell: ({ row }) => {
        // console.log(row);
        // if (
        //   row &&
        //   row.original &&
        //   row.original.transaction_partner &&
        //   (row.original.transaction_partner.partner_type === "CUSTOMER" ||
        //     row.original.transaction_partner.partner_type === "LEAD")
        // ) {
        //   if (row.original.transaction_total_amount >= 0) {
        //     return (
        //       <div
        //         style={{
        //           width: "100%",
        //           display: "flex",
        //           justifyContent: "center",
        //         }}
        //       >
        //         <div
        //           style={{
        //             backgroundColor: "#1AB395",
        //             padding: "4px",
        //             color: "white",
        //             borderRadius: "5px",
        //             fontSize: "10px",
        //             fontWeight: "bold",
        //             width: 55,
        //             textAlign: "center",
        //           }}
        //         >
        //           Received
        //         </div>
        //       </div>
        //     );
        //   }
        //   return (
        //     <div
        //       style={{
        //         width: "100%",
        //         display: "flex",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <div
        //         style={{
        //           backgroundColor: "#DE5474",
        //           padding: "4px",
        //           color: "white",
        //           borderRadius: "5px",
        //           fontSize: "10px",
        //           fontWeight: "bold",
        //           width: 55,
        //           textAlign: "center",
        //         }}
        //       >
        //         Paid
        //       </div>
        //     </div>
        //   );
        // } else if (
        //   row &&
        //   row.original &&
        //   row.original.transaction_partner &&
        //   row.original.transaction_partner.partner_type === "SUPPLIER"
        // ) {
        //   if (row.original.transaction_total_amount >= 0) {
        //     return (
        //       <div
        //         style={{
        //           width: "100%",
        //           display: "flex",
        //           justifyContent: "center",
        //         }}
        //       >
        //         <div
        //           style={{
        //             backgroundColor: "#DE5474",
        //             padding: "4px",
        //             color: "white",
        //             borderRadius: "5px",
        //             fontSize: "10px",
        //             fontWeight: "bold",
        //             width: 55,
        //             textAlign: "center",
        //           }}
        //         >
        //           Paid
        //         </div>
        //       </div>
        //     );
        //   }
        //   return (
        //     <div
        //       style={{
        //         width: "100%",
        //         display: "flex",
        //         justifyContent: "center",
        //       }}
        //     >
        //       <div
        //         style={{
        //           backgroundColor: "#1AB395",
        //           padding: "4px",
        //           color: "white",
        //           borderRadius: "5px",
        //           fontSize: "10px",
        //           fontWeight: "bold",
        //           width: 55,
        //           textAlign: "center",
        //         }}
        //       >
        //         Received
        //       </div>
        //     </div>
        //   );
        // } else {
        //   return <span className="text-success"></span>;
        // }
        if (
          row &&
          row.original &&
          row.original.transaction_partner &&
          (row.original.transaction_partner.partner_type === "CUSTOMER" ||
            row.original.transaction_partner.partner_type === "LEAD")
        ) {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#1AB395",
                  padding: "4px",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  // width: 55,
                  textAlign: "center",
                }}
              >
                Received
              </div>
            </div>
          );
        } else if (
          row &&
          row.original &&
          row.original.transaction_partner &&
          row.original.transaction_partner.partner_type === "SUPPLIER"
        ) {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "#DE5474",
                  padding: "4px",
                  color: "white",
                  borderRadius: "5px",
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: 55,
                  textAlign: "center",
                }}
              >
                Paid
              </div>
            </div>
          );
        } else {
          return <span className="text-success"></span>;
        }
      },
    },
    {
      Header: () => {
        return <div style={{ textAlign: "center" }}>Payment Mode</div>;
      },
      accessor: `transaction_payment_method`,
      Cell: ({ value, row }) => {
        return <div style={{ textAlign: "center" }}>{value ? value : "-"}</div>;
      },
    },
    // {
    //   Header: "Time",
    //   accessor: "transaction_created_at",
    //   Cell: ({ value }) => {
    //     let diff = new Date() - value;
    //     let yr = parseInt(getDifferenceInYears(diff));
    //     let month = parseInt(getDifferenceInMonths(diff));
    //     let week = parseInt(getDifferenceInWeeks(diff));
    //     let day = parseInt(getDifferenceInDays(diff));
    //     let hr = parseInt(getDifferenceInHours(diff));
    //     let min = parseInt(getDifferenceInMinutes(diff));
    //     // console.log(diff, yr, month, week, day, hr, min);
    //     if (yr != 0) {
    //       return `${Math.abs(yr)} ${
    //         yr > 1 ? "years ago" : yr < 0 ? "years from now" : "year ago"
    //       }`;
    //     } else {
    //       if (month != 0) {
    //         return `${Math.abs(month)} ${
    //           month > 1
    //             ? "months ago"
    //             : month < 0
    //             ? "months from now"
    //             : "month ago"
    //         }`;
    //       } else {
    //         if (week != 0) {
    //           return `${Math.abs(week)} ${
    //             week > 1
    //               ? "weeks ago"
    //               : week < 0
    //               ? "weeks from now"
    //               : "week ago"
    //           }`;
    //         } else {
    //           if (day != 0) {
    //             return `${Math.abs(day)} ${
    //               day > 1 ? "days ago" : day < 0 ? "days from now" : "day ago"
    //             }`;
    //           } else {
    //             if (hr != 0) {
    //               return `${Math.abs(hr)} ${
    //                 hr > 1
    //                   ? "hours ago"
    //                   : hr < 0
    //                   ? "hours from now"
    //                   : "hour ago"
    //               }`;
    //             } else {
    //               if (min != 0) {
    //                 return `${Math.abs(min)} ${
    //                   min > 1
    //                     ? "minutes ago"
    //                     : min < 0
    //                     ? "minutes from now"
    //                     : "minute ago"
    //                 }`;
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //     return "moments ago";
    //   },
    // },
    // {
    //   Header: () => (
    //     <div
    //       style={{
    //         textAlign: "center",
    //       }}
    //     >
    //       Actions
    //     </div>
    //   ),
    //   width: 80,
    //   accessor: "actions",
    //   disableSortBy: true,
    //   Cell: ({ row }) => {
    //     // const num = parseInt(value);
    //     return (
    //       <div style={{ display: "flex", justifyContent: "end" }}>
    //         <ButtonGroup>
    //           {/* <Button
    //             size="sm"
    //             variant="light"
    //             style={{ backgroundColor: "white" }}
    //             className="border"
    //             onClick={(e) => {
    //               e.stopPropagation();
    //               // navigate("edit-item/" + row.original.item_id, {
    //               //   state: { row: row.original, items: props.items },
    //               // });
    //             }}
    //           >
    //             View
    //           </Button> */}

    //           <Button
    //             size="sm"
    //             variant="light"
    //             style={{ backgroundColor: "white" }}
    //             className="border"
    //             onClick={(e) => {
    //               e.stopPropagation();
    //               mixpanel.track("Edit (Transaction List Web)");

    //               // navigate("edit-item/" + row.original.item_id, {
    //               //   state: { row: row.original, items: props.items },
    //               // });
    //             }}
    //           >
    //             Edit
    //           </Button>
    //           {/* <Button
    //             size="sm"
    //             variant="light"
    //             style={{ backgroundColor: "white" }}
    //             className="border"
    //             onClick={(e) => {
    //               e.stopPropagation();
    //               // setDeletedata({
    //               //   item_id: row.original.item_id,
    //               //   item_name: row.original.item_name,
    //               // });
    //               // setOpenModals(true);
    //             }}
    //           >
    //             Delete
    //           </Button> */}
    //         </ButtonGroup>
    //       </div>
    //     );
    //   },
    // },
  ];
  // all functions are used in calculating the time passed after creating the transaction
  function getDifferenceInYears(diffInMs) {
    return diffInMs / (1000 * 60 * 60 * 24 * 365.25);
  }

  function getDifferenceInMonths(diffInMs) {
    return diffInMs / (1000 * 60 * 60 * 24 * 7 * 4);
  }

  function getDifferenceInWeeks(diffInMs) {
    return diffInMs / (1000 * 60 * 60 * 24 * 7);
  }

  function getDifferenceInDays(diffInMs) {
    return diffInMs / (1000 * 60 * 60 * 24);
  }

  function getDifferenceInHours(diffInMs) {
    return diffInMs / (1000 * 60 * 60);
  }

  function getDifferenceInMinutes(diffInMs) {
    return diffInMs / (1000 * 60);
  }
  console.log(props.TransactionData);
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => workingdata);

  // const defaultColumn = useMemo(() => {
  //   return {
  //     Filter: ColumnFilter,
  //   };
  // }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  // const { globalFilter } = state;

  return (
    <>
      {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <PlusSquare
          stroke="white"
          onClick={() => navigate("/transactionadd")}
          fill="#316284"
          size={35}
        />
      </div> */}
      <InfiniteScroll
        dataLength={rows.length}
        next={
          !props.searchtable
            ? () => props.update(props.token, props.id)
            : () =>
                // props.setSearchdata([]);
                props.update(
                  props.searchterm,
                  props.searchtotal,
                  props.token,
                  props.id,
                  props.signal
                )
        }
        hasMore={props.hasmore}
        style={{ overflowX: "scroll", overflowY: "hidden" }}
        loader={
          <div className="d-flex justify-content-center">
            <Spinner
              animation="border"
              variant="dark"
              size="sm"
              className="me-2"
            />
          </div>
        }
      >
        <Table
          striped
          bordered
          size="sm"
          {...getTableProps()}
          style={{ color: "grey" }}
        >
          <thead style={{ color: "black", backgroundColor: "#F2f2f4" }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getFooterGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ borderTop: 0 }}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{ width: cell.column.width, color: "black" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </InfiniteScroll>
      {workingdata.length === 0 ? (
        props.searchloading ? (
          <></>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center pt-3 flex-column "
            id={"Emptybox"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              mixpanel.track("Add Transaction (Transaction List Web)");
              navigate("/transactionadd");
            }}
          >
            {!props.searchtable ? (
              <p
                className="mt-2"
                style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
              >
                Start recording your transactions
              </p>
            ) : (
              <p
                className="mt-2"
                style={{ fontSize: 20, fontWeight: "bold", color: "black" }}
              >
                No Result Found. Click to Add Transaction
              </p>
            )}
            <img
              src={empty_transaction}
              style={{ height: "80px", marginTop: 30, marginBottom: 20 }}
              alt="empty_transaction"
            ></img>
            <Button
              onClick={() => {
                mixpanel.track("Add Transaction (Transaction List Web)");
                navigate("/transactionadd");
              }}
              style={{ padding: 10, fontWeight: "bold" }}
            >
              Add Transaction
            </Button>
          </div>
        )
      ) : (
        <></>
      )}
    </>
  );
};

const Transaction = () => {
  const controller = new AbortController();
  const signal = controller.signal;

  const [token, settoken] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [id, setId] = useState(0);
  const [hasmore, sethasmore] = useState(true);

  const navigate = useNavigate();
  const [searchtable, showSearchtable] = useState(false);
  const [searchdata, setSearchdata] = useState([]);
  const [searchterm, setSearchterm] = useState("");
  const [searchtotal, setSearchtotal] = useState("");
  const [searchhasmore, setSearchhasmore] = useState(true);
  const [searchid, setSearchid] = useState(0);
  const [searchloading, setsearchloading] = useState(true);

  const getData = (token, id) => {
    fetch("https://bestmate.us/api/transactions/findAllPaginated/" + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setData((old) => [...old, ...result]);
          setLoading(false);
          if (result.length === 0 || result.length <= 5) {
            sethasmore(false);
          } else if (result.length > 5) {
            setId(result[result.length - 1].transaction_modified_at);
          }
          //   sethasmore(false);
          // } else if (result.length > 0) {

          //   setId(result[result.length - 1].transaction_modified_at);
          // }

          // if (result.length > 0) {
          //   setId(result[result.length - 1].transaction_id);
          // }
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };

  const searchData = async (
    searchterm,
    searchtotal,
    token,
    searchid,
    signal
  ) => {
    fetch(
      `https://bestmate.us/api/transactions/searchMix/${searchid}?name=${searchterm}&total=${searchtotal}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        signal,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        // if (result.length === 0) {
        //   setSearchhasmore(false);
        // } else if (result.length > 0) {
        //   setSearchdata((old) => [...old, ...result]);
        //   setsearchloading(false);
        //   if (result.length === 0 || result.length <= 5) {
        //     setSearchhasmore(false);
        //   } else if (result.length > 5) {
        //     // setSearchid(result[result.length - 1].invoice_modified_at);
        //     setSearchid(result[result.length - 1].transaction_modified_at);
        //   }
        // }
        setSearchdata((old) => [...old, ...result]);
        setsearchloading(false);
        if (result.length === 0 || result.length <= 5) {
          setSearchhasmore(false);
        } else if (result.length > 5) {
          setSearchid(result[result.length - 1].transaction_modified_at);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setsearchloading(true);
    setSearchdata([]);
    setSearchid(0);
    showSearchtable(false);
    if (searchterm !== "" || searchtotal !== "") {
      showSearchtable(true);
      searchData(searchterm, searchtotal, token, searchid, signal);
    }
  }, [searchterm, searchtotal]);

  useEffect(async () => {
    mixpanel.track(`On Transaction`);
    var user = firebase.auth().currentUser;

    // firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      let tokenss = await user.getIdToken();
      console.log(tokenss);

      settoken(tokenss);
      getData(tokenss, id);
      // getItems(tokenss);
    }
    // });
  }, []);

  // const fetchData = () =>
  // useEffect(() => {
  //   firebase.auth().onAuthStateChanged(async (user) => {
  //     if (user) {
  //       let tokenss = await user.getIdToken();
  //       settoken(tokenss);
  //       getData(tokenss, id);
  //     }
  //   });
  // }, []);
  const deb0 = useCallback(
    debounce((value) => {
      mixpanel.track("Search Transaction Name (Transaction List Web)", {
        searhcterm: value,
      });
      setSearchterm(value);
    }, 1000),
    []
  );
  const handleSearch0 = (value) => {
    deb0(value);
  };

  const deb1 = useCallback(
    debounce((value) => {
      mixpanel.track("Search Transaction Total (Transaction List Web)", {
        searhcterm: value,
      });
      setSearchtotal(value);
    }, 1000),
    []
  );
  const handleSearch1 = (value) => {
    deb1(value);
  };

  // const deb2 = useCallback(
  //   debounce((value) => setSearchsell(value), 500),
  //   []
  // );
  // const handleSearch2 = (value) => {
  //   deb2(value);
  // };

  // const deb3 = useCallback(
  //   debounce((value) => setSearchqty(value), 500),
  //   []
  // );
  // const handleSearch3 = (value) => {
  //   deb3(value);
  // };

  return (
    <React.Fragment>
      <Helmet title="Transaction" />
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Transaction</h1> */}
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor: "white",
            padding: "0rem 1.5rem 2rem 1.5rem",
          }}
          className="pt-2 pb-2 border-bottom "
        >
          <div>
            <h2
              className="mt-3 lead"
              style={{
                fontSize: "24px",
                // , fontWeight: 100
              }}
            >
              Transactions
            </h2>
            <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
              <Breadcrumb.Item
                active
                onClick={() => navigate("/dashboard")}
                style={{ cursor: "pointer" }}
              >
                Home
              </Breadcrumb.Item>
              {/* <Breadcrumb.Item active>Invoice</Breadcrumb.Item> */}
              <Breadcrumb.Item active className="font-weight-bold">
                Transactions
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div style={{ marginRight: "21px" }}>
            <Link
              to="/transactionadd"
              onClick={() =>
                mixpanel.track("Add Transaction (Transaction List Web)")
              }
            >
              {/* <PlusSquare stroke="white" fill="#316284" size={35} /> */}
              <div
                style={{
                  // backgroundColor: "#3367d6",
                  backgroundColor: "#3f80ea",
                  borderRadius: "5px",
                  padding: "8px 15px",
                  // padding: "0.375rem 0.75rem",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  // color: "#676a6c",
                  alignItems: "center",
                  textDecoration: "none",
                  // fontSize: 12,
                  cursor: "pointer",
                }}
              >
                <Plus
                  size={15}
                  strokeWidth={3.5}
                  stroke="white"
                  style={{ marginRight: "5px" }}
                />
                Add Transaction
              </div>
            </Link>
          </div>
        </Card>
        <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
          <Row>
            <Col>
              <Card className="mb-2 border rounded-0">
                <Card.Header>
                  <Form>
                    <Row>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label> Name</Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid(0);
                              setSearchhasmore(true);
                              handleSearch0(e.target.value);
                            }}
                            placeholder="Name"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label> Total</Form.Label>
                          <Form.Control
                            type="text"
                            onChange={(e) => {
                              controller.abort();
                              setsearchloading(true);
                              setSearchdata([]);
                              setSearchid(0);
                              setSearchhasmore(true);
                              handleSearch1(e.target.value);

                              // handleSearch(...Array(3), e.target.value);
                              // setSearchterm(e.target.value);
                            }}
                            // name="email"
                            placeholder=" Total"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        {/* <Form.Group className="mb-3">
                          <Form.Label>Invoice Number</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Invoice Number"
                          />
                        </Form.Group> */}
                      </Col>
                      <Col md={4}>
                        {/* <Form.Group className="mb-3">
                          <Form.Label>Status</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Status"
                          />
                        </Form.Group> */}
                      </Col>
                    </Row>
                  </Form>
                </Card.Header>
              </Card>
              <Card className="mb-2 border rounded-0">
                <Card.Header>
                  {loading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "200px" }}
                    >
                      <Spinner
                        animation="border"
                        variant="dark"
                        className="me-2"
                      />
                    </div>
                  ) : (
                    <>
                      {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="mb-2 d-flex justify-content-between">
                          <div className="justify-content-start">
                            <strong>Search:</strong>{" "}
                            <input
                              size={20}
                              // value={searchterm}
                              onChange={
                                (e) => {
                                  setsearchloading(true);
                                  setSearchdata([]);
                                  setSearchid(0);
                                  setSearchhasmore(true);
                                  handleSearch(e.target.value);
                                  // setSearchterm(e.target.value);
                                }

                                // onChange(e.target.value);
                                // (e) => console.log(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <Link to="/transactionadd">
                          <PlusSquare stroke="white" fill="#316284" size={35} />
                        </Link>
                      </div> */}
                      {!searchtable ? (
                        <FilteringTable
                          productData={data}
                          token={token}
                          searchterm={searchterm}
                          searchtable={searchtable}
                          update={getData}
                          hasmore={hasmore}
                          id={id}
                        />
                      ) : (
                        <FilteringTable
                          productData={searchdata}
                          token={token}
                          searchterm={searchterm}
                          searchtotal={searchtotal}
                          searchtable={searchtable}
                          update={searchData}
                          hasmore={searchhasmore}
                          id={searchid}
                          searchloading={searchloading}
                          signal={signal}
                        />
                      )}
                    </>
                    // <FilteringTable TransactionData={data} />
                  )}
                </Card.Header>
                {/* <Card.Body>&nbsp;</Card.Body> */}
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Transaction;
