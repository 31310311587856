import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumb,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import mixpanel from "mixpanel-browser";

import Page404Authenticated from "../auth/Page404authenticated";
import icon from "../../assets/img/illustrations/icon.png";
import * as Yup from "yup";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import {
  Briefcase,
  Calendar,
  Clipboard,
  Edit2,
  Trello,
  Mail,
  MapPin,
  Phone,
  Send,
  X,
  Archive,
  Codesandbox,
} from "react-feather";
import useAuth from "../../hooks/useAuth";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { Formik } from "formik";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import useSidebar from "../../hooks/useSidebar";
import NotyfContext from "../../contexts/NotyfContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import InvoiceTemplate from "../../components/InvoiceTemplate";
import { logError } from "../../utils/useErrorlogging";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const addressFormating = (value) => {
  if (!value || Object.keys(value).length === 0) {
    return "-";
  }
  const { street, city, state, zip_code, country } = value;
  console.log(street, city, state, zip_code, country);

  const lineone = [street, city].filter((field) => field).join(", ");
  const linetwo = [state, zip_code].filter((field) => field).join(" - ");
  const linethree = [country].filter((field) => field).join("");

  console.log(lineone, linetwo, linethree);
  if (!lineone && !linetwo && !linethree) {
    return "-";
  }

  // return (
  //   <>
  //     {lineone && (
  //       <>
  //         <p className="m-0">{lineone} </p>
  //       </>
  //     )}
  //     {linetwo && (
  //       <>
  //         <p className="m-0">{linetwo} </p>
  //       </>
  //     )}
  //     {linethree && (
  //       <>
  //         <p className="m-0">{linethree} </p>
  //       </>
  //     )}
  //   </>
  // );
  return `${lineone ? lineone : ""} ${lineone && linetwo && "\n"}${
    linetwo ? linetwo : ""
  }${linetwo && linethree && "\n"}${linethree ? linethree : ""}`;
};
const schema = Yup.object().shape({
  mail: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  subject: Yup.string().required(),
});

const RequestDetail = () => {
  let usercurrency = localStorage.getItem("currency") || "USD";
  const { bdata } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const [mailstatus, setMailstatus] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [openModals, setOpenModals] = useState(false);
  const [opendeleteModals, setOpendeleteModals] = useState(false);
  const [openarchiveModal, setOpenArchiveModal] = useState(false);
  const [rowdata, setRowdata] = useState({});
  const [token, settoken] = useState("");
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("CUSTOMER");
  const [multiplier, setMultiplier] = useState(1);
  const [invoiceerror, setinvoiceerror] = useState(false);
  const [paidtotal, setpaidtotal] = useState(0);
  const [markaspaiddialog, setmarkaspaiddialog] = useState(false);
  const [acceptdialog, setacceptdialog] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [servAddress, setServAddress] = useState("");
  const [workflow, setWorkflow] = useState({ type: "Invoice" });
  const [notesmodal, setNotesModal] = useState(false);
  const [intnotesmodal, setIntNotesModal] = useState(false);
  const [customernote, setCurtomernote] = useState("");
  const [internalnote, setInternalnote] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [disabledelete, setDisableDelete] = useState(false);
  const [disableArchive, setDisableArchive] = useState(false);
  const { isTeamAccount, userPermission } = useAuth();
  let invoicePermission =
    Object.keys(userPermission).includes("invoice") &&
    Object.keys(userPermission.invoice).includes("permission_key")
      ? userPermission.invoice.permission_key
      : "";
  let estiamtePermission =
    Object.keys(userPermission).includes("estimate") &&
    Object.keys(userPermission.estimate).includes("permission_key")
      ? userPermission.estimate.permission_key
      : "";
  let bookingPermission =
    Object.keys(userPermission).includes("booking") &&
    Object.keys(userPermission.booking).includes("permission_key")
      ? userPermission.booking.permission_key
      : "";
  let requestPermission =
    Object.keys(userPermission).includes("request") &&
    Object.keys(userPermission.request).includes("permission_key")
      ? userPermission.request.permission_key
      : "";
  console.log(rowdata);
  const getRequiredData = (token) => {
    const timeZoneOffset = -(new Date().getTimezoneOffset() * 60);

    fetch(
      `https://bestmate.us/api/invoices/get/${id}?timeZoneOffset=${timeZoneOffset}` +
        `${isTeamAccount ? "&team=true" : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          setinvoiceerror(true);
          setLoading(false);
          setFetchError(true);
          throw new Error(`HTTP error, status code ${res.status}`);
        }

        return res.json();
      })
      .then((result) => {
        console.log("new asdfasdf");
        console.log(result);
        setRowdata(result);
        setpaidtotal(result.invoice_grand_total);
        setInternalnote(result.invoice_internal_notes);
        setCurtomernote(result.invoice_notes);
        console.log(location);
        if (location.state.action === "send") {
          setOpenModals(true);
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  };

  useEffect(() => {
    if (opendeleteModals) {
      setDisableDelete(false);
    }
  }, [opendeleteModals]);

  useEffect(async () => {
    mixpanel.track(`On Request Detail (Web)`);
    setLoading(true);
    setFetchError(false);
    setSubtotal(0);
    var user = firebase.auth().currentUser;
    if (user) {
      let tokenss = await user.getIdToken();
      settoken(tokenss);

      console.log("second");
      getRequiredData(tokenss);
    }
  }, [id]);

  useEffect(() => {
    if (rowdata && Object.keys(rowdata).length !== 0) {
      setinvoiceerror(false);
      setWorkflow({
        type: capitalizeFirstLetter(
          rowdata.invoice_workflow_type.toLowerCase() === "order"
            ? "request"
            : rowdata.invoice_workflow_type.toLowerCase()
        ),
      });
      setType(
        rowdata.invoice_partner.partner_type
          ? rowdata.invoice_partner.partner_type
          : "CUSTOMER"
      );
      const mult =
        rowdata !== undefined && Object.keys(rowdata).length !== 0
          ? rowdata.invoice_partner.partner_price_list !== undefined &&
            Object.keys(rowdata.invoice_partner.partner_price_list).length !== 0
            ? rowdata.invoice_partner.partner_type === "SUPPLIER"
              ? 1
              : Number(
                  rowdata.invoice_partner.partner_price_list
                    .price_list_multiplier
                )
            : 1
          : 1;
      setMultiplier(mult);
      rowdata.invoice_order.forEach((element) => {
        setSubtotal(
          (prev) =>
            prev +
            element.item_quantity *
              (rowdata.invoice_partner.partner_type === "SUPPLIER"
                ? element.item_cost_price
                  ? element.item_cost_price
                  : 0
                : element.item_selling_price
                ? element.item_selling_price
                : 0) *
              mult
        );
      });
      const add = addressFormating(
        rowdata.invoice_partner.partner_shipping_address
      );
      setServAddress(add);
      console.log(add);
      setLoading(false);
    } else if (rowdata === undefined) {
      setinvoiceerror(true);
      setLoading(false);
    }
    return () => setSubtotal(0);
  }, [rowdata]);

  console.log(rowdata);
  console.log();

  const printableArea = useRef();
  console.log(rowdata, multiplier);

  const submitData = async (dat) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      if (isTeamAccount) {
        dat.team = true;
      } else {
        delete dat.team;
      }
      fetch("https://bestmate.us/api/invoices/send", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(dat),
      })
        .then(async (res) => {
          console.log(res);
          // const res2 = await res.json();
          // console.log(res2);
          // setMailstatus("sent");
          notyf.open({
            type: isOpen ? "home" : "full",
            message: `${workflow.type} email sent to ${dat.invoice_email_recipient}`,
            ripple: "true",
            dismissible: "true",
          });
        })
        .catch((err) => {
          console.log(err);
          // setMailstatus("fail");
        });
    }
  };
  const submitHandler = (elem) => {
    const dat = {
      invoice_id: id,
      invoice_email_recipient: elem.mail,
      invoice_email_subject: elem.subject,
      invoice_send_copy: elem.sendcopytome,
    };
    mixpanel.track(`Send >>> Send Modal (Request Detail Web)`, {
      ...dat,
    });
    submitData(dat);
    console.log(dat);
    setOpenModals(!openModals);
  };

  const deleteHandler = async (type) => {
    setDisableDelete(true);
    var user = firebase.auth().currentUser;
    mixpanel.track(`Delete >>> Send Modal (Request Detail Web)`, {
      id: id,
    });
    if (user) {
      let tokenss = await user.getIdToken();
      console.log(rowdata.invoice_id);
      let deleteData = {
        invoice_id: id,
        // invoice_id: "BmuiRP5cpYWsOUluqHbj",
      };
      if (isTeamAccount) {
        deleteData.team = true;
      } else {
        delete deleteData.team;
      }
      fetch("https://bestmate.us/api/invoices/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(deleteData),
      })
        .then((res) => {
          // navigate(-1);
          if (res.status >= 200 && res.status <= 300) {
            // if (res.status >= 200 && res.status <= 300) {
            notyf.open({
              type: isOpen ? "home" : "full",
              message: `${type} deleted successfully`,
              ripple: "true",
              dismissible: "true",
            });
            navigate(`/${workflow.type.toLowerCase()}s`);
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: "Something went wrong.",
              ripple: "true",
              dismissible: "true",
            });
            // mixpanel.track("Fetch Error (Request Detail Web)", {
            //   error: "invoice delete api",
            // });
            logError({
              error: "fetch error",
              api: "invoice delete api",
              component: `Request Detail Web`,
            });
          }
        })
        .catch((err) => {
          // mixpanel.track("Error (Request Detail Web)", {
          //   error: err.message,
          //   stack: err.stack,
          // });
          logError({
            error: err.message,
            stack: err.stack,
            component: `Request Detail Web`,
          });
          console.log(err);
        });
    }
  };
  const title = `${workflow.type} Detail`;
  console.log(rowdata);
  return (
    <>
      {!loading ? (
        <>
          {!invoiceerror ? (
            <>
              <Helmet title={title} />
              <Container fluid className="p-0 ">
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // backgroundColor: "white",
                    padding: "0rem 1.5rem 2rem 1.5rem",
                  }}
                  className="pt-2 pb-2 border-bottom "
                >
                  <div>
                    <h2
                      className="mt-3 lead"
                      style={{
                        fontSize: "24px",
                        // , fontWeight: 100
                      }}
                    >
                      {/* Invoices */}
                      {title + "s"}
                    </h2>
                    <Breadcrumb
                      className="mb-0 lead"
                      style={{ fontSize: "13px" }}
                    >
                      <Breadcrumb.Item
                        active
                        onClick={() => navigate("/dashboard")}
                        style={{ cursor: "pointer" }}
                      >
                        Home
                      </Breadcrumb.Item>
                      <Breadcrumb.Item active>Work</Breadcrumb.Item>
                      <Breadcrumb.Item
                        active
                        onClick={() =>
                          navigate(`/${workflow.type.toLowerCase()}s`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {workflow.type}s
                      </Breadcrumb.Item>
                      <Breadcrumb.Item active className="font-weight-bold">
                        {title + "s"}
                      </Breadcrumb.Item>
                    </Breadcrumb>
                    <div style={{ display: "flex" }}>
                      {/* <>
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            fontSize: 12,
                            // marginRight: 10,
                          }}
                        >
                          {`Created from: `}
                        </p>
                        {rowdata.invoice_parent &&
                        rowdata.invoice_parent.length !== 0 ? (
                          <>
                            {rowdata.invoice_parent.map((elem, index) => (
                              <p
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  fontSize: 12,
                                  marginLeft: 3,
                                  color: "#407fea",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  mixpanel.track(
                                    `Created from (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`,
                                    {
                                      id: elem.invoice_id,
                                    }
                                  );
                                  navigate(
                                    `/${
                                      elem.invoice_workflow_type === "ORDER"
                                        ? "request"
                                        : elem.invoice_workflow_type.toLowerCase()
                                    }s/${elem.invoice_id}`
                                  );
                                }}
                              >{` ${
                                elem.invoice_workflow_type === "ORDER"
                                  ? "Request"
                                  : capitalizeFirstLetter(
                                      elem.invoice_workflow_type.toLowerCase()
                                    )
                              } #${elem.invoice_number}${
                                rowdata.invoice_parent.length - 1 === index
                                  ? ""
                                  : ","
                              }  `}</p>
                            ))}
                          </>
                        ) : (
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              fontSize: 12,
                              marginLeft: 3,
                              color: "#407fea",
                              cursor: "pointer",
                              marginRight: 13,
                            }}
                          >
                            No Estimate
                          </p>
                        )}
                      </> */}

                      <>
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            fontSize: 12,
                            marginLeft: rowdata.invoice_parent ? 10 : 0,
                          }}
                        >
                          {`Used for: `}
                        </p>
                        {rowdata.invoice_children &&
                        rowdata.invoice_children.length !== 0 ? (
                          <>
                            {rowdata.invoice_children.map((elem, index) => (
                              <p
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  fontSize: 12,
                                  marginLeft: 3,
                                  color: "#407fea",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  mixpanel.track(
                                    `Used for (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`,
                                    {
                                      id: elem.invoice_id,
                                    }
                                  );
                                  if (
                                    elem.invoice_workflow_type === "ORDER" &&
                                    bookingPermission
                                  ) {
                                    navigate(
                                      `/${
                                        elem.invoice_workflow_type === "ORDER"
                                          ? "booking"
                                          : elem.invoice_workflow_type.toLowerCase()
                                      }s/${elem.invoice_id}`
                                    );
                                  }
                                  if (
                                    elem.invoice_workflow_type === "REQUEST" &&
                                    requestPermission
                                  ) {
                                    navigate(
                                      `/${
                                        elem.invoice_workflow_type === "ORDER"
                                          ? "booking"
                                          : elem.invoice_workflow_type.toLowerCase()
                                      }s/${elem.invoice_id}`
                                    );
                                  }
                                  if (
                                    elem.invoice_workflow_type === "ESTIMATE" &&
                                    estiamtePermission
                                  ) {
                                    navigate(
                                      `/${
                                        elem.invoice_workflow_type === "ORDER"
                                          ? "booking"
                                          : elem.invoice_workflow_type.toLowerCase()
                                      }s/${elem.invoice_id}`
                                    );
                                  }
                                  if (
                                    elem.invoice_workflow_type === "INVOICE" &&
                                    invoicePermission
                                  ) {
                                    navigate(
                                      `/${
                                        elem.invoice_workflow_type === "ORDER"
                                          ? "booking"
                                          : elem.invoice_workflow_type.toLowerCase()
                                      }s/${elem.invoice_id}`
                                    );
                                  }
                                }}
                              >{` ${
                                elem.invoice_workflow_type === "ORDER"
                                  ? "Booking"
                                  : capitalizeFirstLetter(
                                      elem.invoice_workflow_type.toLowerCase()
                                    )
                              } #${elem.invoice_number}${
                                rowdata.invoice_children.length - 1 === index
                                  ? ""
                                  : ","
                              } `}</p>
                            ))}
                          </>
                        ) : (
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              fontSize: 12,
                              marginLeft: 3,
                              color: "#407fea",
                              cursor: "pointer",
                            }}
                          >
                            Not Used
                          </p>
                        )}
                      </>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: "21px",
                    }}
                  >
                    {workflow.type !== "Invoice" &&
                    (bookingPermission.includes("ADD") ||
                      invoicePermission.includes("ADD") ||
                      estiamtePermission.includes("ADD")) ? (
                      <>
                        <div className="dropdown">
                          <div
                            // className="dropdown-toggle"
                            style={{
                              // border: "1px #E7EAEC solid",
                              border: "1px #838485 solid",

                              borderRadius: "3px",
                              padding: "0.375rem 0.75rem",
                              display: "flex",
                              justifyContent: "center",
                              color: "#676a6c",
                              alignItems: "center",
                              textDecoration: "none",
                              fontSize: "inherit",
                              cursor: "pointer",
                              marginRight: "6px",
                              fontWeight: "bold",
                            }}
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Convert to
                            <div className="dropdown-toggle ms-2"></div>
                          </div>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            {estiamtePermission.includes("ADD") ? (
                              <button
                                className="dropdown-item"
                                type="button"
                                eventKey="3"
                                onClick={() => {
                                  const { invoice_children, ...restdata } =
                                    rowdata;

                                  console.log(rowdata, restdata);
                                  navigate(`/estimates/add/`, {
                                    state: {
                                      ...restdata,
                                      // invoice_workflow_type: "ESTIMATE",
                                      invoice_id: id,
                                    },
                                  });
                                  console.log({
                                    ...restdata,
                                    // invoice_workflow_type: "ESTIMATE",
                                    invoice_id: id,
                                  });
                                }}
                              >
                                Estimate
                              </button>
                            ) : (
                              <></>
                            )}
                            {bookingPermission.includes("ADD") ? (
                              <button
                                className="dropdown-item"
                                type="button"
                                eventKey="3"
                                onClick={() => {
                                  const { invoice_children, ...restdata } =
                                    rowdata;

                                  console.log(rowdata, restdata);
                                  navigate(`/bookings/add/`, {
                                    state: {
                                      ...restdata,
                                      // invoice_workflow_type: "ESTIMATE",
                                      invoice_id: id,
                                    },
                                  });
                                  console.log({
                                    ...restdata,
                                    // invoice_workflow_type: "ESTIMATE",
                                    invoice_id: id,
                                  });
                                }}
                              >
                                Booking
                              </button>
                            ) : (
                              <></>
                            )}

                            {invoicePermission.includes("ADD") ? (
                              <button
                                className="dropdown-item"
                                type="button"
                                eventKey="3"
                                onClick={() => {
                                  const { invoice_children, ...restdata } =
                                    rowdata;
                                  mixpanel.track(
                                    `Convert to Invoice(Request Detail Web)`,
                                    {
                                      ...restdata,
                                      invoice_id: id,
                                    }
                                  );
                                  navigate(`/invoices/add/`, {
                                    state: {
                                      ...restdata,
                                      // invoice_workflow_type: "ESTIMATE",
                                      invoice_id: id,
                                    },
                                  });
                                  console.log({ ...restdata, invoice_id: id });
                                }}
                              >
                                Invoice
                              </button>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="dropdown">
                      {requestPermission.includes("EDIT") ||
                      requestPermission.includes("DELETE") ? (
                        <div
                          // className="dropdown-toggle"
                          style={{
                            // border: "1px #E7EAEC solid",
                            border: "1px #838485 solid",

                            borderRadius: "3px",
                            padding: "0.375rem 0.75rem",
                            display: "flex",
                            justifyContent: "center",
                            color: "#676a6c",
                            alignItems: "center",
                            textDecoration: "none",
                            fontSize: "inherit",
                            cursor: "pointer",
                            marginRight: "6px",
                            fontWeight: "bold",
                          }}
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          More
                          <div className="dropdown-toggle ms-2"></div>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        {requestPermission.includes("EDIT") ? (
                          <button
                            className="dropdown-item"
                            type="button"
                            eventKey="3"
                            onClick={() => {
                              mixpanel.track(`Edit(Request Detail Web)`);
                              navigate(
                                `/${workflow.type.toLowerCase()}s/edit/` + id,
                                {
                                  state: { ...rowdata, invoice_id: id },
                                }
                              );
                            }}
                          >
                            Edit
                          </button>
                        ) : (
                          <></>
                        )}
                        {/* <ReactToPrint
                          trigger={() => (
                            <button
                              className="dropdown-item"
                              type="button"
                              eventKey="1"
                            >
                              Print
                            </button>
                          )}
                          content={() => printableArea.current}
                        />
                        <a
                          className="dropdown-item"
                          type="button"
                          href={
                            "https://bestmate.us/invoice/view/download/" + id
                          }
                          // onClick={() =>
                          //   `https://bestmate.us/invoice/view/download/${id}`
                          // }
                          eventKey="2"
                        >
                          Download
                        </a> */}
                        {requestPermission.includes("DELETE") ? (
                          <button
                            className="dropdown-item"
                            type="button"
                            eventKey="3"
                            onClick={() => {
                              mixpanel.track(`Delete (Request Detail Web)`);
                              console.log("start");
                              setOpendeleteModals(true);
                              console.log("end");
                            }}
                          >
                            Delete
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        mixpanel.track(`Archive Request (Request Detail Web)`);
                        if (
                          rowdata.invoice_status !== "ARCHIVED" &&
                          requestPermission.includes("EDIT")
                        ) {
                          setOpenArchiveModal(true);
                        }
                      }}
                    >
                      <div
                        style={{
                          border: "1px #E7EAEC solid",
                          borderRadius: "3px",
                          padding: "0.375rem 0.75rem",
                          display: "flex",
                          justifyContent: "center",
                          color:
                            rowdata.invoice_status === "ARCHIVED"
                              ? "#666666"
                              : "white",
                          // backgroundColor: "#4f83cb",
                          backgroundColor:
                            rowdata.invoice_status === "ARCHIVED"
                              ? "#cccccc"
                              : "#407FEA",

                          alignItems: "center",
                          textDecoration: "none",
                          fontSize: "inherit",
                          cursor:
                            rowdata.invoice_status === "ARCHIVED" ||
                            !requestPermission.includes("EDIT")
                              ? "not-allowed"
                              : "pointer",
                          width: "120px",
                        }}
                      >
                        <Archive
                          size={15}
                          strokeWidth={2.5}
                          stroke={
                            rowdata.invoice_status === "ARCHIVED"
                              ? "#666666"
                              : "white"
                          }
                          style={{ marginRight: "8px" }}
                        />
                        {rowdata.invoice_status === "ARCHIVED"
                          ? "Archived"
                          : "Archive"}
                      </div>
                    </div>
                  </div>
                </Card>
                <div style={{ padding: "0rem 1.5rem 2rem 1.5rem" }}>
                  <Row>
                    <Col md={8}>
                      <Card
                        style={{
                          width: "100%",
                          height: "100%",
                          minHeight: 420,
                          backgroundColor: "white",
                        }}
                        className="border rounded-0"
                      >
                        <div
                          className="p-3"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h3 className="m-0">
                            {/* {`${rowdata.invoice_title} at
                          ${rowdata.invoice_partner.partner_first_name}
                          ${rowdata.invoice_partner.partner_last_name}

                          ${rowdata.invoice_partner.partner_first_name}
                          ${rowdata.invoice_partner.partner_last_name}`} */}
                            {`REQUEST #${rowdata.invoice_number}`}
                          </h3>

                          {/* <p
                            className="m-0"
                            style={{ fontSize: "16px" }}
                          >{`Job # ${rowdata.invoice_number}`}</p> */}
                          {requestPermission.includes("EDIT") ? (
                            <Edit2
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                // marginRight: 15,
                                position: "relative",
                                // top: 5,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                mixpanel.track(`Edit(Request Detail Web)`);
                                navigate(
                                  `/${workflow.type.toLowerCase()}s/edit/` + id,
                                  {
                                    state: { ...rowdata, invoice_id: id },
                                  }
                                );
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div
                          style={{
                            height: "30px",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="ps-3 m-0"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            CUSTOMER
                          </p>
                        </div>
                        <div>
                          <p
                            className="p-3 m-0"
                            style={{ fontSize: "16px", color: "#4F83CA" }}
                          >{`${rowdata.invoice_partner.partner_first_name}
                          ${rowdata.invoice_partner.partner_last_name}`}</p>
                        </div>
                        <hr className="m-0" />
                        <div className="p-3 ">
                          <Row>
                            <Col md={6}>
                              <div
                                className="d-flex"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  console.log(servAddress);
                                  if (servAddress !== "-") {
                                    mixpanel.track(
                                      `Service Address Partner (Request Detail Web)`
                                    );
                                    window.open(
                                      `https://www.google.com/maps?q=${servAddress}`
                                    );
                                  }
                                }}
                              >
                                <MapPin
                                  size={16}
                                  strokeWidth={2}
                                  stroke="#485056"
                                  style={{
                                    marginRight: 15,
                                    position: "relative",
                                    top: 5,
                                  }}
                                />
                                <div style={{ flex: 1, width: "90%" }}>
                                  <p className="m-0" style={{ fontSize: 11 }}>
                                    Address
                                  </p>

                                  <p
                                    style={{
                                      color:
                                        servAddress === "-"
                                          ? "#b5b3b3"
                                          : "black",
                                      whiteSpace: "pre-line",
                                    }}
                                  >
                                    {servAddress}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col
                              md={6}
                              style={{
                                borderLeft: "1px solid #D9DCDE",
                                paddingLeft: 20,
                              }}
                            >
                              <div
                                className="d-flex"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (rowdata.invoice_partner.partner_number) {
                                    mixpanel.track(
                                      `Phone Partner (Request Detail Web)`
                                    );
                                    window.location = `tel:${rowdata.invoice_partner.partner_number}`;
                                  }
                                }}
                              >
                                <Phone
                                  size={16}
                                  strokeWidth={2}
                                  stroke="#485056"
                                  style={{
                                    marginRight: 15,
                                    position: "relative",
                                    top: 5,
                                  }}
                                />
                                <div style={{ flex: 1, width: "90%" }}>
                                  <p className="m-0" style={{ fontSize: 11 }}>
                                    Phone No.
                                  </p>
                                  <p
                                    style={{
                                      color: rowdata.invoice_partner
                                        .partner_number
                                        ? "black"
                                        : "#b5b3b3",
                                      fontSize: rowdata.invoice_partner
                                        .partner_number
                                        ? "14px"
                                        : "12px",
                                    }}
                                  >
                                    {rowdata.invoice_partner.partner_number
                                      ? rowdata.invoice_partner.partner_number
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="d-flex"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (rowdata.invoice_partner.partner_email) {
                                    mixpanel.track(
                                      `Email Partner (Request Detail Web)`
                                    );
                                    window.location = `mailto:${rowdata.invoice_partner.partner_email}`;
                                  }
                                }}
                              >
                                <Mail
                                  size={16}
                                  strokeWidth={2}
                                  stroke="#485056"
                                  style={{
                                    marginRight: 15,
                                    position: "relative",
                                    top: 5,
                                  }}
                                />
                                <div style={{ flex: 1, width: "90%" }}>
                                  <p className="m-0" style={{ fontSize: 11 }}>
                                    Email
                                  </p>
                                  <p
                                    style={{
                                      color: rowdata.invoice_partner
                                        .partner_email
                                        ? "black"
                                        : "#b5b3b3",
                                      fontSize: rowdata.invoice_partner
                                        .partner_email
                                        ? "14px"
                                        : "12px",
                                    }}
                                  >
                                    {rowdata.invoice_partner.partner_email
                                      ? rowdata.invoice_partner.partner_email
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div
                          style={{
                            height: "5px",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* <p
                            className="ps-3 m-0"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            NOTES
                          </p> */}
                        </div>
                        {/* <div className="p-3">
                          <div className="d-flex">
                            <Clipboard
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                marginRight: 15,
                                position: "relative",
                                top: 5,
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <p className="m-0" style={{ fontSize: 11 }}>
                                Customer Notes

                              </p>
                              <p
                                style={{
                                  color: rowdata.invoice_notes
                                    ? "black"
                                    : "#b5b3b3",
                                  fontSize: rowdata.invoice_notes
                                    ? "14px"
                                    : "12px",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {rowdata.invoice_notes
                                  ? rowdata.invoice_notes
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex">
                            <Clipboard
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                marginRight: 15,
                                position: "relative",
                                top: 5,
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <p className="m-0" style={{ fontSize: 11 }}>
                                Internal Notes

                              </p>
                              <p
                                style={{
                                  color: rowdata.invoice_internal_notes
                                    ? "black"
                                    : "#b5b3b3",
                                  fontSize: rowdata.invoice_internal_notes
                                    ? "14px"
                                    : "12px",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {rowdata.invoice_internal_notes
                                  ? rowdata.invoice_internal_notes
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex">
                            <Clipboard
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                marginRight: 15,
                                position: "relative",
                                top: 5,
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <p className="m-0" style={{ fontSize: 11 }}>
                                Terms and Conditions
                              </p>
                              <p
                                style={{
                                  color: rowdata.invoice_terms_and_conditions
                                    ? "black"
                                    : "#b5b3b3",
                                  fontSize: rowdata.invoice_terms_and_conditions
                                    ? "14px"
                                    : "12px",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {rowdata.invoice_terms_and_conditions
                                  ? rowdata.invoice_terms_and_conditions
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </div> */}

                        {/* <div
                          style={{
                            height: "30px",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "end",
                          }}
                        >
                          <p
                            className="ps-3 m-0"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            JOB DETAILS
                          </p>
                        </div>
                        <div className="p-3">
                          <Row>
                            <Col md={6}>
                              <div className="d-flex">
                                <Edit2
                                  size={16}
                                  strokeWidth={3}
                                  stroke="grey"
                                  style={{
                                    marginRight: 10,
                                    position: "relative",
                                    top: 5,
                                  }}
                                />
                                <div style={{ flex: 1 }}>
                                  <p className="m-0" style={{ fontSize: 11 }}>
                                    Job Title
                                  </p>
                                  <p
                                    style={{
                                      color: rowdata.invoice_title
                                        ? "black"
                                        : "#b5b3b3",
                                      fontSize: rowdata.invoice_title
                                        ? "14px"
                                        : "12px",
                                    }}
                                  >
                                    {rowdata.invoice_title}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex">
                                <Edit
                                  size={16}
                                  strokeWidth={3}
                                  stroke="grey"
                                  style={{
                                    marginRight: 10,
                                    position: "relative",
                                    top: 5,
                                  }}
                                />
                                <div style={{ flex: 1 }}>
                                  <p className="m-0" style={{ fontSize: 11 }}>
                                    Job Description
                                  </p>
                                  <p
                                    style={{
                                      color: rowdata.invoice_description
                                        ? "black"
                                        : "#b5b3b3",
                                      fontSize: rowdata.invoice_description
                                        ? "14px"
                                        : "12px",
                                    }}
                                  >
                                    {rowdata.invoice_description
                                      ? rowdata.invoice_description
                                      : "N/A"}
                                  </p>
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="d-flex">
                                <Calendar
                                  size={16}
                                  strokeWidth={3}
                                  stroke="grey"
                                  style={{
                                    marginRight: 10,
                                    position: "relative",
                                    top: 5,
                                  }}
                                />
                                <div style={{ flex: 1 }}>
                                  <p className="m-0" style={{ fontSize: 11 }}>
                                    Date
                                  </p>
                                  <div
                                    className="d-flex"
                                    style={{ justifyContent: "space-between" }}
                                  >
                                    <p
                                      style={{
                                        color:
                                          rowdata.invoice_visits.length !== 0 &&
                                          rowdata.invoice_visits[0]
                                            .appointment_start_time
                                            ? "black"
                                            : "#b5b3b3",
                                        fontSize:
                                          rowdata.invoice_visits.length !== 0 &&
                                          rowdata.invoice_visits[0]
                                            .appointment_start_time
                                            ? "14px"
                                            : "12px",
                                      }}
                                    >
                                      {(rowdata.invoice_visits.length !== 0 &&
                                        rowdata.invoice_visits[0]
                                          .appointment_start_time &&
                                        new Date(
                                          rowdata.invoice_visits[0].appointment_start_time
                                        ).toDateString()) ||
                                        "N/A"}
                                    </p>
                                    <>
                                      {rowdata.invoice_status ===
                                      "CONVERTED" ? (
                                        <div
                                          style={{
                                            // width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <div
                                            style={{
                                              // backgroundColor: "#1a7bb8",
                                              backgroundColor: "#3f80ea",
                                              // padding: "4px",
                                              marginLeft: "5px",
                                              color: "white",
                                              borderRadius: "5px",
                                              marginBottom: "13.2px",
                                              fontSize: "10px",
                                              fontWeight: "bold",
                                              width: 75,
                                              textAlign: "center",
                                            }}
                                          >
                                            Invoiced
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          {rowdata.invoice_time_status ===
                                            "PAST" &&
                                          rowdata.invoice_visits &&
                                          rowdata.invoice_visits.length !==
                                            0 ? (
                                            <div
                                              style={{
                                                // width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: "#807e7f",
                                                  // padding: "4px",
                                                  marginBottom: "13.2px",
                                                  marginLeft: "5px",
                                                  color: "white",
                                                  borderRadius: "5px",
                                                  fontSize: "10px",
                                                  fontWeight: "bold",
                                                  width: 75,
                                                  textAlign: "center",
                                                }}
                                              >
                                                Past
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              {rowdata.invoice_time_status ===
                                                "UPCOMING" &&
                                              rowdata.invoice_visits &&
                                              rowdata.invoice_visits.length !==
                                                0 ? (
                                                <div
                                                  style={{
                                                    // width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      backgroundColor:
                                                        "#1AB395",
                                                      // padding: "4px",
                                                      color: "white",
                                                      marginBottom: "13.2px",
                                                      marginLeft: "5px",
                                                      borderRadius: "5px",
                                                      fontSize: "10px",
                                                      fontWeight: "bold",
                                                      width: 75,
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Upcoming
                                                  </div>
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    // width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      backgroundColor:
                                                        "#EC5667",
                                                      // padding: "4px",
                                                      color: "white",
                                                      marginLeft: "5px",
                                                      marginBottom: "13.2px",
                                                      borderRadius: "5px",
                                                      fontSize: "10px",
                                                      fontWeight: "bold",
                                                      width: 75,
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Unscheduled
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex">
                                <Clock
                                  size={16}
                                  strokeWidth={3}
                                  stroke="grey"
                                  style={{
                                    marginRight: 10,
                                    position: "relative",
                                    top: 5,
                                  }}
                                />
                                <div style={{ flex: 1 }}>
                                  <p className="m-0" style={{ fontSize: 11 }}>
                                    Time
                                  </p>
                                  <div className="d-flex">
                                    <p
                                      style={{
                                        color:
                                          rowdata.invoice_visits.length !== 0 &&
                                          rowdata.invoice_visits[0]
                                            .appointment_start_time
                                            ? "black"
                                            : "#b5b3b3",
                                        fontSize:
                                          rowdata.invoice_visits.length !== 0 &&
                                          rowdata.invoice_visits[0]
                                            .appointment_start_time
                                            ? "14px"
                                            : "12px",
                                      }}
                                    >
                                      {(rowdata.invoice_visits.length !== 0 &&
                                        rowdata.invoice_visits[0]
                                          .appointment_start_time &&
                                        moment(
                                          new Date(
                                            rowdata.invoice_visits[0].appointment_start_time
                                          )
                                        ).format("hh:mm a")) ||
                                        "N/A"}
                                    </p>
                                    <p>&nbsp; - &nbsp;</p>
                                    <p
                                      style={{
                                        color:
                                          rowdata.invoice_visits.length !== 0 &&
                                          rowdata.invoice_visits[0]
                                            .appointment_end_time
                                            ? "black"
                                            : "#b5b3b3",
                                        fontSize:
                                          rowdata.invoice_visits.length !== 0 &&
                                          rowdata.invoice_visits[0]
                                            .appointment_end_time
                                            ? "14px"
                                            : "12px",
                                      }}
                                    >
                                      {(rowdata.invoice_visits.length !== 0 &&
                                        rowdata.invoice_visits[0]
                                          .appointment_end_time &&
                                        moment(
                                          new Date(
                                            rowdata.invoice_visits[0].appointment_end_time
                                          )
                                        ).format("hh:mm a")) ||
                                        "N/A"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div> */}
                      </Card>
                    </Col>
                    <Col md={4} className="my-4 my-md-0">
                      <Card
                        style={{
                          width: "100%",
                          height: "100%",
                          minHeight: 420,
                          backgroundColor: "white",
                        }}
                        className="border rounded-0"
                      >
                        <div
                          style={{
                            height: 55,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginRight: 15,
                            marginLeft: 15,
                          }}
                        >
                          <p
                            className="m-0"
                            style={{ fontSize: "16px" }}
                          >{`REQUEST #${rowdata.invoice_number}`}</p>
                          {/* <div
                            style={{
                              backgroundColor: "#3f80ea",
                              borderRadius: "5px",
                              padding: "3px 10px",
                              display: "flex",
                              justifyContent: "center",
                              color: "white",
                              alignItems: "center",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              console.log("Asdf");
                              const { invoice_children, ...restdata } = rowdata;
                              navigate(`/invoices/add/`, {
                                state: {
                                  ...restdata,
                                  // invoice_workflow_type: "ESTIMATE",
                                  invoice_id: id,
                                },
                              });
                            }}
                          >
                            Convert to Invoice
                          </div> */}
                          <>
                            {/* {rowdata.invoice_status === "CONVERTED" ? (
                              <div
                                style={{
                                  // width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    // backgroundColor: "#1a7bb8",
                                    backgroundColor: "#3f80ea",
                                    padding: "4px",
                                    marginLeft: "5px",
                                    color: "white",
                                    borderRadius: "5px",
                                    // marginBottom: "13.2px",
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                    width: 75,
                                    textAlign: "center",
                                  }}
                                >
                                  Invoiced
                                </div>
                              </div>
                            ) : (
                              <>
                                {rowdata.invoice_time_status === "PAST" &&
                                rowdata.invoice_visits &&
                                rowdata.invoice_visits.length !== 0 ? (
                                  <div
                                    style={{
                                      // width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: "#807e7f",
                                        padding: "4px",
                                        // marginBottom: "13.2px",
                                        marginLeft: "5px",
                                        color: "white",
                                        borderRadius: "5px",
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                        width: 75,
                                        textAlign: "center",
                                      }}
                                    >
                                      Past
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    {rowdata.invoice_time_status ===
                                      "UPCOMING" &&
                                    rowdata.invoice_visits &&
                                    rowdata.invoice_visits.length !== 0 ? (
                                      <div
                                        style={{
                                          // width: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#71BE1D",
                                            padding: "4px",
                                            color: "white",
                                            // marginBottom: "13.2px",
                                            marginLeft: "5px",
                                            borderRadius: "5px",
                                            fontSize: "10px",
                                            fontWeight: "bold",
                                            width: 75,
                                            textAlign: "center",
                                          }}
                                        >
                                          Upcoming
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          // width: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "white",
                                            padding: "4px",
                                            color: "grey",
                                            border: "1px solid #71BE1D",
                                            borderRadius: "5px",
                                            fontSize: "10px",
                                            fontWeight: "bold",
                                            // width: 75,
                                            textAlign: "center",
                                          }}
                                        >
                                          Unscheduled
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            )} */}
                            {rowdata.invoice_status === "CONVERTED" ? (
                              <>
                                <div
                                  style={{
                                    // width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <div
                                    style={{
                                      // backgroundColor: "#1a7bb8",
                                      backgroundColor: "#3f80ea",
                                      padding: "4px",
                                      marginLeft: "5px",
                                      color: "white",
                                      borderRadius: "5px",
                                      // marginBottom: "13.2px",
                                      fontSize: "10px",
                                      fontWeight: "bold",
                                      width: 75,
                                      textAlign: "center",
                                    }}
                                  >
                                    Converted
                                  </div>
                                </div>
                              </>
                            ) : rowdata.invoice_status === "ARCHIVED" ? (
                              <div
                                style={{
                                  // width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#807e7f",
                                    padding: "4px",
                                    // marginBottom: "13.2px",
                                    marginLeft: "5px",
                                    color: "white",
                                    borderRadius: "5px",
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                    width: 75,
                                    textAlign: "center",
                                  }}
                                >
                                  Archived
                                </div>
                              </div>
                            ) : rowdata.invoice_partner_type === "LEAD" ? (
                              <div
                                style={{
                                  // width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#71BE1D",
                                    padding: "4px",
                                    color: "white",
                                    // marginBottom: "13.2px",
                                    marginLeft: "5px",
                                    borderRadius: "5px",
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                    width: 75,
                                    textAlign: "center",
                                  }}
                                >
                                  Lead
                                </div>
                              </div>
                            ) : rowdata.invoice_status === "OPEN" ? (
                              <div
                                style={{
                                  // width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "#DE5474",
                                    padding: "4px",
                                    color: "white",
                                    borderRadius: "5px",
                                    fontSize: "10px",
                                    fontWeight: "bold",
                                    width: 65,
                                    textAlign: "center",
                                  }}
                                >
                                  Open
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        </div>
                        {/* <div
                          style={{
                            height: "30px",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "end",
                          }}
                        >
                          <p
                            className="ps-3 m-0"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            ITEM DETAILS
                          </p>
                        </div>
                        <div>
                          {rowdata.invoice_order.length !== 0 ? (
                            <>
                              {rowdata.invoice_order.map((elem) => {
                                return (
                                  <>
                                    <div style={{}} className="p-3">
                                      <p className="m-0">{elem.item_name}</p>
                                      <div
                                        className="d-flex mt-2"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="d-flex">
                                          <p className="m-0">
                                            {elem.item_quantity}
                                          </p>
                                          <p className="m-0">&nbsp;X&nbsp;</p>
                                          <p className="m-0">
                                            {(
                                              (type === "SUPPLIER"
                                                ? elem.item_cost_price
                                                  ? elem.item_cost_price
                                                  : 0
                                                : elem.item_selling_price
                                                ? elem.item_selling_price
                                                : 0) * multiplier
                                            ).toLocaleString("en-US", {
                                              style: "currency",
                                              currency:
                                                rowdata &&
                                                rowdata.invoice_currency_code
                                                  ? rowdata.invoice_currency_code
                                                  : usercurrency,
                                            })}
                                          </p>
                                        </div>
                                        <p className="m-0">
                                          {(
                                            elem.item_quantity *
                                            (type === "SUPPLIER"
                                              ? elem.item_cost_price
                                                ? elem.item_cost_price
                                                : 0
                                              : elem.item_selling_price
                                              ? elem.item_selling_price
                                              : 0) *
                                            multiplier
                                          ).toLocaleString("en-US", {
                                            style: "currency",
                                            currency:
                                              rowdata &&
                                              rowdata.invoice_currency_code
                                                ? rowdata.invoice_currency_code
                                                : usercurrency,
                                          })}
                                        </p>
                                      </div>
                                    </div>
                                    <hr className="m-0" />
                                  </>
                                );
                              })}
                              <div
                                className="p-3 d-flex"
                                style={{ justifyContent: "space-between" }}
                              >
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Subtotal
                                </p>
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {subtotal.toLocaleString("en-US", {
                                    style: "currency",
                                    currency:
                                      rowdata && rowdata.invoice_currency_code
                                        ? rowdata.invoice_currency_code
                                        : usercurrency,
                                  })}
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <p className="p-3 m-0">
                     No Product or Service added to this Job.
                              </p>
                            </>
                          )}
                        </div> */}
                        <div
                          style={{
                            height: "30px",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="ps-3 m-0"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            REQUEST DETAILS
                          </p>
                        </div>

                        <div
                          className="p-3 pb-0"
                          style={
                            {
                              // backgroundColor: "green",
                              // wordWrap: "break-word",
                            }
                          }
                        >
                          <div className="d-flex">
                            <Briefcase
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                marginRight: 15,
                                position: "relative",
                                top: 5,
                              }}
                            />
                            <div
                              style={{
                                flex: 1,
                                width: "90%",
                              }}
                            >
                              <p className="m-0" style={{ fontSize: 11 }}>
                                Request Title
                              </p>
                              <p
                                style={{
                                  color: rowdata.invoice_title
                                    ? "black"
                                    : "#b5b3b3",
                                  fontSize: rowdata.invoice_title
                                    ? "14px"
                                    : "12px",

                                  wordWrap: "break-word",
                                }}
                              >
                                {rowdata.invoice_title}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex">
                            <Trello
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                marginRight: 15,
                                position: "relative",
                                top: 5,
                              }}
                            />
                            <div style={{ flex: 1, width: "90%" }}>
                              <p className="m-0" style={{ fontSize: 11 }}>
                                Request Description
                              </p>
                              <p
                                style={{
                                  color: rowdata.invoice_description
                                    ? "black"
                                    : "#b5b3b3",
                                  fontSize: rowdata.invoice_description
                                    ? "14px"
                                    : "12px",
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {rowdata.invoice_description
                                  ? rowdata.invoice_description
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <hr className="m-0" />

                          <div className="d-flex" style={{ marginTop: 15 }}>
                            <Calendar
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                marginRight: 15,
                                position: "relative",
                                top: 5,
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <p className="m-0" style={{ fontSize: 11 }}>
                                Starts
                              </p>
                              <div
                                className="d-flex"
                                // style={{ justifyContent: "space-between" }}
                              >
                                <p
                                  style={{
                                    color:
                                      rowdata.invoice_visits.length !== 0 &&
                                      rowdata.invoice_visits[0]
                                        .appointment_start_time
                                        ? "black"
                                        : "#b5b3b3",
                                    fontSize:
                                      rowdata.invoice_visits.length !== 0 &&
                                      rowdata.invoice_visits[0]
                                        .appointment_start_time
                                        ? "14px"
                                        : "12px",
                                  }}
                                >
                                  {(rowdata.invoice_visits.length !== 0 &&
                                    rowdata.invoice_visits[0]
                                      .appointment_start_time &&
                                    new Date(
                                      rowdata.invoice_visits[0].appointment_start_time
                                    ).toDateString()) ||
                                    "-"}
                                </p>
                                <p>&nbsp; - &nbsp;</p>
                                <p
                                  style={{
                                    color:
                                      rowdata.invoice_visits.length !== 0 &&
                                      rowdata.invoice_visits[0]
                                        .appointment_start_time
                                        ? "black"
                                        : "#b5b3b3",
                                    fontSize:
                                      rowdata.invoice_visits.length !== 0 &&
                                      rowdata.invoice_visits[0]
                                        .appointment_start_time
                                        ? "14px"
                                        : "12px",
                                  }}
                                >
                                  {(rowdata.invoice_visits.length !== 0 &&
                                    rowdata.invoice_visits[0]
                                      .appointment_start_time &&
                                    moment(
                                      new Date(
                                        rowdata.invoice_visits[0].appointment_start_time
                                      )
                                    ).format("hh:mm a")) ||
                                    "-"}
                                </p>
                                {/* <>
                                  {rowdata.invoice_status === "CONVERTED" ? (
                                    <div
                                      style={{
                                        // width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <div
                                        style={{
                                          // backgroundColor: "#1a7bb8",
                                          backgroundColor: "#3f80ea",
                                          padding: "4px",
                                          marginLeft: "5px",
                                          color: "white",
                                          borderRadius: "5px",
                                          marginBottom: "13.2px",
                                          fontSize: "10px",
                                          fontWeight: "bold",
                                          width: 75,
                                          textAlign: "center",
                                        }}
                                      >
                                        Invoiced
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {rowdata.invoice_time_status === "PAST" &&
                                      rowdata.invoice_visits &&
                                      rowdata.invoice_visits.length !== 0 ? (
                                        <div
                                          style={{
                                            // width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <div
                                            style={{
                                              backgroundColor: "#807e7f",
                                              padding: "4px",
                                              marginBottom: "13.2px",
                                              marginLeft: "5px",
                                              color: "white",
                                              borderRadius: "5px",
                                              fontSize: "10px",
                                              fontWeight: "bold",
                                              width: 75,
                                              textAlign: "center",
                                            }}
                                          >
                                            Past
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          {rowdata.invoice_time_status ===
                                            "UPCOMING" &&
                                          rowdata.invoice_visits &&
                                          rowdata.invoice_visits.length !==
                                            0 ? (
                                            <div
                                              style={{
                                                // width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: "#1AB395",
                                                  padding: "4px",
                                                  color: "white",
                                                  marginBottom: "13.2px",
                                                  marginLeft: "5px",
                                                  borderRadius: "5px",
                                                  fontSize: "10px",
                                                  fontWeight: "bold",
                                                  width: 75,
                                                  textAlign: "center",
                                                }}
                                              >
                                                Upcoming
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                // width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  backgroundColor: "#EC5667",
                                                  padding: "4px",
                                                  color: "white",
                                                  marginLeft: "5px",
                                                  marginBottom: "13.2px",
                                                  borderRadius: "5px",
                                                  fontSize: "10px",
                                                  fontWeight: "bold",
                                                  width: 75,
                                                  // padding
                                                  textAlign: "center",
                                                }}
                                              >
                                                Unscheduled
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </> */}
                              </div>
                            </div>
                          </div>

                          <div className="d-flex">
                            <Calendar
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                marginRight: 15,
                                position: "relative",
                                top: 5,
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <p className="m-0" style={{ fontSize: 11 }}>
                                Ends
                              </p>
                              <div className="d-flex">
                                <p
                                  style={{
                                    color:
                                      rowdata.invoice_visits.length !== 0 &&
                                      rowdata.invoice_visits[0]
                                        .appointment_start_time
                                        ? "black"
                                        : "#b5b3b3",
                                    fontSize:
                                      rowdata.invoice_visits.length !== 0 &&
                                      rowdata.invoice_visits[0]
                                        .appointment_start_time
                                        ? "14px"
                                        : "12px",
                                  }}
                                >
                                  {
                                    (rowdata.invoice_visits.length !== 0 &&
                                      rowdata.invoice_visits[0]
                                        .appointment_end_time &&
                                      new Date(
                                        rowdata.invoice_visits[0].appointment_end_time
                                      ).toDateString()) ||
                                      "-" /* {(rowdata.invoice_visits.length !== 0 &&
                                    rowdata.invoice_visits[0]
                                      .appointment_start_time &&
                                    moment(
                                      new Date(
                                        rowdata.invoice_visits[0].appointment_start_time
                                      )
                                    ).format("hh:mm a")) ||
                                    "-"} */
                                  }
                                </p>
                                <p>&nbsp; - &nbsp;</p>
                                <p
                                  style={{
                                    color:
                                      rowdata.invoice_visits.length !== 0 &&
                                      rowdata.invoice_visits[0]
                                        .appointment_end_time
                                        ? "black"
                                        : "#b5b3b3",
                                    fontSize:
                                      rowdata.invoice_visits.length !== 0 &&
                                      rowdata.invoice_visits[0]
                                        .appointment_end_time
                                        ? "14px"
                                        : "12px",
                                  }}
                                >
                                  {(rowdata.invoice_visits.length !== 0 &&
                                    rowdata.invoice_visits[0]
                                      .appointment_end_time &&
                                    moment(
                                      new Date(
                                        rowdata.invoice_visits[0].appointment_end_time
                                      )
                                    ).format("hh:mm a")) ||
                                    "-"}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* </Col> */}
                          {/* </Row> */}
                        </div>
                        {/* <div
                          style={{
                            height: "30px",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="ps-3 m-0"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            ITEM DETAILS
                          </p>
                        </div>
                        <div>
                          {rowdata.invoice_order.length !== 0 ? (
                            <>
                              {rowdata.invoice_order.map((elem) => {
                                return (
                                  <>
                                    <div style={{}} className="p-3">
                                      <p className="m-0">{elem.item_name}</p>
                                      <div
                                        className="d-flex mt-2"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="d-flex">
                                          <p className="m-0">
                                            {elem.item_quantity}
                                          </p>
                                          <p className="m-0">&nbsp;X&nbsp;</p>
                                          <p className="m-0">
                                            {(
                                              (type === "SUPPLIER"
                                                ? elem.item_cost_price
                                                  ? elem.item_cost_price
                                                  : 0
                                                : elem.item_selling_price
                                                ? elem.item_selling_price
                                                : 0) * multiplier
                                            ).toLocaleString("en-US", {
                                              style: "currency",
                                              currency:
                                                rowdata &&
                                                rowdata.invoice_currency_code
                                                  ? rowdata.invoice_currency_code
                                                  : usercurrency,
                                            })}
                                          </p>
                                        </div>
                                        <p className="m-0">
                                          {(
                                            elem.item_quantity *
                                            (type === "SUPPLIER"
                                              ? elem.item_cost_price
                                                ? elem.item_cost_price
                                                : 0
                                              : elem.item_selling_price
                                              ? elem.item_selling_price
                                              : 0) *
                                            multiplier
                                          ).toLocaleString("en-US", {
                                            style: "currency",
                                            currency:
                                              rowdata &&
                                              rowdata.invoice_currency_code
                                                ? rowdata.invoice_currency_code
                                                : usercurrency,
                                          })}
                                        </p>
                                      </div>
                                    </div>
                                    <hr className="m-0" />
                                  </>
                                );
                              })}
                              <div
                                className="p-3 d-flex"
                                style={{ justifyContent: "space-between" }}
                              >
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Subtotal
                                </p>
                                <p
                                  className="m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {subtotal.toLocaleString("en-US", {
                                    style: "currency",
                                    currency:
                                      rowdata && rowdata.invoice_currency_code
                                        ? rowdata.invoice_currency_code
                                        : usercurrency,
                                  })}
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <p className="p-3 m-0">
                                No Product or Service added to this Request.
                              </p>
                            </>
                          )}
                        </div> */}
                        {/* {rowdata.invoice_attachments &&
                        rowdata.invoice_attachments.length !== 0 ? (
                          <>
                            <div
                              style={{
                                height: "30px",
                                width: "100%",
                                backgroundColor: "#f5f5f5",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <p
                                className="ps-3 m-0"
                                style={{ fontSize: "11px", fontWeight: "bold" }}
                              >
                                PHOTOS & DOCUMENTS
                              </p>
                            </div>
                            <div className="p-3 pb-0">
                              {rowdata.invoice_attachments.map(
                                (elem, index) => {
                                  if (elem.type.includes("image")) {
                                    return (
                                      <div
                                        style={{
                                          paddingBottom: 10,
                                          paddingTop: 10,
                                          display: "flex",
                                          borderBottom: "1px solid #edebeb",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => window.open(elem.id)}
                                      >
                                        <div
                                          style={{
                                            height: 100,
                                            width: 100,
                                            backgroundColor: "#f5f5f5",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            src={elem.id}
                                            style={{
                                              maxWidth: "100%",
                                              maxHeight: "100%",
                                            }}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            padding: "5px 20px",
                                            whiteSpace: "pre-line",
                                          }}
                                        >
                                          {elem.description
                                            ? elem.description
                                            : `Image No #${index + 1}`}
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        style={{
                                          paddingBottom: 10,
                                          paddingTop: 10,
                                          display: "flex",
                                          borderBottom: "1px solid #edebeb",

                                          cursor: "pointer",
                                        }}
                                        onClick={() => window.open(elem.id)}
                                      >
                                        <div
                                          style={{
                                            height: 100,
                                            width: 100,
                                            backgroundColor: "#f5f5f5",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            style={{
                                              border: "1px solid grey",
                                              padding: 10,
                                              paddingRight: 15,
                                              paddingLeft: 15,
                                            }}
                                          >
                                            {elem.type
                                              .split("/")[1]
                                              .toUpperCase()}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            padding: "5px 20px",
                                            whiteSpace: "pre-line",
                                          }}
                                        >
                                          {elem.description
                                            ? elem.description
                                            : `Document No #${index + 1}`}
                                        </div>
                                      </div>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )} */}
                        <div
                          style={{
                            height: "30px",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="ps-3 m-0"
                            style={{ fontSize: "11px", fontWeight: "bold" }}
                          >
                            WORK
                          </p>
                        </div>
                        <div className="p-3 ">
                          <div className="d-flex">
                            <Codesandbox
                              size={16}
                              strokeWidth={2}
                              stroke="#485056"
                              style={{
                                marginRight: 15,
                                // position: "relative",
                                // top: 5,
                              }}
                            />
                            <div style={{ flex: 1 }}>
                              <p className="m-0" style={{ fontSize: 11 }}>
                                Used For
                              </p>
                              {rowdata.invoice_children &&
                              rowdata.invoice_children.length !== 0 ? (
                                <>
                                  {rowdata.invoice_children.map((elem) => (
                                    <p
                                      style={{
                                        margin: 0,
                                        padding: 2,
                                        // fontSize: 14,
                                        // marginLeft: 3,
                                        color: "#407fea",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        mixpanel.track(
                                          `Used for (${rowdata.invoice_workflow_type.toLowerCase()} Detail Web)`,
                                          {
                                            id: elem.invoice_id,
                                          }
                                        );
                                        if (
                                          elem.invoice_workflow_type ===
                                            "ORDER" &&
                                          bookingPermission
                                        ) {
                                          navigate(
                                            `/${
                                              elem.invoice_workflow_type ===
                                              "ORDER"
                                                ? "booking"
                                                : elem.invoice_workflow_type.toLowerCase()
                                            }s/${elem.invoice_id}`
                                          );
                                        }
                                        if (
                                          elem.invoice_workflow_type ===
                                            "REQUEST" &&
                                          requestPermission
                                        ) {
                                          navigate(
                                            `/${
                                              elem.invoice_workflow_type ===
                                              "ORDER"
                                                ? "booking"
                                                : elem.invoice_workflow_type.toLowerCase()
                                            }s/${elem.invoice_id}`
                                          );
                                        }
                                        if (
                                          elem.invoice_workflow_type ===
                                            "ESTIMATE" &&
                                          estiamtePermission
                                        ) {
                                          navigate(
                                            `/${
                                              elem.invoice_workflow_type ===
                                              "ORDER"
                                                ? "booking"
                                                : elem.invoice_workflow_type.toLowerCase()
                                            }s/${elem.invoice_id}`
                                          );
                                        }
                                        if (
                                          elem.invoice_workflow_type ===
                                            "INVOICE" &&
                                          invoicePermission
                                        ) {
                                          navigate(
                                            `/${
                                              elem.invoice_workflow_type ===
                                              "ORDER"
                                                ? "booking"
                                                : elem.invoice_workflow_type.toLowerCase()
                                            }s/${elem.invoice_id}`
                                          );
                                        }
                                      }}
                                    >{` ${
                                      elem.invoice_workflow_type === "ORDER"
                                        ? "Booking"
                                        : capitalizeFirstLetter(
                                            elem.invoice_workflow_type.toLowerCase()
                                          )
                                    } #${elem.invoice_number} `}</p>
                                  ))}
                                </>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: 0,
                                      padding: 2,
                                      // fontSize: 12,
                                      // marginLeft: 3,
                                      color: "#407fea",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Not Used
                                  </p>
                                  {/* <div
                                      style={{
                                        backgroundColor: "#3f80ea",
                                        borderRadius: "5px",
                                        padding: "3px 10px",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "white",
                                        alignItems: "center",
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        console.log("Asdf");
                                        const {
                                          invoice_children,
                                          ...restdata
                                        } = rowdata;
                                        navigate(`/invoices/add/`, {
                                          state: {
                                            ...restdata,
                                            // invoice_workflow_type: "ESTIMATE",
                                            invoice_id: id,
                                          },
                                        });
                                      }}
                                    >
                                      Convert to Invoice
                                    </div> */}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Container>

              <Modal
                show={openModals}
                onHide={() => {
                  mixpanel.track(`Close >>> send modal(Request Detail Web)`);
                  setOpenModals(!openModals);
                }}
                centered
                size={workflow.type === "Request" ? "md" : "lg"}
              >
                <Modal.Body style={{ padding: 0 }}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#f4f4f4",
                      height: 46,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "16px 24px",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "black",
                        margin: 0,
                      }}
                    >
                      Send {workflow.type}
                    </h1>
                    <div
                      onClick={() => {
                        mixpanel.track(
                          `Close >>> send modal(Request Detail Web)`
                        );
                        setOpenModals(!openModals);
                      }}
                    >
                      <X
                        size={24}
                        strokeWidth={2}
                        stroke="black"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div style={{ padding: "10px 24px 0 24px" }}>
                    <Row>
                      <Col lg={workflow.type === "Request" ? 12 : 6} xs={12}>
                        <Formik
                          validationSchema={schema}
                          onSubmit={(elem) => submitHandler(elem)}
                          initialValues={{
                            mail: rowdata.invoice_partner.partner_email,
                            subject: `You have new ${workflow.type} from ${
                              bdata && bdata.business_name
                                ? bdata.business_name
                                : bdata.business_user_name
                            }`,
                            sendcopytome: false,
                          }}
                          validateOnChange={false}
                          validateOnBlur={false}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                            dirty,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Recipient"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="mail"
                                  name="mail"
                                  placeholder="text"
                                  value={values.mail}
                                  onChange={handleChange}
                                  isInvalid={!!errors.mail}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.mail}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Subject"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  name="subject"
                                  placeholder="text"
                                  value={values.subject}
                                  onChange={handleChange}
                                  isInvalid={!!errors.subject}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.subject}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Body"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  name="text"
                                  placeholder="text"
                                  disabled
                                  value={`Hi ${
                                    rowdata.invoice_partner.partner_first_name
                                  } ${
                                    rowdata.invoice_partner.partner_last_name
                                  },\n\n${
                                    bdata && bdata.business_user_name
                                      ? bdata.business_user_name
                                      : ""
                                  } has scheduled a new booking with you.\n\n${
                                    rowdata.invoice_title
                                      ? `Booking Title:\n${rowdata.invoice_title}`
                                      : ""
                                  }\n\n${
                                    rowdata.invoice_visits.length !== 0 &&
                                    rowdata.invoice_visits[0]
                                      .appointment_start_time &&
                                    rowdata.invoice_visits[0]
                                      .appointment_end_time
                                      ? `Booking Date/Time:\n${moment(
                                          rowdata.invoice_visits[0]
                                            .appointment_start_time
                                        ).format(
                                          "MMM Do YYYY ddd h:mm a"
                                        )} - ${moment(
                                          rowdata.invoice_visits[0]
                                            .appointment_end_time
                                        ).format("h:mm a")}\n\n`
                                      : ""
                                  }${
                                    rowdata.invoice_partner
                                      .partner_first_name &&
                                    rowdata.invoice_partner.partner_last_name
                                      ? `Customer:\n${rowdata.invoice_partner.partner_first_name} ${rowdata.invoice_partner.partner_last_name}`
                                      : ""
                                  }\n\n${
                                    values.mail
                                      ? `Customer Email:\n${values.mail}`
                                      : ""
                                  }`}
                                  onChange={handleChange}
                                  as="textarea"
                                  // rows={3}
                                  style={{
                                    height: "300px",
                                    // borderColor: "#e8e8e8",
                                  }}

                                  // isInvalid={!!errors.mail}
                                />
                              </FloatingLabel>
                              <Form.Check
                                id="checkbox1"
                                label="Send copy to me"
                                name="sendcopytome"
                                style={{ fontSize: "15px" }}
                                onChange={handleChange}
                                type="checkbox"
                              />
                              <hr style={{ margin: 0 }} />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  margin: "7px 0",
                                }}
                              >
                                <button
                                  type="button"
                                  style={{
                                    marginRight: 5,
                                    padding: "5px 10px",
                                    backgroundColor: "white",
                                    borderWidth: 1,
                                  }}
                                  onClick={() => {
                                    mixpanel.track(
                                      `Close >>> send modal(Request Detail Web)`
                                    );
                                    setOpenModals(!openModals);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  style={{
                                    padding: "5px 10px",
                                    backgroundColor: "#3679e8",
                                    borderWidth: 0,
                                    color: "white",
                                    width: 80,
                                  }}
                                  // onClick={() => {
                                  //   setOpenModals(!openModals);
                                  // }}
                                >
                                  Send
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </Col>
                      <Col
                        lg={6}
                        className={`d-none ${
                          workflow.type === "Request" ? "" : "d-lg-block"
                        } p-0`}
                      >
                        <InvoiceTemplate data={rowdata} subtotal={subtotal} />
                      </Col>
                    </Row>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                show={opendeleteModals}
                onHide={() => {
                  mixpanel.track(`Close >>> Delete Modal(Request Detail Web)`);
                  setOpendeleteModals(false);
                }}
                centered
                size="sm"
              >
                <Modal.Body style={{ padding: 0 }}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#f4f4f4",
                      height: 46,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "16px 24px",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "black",
                        margin: 0,
                      }}
                    >
                      Delete {workflow.type}
                    </h1>
                    <div
                      onClick={() => {
                        mixpanel.track(
                          `Close >>> Delete Modal(Request Detail Web)`
                        );
                        setOpendeleteModals(false);
                      }}
                    >
                      <X
                        size={24}
                        strokeWidth={2}
                        stroke="black"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div style={{ padding: "10px 24px 0 24px" }}>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        // flexDirection: "column",
                        // alignItems: "center",
                      }}
                    >
                      <p
                        style={{ margin: "15px 0px 20px", textAlign: "center" }}
                      >
                        Are you sure you want to delete Request #
                        {rowdata.invoice_number}?
                      </p>
                    </div>

                    <hr style={{ margin: 0 }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "12px 0",
                      }}
                    >
                      <button
                        type="button"
                        style={{
                          marginRight: 5,
                          padding: "5px 10px",
                          backgroundColor: "white",
                          borderWidth: 1,
                        }}
                        onClick={() => {
                          mixpanel.track(
                            `Close >>> Delete Modal(Request Detail Web)`
                          );
                          setOpendeleteModals(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                          width: 80,
                        }}
                        disabled={disabledelete}
                        onClick={() => {
                          setOpendeleteModals(false);
                          deleteHandler(workflow.type);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                show={openarchiveModal}
                onHide={() => {
                  mixpanel.track(`Close >>> Archive Modal(Request Detail Web)`);
                  setOpenArchiveModal(false);
                }}
                centered
                size="sm"
              >
                <Modal.Body style={{ padding: 0 }}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#f4f4f4",
                      height: 46,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "16px 24px",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "black",
                        margin: 0,
                      }}
                    >
                      Archive {workflow.type}
                    </h1>
                    <div
                      onClick={() => {
                        mixpanel.track(
                          `Close >>> Archive Modal(Request Detail Web)`
                        );
                        setOpenArchiveModal(false);
                      }}
                    >
                      <X
                        size={24}
                        strokeWidth={2}
                        stroke="black"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div style={{ padding: "10px 24px 0 24px" }}>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        // flexDirection: "column",
                        // alignItems: "center",
                      }}
                    >
                      <p
                        style={{ margin: "15px 0px 20px", textAlign: "center" }}
                      >
                        Are you sure you want to Archive Request #
                        {rowdata.invoice_number}?
                      </p>
                    </div>

                    <hr style={{ margin: 0 }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "12px 0",
                      }}
                    >
                      <button
                        type="button"
                        style={{
                          marginRight: 5,
                          padding: "5px 10px",
                          backgroundColor: "white",
                          borderWidth: 1,
                        }}
                        onClick={() => {
                          mixpanel.track(
                            `Close >>> Archive Modal(Request Detail Web)`
                          );
                          setOpenArchiveModal(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                          width: 80,
                        }}
                        disabled={disableArchive}
                        onClick={async () => {
                          setDisableArchive(true);
                          try {
                            var user = firebase.auth().currentUser;

                            let tokenss = await user.getIdToken();
                            let data = {
                              ...rowdata,
                              invoice_id: id,
                              invoice_status: "ARCHIVED",
                            };
                            if (isTeamAccount) {
                              data.team = true;
                            } else {
                              delete data.team;
                            }
                            let response = await fetch(
                              "https://bestmate.us/api/invoices/update",
                              {
                                method: "POST",
                                headers: {
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                  Authorization: "Bearer " + tokenss,
                                },
                                body: JSON.stringify(data),
                              }
                            );
                            if (
                              response.status >= 200 &&
                              response.status <= 300
                            ) {
                              setRowdata({
                                ...rowdata,
                                invoice_id: id,
                                invoice_status: "ARCHIVED",
                              });
                              setOpenArchiveModal(false);
                              setDisableArchive(false);
                            }
                            // console.log(paidtotal);
                          } catch (err) {
                            setDisableArchive(false);
                          }
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                show={notesmodal}
                onHide={() => {
                  setCurtomernote(
                    rowdata.invoice_notes ? rowdata.invoice_notes : ""
                  );
                  setNotesModal(!notesmodal);
                }}
                centered
                size="sm"
              >
                <Modal.Body style={{ padding: 0 }}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#f4f4f4",
                      height: 46,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "16px 24px",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "black",
                        margin: 0,
                      }}
                    >
                      Edit Customer Notes
                    </h1>
                    <div
                      onClick={() => {
                        setCurtomernote(
                          rowdata.invoice_notes ? rowdata.invoice_notes : ""
                        );
                        setNotesModal(!notesmodal);
                      }}
                    >
                      <X
                        size={24}
                        strokeWidth={2}
                        stroke="black"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div style={{ padding: "10px 24px 0 24px" }}>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        flexDirection: "column",
                        // alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <p className="mb-0 font-weight-bold">Customer Notes</p>
                      <Form.Control
                        type="text"
                        name="invoice_notes"
                        as="textarea"
                        placeholder="Type Here"
                        style={{ height: "100px" }}
                        defaultValue={customernote}
                        onChange={(e) => setCurtomernote(e.target.value)}
                      />
                    </div>

                    <hr style={{ margin: 0 }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "7px 0",
                      }}
                    >
                      <button
                        type="button"
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                          width: 80,
                          marginRight: 10,
                        }}
                        onClick={() => {
                          setCurtomernote(
                            rowdata.invoice_notes ? rowdata.invoice_notes : ""
                          );
                          setNotesModal(!notesmodal);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                          width: 80,
                        }}
                        onClick={async () => {
                          try {
                            var user = firebase.auth().currentUser;

                            let tokenss = await user.getIdToken();
                            let data = {
                              ...rowdata,
                              invoice_id: id,
                              invoice_notes: customernote,
                            };
                            if (isTeamAccount) {
                              data.team = true;
                            } else {
                              delete data.team;
                            }
                            let response = await fetch(
                              "https://bestmate.us/api/invoices/update",
                              {
                                method: "POST",
                                headers: {
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                  Authorization: "Bearer " + tokenss,
                                },
                                body: JSON.stringify(data),
                              }
                            );
                            console.log({
                              ...rowdata,
                              invoice_id: id,
                              invoice_notes: customernote,
                            });
                            if (
                              response.status >= 200 &&
                              response.status <= 300
                            ) {
                              setRowdata({
                                ...rowdata,
                                invoice_id: id,
                                // invoice_payment_status: "PAID",
                                invoice_notes: customernote,
                              });

                              setNotesModal(!notesmodal);
                            }
                            // console.log(paidtotal);
                          } catch (err) {}
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                show={intnotesmodal}
                onHide={() => {
                  setInternalnote(
                    rowdata.invoice_internal_notes
                      ? rowdata.invoice_internal_notes
                      : ""
                  );
                  setIntNotesModal(!intnotesmodal);
                }}
                centered
                size="sm"
              >
                <Modal.Body style={{ padding: 0 }}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#f4f4f4",
                      height: 46,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "16px 24px",
                    }}
                  >
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "black",
                        margin: 0,
                      }}
                    >
                      Edit Internal Notes
                    </h1>
                    <div
                      onClick={() => {
                        setInternalnote(
                          rowdata.invoice_internal_notes
                            ? rowdata.invoice_internal_notes
                            : ""
                        );
                        setIntNotesModal(!intnotesmodal);
                      }}
                    >
                      <X
                        size={24}
                        strokeWidth={2}
                        stroke="black"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div style={{ padding: "10px 24px 0 24px" }}>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        flexDirection: "column",
                        // alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <p className="mb-0 font-weight-bold">Internal Notes</p>
                      <Form.Control
                        type="text"
                        name="invoic_internal_notes"
                        as="textarea"
                        placeholder="Type Here"
                        style={{ height: "100px" }}
                        defaultValue={internalnote}
                        onChange={(e) => setInternalnote(e.target.value)}
                      />
                    </div>

                    <hr style={{ margin: 0 }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: "7px 0",
                      }}
                    >
                      <button
                        type="button"
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                          width: 80,
                          marginRight: 10,
                        }}
                        onClick={() => {
                          setInternalnote(
                            rowdata.invoice_internal_notes
                              ? rowdata.invoice_internal_notes
                              : ""
                          );
                          setIntNotesModal(!intnotesmodal);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          padding: "5px 10px",
                          backgroundColor: "#3679e8",
                          borderWidth: 0,
                          color: "white",
                          width: 80,
                        }}
                        onClick={async () => {
                          try {
                            var user = firebase.auth().currentUser;

                            let tokenss = await user.getIdToken();
                            let data = {
                              ...rowdata,
                              invoice_id: id,
                              invoice_internal_notes: internalnote,
                            };
                            if (isTeamAccount) {
                              data.team = true;
                            } else {
                              delete data.team;
                            }
                            let response = await fetch(
                              "https://bestmate.us/api/invoices/update",
                              {
                                method: "POST",
                                headers: {
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                  Authorization: "Bearer " + tokenss,
                                },
                                body: JSON.stringify(data),
                              }
                            );
                            if (
                              response.status >= 200 &&
                              response.status <= 300
                            ) {
                              setRowdata({
                                ...rowdata,
                                invoice_id: id,
                                // invoice_payment_status: "PAID",
                                invoice_internal_notes: internalnote,
                              });
                              // setpaidtotal(rowdata.invoice_grand_total);
                              setIntNotesModal(!intnotesmodal);
                            }
                            // console.log(paidtotal);
                          } catch (err) {}
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          ) : (
            <Page404Authenticated />
          )}
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <Spinner animation="border" variant="dark" className="me-2" />
        </div>
      )}
      <Modal
        show={markaspaiddialog}
        onHide={() => {
          setmarkaspaiddialog(false);
          setpaidtotal(rowdata.invoice_grand_total);
        }}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Set Settled Amount
            </h1>
            <div
              onClick={() => {
                setmarkaspaiddialog(false);
                setpaidtotal(rowdata.invoice_grand_total);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                flexDirection: "column",
                // alignItems: "center",
                marginBottom: 20,
              }}
            >
              <p className="mb-0 font-weight-bold">Enter Settled Amount</p>
              <Form.Control
                type="number"
                // isInvalid={errors.invoice_settled_amount}
                // required
                name="invoice_settled_amount"
                min={0}
                // placeholder="100000"
                defaultValue={paidtotal}
                onChange={setpaidtotal}
              />
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "7px 0",
              }}
            >
              <button
                type="button"
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                  marginRight: 10,
                }}
                onClick={() => {
                  setmarkaspaiddialog(false);
                  setpaidtotal(rowdata.invoice_grand_total);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                onClick={async () => {
                  try {
                    var user = firebase.auth().currentUser;

                    let tokenss = await user.getIdToken();
                    let data = {
                      ...rowdata,
                      invoice_id: id,
                      invoice_settled_amount: Number(paidtotal),
                    };
                    if (isTeamAccount) {
                      data.team = true;
                    } else {
                      delete data.team;
                    }
                    let response = await fetch(
                      "https://bestmate.us/api/invoices/update",
                      {
                        method: "POST",
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + tokenss,
                        },
                        body: JSON.stringify(data),
                      }
                    );
                    if (response.status >= 200 && response.status <= 300) {
                      setRowdata({
                        ...rowdata,
                        invoice_id: id,
                        invoice_settled_amount: Number(paidtotal),
                      });
                      setmarkaspaiddialog(false);
                    }
                  } catch (err) {}
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={acceptdialog}
        onHide={() => {
          setacceptdialog(false);
        }}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Set Accept Status
            </h1>
            <div
              onClick={() => {
                setacceptdialog(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                flexDirection: "column",
                // alignItems: "center",
                marginBottom: 20,
              }}
            >
              <p
                className="mb-0 font-weight-bold"
                style={{ marginTop: 15, textAlign: "center" }}
              >
                Click confirm to accept Estimate.
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "7px 0",
              }}
            >
              <button
                type="button"
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                  marginRight: 10,
                }}
                onClick={() => {
                  setacceptdialog(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                onClick={async () => {
                  try {
                    var user = firebase.auth().currentUser;
                    let tokenss = await user.getIdToken();
                    let data = {
                      ...rowdata,
                      invoice_id: id,
                      invoice_status: "ACCEPTED",
                    };
                    if (isTeamAccount) {
                      data.team = true;
                    } else {
                      delete data.team;
                    }
                    let response = await fetch(
                      "https://bestmate.us/api/invoices/update",
                      {
                        method: "POST",
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + tokenss,
                        },
                        body: JSON.stringify({
                          ...rowdata,
                          invoice_id: id,
                          invoice_status: "ACCEPTED",
                        }),
                      }
                    );
                    if (response.status >= 200 && response.status <= 300) {
                      setRowdata({
                        ...rowdata,
                        invoice_id: id,
                        invoice_status: "ACCEPTED",
                      });
                      setacceptdialog(false);
                    }
                  } catch (err) {}
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RequestDetail;
