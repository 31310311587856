import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Card,
  Col,
  FloatingLabel,
  InputGroup,
  Row,
  Alert,
  Button,
  Form,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
// import { Alert, Button, Form } from "react-bootstrap";
import "../../assets/css/App.css";

import SignUp from "../../components/auth/SignUp";
import icon from "../../assets/img/illustrations/icon.png";
import google from "../../assets/img/icons/google.png";
import apple from "../../assets/img/icons/apple.png";
import worker from "../../assets/img/icons/worker.jpeg";
import useAuth from "../../hooks/useAuth";
import GoogleButton from "react-google-button";
import mixpanel from "mixpanel-browser";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { Eye, EyeOff, MapPin } from "react-feather";
import { logError } from "../../utils/useErrorlogging";

const SignInPage = () => {
  const navigate = useNavigate();
  const [rememberme, setRememberme] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const { signIn, signInWithGoogle, signInWithApple } = useAuth();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // if (isAuthenticated) {
  //   navigate("/dashboard");
  // }

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      if (!rememberme) {
        firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.SESSION)
          .then(async () => {
            await signInWithGoogle();
            navigate("/dashboard");
          })
          .catch((error) => {
            // mixpanel.track(`Error Sign In with Google (Sign In Web)`, {
            //   error: error.code,
            // });
            logError({
              error: error.code,
              component: `Error Sign In with Google (Sign In Web)`,
            });
          });
      } else {
        await signInWithGoogle();
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error.message);
      // mixpanel.track(`Error Sign In with Google (Sign In Web)`, {
      //   error: error.code,
      // });
      logError({
        error: error.code,
        component: `Error Sign In with Google (Sign In Web)`,
      });
    }
  };
  const handleAppleSignIn = async (e) => {
    e.preventDefault();
    try {
      if (!rememberme) {
        firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.SESSION)
          .then(async () => {
            await signInWithApple();
            navigate("/dashboard");
          })
          .catch((error) => {
            console.log(error);
            // mixpanel.track(`Error Sign In with Apple (Sign In Web)`, {
            //   error: error.code,
            // });
            logError({
              error: error.code,
              component: `Error Sign In with Apple (Sign In Web)`,
            });
          });
      } else {
        await signInWithApple();
        navigate("/dashboard");
      }
    } catch (error) {
      console.log(error.message);
      // mixpanel.track(`Error Sign In with Apple (Sign In Web)`, {
      //   error: error.code,
      // });
      logError({
        error: error.code,
        component: `Error Sign In with Apple (Sign In Web)`,
      });
    }
  };

  console.log("Signup");
  console.log(rememberme);
  return (
    <React.Fragment>
      <Helmet title="Sign In" />
      {/* <div className="text-center mt-4">
        <h2>Welcome back to BestMate</h2>
        <p className="lead">Sign in to your account to continue</p>
      </div>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                className="img-fluid rounded-circle"
                src={icon}
                alt="BestMate"
                width="132"
                height="132"
              />
            </div>
            <SignIn />
          </div>
        </Card.Body>
      </Card> */}
      <div
        id="authheader"
        style={{
          width: "100%",
          // height: "80px",
          backgroundColor: "white",
          borderBottom: "1px solid #ebebeb",
          // boxShadow: "0px 45px 10px -15px #111",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            maxWidth: "820px",
            // maxWidth: "1050px",
            margin: "auto",
            paddingRight: 20,
            paddingLeft: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 0,
              marginTop: 4,
            }}
          >
            <img
              src={icon}
              alt="BestMate"
              id="authheaderlogo"
              style={{ alignSelf: "center" }}
            />
            <strong id="authheadertext" style={{ fontWeight: "bolder" }}>
              BESTMATE
            </strong>
          </div>
          {/* <img src={icon} alt="BestMate" id="authheaderlogo" /> */}
          <a
            style={{
              color: "black",
              fontSize: "16px",
              margin: 0,
              fontWeight: "400",
            }}
            href="mailto:support@bestmate.us"
            onClick={() => mixpanel.track(`Help (Sign In Web)`)}
          >
            Help
          </a>
        </div>
      </div>
      {/* <div style={{ backgroundColor: "#f3f3f3" }}> */}
      <Row
        style={{
          marginRight: 0,
          paddingTop: 20,
          // paddingBottom: 20,
          backgroundColor: "white",
          // backgroundColor: "#f3f3f3",
          // height: "100vh",
          minHeight: "calc(100vh - 80px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col
          sm={6}
          // id="leftsigninbox"
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 0,
            borderRight: "1px solid grey",
            // borderRight: "1px solid #ebebeb",
          }}
        >
          {/* <div id="leftsigninbox" style={{ width: "100%" }}> */}
          <div
            style={{
              // height: 500,
              maxWidth: 500,
              width: "100%",
              // backgroundColor: "pink",
              padding: "30px 60px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <h2 style={{ fontWeight: "bold", color: "black" }}>Login</h2>
                <p style={{ margin: 0, color: "black", fontSize: 15 }}>
                  {/* Hi, Welcome back &#128075; */}
                  Welcome back!
                </p>
              </div>

              {/* <Link
                to="/sign-up"
                style={{
                  fontSize: 16,
                  color: "#75a8fa",
                  fontWeight: "bold",
                }}
              >
                Sign up
              </Link> */}
            </div>
            <button
              style={{
                width: "100%",
                border: "1px solid #c4c4c2",
                padding: 10,
                backgroundColor: "transparent",
                fontWeight: "500",
                marginTop: 20,
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
              }}
              onClick={(e) => {
                mixpanel.track(`Sign In with Google (Sign In Web)`);
                return handleGoogleSignIn(e);
              }}
            >
              <img
                src={google}
                alt="google"
                style={{ height: 20, marginRight: 5 }}
              />
              Sign in with Google
            </button>
            <button
              style={{
                width: "100%",
                border: "1px solid #c4c4c2",
                padding: 10,
                backgroundColor: "transparent",
                fontWeight: "500",
                marginTop: 10,
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
              }}
              onClick={(e) => {
                mixpanel.track(`Sign In with Apple (Sign In Web)`);
                return handleAppleSignIn(e);
              }}
            >
              <img
                src={apple}
                alt="apple"
                style={{ height: 18, marginRight: 8 }}
              />
              Sign in with Apple
            </button>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <hr style={{ width: "45%" }} />
              <p style={{ margin: 0 }}>OR</p>
              <hr style={{ width: "45%" }} />
            </div>
            <div style={{ marginTop: 20 }}>
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                  email: "",
                  password: "",
                  submit: false,
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Must be a valid email")
                    .required("Email is required"),
                  password: Yup.string().required("Password is required"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    // let token =
                    // "eyJhbGciOiJSUzI1NiIsImtpZCI6ImFlYzU4NjcwNGNhOTZiZDcwMzZiMmYwZDI4MGY5NDlmM2E5NzZkMzgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vYmVzdG1hdGUtYXBwIiwiYXVkIjoiYmVzdG1hdGUtYXBwIiwiYXV0aF90aW1lIjoxNzA3ODk3MjA1LCJ1c2VyX2lkIjoiY2NLaXpwdTZzYmJFYmZUcDJWSjRaem11eGE1MiIsInN1YiI6ImNjS2l6cHU2c2JiRWJmVHAyVko0WnptdXhhNTIiLCJpYXQiOjE3MDc4OTcyMDUsImV4cCI6MTcwNzkwMDgwNSwiZW1haWwiOiJkZW1vQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJlbWFpbCI6WyJkZW1vQGdtYWlsLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.HGxhnLvmjnpBECUWWqtEU5TsAT45nzJlYo6FCOsxwxhv8B9j0G1oTXzHYujRlMGJXCIwzO6XTKxtL6GK-D8YUDtSS_BmvHAe3xCFBv914UhYzuaUWjuW9aWOHgy3Uqw75JsijQCemt-E3UmjGtJTtKvr3fVPYunlW5I0P-s0usOzBh1Fhw8QctwLz6U2--_OFTkuh6Pn5ulHTQr50HNbirNA7BM47LxUDffFSOEVIiY6F4N6jJY1kD3iYpoftUqf9kgCWLjSvoCF1JVZpFb4xR-Jci7N6Nofd_kIxXoiAjF3Svak9rnvcNpAoO8NtjRrslrF1H4FDStUVokIkqbAsw";
                    // "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTcwNzg5NjUyMywiZXhwIjoxNzA3OTAwMTIzLCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay1pZTh4bEBiZXN0bWF0ZS1hcHAuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJzdWIiOiJmaXJlYmFzZS1hZG1pbnNkay1pZTh4bEBiZXN0bWF0ZS1hcHAuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJ1aWQiOiJ4Z016UkpuMEZWZVRDOTRpaFQwSTNKMGE2MUUzIn0.Rlyu4ztwhLpt6e6F_Hkf8TqoDdjynmRtMacP7VrHj9zorIBQsGGzJCKpGW0G-RL7j1d6R3JZSSRVY43heNnc17YiKio8NiX9QpXhSAmPT3mUnS3yZeH4jf-YGsA3po607WHD3xQpYYuyg6sR4vYhmTy8Rb79o7MeN4xe0M7hCDIJ4ex62LLKN7DdFr-b5Cv7VL9vU-lFVkPR0W5ebL-7AHPf5ZpBivJBMs7IeDCL-dF4NnXYAJCvTik4MVvxS8ME8kVyhMmt6kAZph8qeZ7X60_pxOzp8adjTDmjgdLBpWlpnE0MEjYkRiwfVCrD09xbYKWwLPPV0YGhdsMZKul4kg";
                    if (!rememberme) {
                      firebase
                        .auth()
                        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
                        .then(async () => {
                          await signIn(values.email, values.password);
                          // await firebase.auth().signInWithCustomToken(token);
                          navigate("/dashboard");
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    } else {
                      await signIn(values.email, values.password);
                      // await firebase.auth().signInWithCustomToken(token);
                      navigate("/dashboard");
                    }
                  } catch (error) {
                    console.log(error);
                    // mixpanel.track(`Error Sign In with Email (Sign In Web)`, {
                    //   error: error.code,
                    // });
                    logError({
                      error: error.code,
                      component: `Error Sign In with Email (Sign In Web)`,
                    });
                    if (error.code === "auth/user-not-found") {
                      setErrors({
                        submit:
                          "No user found with this email. Please sign up.",
                      });
                    } else if (error.code === "auth/network-request-failed") {
                      setErrors({
                        submit:
                          "A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred",
                      });
                    } else if (error.code === "auth/invalid-email") {
                      setErrors({
                        submit: "Invalid email address. Please try again.",
                      });
                    } else if (error.code === "auth/user-disabled") {
                      setErrors({
                        submit: "No user found with this email.",
                      });
                    } else if (error.code === "auth/wrong-password") {
                      setErrors({
                        submit: "Incorrect password. Please try again.",
                      });
                    } else if (error.code === "auth/too-many-requests") {
                      setErrors({
                        submit:
                          "Too many unsuccessful login attempts. Please try again later.",
                      });
                    } else if (error.code === "auth/operation-not-allowed") {
                      setErrors({
                        submit: "This sign-in method is not allowed.",
                      });
                    } else {
                      setErrors({ submit: "Something went wrong" });
                    }
                    setStatus({ success: false });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  validateForm,
                }) => (
                  <Form
                    noValidate
                    onSubmit={async (e) => {
                      e.preventDefault();
                      const obj = await validateForm();
                      if (Object.keys(obj).length !== 0) {
                        // mixpanel.track(
                        //   `Error Sign In with Email (Sign In Web)`,
                        //   {
                        //     error: obj,
                        //   }
                        // );
                        logError({
                          error: obj,
                          component: `Error Sign In with Email (Sign In Web)`,
                        });
                      }
                      handleSubmit();
                    }}
                  >
                    {errors.submit && (
                      <Alert className="my-3" variant="danger">
                        <div className="alert-message">{errors.submit}</div>
                      </Alert>
                    )}

                    {/* <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        size="lg"
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={values.email}
                        isInvalid={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group> */}
                    {/* <FloatingLabel
                      controlId="floatingInput"
                      label="Email"
                      className="mb-3"
                    > */}
                    <Form.Group style={{ marginBottom: 10 }}>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        size="lg"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        isInvalid={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        style={{
                          borderColor: "black",
                          borderRadius: 5,
                          borderColor: "#c4c4c2",
                        }}
                      />
                      {!!touched.email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    {/* </FloatingLabel> */}
                    {/* <FloatingLabel
                      controlId="floatingInput"
                      label="Password"
                      className="mb-3"
                    > */}
                    <Form.Group style={{ marginBottom: 10 }}>
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <Form.Control
                          size="lg"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          value={values.password}
                          isInvalid={Boolean(
                            touched.password && errors.password
                          )}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor: "black",
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5,
                            borderColor: "#c4c4c2",
                          }}
                          className="password_field"
                        />
                        <InputGroup.Text
                          style={{
                            borderTopRightRadius: 5,
                            borderBottomRightRadius: 5,
                            cursor: "pointer",
                            width: 50,
                            display: "flex",
                            justifyContent: "center",
                          }}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <EyeOff
                              size={15}
                              strokeWidth={2}
                              stroke="#485056"
                            />
                          ) : (
                            <Eye size={15} strokeWidth={2} stroke="#485056" />
                          )}
                        </InputGroup.Text>
                        {!!touched.password && (
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        )}
                      </InputGroup>
                    </Form.Group>

                    {/* </FloatingLabel> */}

                    {/* <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        size="lg"
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                        value={values.password}
                        isInvalid={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.password && (
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      )}
                      <small>
                        <Link to="/reset-password">Forgot password?</Link>
                      </small>
                    </Form.Group> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Check
                        type="checkbox"
                        id="rememberMe"
                        label="Remember me"
                        defaultChecked
                        value={rememberme}
                        onChange={(e) => {
                          setRememberme(!rememberme);
                        }}
                      />
                      <p style={{ fontSize: 13, marginBottom: 5 }}>
                        <Link
                          to="/reset-password"
                          onClick={() =>
                            mixpanel.track(`Forgot password (Sign In Web)`)
                          }
                        >
                          Forgot password?
                        </Link>
                      </p>
                    </div>

                    <div className="text-center mt-3">
                      <Button
                        type="submit"
                        variant="primary"
                        size="lg"
                        disabled={isSubmitting}
                        style={{
                          width: "100%",
                          borderRadius: 5,
                        }}
                      >
                        Sign in
                      </Button>
                      <p style={{ margin: 0, marginTop: 10, fontSize: 11 }}>
                        By clicking "Sign in" you agree to Bestmate's{" "}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.open(
                              "https://bestmate.us/terms-of-services.html",
                              "_blank"
                            );
                          }}
                        >
                          Terms of Service
                        </span>{" "}
                        and{" "}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            window.open(
                              "https://bestmate.us/privacy.html",
                              "_blank"
                            );
                          }}
                        >
                          Privacy Policy
                        </span>
                      </p>
                    </div>

                    {/* <div align="center">
                      <GoogleButton
                        className="g-btn"
                        type="dark"
                        onClick={handleGoogleSignIn}
                      />
                    </div> */}
                  </Form>
                )}
              </Formik>
              <p className="mt-3" style={{ textAlign: "center" }}>
                Don't have an account ?{" "}
                <Link
                  to="/sign-up"
                  onClick={() => mixpanel.track(`Sign Up (Sign In Web)`)}
                >
                  Sign up
                </Link>
              </p>
            </div>
            {/* </div> */}
          </div>
        </Col>
        <Col
          sm={6}
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 0,
          }}
        >
          <div
            style={{
              // height: 500,
              maxWidth: 500,
              width: "100%",
              paddingRight: 20,
              paddingLeft: 20,
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              flexDirection: "column",
              // backgroundColor: "red",
            }}
          >
            <img
              src={worker}
              alt="BestMate"
              style={{ width: "70%", alignSelf: "center" }}
            />
            {/* <h4 style={{ color: "black", marginTop: 30 }}>
              Manage your business with Bestmate
            </h4>
            <p style={{ color: "black", marginTop: 0 }}>
              Bestmate all-in-one app powers your entire business. Invoice,
              Estimate, Schedules, Payments, Inventory, & Customers Management
              (CRM)
            </p> */}
            <div style={{ textAlign: "center", marginTop: 30 }}>
              <h2 style={{ fontSize: "2rem", color: "black" }}>
                Manage. Organize.
              </h2>
              <h2 style={{ fontSize: "2rem", color: "#086AD8" }}>
                Grow your Business.
              </h2>
              <p style={{ color: "black", marginTop: 20 }}>
                Invoices | Estimates | Online Payments | Scheduling & Bookings |
                Manage Customers & Suppliers | Inventory
              </p>
            </div>
          </div>
        </Col>
      </Row>
      {/* </div> */}
    </React.Fragment>
  );
};

export default SignInPage;
