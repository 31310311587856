import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Container,
  Card,
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Alert,
  Modal,
  Spinner,
  Breadcrumb,
} from "react-bootstrap";
import { User, X, ChevronUp, ChevronDown, AlertCircle } from "react-feather";
import * as Yup from "yup";
import "../assets/css/App.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Selectmodal from "./Selectmodal";
import Newpartnermodal from "./Newpatnermodal";
import NotyfContext from "../contexts/NotyfContext";
import { useNavigate } from "react-router-dom";
import useSidebar from "../hooks/useSidebar";
import mixpanel from "mixpanel-browser";
import useAuth from "../hooks/useAuth";
import { logError } from "../utils/useErrorlogging";

function truncateTextByWordCount(text, wordLimit) {
  console.log(text.length);
  if (text.length > wordLimit) {
    return text.slice(0, wordLimit) + "...";
  } else {
    console.log(text);
    return text;
  }
}

const Editschedulemodal = (props) => {
  console.log(props);
  const [savemodalloading, setSavemodalloading] = useState(false);
  const [savemodalopen, setsavemodalopen] = useState("first");
  const [globalValidationError, setGlobalValidationError] = useState(false);
  const [partners, setPartners] = useState(
    props.selectedEvent ? props.selectedEvent.appointment_partner : null
  );
  const [disablesave, setDisablesave] = useState(false);
  const [openModals, setOpenModals] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchterm, setsearchtem] = useState("");
  const [token, settoken] = useState("");
  const [modloading, setModloading] = useState(true);
  const [createnewmodal, setCreatenewmodal] = useState(false);
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const navigate = useNavigate();
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const { isTeamAccount, userPermission } = useAuth();
  let schedulePermission =
    Object.keys(userPermission).includes("schedule") &&
    Object.keys(userPermission.schedule).includes("permission_key")
      ? userPermission.schedule.permission_key
      : "";
  let partnerPermission =
    Object.keys(userPermission).includes("partner") &&
    Object.keys(userPermission.partner).includes("permission_key")
      ? userPermission.partner.permission_key
      : "";
  const [teamMember, setTeamMember] = useState(
    props.selectedEvent && props.selectedEvent.appointment_assignee
      ? props.selectedEvent.appointment_assignee
      : {}
  );

  useEffect(() => {
    // if (props.selecteddate) {
    //   let Start = props.selecteddate.startStr
    //     ? props.selecteddate.startStr
    //     : "";
    //   console.log(dateStart);
    //   let End = props.selecteddate.endStr ? props.selecteddate.endStr : "";
    //   // console.log(dateStart, dateEnd);
    //   // let startTime = "";
    //   // let endTime = "";
    //   if (Start.length > 10) {
    //     setStartTime(Start.match(/\d\d:\d\d/)[0]);
    //     setEndTime(End.match(/\d\d:\d\d/)[0]);
    //     setDateStart(Start.substring(0, 10));
    //     setDateEnd(End.substring(0, 10));
    //   } else {
    //     setStartTime("00:00");
    //     setEndTime("00:00");
    //     setDateStart(Start.substring(0, 10));
    //     setDateEnd(End.substring(0, 10));
    //   }
    // } else {
    //   setStartTime("00:00");
    //   setEndTime("00:00");
    //   setDateStart("");
    //   setDateEnd("");
    // }
    // console.log("sasdfa");
    // console.log(props);
    if (props.selectedEvent) {
      setPartners(props.selectedEvent.appointment_partner);
      let trackstart = new Date(props.selectedEvent.appointment_start_time);
      let trackend = new Date(props.selectedEvent.appointment_end_time);
      let start = new Date(
        props.selectedEvent.appointment_start_time
      ).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
      if (start.startsWith("24")) {
        setStartTime("00" + start.slice(2));
      } else {
        setStartTime(start);
      }
      let end = new Date(
        props.selectedEvent.appointment_end_time
      ).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
      if (end.startsWith("24")) {
        setEndTime("00" + end.slice(2));
      } else {
        setEndTime(end);
      }
      setDateStart(trackstart.toLocaleDateString("sv"));
      setDateEnd(trackend.toLocaleDateString("sv"));
    }
  }, [props]);
  useEffect(() => {
    if (props.createnewmodal) {
      setDisablesave(false);
      if (
        props.selectedEvent &&
        Object.keys(props.selectedEvent).includes("appointment_assignee")
      ) {
        setTeamMember(props.selectedEvent.appointment_assignee);
      } else {
        setTeamMember({});
      }
    } else {
      setTeamMember({});
    }
  }, [props.createnewmodal]);
  const handleKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };
  const UpdateAppointment = async (UpdatedData) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      console.log(UpdatedData);
      if (isTeamAccount) {
        UpdatedData.team = true;
      } else {
        delete UpdatedData.team;
      }
      if (teamMember && Object.keys(teamMember).length !== 0) {
        UpdatedData.appointment_assignee_uid = teamMember.team_member_uid;
        UpdatedData.appointment_assignee_ref_id =
          teamMember.team_member_reference_id;
        UpdatedData.appointment_assignee = teamMember;
      }
      fetch("https://bestmate.us/api/appointments/update", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(UpdatedData),
      })
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            // props.fetchData("0", "1672511400000", props.token);
            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Event updated successfully",
              ripple: "true",
              dismissible: "true",
            });
            setsavemodalopen("first");
            //   props.setSelecteddate(null);
            props.setCreatenewmodal(false);
            setGlobalValidationError(false);
            props.setLoading(true);
            // setDisablesave(false);
            // props.setEventsData([]);
            // navigate("/schedule");
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: `Something went wrong.`,
              ripple: "true",
              dismissible: "true",
            });
            setsavemodalopen("first");
            //   props.setSelecteddate(null);
            props.setCreatenewmodal(false);
            setGlobalValidationError(false);
            setDisablesave(false);
            // mixpanel.track(`Fetch Error (Schedule Edit Modal Web)`, {
            //   error: "schedule edit api",
            // });
            logError({
              error: "fetch error",
              api: "schedule edit api",
              component: "Schedule Edit Modal Web",
            });
          }
        })
        .catch((err) => {
          setDisablesave(false);
          console.log(err);
          // mixpanel.track(`Fetch Error (Schedule Edit Modal Web)`, {
          //   error: err.message,
          //   stack: err.stack,
          // });
          logError({
            error: "fetch error",
            api: "schedule edit api",
            component: "Schedule Edit Modal Web",
          });
        });
    }
  };
  const DeleteAppointment = async (Id) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      let dat = {
        appointment_id: Id,
      };
      if (isTeamAccount) {
        dat.team = true;
      } else {
        delete dat.team;
      }
      fetch("https://bestmate.us/api/appointments/delete", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenss,
        },
        body: JSON.stringify(dat),
      })
        .then((res) => {
          if (res.status >= 200 && res.status <= 300) {
            notyf.open({
              type: isOpen ? "home" : "full",
              message: "Event deleted successfully",
              ripple: "true",
              dismissible: "true",
            });
            setsavemodalopen("first");
            //   props.setSelecteddate(null);
            props.setCreatenewmodal(false);
            setGlobalValidationError(false);
            props.setLoading(true);
            setDisablesave(false);
            // props.setEventsData([]);
            // navigate("/schedule");
          } else {
            notyf.open({
              type: isOpen ? "homewarning" : "fullwarning",
              message: `Something went wrong.`,
              ripple: "true",
              dismissible: "true",
            });
            setDisablesave(false);
            // mixpanel.track(`Fetch Error (Schedule Edit Modal Web)`, {
            //   error: "schedule delete api",
            // });
            logError({
              error: "fetch error",
              api: "schedule delete api",
              component: "Schedule Edit Modal Web",
            });
          }
        })
        .catch((err) => {
          setDisablesave(false);
          console.log(err);
          alert("Something went Wrong");
          // mixpanel.track(`Error (Schedule Edit Modal Web)`, {
          //   error: err.message,
          //   stack: err.stack,
          // });
          logError({
            error: err.message,
            stack: err.stack,
            component: "Schedule Edit Modal Web",
          });
        });
    }
  };

  const fetchPartners = async () => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;

    if (user) {
      let tokenss = await user.getIdToken();
      fetch(
        "https://bestmate.us/api/partners/findAll" +
          `${isTeamAccount ? "?team=true" : ""}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenss,
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            let data = [
              {
                label: "Customer",
                options: [],
              },
              {
                label: "Supplier",
                options: [],
              },
              {
                label: "Lead",
                options: [],
              },
            ];
            result.map((elem) => {
              const tempData = {
                label: `${elem.partner_first_name} ${elem.partner_last_name}`,
                value: elem,
              };
              if (elem.partner_type === "CUSTOMER") {
                data["0"].options.push(tempData);
              } else if (elem.partner_type === "SUPPLIER") {
                data["1"].options.push(tempData);
              } else if (elem.partner_type === "LEAD") {
                data["2"].options.push(tempData);
              }
            });
            // console.log(data);
            setOptions(data);
            setModloading(false);
            // props.setLoading(false);
          },
          (error) => {
            console.error("Error fetching data: ", error);
          }
        );
    }
    // });
  };
  console.log(props.selectedEvent);
  console.log(partners);

  return (
    <>
      <Modal
        show={props.createnewmodal}
        onHide={() => {
          mixpanel.track("Close (Edit Schedule Modal Web)");
          setsavemodalopen("first");
          props.setSelectedEvent(null);
          props.setCreatenewmodal(false);
          setGlobalValidationError(false);
        }}
        centered
        size="md"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 56,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 20,
                color: "black",
                margin: 0,
                wordBreak: "break-word",
              }}
            >
              {`Edit
              ${props.type.charAt(0).toUpperCase() + props.type.slice(1)}
              with ${
                partners !== null
                  ? truncateTextByWordCount(partners.partner_first_name, 30)
                  : ""
              }`}
            </h1>
            <div
              onClick={() => {
                mixpanel.track("Close (Edit Schedule Modal Web)");
                setsavemodalopen("first");
                props.setSelectedEvent(null);
                props.setCreatenewmodal(false);
                setGlobalValidationError(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          {props.selectedEvent === null ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "550px" }}
            >
              <Spinner animation="border" variant="dark" className="me-2" />
            </div>
          ) : (
            <div style={{ padding: "10px 24px" }}>
              <Formik
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={Yup.object().shape({
                  appointment_name: Yup.string().required("Name is required"),
                  appointment_partner: Yup.string().required(
                    "Partner is required"
                  ),
                  appointment_start_date: Yup.date().required(
                    "Start Date is required"
                  ),
                  appointment_end_date: Yup.date()
                    .min(
                      Yup.ref("appointment_start_date"),
                      "End date can't be before start date"
                    )
                    .required("End Date is required"),
                  appointment_start_time: Yup.string().required(
                    "Start Time is required"
                  ),
                  appointment_end_time: Yup.string()
                    .required("End Time is required")
                    .test(
                      "is-start-date-time-valid",
                      "Start date and time must be before end date and time",
                      function (value) {
                        const {
                          appointment_start_date,
                          appointment_start_time,
                          appointment_end_date,
                        } = this.parent;
                        console.log(
                          appointment_start_date,
                          appointment_start_time,
                          appointment_end_date
                          // appointment_end_time
                        );

                        if (
                          appointment_start_date &&
                          !isNaN(appointment_start_date) &&
                          appointment_end_date &&
                          !isNaN(appointment_end_date) &&
                          appointment_start_time &&
                          value
                        ) {
                          const [shours, sminutes] = appointment_start_time
                            .split(":")
                            .map(Number);

                          appointment_start_date.setHours(
                            shours,
                            sminutes,
                            0,
                            0
                          );

                          const stimeInMilliseconds =
                            appointment_start_date.getTime();

                          console.log(stimeInMilliseconds);

                          const [ehours, eminutes] = value
                            .split(":")
                            .map(Number);

                          appointment_end_date.setHours(ehours, eminutes, 0, 0);

                          const etimeInMilliseconds =
                            appointment_end_date.getTime();

                          console.log(etimeInMilliseconds, value);

                          console.log(
                            stimeInMilliseconds < etimeInMilliseconds
                          );
                          return stimeInMilliseconds < etimeInMilliseconds;
                        } else {
                          console.log("not passing");
                          return false;
                        }
                      }
                    ),
                })}
                onSubmit={(values) => {
                  setDisablesave(true);

                  // console.log(values);
                  console.log("ghhghg");
                  console.log(values);
                  const UpdatedData = JSON.parse(JSON.stringify(values));
                  UpdatedData["appointment_partner"] = partners;
                  // UpdatedData["appointment_partner_id"] = partners.partner_id;
                  UpdatedData["appointment_zone_offset"] = -(
                    new Date(
                      UpdatedData.appointment_start_date
                    ).getTimezoneOffset() * 60
                  );
                  var Start_Str = UpdatedData["appointment_start_date"].concat(
                    " ",
                    UpdatedData["appointment_start_time"]
                  );
                  var End_Str = UpdatedData["appointment_end_date"].concat(
                    " ",
                    UpdatedData["appointment_end_time"]
                  );
                  UpdatedData["appointment_start_time"] = new Date(
                    Start_Str
                  ).getTime();
                  UpdatedData["appointment_end_time"] = new Date(
                    End_Str
                  ).getTime();
                  UpdatedData["appointment_reminders"] = values.reminder
                    ? [
                        {
                          value: 1,
                        },
                      ]
                    : [];
                  delete UpdatedData["appointment_start_date"];
                  delete UpdatedData["appointment_end_date"];
                  delete UpdatedData["reminder"];

                  //   UpdatedData["appointment_invoice_grand_total"] =
                  //     props.selectedEvent["appointment_invoice_grand_total"];
                  //   UpdatedData["appointment_invoice_discount_rate"] =
                  //     props.selectedEvent["appointment_invoice_discount_rate"];
                  //   UpdatedData["appointment_invoice_order"] =
                  //     props.selectedEvent["appointment_invoice_order"];
                  //   UpdatedData["appointment_invoice_tax_rate"] =
                  //     props.selectedEvent["appointment_invoice_tax_rate"];
                  UpdatedData["appointment_id"] =
                    props.selectedEvent["appointment_id"];
                  UpdatedData["appointment_business_id"] =
                    props.selectedEvent["appointment_business_id"];

                  console.log(UpdatedData);
                  mixpanel.track("Update Schedule (Edit Schedule Modal Web)", {
                    ...UpdatedData,
                  });
                  UpdateAppointment(UpdatedData);
                }}
                initialValues={{
                  //   appointment_name: "",
                  //   appointment_description: "",
                  //   appointment_notes: "",
                  //   appointment_start_date: dateStart,
                  //   appointment_end_date: dateEnd,
                  //   appointment_start_time: startTime,
                  //   appointment_end_time: endTime,
                  //   appointment_partner: "",
                  //   appointment_send_confirmation: false,
                  //   reminder: false,
                  appointment_name: props.selectedEvent.appointment_name,
                  appointment_description:
                    props.selectedEvent.appointment_description,
                  appointment_notes: props.selectedEvent.appointment_notes,
                  appointment_start_date: dateStart,
                  appointment_end_date: dateEnd,
                  appointment_start_time: startTime,
                  appointment_end_time: endTime,
                  appointment_partner:
                    props.selectedEvent.appointment_partner.partner_first_name +
                    " " +
                    props.selectedEvent.appointment_partner.partner_last_name,
                  appointment_send_confirmation: props.selectedEvent
                    .appointment_send_confirmation
                    ? props.selectedEvent.appointment_send_confirmation
                    : false,
                  reminder:
                    props.selectedEvent.appointment_reminders &&
                    props.selectedEvent.appointment_reminders.length !== 0
                      ? true
                      : false,
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  handleReset,
                  setFieldValue,
                  validateForm,
                }) => (
                  <Form
                    noValidate
                    onSubmit={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const obj = await validateForm();
                      console.log(obj);
                      if (Object.keys(obj).length !== 0) {
                        setGlobalValidationError(true);
                      } else {
                        setGlobalValidationError(false);
                        handleSubmit();
                      }
                    }}
                    // onKeyDown={(keyEvent) => {
                    //   if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    //     keyEvent.preventDefault();
                    //   }
                    // }}
                  >
                    {errors.submit && (
                      <Alert className="my-3" variant="danger">
                        <div className="alert-message">{errors.submit}</div>
                      </Alert>
                    )}
                    <div
                      className="createnewhover"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                        // backgroundColor: "pink",
                        padding: "10px 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => setsavemodalopen("first")}
                    >
                      {/* <div>{`${
                        props.type.charAt(0).toUpperCase() + props.type.slice(1)
                      } Details`}</div> */}
                      <div>{`Customer & Event Details`}</div>
                      {savemodalopen === "first" ? (
                        <ChevronUp
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ChevronDown
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                    {savemodalopen === "first" ? (
                      <>
                        <div style={{ margin: "5px 0" }}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Customer"
                            className="mb-3"
                            onClick={() => {
                              if (
                                partnerPermission &&
                                !partnerPermission.includes("LIMITED")
                              ) {
                                mixpanel.track(
                                  "Select Partner (Edit Schedule Modal Web)"
                                );
                                setModloading(true);
                                fetchPartners();
                                setOpenModals(true);
                              }
                            }}
                          >
                            <Form.Control
                              // disabled={true}
                              type="text"
                              onKeyDown={handleKeyDown}
                              name="appointment_partner"
                              placeholder="text"
                              value={values.appointment_partner}
                              isInvalid={errors.appointment_partner}
                              style={{
                                backgroundColor: "white",
                                cursor:
                                  partnerPermission &&
                                  !partnerPermission.includes("LIMITED")
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                              // onChange={handleChange}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.appointment_partner}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Event Name "
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              onKeyDown={handleKeyDown}
                              isInvalid={errors.appointment_name}
                              name="appointment_name"
                              placeholder="text"
                              value={values.appointment_name}
                              onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.appointment_name}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Event Description "
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              as="textarea"
                              isInvalid={errors.appointment_description}
                              name="appointment_description"
                              placeholder="text"
                              value={values.appointment_description}
                              onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.appointment_description}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Internal Notes "
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              as="textarea"
                              isInvalid={errors.appointment_notes}
                              name="appointment_notes"
                              placeholder="text"
                              value={values.appointment_notes}
                              onChange={handleChange}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.appointment_notes}
                            </Form.Control.Feedback>
                          </FloatingLabel>
                          {schedulePermission.includes("ALL") ? (
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Team Member"
                              className="mb-3"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="category"
                                onKeyDown={handleKeyDown}
                                onChange={(event) => {
                                  const selectedId = event.target.value;
                                  if (selectedId) {
                                    const selectedMember =
                                      props.teamMemberList.find((member) => {
                                        console.log(
                                          member,
                                          member.team_member_email,
                                          selectedId,
                                          event
                                        );
                                        return (
                                          member.team_member_email ===
                                          selectedId
                                        );
                                      });
                                    console.log(selectedMember);
                                    setTeamMember(selectedMember);
                                  } else {
                                    setTeamMember({});
                                  }
                                }}
                                value={teamMember.team_member_email}
                              >
                                <option
                                  disabled={
                                    Object.keys(teamMember).length === 0
                                      ? false
                                      : true
                                  }
                                  value={""}
                                >
                                  Select Team Member
                                </option>

                                {props.teamMemberList.map((elem) => (
                                  <option value={elem.team_member_email}>
                                    {elem.team_member_name}
                                  </option>
                                ))}
                              </Form.Select>
                            </FloatingLabel>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <hr style={{ margin: 0 }} />

                    <div
                      className="createnewhover"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        cursor: "pointer",
                        padding: "10px 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => setsavemodalopen("second")}
                    >
                      <div>Event Date & Time</div>
                      {savemodalopen === "second" ? (
                        <ChevronUp
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ChevronDown
                          size={24}
                          strokeWidth={2}
                          stroke="#3679e8"
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                    {savemodalopen === "second" ? (
                      <>
                        <div style={{ margin: "5px 0" }}>
                          <Row className="g-2">
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Start Date "
                                className="mb-3"
                              >
                                <Form.Control
                                  type="date"
                                  className="calendar-input"
                                  onKeyDown={handleKeyDown}
                                  isInvalid={errors.appointment_start_date}
                                  required
                                  name="appointment_start_date"
                                  placeholder="text"
                                  value={values.appointment_start_date}
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.appointment_start_date}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="Start Time "
                                className="mb-3"
                              >
                                <Form.Control
                                  type="time"
                                  onKeyDown={handleKeyDown}
                                  className="calendar-input"
                                  isInvalid={errors.appointment_start_time}
                                  name="appointment_start_time"
                                  placeholder="text"
                                  value={values.appointment_start_time}
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.appointment_start_time}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <Row className="g-2">
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="End Date "
                                className="mb-3"
                              >
                                <Form.Control
                                  type="date"
                                  onKeyDown={handleKeyDown}
                                  className="calendar-input"
                                  isInvalid={errors.appointment_end_date}
                                  required
                                  name="appointment_end_date"
                                  placeholder="text"
                                  value={values.appointment_end_date}
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.appointment_end_date}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                            <Col md>
                              <FloatingLabel
                                controlId="floatingInput"
                                label="End Time "
                                className="mb-3"
                              >
                                <Form.Control
                                  type="time"
                                  className="calendar-input"
                                  onKeyDown={handleKeyDown}
                                  isInvalid={errors.appointment_end_time}
                                  name="appointment_end_time"
                                  placeholder="text"
                                  value={values.appointment_end_time}
                                  onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.appointment_end_time}
                                </Form.Control.Feedback>
                              </FloatingLabel>
                            </Col>
                          </Row>
                        </div>
                        <div style={{ padding: "0 0px", marginBottom: 10 }}>
                          <Card.Title className="mb-0">
                            Email Communication
                          </Card.Title>
                          {/* </Card.Header> */}
                          {/* <Card.Body> */}
                          <Row
                            className="g-2"
                            style={{ padding: "0 5px", marginTop: 1 }}
                          >
                            <Form.Check
                              id="checkbox1"
                              label="Send confirmation email to customer"
                              name="appointment_send_confirmation"
                              style={{ fontSize: "15px" }}
                              onChange={handleChange}
                              type="checkbox"
                              defaultChecked={
                                values.appointment_send_confirmation
                              }
                            />
                          </Row>
                          <Row className="g-2" style={{ padding: "0 5px" }}>
                            <Form.Check
                              id="checkbox2"
                              name="reminder"
                              label="Send reminder email to customer (one day before)"
                              style={{ fontSize: "15px" }}
                              onChange={handleChange}
                              value={1}
                              type="checkbox"
                              defaultChecked={values.reminder}
                            />
                          </Row>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <hr style={{ margin: 0 }} />
                    {globalValidationError ? (
                      <div
                        style={{
                          display: "flex",
                          marginTop: 10,
                          alignItems: "center",
                        }}
                      >
                        <AlertCircle
                          size={15}
                          strokeWidth={2}
                          stroke="#d8534e"
                        />
                        <div
                          style={{
                            color: "#d8534e",
                            fontSize: 11,
                            fontWeight: "400",
                            marginLeft: 5,
                          }}
                        >
                          There was an error saving the form. Please check the
                          messages above and try again.
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                      }}
                    >
                      {schedulePermission.includes("DELETE") ? (
                        <button
                          type="button"
                          style={{
                            marginRight: 5,
                            padding: "5px 10px",
                            backgroundColor: "white",
                            borderWidth: 1,
                            color: "red",
                            borderColor: "red",
                          }}
                          onClick={() => {
                            mixpanel.track("Delete (Edit Schedule Modal Web)", {
                              id: props.selectedEvent.appointment_id,
                            });
                            setDisablesave(true);
                            // setsavemodalopen("first");
                            // props.setSelectedEvent(null);
                            // props.setCreatenewmodal(false);
                            // setGlobalValidationError(false);
                            DeleteAppointment(
                              props.selectedEvent.appointment_id
                            );
                          }}
                          disabled={disablesave}
                        >
                          Delete
                        </button>
                      ) : (
                        <div></div>
                      )}
                      <div>
                        <button
                          type="button"
                          style={{
                            marginRight: 5,
                            padding: "5px 10px",
                            backgroundColor: "white",
                            borderWidth: 1,
                          }}
                          onClick={() => {
                            mixpanel.track("Close (Edit Schedule Modal Web)");
                            setsavemodalopen("first");
                            props.setSelectedEvent(null);
                            props.setCreatenewmodal(false);
                            setGlobalValidationError(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          style={{
                            padding: "5px 10px",
                            backgroundColor: "#3679e8",
                            borderWidth: 0,
                            color: "white",
                          }}
                          type="submit"
                          disabled={disablesave}
                        >
                          {`Update ${
                            props.type.charAt(0).toUpperCase() +
                            props.type.slice(1)
                          }`}
                        </button>
                      </div>
                    </div>
                    <Selectmodal
                      type={"customer"}
                      partner={""}
                      pricelist={""}
                      index={0}
                      openModals={openModals}
                      setOpenModals={setOpenModals}
                      modloading={modloading}
                      searchterm={searchterm}
                      setsearchtem={setsearchtem}
                      fetchPriceList={() => {}}
                      setsavemodalopen={() => {}}
                      setCreatenewmodal={setCreatenewmodal}
                      options={options}
                      setFieldValue={setFieldValue}
                      setPartners={setPartners}
                      data={{}}
                      setData={() => {}}
                    />
                    <Newpartnermodal
                      type={"customer"}
                      createnewmodal={createnewmodal}
                      setCreatenewmodal={setCreatenewmodal}
                      setFieldValue={setFieldValue}
                      setPartners={setPartners}
                      data={{}}
                      setData={() => {}}
                      // setCreatenewselectedplist={setCreatenewselectedplist}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Editschedulemodal;
